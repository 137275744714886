import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class CandidatesGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      candidatesData: props.candidatesData,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const candidatesData = props.candidatesData;
    state.candidatesData = candidatesData;
    return state;
  }

  render() {
    return (
      <tr>
        <th scope="row">
          <small>{this.state.candidatesData.userNameOrEmail}</small>
        </th>
        <th scope="row">
          <small>{this.state.candidatesData.remarks}</small>
        </th>
        <th scope="row">
          <small>{this.state.candidatesData.category}</small>
        </th>
        <th scope="row">
          <small>{this.state.candidatesData.offeredTerm}</small>
        </th>
        <th scope="row">
          <small>{this.state.candidatesData.status}</small>
        </th>
      </tr>
    );
  }
}

export default withRouter(CandidatesGridLine);
