import React, { Component } from "react";
import { withRouter } from "react-router-dom";


class OtherLeaves extends Component {
    render() {
        return (
            <>
                <h3 className={"pages-mainheader-text"}> Other Leaves </h3>
                <label></label>
                <p className={"pages-content-text"}>To provide leaves to Employees in case of death of their closed one and to attend funeral or any accidental event.</p>
                <label></label>
                <p>
                    <li className={"pages-content-text"}>An Employee is entitled for 2 working days of Bereavement leaves in case of death of immediate family members (spouse, child, parent or sibling).</li>
                    <li className={"pages-content-text"} >An Employee is entitled for 1 working day of Bereavement leave in case of extended family members’ (grandparent, cousin, aunt, or uncle) </li>
                    <li className={"pages-content-text"} >Employee will be fully paid by the Company.</li>
                    <li className={"pages-content-text"} >This leave should be taken immediately after the unfortunate event. This leave cannot be saved for later use.</li>
                    
                    
                </p>
                
            </>
        )

    }
          
        

    
}
export default withRouter(OtherLeaves);