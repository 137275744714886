import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  illustration,
  greeting,
  socialMediaLinks,
} from "../content/PageContent";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DisplayLottie from "../splashScreen/displayLottie/DisplayLottie";
import landingPerson from "../../../assets/lottie/menandwomen.json";
import Business from "../business/Business";
import { COOKIE_PREF } from "../../../config/Config";

import oneone from "../../../assets/images/img/about/1.jpg";
import onetwo from "../../../assets/images/img/about/2.jpg";
import onethree from "../../../assets/images/img/about/3.jpg";
import onefour from "../../../assets/images/img/about/4.jpg";
import teamone from "../../../assets/images/img/team/1.jpg";
import teamtwo from "../../../assets/images/img/team/2.jpg";
import teamthree from "../../../assets/images/img/team/3.jpg";
import kannaiyan from "../../../assets/images/img/team/kannaiyanPic.jpg";
import venkat from "../../../assets/images/img/team/venkat.jpg";
import priya from "../../../assets/images/img/team/gokulapriya.jpg";
import dipthi from "../../../assets/images/img/team/Dipthigadekar.jpg";
import divya from "../../../assets/images/img/team/DivyaPaiyannan.jpg";
import esther from "../../../assets/images/img/team/estherjenita.jpg";
import gokulan from "../../../assets/images/img/team/Gokulan.jpg";
//import lalitha from "../../../assets/images/img/team/lalithambigai.jpg";
//import meiyarasan from "../../../assets/images/img/team/Meiyarasan.jpg";
import pavithra from "../../../assets/images/img/team/Pavithra.jpg";
//import pramodh from "../../../assets/images/img/team/pramodh.jpg";
import rajappan from "../../../assets/images/img/team/RajappanSellamuthu.jpg";
import suneel from "../../../assets/images/img/team/Suneelmunthala.jpg";
import varshasri from "../../../assets/images/img/team/varshasri.jpg";
import maheshkumar from "../../../assets/images/img/team/MaheshKumar.jpg";
import venu from "../../../assets/images/img/team/venu.jpg";
import ezhil from "../../../assets/images/img/team/EzhilArasi.R.jpeg";
import hemalatha from "../../../assets/images/img/team/Hemalatha.jpeg";
import rupavarshini from "../../../assets/images/img/team/Rupavarshini.jpg";
import annalakshmi from "../../../assets/images/img/team/Annalakshmi.jpg";

class Team extends Component {
  state = {
    showCookieModal: false,
    showChooseCookieModal: false,
    cookieRequest: {
      socialMedia: false,
      essential: false,
      commercial: false,
      fullCookie: false,
    },
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.title = "Home page";
  }
  componentDidUpdate() {
    if (this.props.notFound === true) {
      this.props.showAlert("Page not found ! Back to the home page.", "error");
    }
  }

  render() {
    return (
      <>
       <br></br>
       <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Our Management Team</h2>
                    <h3 class="section-subheading text-muted">Many hands make light work.</h3>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={kannaiyan} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Kannaiyan S</h4>
                            <p class="text-muted">CEO</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/kannaiyan-s" aria-label="Parveen Anand LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={venkat} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Venkatesan Vadivel</h4>
                            <p class="text-muted">Technical Architect </p> <a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="https://www.linkedin.com/in/venkatesan-vadivel/" aria-label="Diana Petersen LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={priya} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>GokulaPriya Sivaraj</h4>
                            <p class="text-muted">Human Resource</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/gokulapriya-hr" aria-label="Larry Parker LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Our Technical Team</h2>
                </div>
                <div class="row">
                <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={ezhil} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>EzhilArasi R</h4>
                            <p class="text-muted">Senior Consultant</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/ezhil-arasi0898" aria-label="Parveen Anand LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={divya} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Divya Paiyannan</h4>
                            <p class="text-muted">Senior Consultant</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/16-divya" aria-label="Parveen Anand LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={rajappan} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Rajappan S</h4>
                            <p class="text-muted">Senior Consultant</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/rajappan-sellamuthu-5742b2204" aria-label="Diana Petersen LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={pavithra} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Pavithra</h4>
                            <p class="text-muted">Consultant</p> <a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/pavithra--g" aria-label="Larry Parker LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={hemalatha} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Hemalatha</h4>
                            <p class="text-muted">Consultant</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="https://www.linkedin.com/in/hemalatha-p-643a73281" aria-label="Larry Parker LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={varshasri} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>varshasri</h4>
                            <p class="text-muted">Tech Lead L1</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/varsha-sri-bb4034146" aria-label="Parveen Anand LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={esther} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Esther Jenita</h4>
                            <p class="text-muted">Tech Lead L1</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/esther-jenita" aria-label="Diana Petersen LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={gokulan} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Gokulan</h4>
                            <p class="text-muted">Consultant</p> <a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="https://www.linkedin.com/in/g%C3%B5k%C3%BAl-gd-410b34212" aria-label="Larry Parker LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                  {/*   <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={dipthi} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Dipthi Gadekar</h4>
                            <p class="text-muted">Backend Developer</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="https://www.linkedin.com/in/dipti-gadekar-930146223" aria-label="Parveen Anand LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>*/}
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={suneel} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Suneel Munthala</h4>
                            <p class="text-muted">Senior Consultant</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/suneel-munthala" aria-label="Diana Petersen LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={venu} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Venu Regula</h4>
                            <p class="text-muted">Consultant</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="https://www.linkedin.com/in/venu-regula-497a82201" aria-label="Diana Petersen LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={rupavarshini} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Rupavarshini</h4>
                            <p class="text-muted">Tech Lead L1</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="http://www.linkedin.com/in/rupa-varshni" aria-label="Larry Parker LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={maheshkumar} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Maheshkumar</h4>
                            <p class="text-muted">Senior Consultant</p>
                            <a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="https://www.linkedin.com/in/mahesh-kumar-70b879227" aria-label="Larry Parker LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                   
                    <div class="col-lg-3">
                        <div class="team-member">
                            <img class="mx-auto rounded-circle" src={annalakshmi} alt="..." style={{ maxWidth: 256 }}/>
                            <h4>Annalakshmi</h4>
                            <p class="text-muted">Tech Lead L1</p><a class="btn btn-dark btn-social mx-auto d-flex align-items-center" href="https://www.linkedin.com/in/meiyarasan-a-72551a1a5" aria-label="Larry Parker LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-lg-8 mx-auto text-center"><p class="large text-muted">The aim of Yaazhtech is to translate your brainstorming ideas to a product or mobile app (Android/IOs), as well as to improve business flow quality through BPM process automation..</p></div>
                </div>
            </div>
      </>
    );
  }
}

export default withRouter(Team);
