import React, { Component } from "react";
import { cancelPolicy } from "../content/PrivacyContent";
import { Fade } from "react-reveal";

class CancellationPolicy extends Component {
    render() {
        return (

            <div className="container">
            <br></br>
            <div className="main contact-margin-top" id="contact">
                <div className="contact-div-main">
                    <div className="contact-header">
                    <label>Web app services cancellation policy</label> 
                    
                    <h1 className={"pages-mainheader-text"}>{cancelPolicy.cancelPolicyTitle}</h1>
                    <p className={"pages-content-text"}>{cancelPolicy.cancelPolicyContent} </p>
                    <p className={"pages-content-text"} >{cancelPolicy.cancelPolicyContent1}</p>
                    <p className={"pages-content-text"} >{cancelPolicy.cancelPolicyContent2}</p>
                    <p className={"pages-content-text"}>{cancelPolicy.cancelPolicyContent3} </p>
                    <p className={"pages-content-text"}>{cancelPolicy.cancelPolicyContent4} </p>
                    <p className={"pages-content-text"}>{cancelPolicy.cancelPolicyContent5} </p>
                    

                </div>
            </div>
        </div>
        <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          
    </div>
            
            
        
           
           
        )
    }
}
export default CancellationPolicy;