import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Alert } from "reactstrap";

import Backend from "./backend/Backend";
import Frontend from "./frontend/Frontend";
import FullStack from "./fullstack/FullStack";
import Flexplm from "./flexplm/Flexplm";
import Iam from "./iam/Iam";

class ProfilesDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
    };

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  componentDidMount() {
    document.title = "Profiles dashboard";

    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 2000);
    });
  };
  render() {
    let render;
    let menuItem;
    if (this.props.match.params.render === "backend") {
      render = <Backend showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/profiles/backend" className="nav-link active">
            Backend
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/profiles/frontend" className="nav-link">
            Frontend
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/profiles/fullstack" className="nav-link">
            FullStack
          </Link>
        </NavItem>,
         <NavItem key={4}>
       <Link to="/client/profiles/flexplm" className="nav-link">
       Flexplm
     </Link>
   </NavItem>,
    <NavItem key={5}>
    <Link to="/client/profiles/iam" className="nav-link">
    IAM
  </Link>
</NavItem>,
      ];
    } else if (this.props.match.params.render === "frontend") {
      render = <Frontend showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/profiles/backend" className="nav-link">
            Backend
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/profiles/frontend" className="nav-link active">
            Frontend
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/profiles/fullstack" className="nav-link">
            FullStack
          </Link>
        </NavItem>,
         <NavItem key={4}>
         <Link to="/client/profiles/flexplm" className="nav-link">
         Flexplm
       </Link>
     </NavItem>,
      <NavItem key={5}>
      <Link to="/client/profiles/iam" className="nav-link">
      IAM
    </Link>
  </NavItem>,
      ];
    } else if (this.props.match.params.render === "fullstack") {
      render = <FullStack showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/profiles/backend" className="nav-link">
            Backend
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/profiles/frontend" className="nav-link">
            Frontend
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/profiles/fullstack" className="nav-link active">
            FullStack
          </Link>
        </NavItem>,
         <NavItem key={4}>
         <Link to="/client/profiles/flexplm" className="nav-link">
         Flexplm
       </Link>
     </NavItem>,
      <NavItem key={5}>
      <Link to="/client/profiles/iam" className="nav-link">
      IAM
    </Link>
  </NavItem>,
      ];
    }

else if (this.props.match.params.render === "flexplm") {
      render = <Flexplm showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/profiles/backend" className="nav-link">
            Backend
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/profiles/frontend" className="nav-link">
            Frontend
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/profiles/fullstack" className="nav-link">
            FullStack
          </Link>
        </NavItem>,
        <NavItem key={4}>
        <Link to="/client/profiles/flexplm" className="nav-link active">
          Flexplm
        </Link>
      </NavItem>,
       <NavItem key={5}>
       <Link to="/client/profiles/iam" className="nav-link">
       IAM
     </Link>
   </NavItem>,
      ];
    }
    else if (this.props.match.params.render === "iam") {
      render = <Iam showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/profiles/backend" className="nav-link">
            Backend
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/profiles/frontend" className="nav-link">
            Frontend
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/profiles/fullstack" className="nav-link">
            FullStack
          </Link>
        </NavItem>,
        <NavItem key={4}>
        <Link to="/client/profiles/flexplm" className="nav-link">
          Flexplm
        </Link>
      </NavItem>,
       <NavItem key={5}>
       <Link to="/client/profiles/iam" className="nav-link active">
       IAM
     </Link>
   </NavItem>,
      ];
    }
    

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2">
            <Nav vertical pills>
              {menuItem}
              <hr />
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
            {/**
                    <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light" >
                        <h4 className="alert-heading">Welcome to Developers pool!!</h4>
                        <hr/>
                        <p className="mb-0">
                            Please review and shortlist as per your requirement our hr will connect with you for further onboarding process.
                        </p>
                    </Alert>**/}
            {render}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ProfilesDashboard);
