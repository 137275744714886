import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Alert } from "reactstrap";

import Result from "./result/Result";
import Assessments from "./assessments/Assessments";
import AssessmentOnboard from "./onboard/AssessmentOnboard";
import RequestForm from "./request/RequestForm";

class AssessmentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
    };

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  componentDidMount() {
    document.title = "Assessments dashboard";
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 5000);
    });
  };
  render() {
    let render;
    let menuItem;
    if (this.props.match.params.render === "assessments") {
      render = <Assessments showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/me/assessment/assessments" className="nav-link active">
            Assessments
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/me/assessment/onboard" className="nav-link">
            Onboard
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/me/assessment/result" className="nav-link">
            Result
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/me/assessment/request" className="nav-link">
            Request
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "onboard") {
      render = <AssessmentOnboard showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/me/assessment/assessments" className="nav-link">
            Assessments
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/me/assessment/onboard" className="nav-link active">
            Onboard
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/me/assessment/result" className="nav-link">
            Result
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/me/assessment/request" className="nav-link">
            Request
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "result") {
      render = <Result showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/me/assessment/assessments" className="nav-link">
            Assessments
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/me/assessment/onboard" className="nav-link">
            Onboard
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/me/assessment/result" className="nav-link active">
            Result
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/me/assessment/request" className="nav-link">
            Request
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "request") {
      render = <RequestForm showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/me/assessment/assessments" className="nav-link">
            Assessments
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/me/assessment/onboard" className="nav-link">
            Onboard
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/me/assessment/result" className="nav-link">
            Result
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/me/assessment/request" className="nav-link active">
            Request
          </Link>
        </NavItem>,
      ];
    }

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2">
            <Nav vertical pills>
              {menuItem}
              <hr />
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
            {/**<Alert
              isOpen={this.state.alertIsVisible}
              toggle={this.onDismissAlert}
              className="bg-primary text-light"
            >
              <h4 className="alert-heading">
                Welcome to assessment dashboard.
              </h4>
              <hr />
              <p className="mb-0">
                This is assessment interface, will allow you to manage your
                assessments, results of developers.
              </p>
            </Alert> */}
            {render}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AssessmentDashboard);
