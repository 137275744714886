import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { retrieveClientUser } from "../../../../api/FinanceApi";
import PaymentGrid from "./PaymentGrid";
import AppPagination from "../../../../generic/pagination/AppPagination";

class ViewClient extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props.clientDataProps.clientUserName);
    console.log(this.props.location.clientDataProps.clientUserName);
    this.state = {
      //clientUsername: this.props.clientDataProps,
      userName: "",
      clientName: "",
      clientEmailId: "",
      clientContactNo: "",
      clientStatus: "",
      clientRole: "",
      paymentDataList: [],
      page: {},
      currentPage: 0,
      totalNumberOfElements: 0,
      pageSize: 0,
      loading: true,
      searchText: null,
    };

    this.changeCurrentPage = this.changeCurrentPage.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setStatePromise = this.setStatePromise.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
    //this.updateClientInfo = this.updateClientInfo.bind(this);
  }

  /* static getDerivedStateFromProps(props, state) {
    alert("calling")
    const userName = this.location.props.clientProps.userName;
    state.userName = userName
    //alert("props content check !!!!"+JSON.stringify(subscriptionF));
     alert("userName!!!!!"+userName);
     //alert("paymentInfo!!!!!"+paymentInfo);
      return state;
    }*/

  componentDidMount() {
    document.title = "Invoice management";
    this.getUserFromCookie();
    this.loadData(0, this.state.searchText);
  }

  componentDidUpdate() {
    this.render();
  }

  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  changeCurrentPage(currentPage) {
    this.setState({
      loading: true,
      currentPage,
    });
  }
  updateSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };

  getUserFromCookie() {}
  loadData(page) {
    //let req = this.state;
    //req.userName=this.props.location.clientDataProps.clientUserName;
    //this.setState({ userName: req });
    //alert("userName!!!"+this.state.userName);

    retrieveClientUser(this.props.location.clientDataProps.clientUserName)
      .then((res) => {
        let subscriptionList = res.data.subscription; // list
        //alert("res..."+JSON.stringify(res.data));
        this.setState({ clientName: res.data.name });
        this.setState({ clientEmailId: res.data.email });
        this.setState({ clientContactNo: res.data.mobile });
        this.setState({ clientStatus: res.data.status });
        this.setState({ clientRole: res.data.role });
        let constructPaymentDataList = [];
        let name = res.data.name;
        let userName=res.data.email;
        if (
          subscriptionList !== undefined &&
          subscriptionList !== null &&
          subscriptionList.length > 0
        ) {
          for (let i = 0; i < res.data.subscription.length; i++) {
            //alert("subscription -" + JSON.stringify(subscriptionList[i]));
            let payment = subscriptionList[i].paymentList; // list
            if (
              payment !== undefined &&
              payment !== null &&
              payment.length > 0
            ) {
              for (let j = 0; j < payment.length; j++) {
                let paymentData = {
                  projectRequirement: "",
                  dueDate: "",
                  billingPeriodFrom: "",
                  billingPeriodTo: "",
                  totalBillingDay: "",
                  totalBillingHours: "",
                  costPerDay: "",
                  subscriptionType: "",
                  subscriptionFor:"",
                  costExcludingGstPay:"",
                  costIncludingGstPay:"",
                  paymentStatus:"",
                  subscriptionId:"",
                  paymentId:"",
                  userName:""
                };
                paymentData.subscriptionFor =
                  subscriptionList[i].subscriptionFor;
                paymentData.subscriptionType = subscriptionList[i].subscriptionType;
                paymentData.subscriptionId=subscriptionList[i].subscriptionId;
                paymentData.projectRequirement = subscriptionList[i].projectRequirement;
                paymentData.costPerDay = subscriptionList[i].subscriptionCost;
                paymentData.dueDate = payment[j].dueDate;
                paymentData.billingPeriodFrom = payment[j].paymentBillingFrom;
                paymentData.billingPeriodTo = payment[j].paymentBillingTo;
                paymentData.costExcludingGstPay = payment[j].costExcludingGstPay;
                paymentData.costIncludingGstPay = payment[j].costIncludingGstPay;
                paymentData.paymentId = payment[j].paymentId;
                paymentData.totalBillingDay = payment[j].totalBillingDay;
                paymentData.totalBillingHours = payment[j].totalBillingHours;
                paymentData.paymentStatus = payment[j].paymentStatus;
                paymentData.subscriberName = name;
                paymentData.userName = userName;
                constructPaymentDataList.push(paymentData);
              }
            } else {
              this.setState({ loading: false });
            }
          }
          this.setState({ paymentDataList: constructPaymentDataList });
          //alert("paymentDataList"+JSON.stringify(this.state.paymentDataList))
          this.setStatePromise({
            content: this.state.paymentDataList,
            page: res.data,
            currentPage: res.data.pageNumber,
            totalNumberOfElements: res.data.totalNumberOfElements,
            pageSize: res.data.pageSize,
            loading: false,
          }).then(() => {
            this.forceUpdate();
          });
        } else {
          this.setState({ loading: false });
        }
        /*
          for(let j=0;j<res.data.sub)
          paymentData.payment = res.data.subscription[i].paymentList;
          paymentData.subscriptionFor=res.data.subscription[i].subscriptionFor;
          paymentData.playType=res.data.subscription[i].playType;
          paymentData.planCost=res.data.subscription[i].planCost;

          content.push(paymentData);
        }
*/

        this.setState({ loading: false });
      })
      .catch((error) => {
        if (error.message) {
          this.props.showAlert(error.message, "error");
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ loading: false });
        console.log(error);
      });
  }

  render() {
    const {
      paymentDataList,
      pageSize,
      currentPage,
      totalNumberOfElements,
      loading,
      page,
      clientName,
      clientEmailId,
      clientContactNo,
      clientStatus,
      clientRole,
    } = this.state;

    if (loading) {
      return (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading. Please Wait...</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">
            refresh
          </i>
        </div>
      );
    } else {
      return (
        <>
          <div className="container">
            <div className="row pt-3">
              <div>
                <p>
                  <h3>
                    Subscriptions{" "}
                    <span className="text-muted" style={{ fontSize: 11 }}>
                      details
                    </span>
                  </h3>
                  <span>
                    <form onSubmit={(e) => this.loadData(e)}></form>
                  </span>
                </p>

          

                <PaymentGrid
                  showAlert={this.props.showAlert}
                  content={paymentDataList}
                />
                <AppPagination
                  showAlert={this.props.showAlert}
                  page={page}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  totalNumberOfElements={totalNumberOfElements}
                  handleChangePage={this.loadData}
                />
                <NavLink
                  to={{
                    pathname: "/financier/dashboard/clients",
                  }}
                  exact
                >
                  <button type="button" class="btn btn-dark">
                    Back
                  </button>
                </NavLink>
                <hr />
              </div>
              <div>
                <p>
                  <h3>
                    Client Info{" "}
                    <span className="text-muted" style={{ fontSize: 11 }}>
                      details
                    </span>
                  </h3>
                  <div>
                    <table className="table table-hover">
                      <thead>
                        <h1>
                          <span className="text-muted" style={{ fontSize: 17 }}>
                            {paymentDataList.subscriberName} Data
                          </span>
                        </h1>
                      </thead>
                      <tbody>
                        <tr scope="row">
                          <th> Client Name</th>
                          <th> {clientName}</th>{" "}
                        </tr>
                        <tr scope="row">
                          <th> Client Email Id</th>
                           <th>{clientEmailId}{" "}</th>
                        </tr>

                        <tr scope="row">
                          <th> Client Contact No</th>
                          <th> {clientContactNo}</th>{" "}
                        </tr>
                        <tr scope="row">
                          <th> Status </th>
                          <th> {clientStatus}</th>{" "}
                        </tr>
                        <tr scope="row">
                          <th> Role</th>
                          <th> {clientRole}</th>{" "}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default withRouter(ViewClient);
