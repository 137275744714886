import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getEmployeeData } from "../../../../api/HrApi";
import EmployeeGrid from "./EmployeeGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";

class Employee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      loading: true,
      searchText: null,
      loadNext:0,
    };

   
    this.loadData = this.loadData.bind(this);

    this.updateSearchText=this.updateSearchText.bind(this);
  }

  componentDidMount() {
    document.title = "Employee management";
    this.loadData(0, this.state.searchText);
  }

  componentDidUpdate() {
    this.render();
  }

 
  updateSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };

  loadData() {
    this.setState({ loading: true });
    getEmployeeData(this.state.loadNext,this.state.searchText)
      .then((res) => {
       this.setState({loadNext:this.state.loadNext+1});
      // alert("loadNext"+this.state.loadNext)
        let content = [];
        let user = {
          leaveStatus:'',
          totalLeaveTaken:'',
          totalEligibleLeaves:'',
        };

        for (let i = 0; i < res.data.content.length; i++) {
          user = res.data.content[i];
         let sickLeavesTaken=user.sickLeavesTaken?user.sickLeavesTaken:0;
         let vacationLeavesTaken=user.vacationLeavesTaken?user.vacationLeavesTaken:0;
         let totalLeaveTaken=sickLeavesTaken+vacationLeavesTaken;
         let eligibleSickLeaves=user.eligibleSickLeaves?user.eligibleSickLeaves:0;
         let eligibleVacationLeaves=user.eligibleVacationLeaves?user.eligibleVacationLeaves:0;
         let totalEligibleLeaves=eligibleSickLeaves+eligibleVacationLeaves;
         let leaveStatus=totalEligibleLeaves-totalLeaveTaken;
         user.leaveStatus=leaveStatus;
         user.totalEligibleLeaves=totalEligibleLeaves;
         user.totalLeaveTaken=totalLeaveTaken;
          content.push(user);
         
        }
        content.sort((a,b) => (a.leaveStatus > b.leaveStatus) ? 1 : ((b.leaveStatus > a.leaveStatus) ? -1 : 0))
        this.setState({
          content: content,
          loading: false,
        })
      })
      .catch((error) => {
        if (error.message) {
          this.props.showAlert(error.message, "error");
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ loading: false });
        console.log(error);
      });
      this.setState({ searchText: "" });
  }

  render() {
    const {
      content,
      pageSize,
      currentPage,
      totalNumberOfElements,
      loading,
      page,
      loadNext,
    } = this.state;

    if (loading) {
      return (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading. Please Wait...</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">
            refresh
          </i>
        </div>
      );
    } else {
      return (
        <><div>
          <p>
            <h1>
              Employee {" "}
              <span className="text-muted" style={{ fontSize: 11 }}>
                management
              </span>
            </h1>
            <span>
              <form onSubmit={(e) => this.loadData(e)}>
                <div>
                  <input
                    type="text"
                    id="inputSearchText"
                    className="form-search"
                    placeholder="Search here"
                    onChange={this.updateSearchText}
                    autoComplete="on" />
                  <button type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </form>
            </span>
          </p>
          <hr />

          <EmployeeGrid showAlert={this.props.showAlert} content={content} />
          <span>
                <form onSubmit={(e) => this.loadData(e)}>
                  <div>
                    
                    <button className="alert-link page-link" type="submit"> load again  {"  "}
                      <FontAwesomeIcon icon={faDownload} />
                    </button>

                  </div>
                </form>
              </span>

        </div></>
      );
    }
  }
}

export default withRouter(Employee);
