import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ClientDataGrid from "./ClientDataGrid";
import AppPagination from "../../../../generic/pagination/AppPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getPageUsers } from "../../../../api/AdminApi";

class ClientData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentDataList: [],
      clientDataList: [],
      page: {},
      currentPage: 0,
      totalNumberOfElements: 0,
      pageSize: 0,
      loading: true,
      searchText: null,
    };

    this.changeCurrentPage = this.changeCurrentPage.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setStatePromise = this.setStatePromise.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
  }

  componentDidMount() {
    document.title = "Invoice management";
    this.getUserFromCookie();
    //this.retrieveUsers();
    this.loadData(0, this.state.searchText);
  }

  componentDidUpdate() {
    this.render();
  }

  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  changeCurrentPage(currentPage) {
    this.setState({
      loading: true,
      currentPage,
    });
  }
  updateSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };

  /*retrieveUsers = () => {
    getUsers()
      .then((res) => {
        let userList = res.data.content;
        let constructeClientDataList = [];
        if (
          userList !== undefined &&
          userList !== null &&
          userList.length > 0
        ) {
          for (let i = 0; i < res.data.content.length; i++) {
            let clientData = {
              Name: "",
              userName: "",
              constactNo: "",
              status: "",
              noOfSubscriptions: "",
              subscribedFrom: "",
            };
            clientData.Name=userList[i].name;
            clientData.userName=userList[i].email;
            clientData.constactNo=userList[i].mobile;
            clientData.status=userList[i].status;
            clientData.noOfSubscriptions=userList[i].subscription.length;
            clientData.subscribedFrom=userList[i].createdAt;
            constructeClientDataList.push(clientData);
          }
          this.setState({clientDataList:constructeClientDataList})
          //alert("clientDataList..."+JSON.stringify(this.state.clientDataList))
        }
        //alert("printing users..."+JSON.stringify(res.data.content))
        this.props.showAlert(
          "Your request has been accepted ! role got changed for the user with email of " +
            this.state.user.email,
          "success"
        );
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };*/

  getUserFromCookie() {}
  loadData(page) {
   // alert("load data!!!");
    this.setState({ loading: true });

    getPageUsers(page,"client",null)
      .then((res) => {
        
        let userList = res.data.content;
        let constructClientDataList = [];
       // alert("userList res!!!"+JSON.stringify(userList));
        if (
          userList !== undefined &&
          userList !== null &&
          userList.length > 0
        ) {
         // alert("loaded res!!!");
          for (let i = 0; i < res.data.content.length; i++) {
            let clientData = {
              name: "",
              userName: "",
              contactNo: "",
              status: "",
              noOfSubscriptions: "",
              subscribedFrom: "",
            };
            clientData.name=userList[i].name;
            clientData.userName=userList[i].email;
            clientData.contactNo=userList[i].mobile;
            clientData.status=userList[i].status;
            clientData.noOfSubscriptions=userList[i].subscription?userList[i].subscription.length:0;
            clientData.subscribedFrom=userList[i].createdAt;
            constructClientDataList.push(clientData);
           // alert("loaded res sfd!!!"+clientData);
          }
          this.setState({clientDataList:constructClientDataList})
          //alert("clientDataList111..."+JSON.stringify(this.state.clientDataList))
        this.setStatePromise({
          content: this.state.clientDataList,
          page: res.data,
          currentPage: res.data.pageNumber,
          totalNumberOfElements: res.data.totalNumberOfElements,
          pageSize: res.data.pageSize,
          loading: false,
        }).then(() => {
          this.forceUpdate();
        });
        }
      
      })
      .catch((error) => {
        if (error.message) {
          this.props.showAlert(error.message, "error");
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ loading: false });
        console.log(error);
      });
  }

  render() {
    const {
      clientDataList,
      pageSize,
      currentPage,
      totalNumberOfElements,
      loading,
      page,
    } = this.state;

    if (loading) {
      return (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading. Please Wsddfsd...</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">
            refresh
          </i>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            <h1>
              Payment Invoice{" "}
              <span className="text-muted" style={{ fontSize: 11 }}>
                management
              </span>
            </h1>
            <span>
              <form onSubmit={(e) => this.loadData(e)}>
                <div>
                  <input
                    type="text"
                    id="inputSearchText"
                    className="form-search"
                    placeholder="Search here"
                    onChange={this.updateSearchText}
                    autoComplete="on"
                  />
                  <button type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </form>
            </span>
          </p>
          <hr />

          <ClientDataGrid showAlert={this.props.showAlert} content={clientDataList} />
          <AppPagination
            showAlert={this.props.showAlert}
            page={page}
            pageSize={pageSize}
            currentPage={currentPage}
            totalNumberOfElements={totalNumberOfElements}
            handleChangePage={this.loadData}
          />
        </div>
      );
    }
  }
}

export default withRouter(ClientData);
