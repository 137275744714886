import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { policyHub } from "../../../../common/content/PrivacyContent";
import { Fade } from "react-reveal";
class PolicyHub extends Component {
  render() {
    return (
      <>
        <Fade bottom duration={1000} distance="40px">
          <div className="container">
            <div className="main contact-margin-top" id="contact">
              <div className="contact-div-main">
                <div className="contact-header">
                  <h1 className={"pages-mainheader-text"}>
                    {policyHub.policyTitle}
                  </h1>
                  <p className={"pages-content-text"}>
                    {policyHub.policyContent}
                  </p>
                  <h2 className={"pages-mainheader-text"}>
                    {policyHub.policyTitle1}
                  </h2>
                  <p>
                    <li className={"pages-content-text"}>
                      {policyHub.policyContent1}
                    </li>
                    <li className={"pages-content-text"}>
                      {policyHub.policyContent2}
                    </li>
                    <li className={"pages-content-text"}>
                      {policyHub.policyContent3}
                    </li>
                    <li className={"pages-content-text"}>
                      {policyHub.policyContent4}
                    </li>
                    <li className={"pages-content-text"}>
                      {policyHub.policyContent5}
                    </li>
                  </p>
                  <p className={"pages-content-text"}>
                    {policyHub.policyContent6}
                  </p>
                  <h2 className={"pages-mainheader-text"}>
                    {policyHub.policyTitle2}
                  </h2>
                  <p className={"pages-content-text"}>
                    {policyHub.policyContent7}
                  </p>
                </div>
              </div>
            </div>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
        </Fade>
      </>
    );
  }
}
export default withRouter(PolicyHub);
