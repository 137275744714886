import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { getContent, getEndpoints } from "../../../api/Api";
import { CONTENT_ENDPOINTS } from "../../../config/Config";
import { Nav, NavItem, NavLink } from "reactstrap";
import { items } from "./Slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
//import { copy } from "copy-to-clipboard";
class ContentManagement extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.endpoint);
    console.log("category= " + this.props.category);
    this.state = {
      content: [],
      endpoints: [],
      contentList: [],
      currentEndpoint: "",
      currentCategory: "",
      leftNavigationList: [],
    };
  }

  componentDidMount() {
    this.getEndPointData();
    // this.getContent();
  }

  getEndPointData = () => {
    //this.setState({ showLoading: true });
    getEndpoints()
      .then((res) => {
        //alert("Data"+JSON.stringify(res.data))
        if (res.data === null) {
          this.showAlert(res.message, "error");
        } else {
          let req = this.state.endpoints;
          req.endpoints = res.data;

          this.setState({ endpoints: req.endpoints });
          //alert("Data from state"+JSON.stringify(this.state.endpoints))

          this.setState({ showLoading: true });

          //alert("inside get contetnt")

          let endpoint = "";
          //alert("without local storage outside IF"+JSON.stringify(this.state.endpoints))
          if (this.state.endpoints) {
            //alert("without local storage"+JSON.stringify(this.state.endpoints))
            for (let i = 0; i < this.state.endpoints.length; i++) {
              if (this.state.endpoints[i].category === this.props.category) {
                const firstEndpoint = this.state.endpoints[i];
                const contentStateList = firstEndpoint.contentStateList;
                this.setState({ leftNavigationList: contentStateList });
                const contentDetailsData = contentStateList[0];
                endpoint = contentDetailsData.endpoint;
              }
            }
            if (
              this.props.endpoint !== undefined &&
              this.props.endpoint !== null
            ) {
              getContent(this.props.endpoint, this.props.category)
                .then((res) => {
                  //alert("data"+JSON.stringify(res.data));
                  if (res.data === null) {
                    this.showAlert(res.message, "error");
                  } else {
                    this.setState({ content: res.data });
                    this.setState({ contentList: res.data.contentList });
                    // alert("contentview"+JSON.stringify(this.state.contentList))
                  }

                  this.setState({ showLoading: false });
                })
                .catch((error) => {
                  if (error.status === 401) {
                    this.showAlert(
                      "Your are not eligible to access this endpoint",
                      "error"
                    );
                  }
                  this.setState({ showLoading: false });
                });
            }
          }

          this.setState({ loading: false });
        }
      })

      .catch((error) => {
        if (error.status === 401) {
          this.showAlert(
            "Your are not eligible to access this endpoint",
            "error"
          );
        } else {
          //this.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  render() {
    const { content, contentList, leftNavigationList } = this.state;
    //console.log("datacoming" + JSON.stringify(content));
    // alert("contentlist of data"+JSON.stringify(contentList));
    
    return (
      <>
        {" "}
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-md-2 p-0">
              <div className="sidebar">
                <ul className="sidbar-menu">
                  <Nav vertical>
                    {leftNavigationList.map((contentStateData, index) => (
                      <li key={index + 25} className="sidebar-content">
                        <NavItem className="mr-0">
                          <Link
                            to={
                              "/" +
                              this.props.category +
                              "-" +
                              contentStateData.endpoint
                            }
                            className="nav-link endpoint text-uppercase active"
                            onClick={this.toggle}
                          >
                            {contentStateData.endpoint}
                          </Link>
                        </NavItem>
                      </li>
                    ))}
                  </Nav>
                </ul>
              </div>
            </div>
            {/*  <h2> {content.primaryHeader} </h2> <br />
          {contentList.map((item, i) => (
            <>
              <div>
                <h1>{item.contentHeader}</h1>
                <p>{item.contentBody}</p>
                <p>{item.codeSnippet}</p>
              </div>
            </>
          ))}*/}
            <div class="col-sm-8">
              <h1 style={{ fontWeight: "bold" }}>{content.primaryHeader} </h1>{" "}
              <br />
              <amp-ad
                width="100vw"
                height="320"
                type="adsense"
                data-ad-client="ca-pub-6696823685578914"
                data-ad-slot="1839206645"
                data-auto-format="mcrspv"
                data-full-width=""
              >
                <div overflow=""></div>
              </amp-ad>
              <amp-ad
                width="100vw"
                height="320"
                type="adsense"
                data-ad-client="ca-pub-6696823685578914"
                data-ad-slot="5227787319"
                data-auto-format="rspv"
                data-full-width=""
              >
                <div overflow=""></div>
              </amp-ad>
              <div className="published date d-flex">
                <h4 className="text-bold" style={{ fontSize: 13}}>
                  Last Updated:
                </h4>
                <p className="d-flex align-items-center pt-2 mt-1" style={{ fontSize: 13}}>
                  {content.createdAt}
                </p>
              </div>
              <div class="author_reviewer mt-sm-3">
                <img
                  src={require("../../../assets/images/logoFinal30x30.png")}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ maxWidth: 64 }}
                />
                <div class="author vcard">
                
                  <span class="author-name fn" itemprop="name" style={{ fontSize: 13}}>
                    {content.publishedBy}{" "}
                  </span>
                </div>
              </div>
              <div>
                {contentList.map((item, i) => (
                  <>
                    <div>
                      <h4 className="text-bold" style={{ fontWeight: 500 }}>
                        {item.contentHeader}
                      </h4>

                      <div className="p-1">
                        {/** 1. you have to split contentBody into array by using delimiter. 2. Instead of using  <p>{item.contentBody}</p> you have to use map to iterate and print in next line.*/}

                        <div>
                          {item.contentBody.split("- ").map((bodyItem, j) => (
                            <>
                              <div>
                                <p>{bodyItem}</p>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>

                      <div className="p-1">
                        {/** 1. you have to split contentBody into array by using delimiter. 2. Instead of using  <p>{item.contentBody}</p> you have to use map to iterate and print in next line.*/}
                        <div>
                          {item.codeSnippet != null ?
                           (
                            <>
                              <div className="script-header">
                                <div className="code-snippet" >
                                  <div className="code-snippet-right">
                                    
                                  </div>
                                  <div className="code-snippet-right">
                                    <FontAwesomeIcon
                                      icon={faCopy}
                                      onClick={() => {
                                        navigator.clipboard.writeText(item.codeSnippet);
                                      } }
                                    />
                                  </div>
                                </div>
                                {item.codeSnippet
                                  .split("- ")
                                  .map((bodyItem, j) => (
                                    <>
                                      <p className="pl-3">{bodyItem}</p>
                                    </>
                                  ))}
                              </div>
                            </>
                          ) : (
                            <>
                              <div></div>
                            </>
                          )}
                        </div>
                      </div>

                      <p>
                        {item.contentImageBody ? (
                          <img
                            src={`${item.contentImageBody}`}
                            className="script-header mx-auto"
                          />
                        ) : null}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className="col-sm-2">
              <amp-ad
                width="100vw"
                height="320"
                type="adsense"
                data-ad-client="ca-pub-6696823685578914"
                data-ad-slot="1839206645"
                data-auto-format="mcrspv"
                data-full-width=""
              >
                <div overflow=""></div>
              </amp-ad>
              <amp-ad
                width="100vw"
                height="320"
                type="adsense"
                data-ad-client="ca-pub-6696823685578914"
                data-ad-slot="5227787319"
                data-auto-format="rspv"
                data-full-width=""
              >
                <div overflow=""></div>
              </amp-ad>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ContentManagement);
