import React, { Component } from "react";
import PaymentGridLine from "./PaymentGridLine";

class PaymentGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      paymentData: props.content,
    };
  }

  static getDerivedStateFromProps(props, state) {
    //alert("props contect"+JSON.stringify(props.content));
    state = props.content;
    return state;
  }

  render() {
    const { paymentData } = this.state;

    let showPaymentData = [];

    if (true) {
      showPaymentData = Object.keys(this.state.paymentData).map((key) => (
        <PaymentGridLine
          showAlert={this.props.showAlert}
          key={key}
          showPaymentData={this.state.paymentData[key]}
        />
      ));
    }

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr className="overflow-x-auto">
            
            <th scope="col">Id</th>
              <th scope="col">Project</th>
              <th scope="col">Due Date</th>
              <th scope="col">Period From</th>
              <th scope="col">Period To</th>
              <th scope="col">Quantity</th>
              <th scope="col">Billing Hours</th>
              <th scope="col">Cost per Quantity</th>
              <th scope="col">Total Cost</th>
              <th scope="col">Total Cost+GST</th>
              <th scope="col">Payment Status</th>
              <th scope="col">Download Invoice</th>
              <th scope="col">Pay Button</th>
            </tr>
          </thead>
          <tbody>
            {true ? (
              showPaymentData
            ) : (
              <div className="alert alert-danger" role="alert">
                <strong>Oops !</strong> Nothing to show here...
              </div>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PaymentGrid;
