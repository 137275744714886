import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getApplicantsPage } from "../../../../api/HrApi";
//import ApplicantsGrid from "./ApplicantsGrid";
//import AppPagination from "../../../../generic/pagination/AppPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getContent, getEndpoints } from "../../../../api/Api";
//import { CONTENT_ENDPOINTS } from "../../../../config/Config";
import EditContentGrid from "./EditContentGrid";

class EditContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      publishContent: [],
      page: {},
      currentPage: 0,
      totalNumberOfElements: 0,
      pageSize: 0,
      loading: true,
      searchText: null,
      showLoading:true,
    };

    this.changeCurrentPage = this.changeCurrentPage.bind(this);
   
    this.updateSearchText = this.updateSearchText.bind(this);
    this.setStatePromise = this.setStatePromise.bind(this);
  }

  componentDidMount() {
    document.title = "Content management";
    this.getEndPointData();
    //this.loadData(0, this.state.searchText);
  }

  componentDidUpdate() {
    this.render();
  }

  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  changeCurrentPage(currentPage) {
    this.setState({
      loading: true,
      currentPage,
    });
  }
  updateSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };
  
  getEndPointData = () => {
    //this.setState({ showLoading: true });
    getEndpoints()
      .then((res) => {
        //alert("Data"+JSON.stringify(res.data))
        if (res.data === null) {
          this.showAlert(res.message, "error");
        } else {
          this.setState({ publishContent: res.data });
          this.setState({ loading: false });
        }

        
      })

      .catch((error) => {
        if (error.status === 401) {
          this.showAlert(
            "Your are not eligible to access this endpoint",
            "error"
          );
        } else {
          //this.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  
   
    }/*
  loadData(page) {
    this.setState({ loading: true });
    getContent(this.state.searchText)
      .then((res) => {
        //alert("show"+JSON.stringify(res.data))

        this.setStatePromise({
          publishContent: publishContent,
          page: res.publishContent,
          currentPage: res.publishContent.pageNumber,
          totalNumberOfElements: res.publishContent.totalNumberOfElements,
          pageSize: res.publishContent.pageSize,
          loading: false,
        }).then(() => {
          this.forceUpdate();
        });
        this.setState({searchText:null});
      })
      .catch((error) => {
        if (error.message) {
          //this.props.showAlert(error.message, "error");
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ loading: false });
        console.log(error);
      });
  }*/

  render() {
    const {
      publishContent,
      pageSize,
      currentPage,
      totalNumberOfElements,
      loading,
      page,searchText
    } = this.state;
    //alert("check the publishContent"+JSON.stringify(publishContent));

    if (loading) {
      return (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading. Please Wait...</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">
            refresh
          </i>
        </div>
      );
    } else {
      return (
        <div>
        <p> <h1>
            Content{" "}
            <span className="text-muted" style={{ fontSize: 11 }}>
              management
            </span>
          </h1>
          <span><form  onSubmit={(e) => this.loadData(e)}>
            <div>
              <input
                type="text"
                id="inputSearchText"
                className="form-search"
                placeholder="Search here"
                onChange={this.updateSearchText}
                autoComplete="on"
              />
                <button type="submit" >
            <FontAwesomeIcon icon={faSearch} />
          </button>
            </div>
            </form></span></p>
         
          <hr />
          <EditContentGrid showAlert={this.props.showAlert} publishContent={publishContent} />
          
          {/*<ApplicantsGrid showAlert={this.props.showAlert} publishContent={publishContent} />
          <AppPagination
            showAlert={this.props.showAlert}
            page={page}
            pageSize={pageSize}
            currentPage={currentPage}
            totalNumberOfElements={totalNumberOfElements}
            handleChangePage={this.loadData}
          />
      */}
        </div>
      );
    }
  }
}

export default withRouter(EditContent);
