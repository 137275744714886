import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { experienceLetterGeneration } from "../../../../api/AdminApi";

class ExperienceLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exitEmployeeDetails: {
        exitEmployeeUsername: "",
        exitEmployeeOfficialEmailId: "",
        exitEmploymentType: "",
        employeeExitdate: "",
      },

      showLoading: false,
    };
  }

  componentDidMount() {
    document.title = "Experience Generation";
  }

  updateExitEmployeeUsername = (event) => {
    let req = this.state.exitEmployeeDetails;
    req.exitEmployeeUsername = event.target.value;
    this.setState({ exitEmployeeDetails: req });
  };

  updateExitEmployeeOfficialEmailId = (event) => {
    let req = this.state.exitEmployeeDetails;
    req.exitEmployeeOfficialEmailId = event.target.value;
    this.setState({ exitEmployeeDetails: req });
  };
  updateExitEmploymentType = (event) => {
    let req = this.state.exitEmployeeDetails;
    req.exitEmploymentType = event.target.value;
    this.setState({ exitEmployeeDetails: req });
  };
  updateEmployeeExitdate = (event) => {
    let req = this.state.exitEmployeeDetails;
    req.employeeExitdate = event.target.value;
    this.setState({ exitEmployeeDetails: req });
  };
  experienceLetterGeneration = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });

    experienceLetterGeneration(this.state.exitEmployeeDetails)
      .then((res) => {
        let binaryString = window.atob(res.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download =
          "ExperienceLetter" + ".pdf";

        link.click();

        this.setState({ showLoading: false });
        //this.setState({ experience: "" });
        //  this.props.history.push("/admin/dashboard/letterOfEmployment");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          //this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  render() {
    const { holidayData } = this.state;
    return (
      <div className="row">
        <div className="col-md-6">
          <h3>Experience Letter Generation</h3>
          <hr />
          <div>
            {this.state.showLoading ? (
              <div className="align-content-center text-center">
                <h3>Experience Letter Generation initiated</h3>
                <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                  in progress
                </i>
              </div>
            ) : (
              <form onSubmit={(e) => this.experienceLetterGeneration(e)}>
                <div>
                  <p className="card-title text-center">
                    Experience Letter Generation
                  </p>
                  <div className="mt-2">
                    <label htmlFor="exitEmployeeUsername">
                      Exit EmployeeUsername
                    </label>
                    <input
                      type="text"
                      id="exitEmployeeUsername"
                      className="form-control"
                      placeholder="Enter your exitEmployeeUsername"
                      required
                      onChange={this.updateExitEmployeeUsername}
                      autoComplete="on"
                    />
                  </div>
                  <div className="mt-2">
                    <label htmlFor="exitEmployeeOfficialEmailId">
                      Exit Employee OfficialEmailId
                    </label>
                    <input
                      type="text"
                      id="exitEmployeeOfficialEmailId"
                      className="form-control"
                      placeholder="Enter your exitEmployeeOfficialEmailId"
                      required
                      onChange={this.updateExitEmployeeOfficialEmailId}
                      autoComplete="on"
                    />
                  </div>
                  <div className="mt-2">
                    <label htmlFor="exitEmploymentType">
                      Exit EmploymentType
                    </label>
                    <input
                      type="text"
                      id="exitEmploymentType"
                      className="form-control"
                      placeholder="Enter your exitEmploymentType"
                      required
                      onChange={this.updateExitEmploymentType}
                      autoComplete="on"
                    />
                  </div>
                  <div className="mt-2">
                    <label htmlFor="employeeExitdate">Employee Exit date</label>
                    <input
                      type="text"
                      id="employeeExitdate"
                      className="form-control"
                      placeholder="Enter employeeExitdate"
                      required
                      onChange={this.updateEmployeeExitdate}
                      autoComplete="on"
                    />
                  </div>

                  <br></br>

                  <button
                    className="btn btn-primary btn-block text-uppercase mt-3"
                    type="submit"
                  >
                    Download
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ExperienceLetter);
