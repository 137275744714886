import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { retrieveUser } from "../../../../api/commonApi";
import HireGrid from "./HireGrid";
import AppPagination from "../../../../generic/pagination/AppPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class Hire extends Component {

    constructor(props) {
        super(props);

        this.state = {
            shortListedCandidatesList: [],
            page: {},
            currentPage: 0,
            totalNumberOfElements: 0,
            pageSize: 0,
            loading: true,
            searchText: null,
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.loadData = this.loadData.bind(this);
        this.setStatePromise = this.setStatePromise.bind(this);
        this.updateSearchText=this.updateSearchText.bind(this);
    }

    componentDidMount() {
        document.title = "Hire management";
        this.loadData(0);
    }

    componentDidUpdate(){
        this.render();
    }

    setStatePromise(newState) {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve();
            });
        });
    }

    changeCurrentPage(currentPage) {
        this.setState({
            loading: true,
            currentPage
        });
    }
    updateSearchText = (e) => {
        this.setState({ searchText: e.target.value });
      };
    loadData(page) {
        this.setState({loading: true});
        retrieveUser()
      .then((res) => {
       
        let shortListedCandidatesList = [];
        let shortListedCandidate = {
        
            category:'',
            status:'',
            offeredTerm:'',
            remarks:'',
            createdAt:''
        };
        if (
            res.data.shortListedCandidatesList !== undefined &&
            res.data.shortListedCandidatesList !== null &&
            res.data.shortListedCandidatesList.length > 0
        ) {
          for (let i = 0; i < res.data.shortListedCandidatesList.length; i++) {
            
            shortListedCandidatesList.push(res.data.shortListedCandidatesList[i]);
        }
    } 
    this.setState({shortListedCandidatesList:shortListedCandidatesList});
    this.setState({ loading: false });
      })
      .catch((error) => {
        if (error.message) {
          this.props.showAlert(error.message, "error");
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ loading: false });
        console.log(error);
      });
    }

    render() {

        const {shortListedCandidatesList, pageSize, currentPage, totalNumberOfElements, loading, page} = this.state;

        if (loading) {
            return (
                <div className="align-content-center text-center">
                    <h4 className="text-muted">Loading. Please Wait...</h4>
                    <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
                </div>
            );
        } else {
            return (
                <div>
                <p>
                    <h1>Hire <span className="text-muted" style={{fontSize: 11}}>management</span></h1>
                    <span>
              <form onSubmit={(e) => this.loadData(e)}>
                <div>
                  <input
                    type="text"
                    id="inputSearchText"
                    className="form-search"
                    placeholder="Search here"
                    onChange={this.updateSearchText}
                    autoComplete="on"
                  />
                  <button type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </form>
            </span>
          </p>
                    <hr/>

                    <HireGrid showAlert={this.props.showAlert} content={shortListedCandidatesList}/>
                
                </div>
            );
        }
    }
}

export default withRouter(Hire);