import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { profilePersonalData } from '../../../../../api/commonApi';
import {
    AADHAR_EXACT_LENGTH,
    PAN_EXACT_LENGTH,
    MOBILE_EXACT_LENGTH,
    NAME_MIN_LENGTH
} from "../../../../../config/Config";
class PersonalDataSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            personalDataRequest: {
                firstName: "",
                lastName: "",
                fullName: "",
                panNo: "",
                aadharNo: "",
                dateOfBirth: "",
                presentAddress: "",
                permanentAddress: "",
                bloodGroup: "",
                phoneNo: "",
                fatherName: "",
                emergencyContactNo: "",
                motherName: "",
                fatherDob: "",
                motherDob: "",
                genderStatus: ""
            },
            showLoading: false,

        };


    }

    componentDidMount() {
        document.title = "My account Personal data settings"
    }

    updateFirstName = (event) => {
        let req = this.state.personalDataRequest;
        req.firstName = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateLastName = (event) => {
        let req = this.state.personalDataRequest;
        req.lastName = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateFullName = (event) => {
        let req = this.state.personalDataRequest;
        req.fullName = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updatePanNo = (event) => {
        let req = this.state.personalDataRequest;
        req.panNo = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateDob = (event) => {
        let req = this.state.personalDataRequest;
        req.dateOfBirth = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateAadharNo = (event) => {
        let req = this.state.personalDataRequest;
        req.aadharNo = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updatePresentAddress = (event) => {
        let req = this.state.personalDataRequest;
        req.presentAddress = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updatePermanentAddress = (event) => {
        let req = this.state.personalDataRequest;
        req.permanentAddress = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateBloodGroup = (event) => {
        //alert(+event.target.value);
        let req = this.state.personalDataRequest;
        req.bloodGroup = event.target.value;
        this.setState({ personalDataRequest: req });

    }
    updatePhoneNo = (event) => {
        let req = this.state.personalDataRequest;
        req.phoneNo = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateFatherName = (event) => {
        let req = this.state.personalDataRequest;
        req.fatherName = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateEmergencyContactNo = (event) => {
        let req = this.state.personalDataRequest;
        req.emergencyContactNo = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateMotherName = (event) => {
        let req = this.state.personalDataRequest;
        req.motherName = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateFatherDob = (event) => {
        let req = this.state.personalDataRequest;
        req.fatherDob = event.target.value;
        this.setState({ personalDataRequest: req });
    }
    updateMotherDob = (event) => {
        let req = this.state.personalDataRequest;
        req.motherDob = event.target.value;
        this.setState({ personalDataRequest: req });

    }
    updateGenderStatus = (event) => {
        let req = this.state.personalDataRequest;
        req.genderStatus = event.target.value;
        this.setState({ personalDataRequest: req });

    }



    handleOnboardPersonalProfile = (event) => {
        event.preventDefault();
        this.setState({ showLoading: true });
        const personalData = this.state.personalDataRequest;
        if (personalData.phoneNo.length !== MOBILE_EXACT_LENGTH) {
            this.props.showAlert(
                "Your mobile number must contain exactly " +
                MOBILE_EXACT_LENGTH +
                " and " +
                "no special characters. (+)",
                "error"
            );
            this.setState({ showLoading: false });
            return null;
        }
        if (personalData.aadharNo.length !== AADHAR_EXACT_LENGTH) {
            this.props.showAlert(
                "Your aadhar number must contain exactly " +
                AADHAR_EXACT_LENGTH +
                " and " +
                "no special characters. (+)",
                "error"
            );
            this.setState({ showLoading: false });
            return null;
        }
        if (personalData.panNo.length !== PAN_EXACT_LENGTH) {
            this.props.showAlert(
                "Your pan number must contain exactly " +
                PAN_EXACT_LENGTH +
                " and " +
                "no special characters. (+)",
                "error"
            );
            this.setState({ showLoading: false });
            return null;
        }
        if (personalData.fullName.length < NAME_MIN_LENGTH) {
            this.props.showAlert(
                "full Name length should be greater than " + NAME_MIN_LENGTH + ".",
                "error"
            );
            this.setState({ showLoading: false });
            return null;
        }

        //alert(" data" + JSON.stringify(personalData));


        profilePersonalData(personalData)
            .then((response) => {
                const data = response.data
                this.props.showAlert(data.message, "success");
                this.setState({ showLoading: false });

                this.setState({
                    firstName: '', lastName: '', fullName: '',
                    panNo: '', aadharNo: '', dateOfBirth: '', presentAddress: '', permanentAddress: '',
                    phoneNo: '', emergencyContactNo: '', fatherDob: '', motherDob: '',
                    fatherName: '', motherName: '', bloodGroup: '', genderStatus: ''
                })

            })
            .catch((error) => {
                this.props.showAlert(error);
                this.setState({ showLoading: false });
            });


    };



    render() {
        return (
            <div>
                
                <h2>Personal Onboard</h2>
                <hr />
                <div>
                    {
                        this.state.showLoading
                            ?
                            <div className="align-content-center text-center">
                                <h3>Updating your personal data</h3>
                                <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
                            </div>
                            :
                            <form onSubmit={this.handleOnboardPersonalProfile}>
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text" className="form-control" id="firstName" name='firstName'
                                        placeholder="Enter your First Name" required autoComplete="on"
                                        onChange={this.updateFirstName}

                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="LastName">Last Name</label>
                                    <input type="text" className="form-control" id="lastName"
                                        placeholder="Enter your Last Name" required autoComplete="on" name='lastName'
                                        onChange={this.updateLastName}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="fullName">Full Name</label>
                                    <input type="text" className="form-control" id="fullName" name='fullName'
                                        placeholder="Enter your Full Name" required autoComplete="on"
                                        onChange={this.updateFullName}
                                    />
                                </div>


                                <div className="form-group">
                                        <label htmlFor="panNo">Pancard No</label>
                                    <input type="text" className="form-control" id="panNo" name='panNo'
                                        placeholder="Enter your Pancard No" required autoComplete="on"
                                        onChange={this.updatePanNo}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="aadharNo">Aadhar No</label>
                                    <input type="text" className="form-control" id="aadharNo" name='aadharNo'
                                        placeholder="Enter your Aadharcard No" required autoComplete="on"
                                        onChange={this.updateAadharNo}
                                    />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="dateOfBirth">Date Of Birth</label>
                                    <input type="date" className="form-control" id="dateOfBirth" name='dateOfBirth'
                                        required autoComplete="on"
                                        onChange={this.updateDob}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="genderStatus">Gender Status</label>

                                    <select
                                        className="custom-select"
                                        id="GenderStatus"
                                        placeholder="Select gender status"
                                        onChange={this.updateGenderStatus}
                                    ><option value="Gender Status">Gender Status</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Transgender">Transgender</option>

                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="presentAddress">Present Address</label>
                                    <input type="text" className="form-control" id="presentAddress" name='presentAddress'
                                        placeholder="Enter your present address" required autoComplete="on"
                                        onChange={this.updatePresentAddress}
                                    />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="permanentAddress">Permanent Address</label>
                                    <input type="text" className="form-control" id="permanentAddress" name='permanentAddress'
                                        placeholder="Enter your permanent address" required autoComplete="on"
                                        onChange={this.updatePermanentAddress}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="bloodGroup">BloodGroup</label>
                                    <input type="text" className="form-control" id="bloodGroup" name='bloodGroup'
                                        placeholder="Enter your Blood Group" required autoComplete="on"
                                        onChange={this.updateBloodGroup}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phoneNo">Phone No</label>
                                    <input type="phone" className="form-control" id="phoneNo" name='phoneNo'
                                        placeholder="Enter your Phone No" required autoComplete="on"
                                        onChange={this.updatePhoneNo}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fatherName">Father Name</label>
                                    <input type="text" className="form-control" id="fatherName" name='fatherName'
                                        placeholder="Enter your Father Name" required autoComplete="on"
                                        onChange={this.updateFatherName}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="emergencyContactNo">Emergency Contact No</label>
                                    <input type="phone" className="form-control" id="emergencyContactNo" name='emergencyContactNo'
                                        placeholder="Enter your Emergency Contact No" required autoComplete="on"
                                        onChange={this.updateEmergencyContactNo}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="motherName">Mother Name</label>
                                    <input type="text" className="form-control" id="motherName" name='motherName'
                                        placeholder="Enter your Mother Name" required autoComplete="on"
                                        onChange={this.updateMotherName}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="fatherDob">Father DOB</label>
                                    <input type="date" className="form-control" id="fatherDob" name='fatherDob'
                                        required autoComplete="on"
                                        onChange={this.updateFatherDob}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="motherDob">Mother DOB</label>
                                    <input type="date" className="form-control" id="motherDob" name='motherDob'
                                        required autoComplete="on"
                                        onChange={this.updateMotherDob}
                                    />
                                </div>
                                <button className="btn btn-primary" >Submit</button>
                            </form>
                    }

                </div>

            </div>
        );
    }
}

export default withRouter(PersonalDataSettings);