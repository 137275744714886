import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Alert } from "reactstrap";

import BulkEmail from "./mail/bulkEmail.js";

class BulkEmailDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
    };

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  componentDidMount() {
    document.title = "Assessments dashboard";
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 5000);
    });
  };
  render() {
    let render;
    let menuItem;
    if (this.props.match.params.render === "bulkEmail") {
      render = <BulkEmail showAlert={this.props.showAlert} />;
    }

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2">
            <Nav vertical pills>
              {menuItem}
              <hr />
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
            {/**
                        <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light" >
                            <h4 className="alert-heading">Welcome to Email dashboard.</h4>
                            <hr/>
                            <p className="mb-0">
                                This is email interface, will allow you to manage email service.
                            </p>
                        </Alert>
                        **/}
            {render}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(BulkEmailDashboard);
