import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { jobContent } from '../../content/PageContent';
import { jobRequirements } from '../../content/PageContent';
import { NavLink, withRouter, Link } from "react-router-dom";


class JobFresher extends Component {
  state = {

    showLoading: false,
    showCamundaDeveloper: false,
    showFullStackDeveloper: false,
    showBackendDeveloper: false,
    showBackendDeveloper: false,
    showMobileDeveloper: false,
    showUIDeveloper: false,
    showHRManager: false,
    showApiTesting: false,
    showSQLDeveloper: false,
    showDigitalMarketing: false,
    
  };
  constructor(props) {
    super(props);

    this.toggleCamundaDeveloper = this.toggleCamundaDeveloper.bind(this);
    this.toggleFullStackDeveloper = this.toggleFullStackDeveloper.bind(this);
    this.toggleBackendDeveloper = this.toggleBackendDeveloper.bind(this);
    this.toggleMobileDeveloper = this.toggleMobileDeveloper.bind(this);
    this.toggleUIDeveloper = this.toggleUIDeveloper.bind(this);
    this.toggleDevopsEngineer = this.toggleDevopsEngineer.bind(this);
    this.toggleHRManager = this.toggleHRManager.bind(this);
    this.toggleApiTesting = this.toggleApiTesting.bind(this);
    this.toggleSQLDeveloper = this.toggleSQLDeveloper.bind(this);
    this.toggleDigitalMarketing = this.toggleDigitalMarketing.bind(this);
  }

  toggleCamundaDeveloper() {
    this.setState({ showCamundaDeveloper: !this.state.showCamundaDeveloper });
  }
  toggleFullStackDeveloper() {
    this.setState({ showFullStackDeveloper: !this.state.showFullStackDeveloper });
  }
  toggleBackendDeveloper() {
    this.setState({ showBackendDeveloper: !this.state.showBackendDeveloper });
  }
  toggleMobileDeveloper() {
    this.setState({ showMobileDeveloper: !this.state.showMobileDeveloper });
  }
  toggleUIDeveloper() {
    this.setState({ showUIDeveloper: !this.state.showUIDeveloper });
  }
  toggleDevopsEngineer() {
    this.setState({ showDevopsEngineer: !this.state.showDevopsEngineer });
  }
  toggleHRManager() {
    this.setState({ showHRManager: !this.state.showHRManager });
  }
  toggleApiTesting() {
    this.setState({ showApiTesting: !this.state.showApiTesting });
  }
  toggleSQLDeveloper() {
    this.setState({ showSQLDeveloper: !this.state.showSQLDeveloper });
  }
  toggleDigitalMarketing() {
    this.setState({ showDigitalMarketing: !this.state.showDigitalMarketing });
  }
  componentDidMount() {
    document.title = "My account settings"
  }

  render() {
    return (
      <div>
         <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.camunda}
              </h4>

            </div>

            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/camunda.png")} />
                   
                  </Link>
                </div>
                <div className='col-lg-9'>
                  <p className="card-title text-left">
                 {jobContent.content0}

                  </p>

                  <p className="alert-link page-link"
                    onClick={this.toggleCamundaDeveloper}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showCamundaDeveloper}
                    toggle={this.toggleCamundaDeveloper}>
                  
                    <ModalBody>
                    <ModalHeader toggle={this.toggleCamundaDeveloper}> <small>{jobRequirements.header01}</small></ModalHeader>
                      <p><small>
                       {jobRequirements.description0}
                       </small>  </p>
                     
                      <ModalHeader><small>{jobRequirements.header02}</small></ModalHeader>
                     
                      <p><small>
                       {jobRequirements.Responsibilities0}
                       </small>  </p>
                      
                      <ModalHeader><small>{jobRequirements.header03}</small></ModalHeader>
                     
                      <p><small>
                       {jobRequirements.skils0}  </small>
                      </p>

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleCamundaDeveloper}>Close</Button>

                      </ModalBody>
                  </Modal>

                  <p className="card-title text-left">

                    {jobContent.eligibility}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "CamundaDeveloper",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>


                </div>
              </div>


            </div>
          </div>

        </div>
        <br></br>
        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.fullStack}
              </h4>

            </div>

            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/full-stack-developer140x140.png")} />

                  </Link>
                </div>
                <div className='col-lg-9'>
                  <p className="card-title text-left">
                 {jobContent.content}

                  </p>

                  <p className="alert-link page-link"
                    onClick={this.toggleFullStackDeveloper}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showFullStackDeveloper}
                    toggle={this.toggleFullStackDeveloper}>
                  
                    <ModalBody>
                    <ModalHeader toggle={this.toggleFullStackDeveloper}> <small>{jobRequirements.header11}</small></ModalHeader>
                      <p><small>
                       {jobRequirements.description}
                       </small>  </p>
                     
                      <ModalHeader><small>{jobRequirements.header12}</small></ModalHeader>
                     
                      <p><small>
                       {jobRequirements.Responsibilities}
                       </small>  </p>
                      
                      <ModalHeader><small>{jobRequirements.header13}</small></ModalHeader>
                     
                      <p><small>
                       {jobRequirements.skils}  </small>
                      </p>

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleFullStackDeveloper}>Close</Button>

                      </ModalBody>
                  </Modal>

                  <p className="card-title text-left">

                    {jobContent.eligibility}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "Full Stack Developer",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>


                </div>
              </div>


            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.backend}
              </h4>

            </div>
            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/java.png")} />
                   
                  </Link>
                </div>
                <div className='col-lg-9'>
                  <p className="card-title text-left">

                    {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                    {jobContent.content1}

                  </p>
                  <br></br>
                  <p className="alert-link page-link"
                    onClick={this.toggleBackendDeveloper}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showBackendDeveloper}
                    toggle={this.toggleBackendDeveloper}>
                   
                    <ModalBody>
                    <ModalHeader toggle={this.toggleBackendDeveloper}><small>{jobRequirements.header21}</small></ModalHeader>
                      <small>
                      <p>
                       {jobRequirements.description1}
                      </p></small>
                     
                      <ModalHeader><small>{jobRequirements.header22}</small></ModalHeader>
                    
                      <small> <p>
                       {jobRequirements.Responsibilities1}
                      </p> </small>
                    
                      <ModalHeader><small>{jobRequirements.header23}</small></ModalHeader>
                    
                      <small>  <p>
                       {jobRequirements.skils1}
                      </p> </small>
                      <Button color="secondary" className="float-right"
                        onClick={this.toggleBackendDeveloper}>Close</Button>
                      </ModalBody>
                   
                  </Modal>
                  <p className="card-title text-left">

                    {jobContent.eligibility}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "Backend Developer",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>
                </div>

              </div>
            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.mobile}
              </h4>

            </div>
            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/mobile-apps-circle-250sq140x140.png")} />
                  
                  </Link>
                </div>
                <div className='col-lg-9'>
                  <p className="card-title text-left">

                    {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                    {jobContent.content2}

                  </p>
                  <br></br>
                  <p className="alert-link page-link"
                    onClick={this.toggleMobileDeveloper}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showMobileDeveloper}
                    toggle={this.toggleMobileDeveloper}>
                    
                    <ModalBody>
                    <ModalHeader toggle={this.toggleMobileDeveloper}><small>{jobRequirements.header31}</small></ModalHeader>
                    <small><p>
                       {jobRequirements.description2}
                      </p></small>
                    
                      <ModalHeader><small>{jobRequirements.header32}</small></ModalHeader>
                     
                      <small><p>
                       {jobRequirements.Responsibilities2}
                      </p></small>
                     
                      <ModalHeader><small>{jobRequirements.header33}</small></ModalHeader>
                     
                      <small><p>
                       {jobRequirements.skils2}
                      </p></small>

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleMobileDeveloper}>Close</Button>
                    </ModalBody>
                  </Modal>
                  <p className="card-title text-left">

                    {jobContent.eligibility}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "Mobile App Developer (Android/IOs)",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>
                </div>


              </div>


            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.uiDeveloper}
              </h4>

            </div>
            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/UI developer140x140.png")} />
                   
                  </Link>
                </div>
                <div className='col-lg-9'>
                  <p className="card-title text-left">

                    {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                    {jobContent.content3}

                  </p>
                  <br></br>
                  <p className="alert-link page-link"
                    onClick={this.toggleUIDeveloper}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showUIDeveloper}
                    toggle={this.toggleUIDeveloper}>
                   
                    <ModalBody>
                    <ModalHeader toggle={this.toggleUIDeveloper}><small>{jobRequirements.header41}</small></ModalHeader>
                    <small> <p>
                       {jobRequirements.description3}
                      </p></small> 
                     
                      <ModalHeader><small>{jobRequirements.header42}</small> </ModalHeader>
                    
                      <small>  <p>
                       {jobRequirements.Responsibilities3}
                      </p></small> 
                    
                      <ModalHeader><small>{jobRequirements.header43}</small> </ModalHeader>
                     
                      <small>  <p>
                       {jobRequirements.skils3}
                      </p></small> 
                      <Button color="secondary" className="float-right"
                        onClick={this.toggleUIDeveloper}>Close</Button>
                      </ModalBody>

                  </Modal>
                  <p className="card-title text-left">

                    {jobContent.eligibility}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "UI Developer",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.devops}
              </h4>

            </div>
            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/DevOpsAWS140x140.png")} />
                   
                  </Link>
                </div>
                <div className='col-lg-9'>
                  <p className="card-title text-left">

                    {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                    {jobContent.content4}

                  </p>
                  <br></br>
                  <p className="alert-link page-link"
                    onClick={this.toggleDevopsEngineer}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showDevopsEngineer}
                    toggle={this.toggleDevopsEngineer}>
                 
                    <ModalBody>
                    <ModalHeader toggle={this.toggleDevopsEngineer}><small>{jobRequirements.header51}</small></ModalHeader>
                    <small> <p>
                       {jobRequirements.description4}
                      </p></small>
                    
                      <ModalHeader> <small>{jobRequirements.header52}</small></ModalHeader>
                    
                      <small>  <p>
                       {jobRequirements.Responsibilities4}
                      </p></small>
                     
                      <ModalHeader><small>{jobRequirements.header53}</small></ModalHeader>
                      
                      <small> <p>
                       {jobRequirements.skils4}
                      </p></small>
                     

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleDevopsEngineer}>Close</Button>
                    </ModalBody>

                  </Modal>
                  <p className="card-title text-left">

                    {jobContent.eligibility}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "AWS Cloud Dev Ops Engineer",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>
                </div>


              </div>


            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.hrManager}
              </h4>

            </div>
            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/hrmanager140x140.png")} />
                   
                  </Link>
                </div>
                <div className='col-lg-9'>


                  <p className="card-title text-left">

                    {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                    {jobContent.content5}

                  </p>
                  <br></br>
                  <p className="alert-link page-link"
                    onClick={this.toggleHRManager}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showHRManager}
                    toggle={this.toggleHRManager}>
                  
                    <ModalBody>
                    <ModalHeader toggle={this.toggleHRManager}><small>{jobRequirements.header61}</small></ModalHeader>
                    <small> <p>
                       {jobRequirements.description5}
                      </p></small>
                    
                      <ModalHeader><small>{jobRequirements.header62}</small></ModalHeader>
                     
                      <small>  <p>
                       {jobRequirements.Responsibilities5}
                      </p></small>
                     
                      <ModalHeader><small>{jobRequirements.header63}</small></ModalHeader>
                     
                      <small>  <p>
                       {jobRequirements.skils5}
                      </p></small>

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleHRManager}>Close</Button>
                      </ModalBody>
                   
                  </Modal>
                  <p className="card-title text-left">

                    {jobContent.eligibility1}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "HR Manager",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>
                </div>


              </div>
            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.apiTesting}
              </h4>

            </div>
            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/apitest140x140.png")} />
                  
                  </Link>
                </div>
                <div className='col-lg-9'>

                  <p className="card-title text-left">

                    {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                    {jobContent.content6}

                  </p>
                  <br></br>
                  <p className="alert-link page-link"
                    onClick={this.toggleApiTesting}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showApiTesting}
                    toggle={this.toggleApiTesting}>
                 
                    <ModalBody>
                    <ModalHeader toggle={this.toggleApiTesting}><small>{jobRequirements.header71}</small></ModalHeader>
                    <small> <p>
                       {jobRequirements.description6}
                      </p></small>
                      
                      <ModalHeader><small>{jobRequirements.header72}</small></ModalHeader>
                   
                      <small>   <p>
                       {jobRequirements.Responsibilities6}
                      </p> </small>
                     
                      <ModalHeader><small>{jobRequirements.header73}</small></ModalHeader>
                     
                      <small>  <p>
                       {jobRequirements.skils6}
                      </p></small>

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleApiTesting}>Close</Button>
                      </ModalBody>

                  </Modal>

                  <p className="card-title text-left">

                    {jobContent.eligibility}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "API Test Engineer",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.sqlDeveloper}
              </h4>

            </div>
            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/sqln.png")} />
                   
                  </Link>
                </div>
                <div className='col-lg-9'>
                  <p className="card-title text-left">

                    {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                    {jobContent.content7}

                  </p>
                  <br></br>
                  <p className="alert-link page-link"
                    onClick={this.toggleSQLDeveloper}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showSQLDeveloper}
                    toggle={this.toggleSQLDeveloper}>
                   
                    <ModalBody>
                    <ModalHeader toggle={this.toggleSQLDeveloper}><small>{jobRequirements.header81}</small></ModalHeader>
                    <small><p>
                       {jobRequirements.description6}
                      </p></small>  
                     
                      <ModalHeader><small>{jobRequirements.header82}</small>  </ModalHeader>
                   
                      <small><p>
                       {jobRequirements.Responsibilities6}
                      </p> </small>  
                    
                      <ModalHeader><small>{jobRequirements.header83}</small>  </ModalHeader>
                    
                      <small> <p>
                       {jobRequirements.skils6}
                      </p></small>  

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleSQLDeveloper}>Close</Button>
                      </ModalBody>

                  </Modal>
                  <p className="card-title text-left">

                    {jobContent.eligibility}
                    <br></br>
                    {jobContent.salary}
                    <br></br>
                    {jobContent.location}
                  </p>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "fresher",
                        careerCost: "0",
                        appliedFor: "SQL Developer",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {jobContent.digitalMarketing}
              </h4>

            </div>
            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/digitalmarketing140x140.png")} />
                  
                  </Link>
                </div>
                <div className='col-lg-9'> 
              <p className="card-title text-left">

                {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                {jobContent.content8}

              </p>
              <br></br>
              <p className="alert-link page-link"
                    onClick={this.toggleDigitalMarketing}>
                    Learn more
                  </p>
                  <Modal isOpen={this.state.showDigitalMarketing}
                    toggle={this.toggleDigitalMarketing}>
                   
                    <ModalBody>
                    <ModalHeader toggle={this.toggleDigitalMarketing}><small>{jobRequirements.header91}</small></ModalHeader>
                    <small> <p>
                       {jobRequirements.description8}
                      </p> </small>
                     
                      <ModalHeader><small>{jobRequirements.header92}</small></ModalHeader>
                   
                      <small>  <p>
                       {jobRequirements.Responsibilities8}
                      </p> </small>
                    
                      <ModalHeader><small>{jobRequirements.header93}</small></ModalHeader>
                   
                      <small><p>
                       {jobRequirements.skils8}
                      </p> </small>

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleDigitalMarketing}>Close</Button>
                      </ModalBody>
                     
                    
                  </Modal>
              <p className="card-title text-left">

                {jobContent.eligibility1}
                <br></br>
                {jobContent.salary}
                    <br></br>
                {jobContent.location}
              </p>
              <br></br>
              <NavLink
                to={{
                  pathname: "/register",
                  jobApplicationProps: {
                    careerType: "fresher",
                    careerCost: "0",
                    appliedFor: "Digital Marketing Expert",
                  },
                }}
                exact
              >

                <button type="button" class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                  PROCEED
                </button>
              </NavLink>
              </div>
              </div>

            </div>
          </div>

        </div>
        <br></br>




      </div>


    );
  }
}

export default withRouter(JobFresher);