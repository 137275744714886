import React, { Component } from "react";

import { privacyInfo } from "../content/PrivacyContent";
import { Fade } from "react-reveal";


class Privacy extends Component {
    render() {
        return (
                    <div className="container">
                        <div className="main contact-margin-top" id="contact">
                            <div className="contact-div-main">
                                <div className="contact-header">
                                    <label></label>
                                <p className={"pages-start-text"}>{privacyInfo.main} </p>
                                    <h1 className={"pages-mainheader-text"}>{privacyInfo.title}</h1>
                                    <h1 className={"pages-subhead-text"}> <strong> {privacyInfo.title1}</strong></h1>
                                    <p className={"pages-content-text"} >{privacyInfo.subtitle} </p>
                                    <p className={"pages-content-text"} >{privacyInfo.content1}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content2}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content3}</p>
                                    <p className={"pages-content-text"}><strong>{privacyInfo.content4}</strong></p>
                                    <p>
                                        <li className={"pages-content-text"}>{privacyInfo.content5}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content6}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content7}</li>
                                    </p>
                                    <h1 className={"pages-subhead-text"}><strong> {privacyInfo.title2}</strong></h1>
                                    <p className={"pages-content-text"}>{privacyInfo.content8}</p>
                                    <h1 className={"pages-subhead-text"}><strong> {privacyInfo.title3}</strong> </h1>
                                    <p className={"pages-content-text"}> {privacyInfo.definition1}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.definition2} </p>
                                    <h1 className={"pages-subhead-text"}><strong>{privacyInfo.title4}</strong></h1>
                                    <p className={"pages-content-text"}>{privacyInfo.content9}</p>
                                    <p className={"pages-content-text"}><strong>{privacyInfo.definition3}</strong></p>
                                    <p className={"pages-content-text"}>{privacyInfo.content10}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content11}</p>
                                    <p className={"pages-content-text"}><strong>{privacyInfo.definition4}</strong></p>
                                    <p className={"pages-content-text"}>{privacyInfo.content12}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content13}</p>
                                    <p>
                                        <li className={"pages-content-text"}>{privacyInfo.content14}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content15}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content16}</li>
                                    </p>
                                    <p className={"pages-content-text"}><strong>{privacyInfo.definition5}</strong></p>
                                    <p className={"pages-content-text"}>{privacyInfo.content17}</p>
                                    <p>
                                        <li className={"pages-content-text"}> {privacyInfo.content18}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content19}</li>
                                    </p>
                                    <p className={"pages-content-text"}><strong>{privacyInfo.definition6}</strong></p>
                                    <p className={"pages-content-text"}>{privacyInfo.content20}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content21}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content22}</p>
                                    <h1 className={"pages-subhead-text"}><strong>{privacyInfo.title5}</strong></h1>
                                    <p className={"pages-content-text"}>{privacyInfo.content23}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content24}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content25}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content26}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content27}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content28}</p>
                                    <p>
                                        <li className={"pages-content-text"}>{privacyInfo.content29}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content30}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content31}</li>
                                    </p>
                                    <p className={"pages-content-text"}>{privacyInfo.content32}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content33}</p>
                                    <p className={"pages-content-text"}><strong>{privacyInfo.definition7}</strong></p>
                                    <p className={"pages-content-text"}>{privacyInfo.content34}</p>
                                    <h1 className={"pages-subhead-text"}><strong>{privacyInfo.title6}</strong></h1>
                                    <p className={"pages-content-text"}>{privacyInfo.content35}</p>
                                    <p className={"pages-content-text"}>{privacyInfo.content36}</p>
                                    <p>
                                        <li className={"pages-content-text"}>{privacyInfo.content37}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content38}</li>
                                        <li className={"pages-content-text"}>{privacyInfo.content39}</li>
                                    </p>
                                   
                                    
                                </div>
                            </div>
                        </div>
                        <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          
                    </div>
                
            
        )
    }
}
 export default Privacy;