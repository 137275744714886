import React, { Component } from "react";
import { withRouter } from "react-router-dom";


class PaternityLeaves extends Component {
    render() {
        return (
            <>
                <h3 className={"pages-mainheader-text"}> Paternity Leaves </h3>
                <label></label>
                <p className={"pages-content-text"}>To provide male Employees opportunities to bond with and take care of his new born child and enjoy fatherhood.</p>
                <label></label>
                <p>
                    <li className={"pages-content-text"}>A male employee is entitled to 5 working days of paternity leave in case of child birth.</li>
                    <li className={"pages-content-text"} > Paternity leave is also allowed in case of unfortunate event including still born child or, miscarriage or miscarriage of a child.</li>
                    <li className={"pages-content-text"} >These leaves are Company paid leave.</li>
                    <li className={"pages-content-text"} >Intervening holidays like weekends and public holidays falling within the leave period, will not be taken into account.</li>
                    
                    
                </p>
                
            </>
        )

    }
          
        

    
}
export default withRouter(PaternityLeaves);