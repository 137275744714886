import React, { Component } from "react";
import axios from "axios";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./ui/common/home/Home";
import About from "./ui/common/home/About";
import Services from "./ui/common/home/Services";
import PaymentGatewaySuccessPage from "./ui/common/home/PaymentGatewaySuccessPage";
import Banking from "./ui/common/home/Banking";
import Contact from "./ui/common/home/Contact";
import Team from "./ui/common/home/Team";
import Business from "./ui/common/business/Business";
import Careers from "./ui/common/careers/Careers";
import Login from "./ui/common/login/Login";
import Register from "./ui/common/register/Register";
import Settings from "./ui/user/account/Account";
import ResetPassword from "./ui/common/resetPassword/ResetPassword";
import ConfirmSubscription from "./ui/common/register/ConfirmSubscription";
import ConfirmUnSubscription from "./ui/common/register/ConfirmUnSubscription";
import NotFound from "./generic/notfound/NotFound";
import AdminDashboard from "./ui/admin/dashboard/AdminDashboard";
import HrDashboard from "./ui/hr/dashboard/HrDashboard";
import EmployeeDashboard from "./ui/employee/dashboard/EmployeeDashboard";
import ClientDashboard from "./ui/client/dashboard/ClientDashboard";
import FinancierDashboard from "./ui/financier/dashboard/FinancierDashboard";
import ProfilesDashboard from "./ui/client/profiles/dashboard/ProfilesDashboard";
import AppHeader from "./generic/header/AppHeader";
import AppFooter from "./generic/footer/AppFooter";
import AssessmentDashboard from "./ui/user/assessment/AssessmentDashboard";
import BulkEmailDashboard from "./ui/user/email/bulkEmail/BulkEmailDashboard";
import StudentAnswer from "./ui/hr/dashboard/trackAssessments/StudentAnswer";
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  HR_TOKEN,
  EMPLOYEE_TOKEN,
  CLIENT_TOKEN,
  CONTENT_ENDPOINTS,
  FINANCIER_TOKEN,
} from "./config/Config";
import SplashScreen from "./ui/common/splashScreen/SplashScreen";
import { splashScreenContent } from "./ui/common/content/PageContent";
import HireSubscriptionPricing from "./ui/common/business/HireSubscriptionPricing";
import DevSubscriptionPricing from "./ui/common/business/DevSubscriptionPricing";
import Privacy from "./ui/common/policy/privacy";
import CookiePolicy from "./ui/common/policy/cookiePolicy";
import Terms from "./ui/common/policy/terms";
import CancellationPolicy from "./ui/common/policy/CancellationPolicy";
import FrontendViewProfile from "./ui/client/profiles/dashboard/frontend/FrontendViewProfile";
import FullStackViewProfile from "./ui/client/profiles/dashboard/fullstack/FullStackViewProfile";
import BackendViewProfile from "./ui/client/profiles/dashboard/backend/BackendViewProfile";
import IamViewProfile from "./ui/client/profiles/dashboard/iam/IamViewProfile";
import AssessmentAttempt from "./ui/user/assessment/assessments/AssessmentAttempt";
import ValidateAssessment from "./ui/hr/dashboard/trackAssessments/ViewAnswers";
import ApplicantsAnswers from "./ui/hr/dashboard/trackAssessments/ApplicantsAnswers";
import ContentManagement from "./ui/common/home/ContentManagement";
import PublishContent from "./ui/user/publish/PublishContent";
import { getEndpoints } from "./api/Api";
import FlexplmViewProfile from "./ui/client/profiles/dashboard/flexplm/FlexplmViewProfile";
import UpdateContent from "./ui/user/publish/editcontent/UpdateContent";
import ViewClient from "./ui/financier/dashboard/payment/ViewClient";
import ManualSubscription from "./ui/financier/dashboard/manualSubscription/ManualSubscription";

class App extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      isShowingSplashAnimation: true,
      endpoints: [],
      stateList: [],
    };
    this.showAlert = this.showAlert.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem(CONTENT_ENDPOINTS);
    this.getEndpoints();

    this.setTimer();
    this.isAuthenticated();
  }
  getEndpoints = () => {
    this.setState({ showLoading: true });

    getEndpoints()
      .then((res) => {
       
        let firstEndpoint;
        let contentStateList;
        let contentDetailsData = [];
        if (res.data === null) {
          this.showAlert(res.message, "error");
        } else {
          this.setState({ endpoints: res.data });
          //this.setState({stateList:res.data.contentStateList})
          for (let i = 0; i < res.data.length; i++) {
            //alert("before123" + JSON.stringify(res.data[i]));

            //alert("1234566" + JSON.stringify(res.data[i]));
            firstEndpoint = res.data[i];
            contentStateList = firstEndpoint.contentStateList;
            //alert("string"+JSON.stringify(contentStateList));
            for (let j = 0; j < contentStateList.length; j++) {
              contentDetailsData = contentStateList[j];
            }
          }
          //alert("datacoming234"+JSON.stringify(contentDetailsData));
          this.setState({ stateList: contentDetailsData });

          //alert("Data"+JSON.stringify(res.data));
          //alert("data retrived"+JSON.stringify(res.data.contentStateList[0]));
          //localStorage.setItem(CONTENT_ENDPOINTS, JSON.stringify(res.data));
        }

        this.setState({ showLoading: false });
      })

      .catch((error) => {
        if (error.status === 401) {
          this.showAlert(
            "Your are not eligible to access this endpoint",
            "error"
          );
        } else {
          this.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  componentDidUpdate() {
    this.isAuthenticated();
  }
  componentWillUnmount = () => {
    this.clearTimer();
  };
  setTimer = () => {
    if (this.timerHandle) {
      // Exception?
      return;
    }
    // Remember the timer handle
    this.timerHandle = setTimeout(() => {
      this.setState({ isShowingSplashAnimation: false });
      this.timerHandle = 0;
    }, splashScreenContent.duration);
  };

  clearTimer = () => {
    // Is our timer running?
    if (this.timerHandle) {
      // Yes, clear it
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  };
  showAlert = (message, type) => {
    const Noty = require("noty");
    new Noty({
      text: message,
      timeout: 5000,
      type: type,
      theme: "metroui",
      layout: "topLeft",
      closeWith: ["button"],
    }).show();
  };

  logout() {
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ADMIN_TOKEN);
    localStorage.removeItem(HR_TOKEN);
    localStorage.removeItem(EMPLOYEE_TOKEN);
    localStorage.removeItem(CLIENT_TOKEN);
    localStorage.removeItem(FINANCIER_TOKEN);
    this.showAlert("Your are no longer logged in !", "success");
    this.props.history.push("/");
  }

  isAuthenticated() {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (
      token !== "undefined" &&
      token !== undefined &&
      token !== null &&
      token !== "null" &&
      token !== ""
    ) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      token = JSON.parse(window.atob(base64));
      // console.log(exp, Math.floor(Date.now()/1000));
      if (token.exp <= Math.floor(Date.now() / 1000)) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(ADMIN_TOKEN);
        localStorage.removeItem(HR_TOKEN);
        localStorage.removeItem(EMPLOYEE_TOKEN);
        localStorage.removeItem(CLIENT_TOKEN);
        localStorage.removeItem(FINANCIER_TOKEN);
        this.showAlert("Your session has expired !", "info");
        this.props.history.push("/");
        return false;
      }

      //CHECK IF USER HAS ROLE_ADMIN

      if (token.rol.length >= 1) {
        //alert('token role'+token.rol[0])
        if (token.rol[0] && token.rol[0] === "ADMIN") {
          localStorage.setItem(ADMIN_TOKEN, token.rol[0]);
          /*  let roles = [];

                    Object.entries(token.rol).forEach(([key, value]) => {
                        roles.push(value.authority);
                    });
                    for(let i = 0; i < roles.length; i++){
                        if(roles[i] === "USER"){
                            alert(roles[i])
                            
                        }
                    }*/
        } else if (token.rol[0] && token.rol[0] === "HR") {
          localStorage.setItem(HR_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "EMPLOYEE") {
          // alert("inside employee rol"+token.rol[0])
          localStorage.setItem(EMPLOYEE_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "CLIENT") {
          localStorage.setItem(CLIENT_TOKEN, token.rol[0]);
        } else if (token.rol[0] && token.rol[0] === "FINANCIER") {
          localStorage.setItem(FINANCIER_TOKEN, token.rol[0]);
        }
      }
      return true;
    } else {
      return false;
    }
  }

  render() {
    let routes = [];
    let adminRoutes = [];
    let hrRoutes = [];
    let employeeRoutes = [];
    let clientRoutes = [];
    let financierRoutes = [];
    let cmsRoutes = [];

    for (let i = 0; i < this.state.endpoints.length; i++) {
      this.state.endpoints[i].contentStateList.map(
        (endpointData, endpointIndex) =>
          cmsRoutes.push(
            <Route
              key={endpointIndex + i + 25}
              exact
              path={"/" +this.state.endpoints[i].category+"-"+endpointData.endpoint}
              render={(props) => (
                <ContentManagement
                  {...props}
                  showAlert={this.showAlert}
                  category={this.state.endpoints[i].category}
                  endpoint={endpointData.endpoint}
                />
              )}
            />
          )
      );
    }

    // console.log("cms route"+JSON.stringify(cmsRoutes));
    if (this.isAuthenticated()) {
      routes = [
        <Route
          key={1}
          exact
          path="/me/settings/:render(account|security|curriculum|profile|accountdata|profilepic|skills)"
          render={(props) => <Settings {...props} showAlert={this.showAlert} />}
        />,
        <Route
          exact
          path="/me/assessment/:render(|assessments|onboard|result|request)"
          render={(props) => (
            <AssessmentDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/publish/:render(|content|editContent)"
          render={(props) => (
            <PublishContent
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/publish/editContent/updateContent"
          render={(props) => (
            <UpdateContent
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/me/assessment/onlineAssessment"
          render={(props) => (
            <AssessmentAttempt
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/client/dashboard/:render(|hire|projects|payment|request)"
          render={(props) => (
            <ClientDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/client/payment/banking"
          render={(props) => (
            <Banking
              {...props}
              showAlert={this.showAlert}
              notFound={false}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
      ];
    } else {
      routes = [
        <Route
          key={1}
          exact
          path="/login"
          render={(props) => <Login {...props} showAlert={this.showAlert} />}
        />,
        <Route
          key={2}
          exact
          path="/register"
          render={(props) => <Register {...props} showAlert={this.showAlert} />}
        />,
        <Route
          key={3}
          exact
          path="/reset-password/:token/:id"
          render={(props) => (
            <ResetPassword {...props} showAlert={this.showAlert} />
          )}
        />,
        <Route
          key={4}
          exact
          path="/subscribe/:token/:id"
          render={(props) => (
            <ConfirmSubscription {...props} showAlert={this.showAlert} />
          )}
        />,
        <Route
          key={5}
          exact
          path="/unsubscribe/:token/:id"
          render={(props) => (
            <ConfirmUnSubscription {...props} showAlert={this.showAlert} />
          )}
        />,
        <Route
          key={6}
          exact
          path="/privacy"
          render={(props) => <Privacy {...props} showAlert={this.showAlert} />}
        />,
        <Route
          key={7}
          exact
          path="/cookiePolicy"
          render={(props) => (
            <CookiePolicy {...props} showAlert={this.showAlert} />
          )}
        />,
        <Route
          key={8}
          exact
          path="/terms"
          render={(props) => <Terms {...props} showAlert={this.showAlert} />}
        />,
        <Route
          key={9}
          exact
          path="/cancellationPolicy"
          render={(props) => (
            <CancellationPolicy {...props} showAlert={this.showAlert} />
          )}
        />,
        <Route
          key={2}
          exact
          path="/hireSubscriptionPricing"
          render={(props) => (
            <HireSubscriptionPricing {...props} showAlert={this.showAlert} />
          )}
        />,
        <Route
          key={2}
          exact
          path="/devSubscriptionCost"
          render={(props) => (
            <DevSubscriptionPricing {...props} showAlert={this.showAlert} />
          )}
        />,
      ];
    }
    if (localStorage.getItem(ADMIN_TOKEN)) {
      adminRoutes = [
        <Route
          exact
          path="/admin/dashboard/:render(|index|httptrace|users|letterOfEmployment)"
          render={(props) => (
            <AdminDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        /> /** 
       <Route exact path='/employee/dashboard/:render(|applicants|probation|intern|external|letterHead)' render={(props) => (
            <EmployeeManagement {...props} showAlert={this.showAlert} isAuthenticated={this.isAuthenticated}/>
            )}/>,
        <Route exact path='/cost/dashboard/:render(|applicants|probation|intern|external|letterHead)' render={(props) => (
        <CostManagement {...props} showAlert={this.showAlert} isAuthenticated={this.isAuthenticated}/>
                  )}/>,*/,
      ];
    }
    if (localStorage.getItem(HR_TOKEN)) {
      hrRoutes = [
        <Route
          exact
          path="/hr/dashboard/:render(|applicants|probation|intern|internal|external|letterHead|clientDashboard|hireDeveloper|webApp|training|projectDevelopment|employee|trackAssessments|backlogs)"
          render={(props) => (
            <HrDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,

        <Route
          exact
          path="/hr/email/:render(|bulkEmail)"
          render={(props) => (
            <BulkEmailDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        
        <Route
          exact
          path="/hr/assessment/viewAnswers"
          render={(props) => (
            <ValidateAssessment
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/hr/assessment/validateAnswers"
          render={(props) => (
            <ApplicantsAnswers
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        ,
        <Route
          exact
          path="/hr/assessment/validateStudentAnswers"
          render={(props) => (
            <StudentAnswer
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
      ];
    }
    if (localStorage.getItem(EMPLOYEE_TOKEN)) {
      employeeRoutes = [
        <Route
          exact
          path="/employee/dashboard/:render(profile|time|lms|policy|leaveCirriculum|publicHolidays|sickLeave|vacationLeaves|maternityLeaves|paternityLeaves|otherLeaves|leaveWithoutPay)"
          render={(props) => (
            <EmployeeDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
      ];
    }
    if (localStorage.getItem(CLIENT_TOKEN)) {
      clientRoutes = [
        <Route
          exact
          path="/client/dashboard/:render(|hire|projects|payment|request)"
          render={(props) => (
            <ClientDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/client/profiles/:render(|backend|frontend|fullstack|flexplm|iam)"
          render={(props) => (
            <ProfilesDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/client/profiles/backend/viewprofile"
          render={(props) => (
            <BackendViewProfile
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/client/profiles/frontend/viewprofile"
          render={(props) => (
            <FrontendViewProfile
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/client/profiles/fullstack/viewprofile"
          render={(props) => (
            <FullStackViewProfile
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/client/profiles/flexplm/viewprofile"
          render={(props) => (
            <FlexplmViewProfile
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
        exact
        path="/client/profiles/iam/viewprofile"
        render={(props) => (
          <IamViewProfile
            {...props}
            showAlert={this.showAlert}
            isAuthenticated={this.isAuthenticated}
          />
        )}
      />,
        <Route
          exact
          path="/client/payment/banking"
          render={(props) => (
            <Banking
              {...props}
              showAlert={this.showAlert}
              notFound={false}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
      ];
    }
    if (localStorage.getItem(FINANCIER_TOKEN)) {
      financierRoutes = [
        <Route
          exact
          path="/financier/dashboard/:render(|hire|projects|clients|request|manualSubscription|paymentPending)"
          render={(props) => (
            <FinancierDashboard
              {...props}
              showAlert={this.showAlert}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/financier/dashboard/viewClient/"
          render={(props) => (
            <ViewClient
              {...props}
              showAlert={this.showAlert}
              notFound={false}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
        <Route
          exact
          path="/financier/dashboard/manualSubscription/"
          render={(props) => (
            <ManualSubscription
              {...props}
              showAlert={this.showAlert}
              notFound={false}
              isAuthenticated={this.isAuthenticated}
            />
          )}
        />,
      ]; 
    }
    if (this.state.isShowingSplashAnimation && splashScreenContent.enabled) {
      return <SplashScreen />;
    } else {
      return (
        <div>
          <AppHeader logout={this.logout} />
          <div className="app">
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home
                    {...props}
                    showAlert={this.showAlert}
                    notFound={false}
                  />
                )}
              />
              <Route
                exact
                path="/business"
                render={(props) => (
                  <Business
                    {...props}
                    showAlert={this.showAlert}
                    notFound={false}
                  />
                )}
              />
              <Route
                exact
                path="/banking"
                render={(props) => (
                  <Banking
                    {...props}
                    showAlert={this.showAlert}
                    notFound={false}
                  />
                )}
              />
              <Route
                exact
                path="/about"
                render={(props) => (
                  <About
                    {...props}
                    showAlert={this.showAlert}
                    notFound={false}
                  />
                )}
              />
              <Route
                exact
                path="/services"
                render={(props) => (
                  <Services
                    {...props}
                    showAlert={this.showAlert}
                    notFound={false}
                  />
                )}
              />
              <Route
                exact
                path="/paymentGatewaySuccessPage"
                render={(props) => (
                  <PaymentGatewaySuccessPage
                    {...props}
                    showAlert={this.showAlert}
                    notFound={false}
                  />
                )}
              />
              <Route
                exact
                path="/contact"
                render={(props) => (
                  <Contact
                    {...props}
                    showAlert={this.showAlert}
                    notFound={false}
                  />
                )}
              />
              <Route
                exact
                path="/team"
                render={(props) => (
                  <Team
                    {...props}
                    showAlert={this.showAlert}
                    notFound={false}
                  />
                )}
              />
              <Route
                exact
                path="/careers/:render(|jobs|training-jobs|education)"
                render={(props) => (
                  <Careers {...props} showAlert={this.showAlert} />
                )}
              />
              {routes}
              {cmsRoutes}
              {adminRoutes}
              {hrRoutes}
              {employeeRoutes}
              {clientRoutes}
              {financierRoutes}
              <Route
                render={(props) => (
                  <NotFound {...props} showAlert={this.showAlert} />
                )}
              />
            </Switch>
          </div>
          <AppFooter />
        </div>
      );
    }
  }
}

export default withRouter(App);