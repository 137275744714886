import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { devPricing } from "../content/PageContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "reactstrap";

class DevSubscriptionPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
    };

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  componentDidMount() {
    document.title = "DevSubscriptionPricing";
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 5000);
    });
  };

  render() {
    return (
      <div class="container">
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossorigin="anonymous"
        ></script>

        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
          {/**
          <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light topalert" >
            <h4 className="alert-heading">Thank you for choosing your mobile friendly web apps.</h4>
            <hr />
            <p className="mb-0">
              Please, Choose your option to start your online business.
            </p>
          </Alert> **/}
        </div>

        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">Pricing</h1>
          <p class="lead">{devPricing.header}</p>
          <small className="text-muted">{devPricing.noCards}</small>
        </div>
        <div class="card-deck mb-3 text-center">
          <div class="card mb-4 box-shadow">
            <div class="card-header">
              <h5 class="my-0 font-weight-normal">{devPricing.standard}</h5>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ₹ {devPricing.free} <small class="text-muted ">/ mo</small>
              </h1>
              <p className="card-title text-left">
                <small>
                  <FontAwesomeIcon icon={faCode} /> {devPricing.freeDevAccess}
                </small>{" "}
              </p>
              <p className="card-title text-left">
                <small>
                  <FontAwesomeIcon icon={faCode} /> {devPricing.freeDevUsers}
                </small>{" "}
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} /> {devPricing.freeDevPages}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.freeDevSecureOptions}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.freeDevAdditional}
                </small>
              </p>

              <NavLink
                to={{
                  pathname: "/register",
                  hirePricingProps: {
                    subscriptionFor: devPricing.standard,
                    subscriptionCost: "0",
                    subscriptionType: "PROJECT-webAppDev",
                  },
                }}
                exact
              >
             <br/>
                
                <button type="button" class="btn btn-lg btn-block btn-primary  my-md-5 ">
                  Sign up for free
                </button>
              </NavLink>
            </div>
          </div>
          <div class="card mb-4 box-shadow">
            <div class="card-header">
              <h5 class="my-0 font-weight-normal">{devPricing.premium}</h5>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ₹ {devPricing.premiumCost}
                <small class="text-muted text-left text-sm">/ mo</small>
              </h1>
              <p className="card-title text-left">
                <small>
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.premiumDevAccess}
                </small>{" "}
              </p>
              <p className="card-title text-left">
                <small>
                  <FontAwesomeIcon icon={faCode} /> {devPricing.premiumDevUsers}
                </small>{" "}
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} /> {devPricing.premiumDevPages}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.premiumDevSecureOptions}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.premiumDevSecureEmail}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.premiumDevAdditional}
                </small>
              </p>

              <NavLink
                to={{
                  pathname: "/register",
                  hirePricingProps: {
                    subscriptionFor: devPricing.premiumss,
                    subscriptionCost: devPricing.premiumCost,
                    subscriptionType: "PROJECT-webAppDev",
                  },
                }}
                exact
              >
               
                <button type="button" class="btn btn-lg btn-block btn-primary  my-md-4">
                  Get started
                </button>
              </NavLink>
            </div>
          </div>
          <div class="card mb-4 box-shadow">
            <div class="card-header">
              <h5 class="my-0 font-weight-normal">{devPricing.superPremium}</h5>
            </div>
            <div class="card-body">
            <h1 class="card-title pricing-card-title">
                ₹ {devPricing.superPremiumCost}
                <small class="text-muted text-left text-sm ">/ mo</small>
              </h1>

              <p className="card-title text-left">
                <small>
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.superPremiumDevAccess}
                </small>{" "}
              </p>
              <p className="card-title text-left">
                <small>
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.superPremiumDevUsers}
                </small>{" "}
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.superPremiumDevPages}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.superPremiumDevSecureOptions}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.superPremiumDevSecureEmail}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.superPremiumDevAdditional}
                </small>
              </p>

              <NavLink
                to={{
                  pathname: "/register",
                  hirePricingProps: {
                    subscriptionFor: devPricing.superPremium,
                    subscriptionCost: devPricing.superPremiumCost,
                    subscriptionType: "PROJECT-webAppDev",
                  },
                }}
                exact
              >
               <br/>
               
                <button type="button" class="btn btn-lg btn-block btn-primary my-md-5 ">
                  Get started
                </button>
                
              </NavLink>
            </div>
          </div>
          <div class="card mb-4 box-shadow">
            <div class="card-header">
              <h5 class="my-0  font-weight-normal">{devPricing.annumPremium}</h5>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ₹ {devPricing.annumPremiumCost}
                <small class="text-muted text-left text-sm ">/ An</small>
              </h1>
              <p className="card-title text-left">
                <small>
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.premiumDevAccess}
                </small>{" "}
              </p>
              <p className="card-title text-left">
                <small>
                  <FontAwesomeIcon icon={faCode} /> {devPricing.freeDevUsers}
                </small>{" "}
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} /> {devPricing.premiumDevPages}
                </small>
              </p>
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.premiumDevSecureOptions}
                </small>
              </p>
              
              <p className="card-title text-left">
                <small>
                  {" "}
                  <FontAwesomeIcon icon={faCode} />{" "}
                  {devPricing.premiumDevAdditional}
                </small>
              </p>

              <NavLink
                to={{
                  pathname: "/register",
                  hirePricingProps: {
                    subscriptionFor: devPricing.premium,
                    subscriptionCost: devPricing.premiumCost,
                    subscriptionType: "PROJECT-webAppDev",
                  },
                }}
                exact
              >
                <br/>
               
                <button type="button" class="btn btn-lg btn-block btn-primary  my-md-5">
                  Get started
                </button>
              </NavLink>
            </div>
          </div>
          
        </div>
        
        <div>
          <small className="text-muted">{devPricing.contactUs}</small>
          <br></br>
          <small class="text-muted" style={{ fontSize: 12 }}>
            {devPricing.excludeGST}
          </small>
          <hr></hr>
        </div>
        
      </div>
      
    );
  }
}

export default DevSubscriptionPricing;
