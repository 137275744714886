import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { publicHolidaysUpdate } from "../../../../../api/EmployeeApi"




class PublicHolidays extends Component {

    constructor(props) {

        super(props);

        this.state = {
            // Default year
            publicHolidayYear: new Date().getFullYear(),
            holidays: [],

        };

    }
    componentDidMount() {
        this.handleYearChange();
    }
    yearChange = (event) => {
        this.setState({ publicHolidayYear: event.target.value });
    }

    handleYearChange = event => {
        //event.preventDefault();
        this.setState({ showLoading: true });
        const publicHolidayYear = this.state.publicHolidayYear;
      //  alert("Datas" + JSON.stringify(publicHolidayYear));
        publicHolidaysUpdate(publicHolidayYear)
            .then((res) => {
                this.setState({ holidays: res.data.data });
               // alert("Datas123" + JSON.stringify(res.data));
            })
            .catch(error => {
                this.props.showAlert(error);
                this.setState({ showLoading: false });
            })

    };

    render() {

        const { publicHolidayYear, holidays } = this.state;
        //alert("showing holiday"+JSON.stringify(holidays));
        return (

            <div>

                <h2 className={"pages-mainheader-text"} style={{ color: 'blue' }}>Public Holidays: {publicHolidayYear}</h2>
                <form onSubmit={this.handleYearChange}>


                    <div className="form-group">
                        <label htmlFor="year"> Public Holiday Year:</label>
                        <select
                            className="w3-select"
                            name="option"
                            value={this.state.publicHolidayYear}
                            onChange={(event) => this.yearChange(event)}
                        >
                            <option value={2023}>2023</option>
                            <option value={2024}>2024</option>
                            <option value={2025}>2025</option>
                            <option value={2026}>2026</option>
                            <option value={2027}>2027</option>
                            <option value={2028}>2028</option>
                            <option value={2029}>2029</option>
                            <option value={2030}>2030</option>

                        </select>
                    </div>



                    <button type="submit" className="btn btn-primary ms-auto">
                        Submit
                    </button>
                </form>




                <div>
                    <table class="table table-bordered table-white table-sm w-75 p-3 h-20 -inline">

                        <thead >

                            <tr>

                                <th>Date</th>
                                <th>Day</th>


                            </tr>
                        </thead>
                        <tbody>
                            {
                                ((holidays === undefined) || (holidays === null))
                                    ?
                                    <div className="alert alert-danger" role="alert">
                                        <strong>Oops !</strong> Contact HR for public holidays
                                    </div>

                                    :
                                    (<>
                                        {holidays.map((holiday, index) => (

                                            <tr key={index}>
                                                <td>{holiday.holidayDate}</td>
                                                <td>{holiday.holidayName}</td>

                                            </tr>
                                        ))}
                                    </>

                                    )
                            }


                        </tbody>

                    </table>
                </div>

            </div>

        );

    }

}



export default withRouter(PublicHolidays);