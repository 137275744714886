import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SalaryStatement from "./SalaryStatement";
import ExperienceLetter from "./ExperienceLetter";
import { documentGeneration } from "../../../../api/AdminApi";


class OfferLetter extends Component {
  state = {
    offerLetterRequest: {
      employeeFirstName: "",
      employeeLastName: "",
      designation:"",
      offeredCTC: "",
      annualIncentive:"",
      experience:"",
      joiningDate:"",
    },


    showLoading: false,
    
  };

  componentDidMount() {
    document.title = "OfferLetter Generation";
  }


  updateEmployeeFirstName = (event) => {
    let req = this.state.offerLetterRequest;
    req.employeeFirstName = event.target.value;
    this.setState({ offerLetterRequest: req });
  };
  updateEmployeeLastName = (event) => {
    let req = this.state.offerLetterRequest;
    req.employeeLastName = event.target.value;
    this.setState({ offerLetterRequest: req });
  };
  updateDesignation = (event) => {
    let req = this.state.offerLetterRequest;
    req.designation = event.target.value;
    this.setState({ offerLetterRequest: req });
  };
  updateOfferedCTC = (event) => {
    let req = this.state.offerLetterRequest;
    req.offeredCTC = event.target.value;
    this.setState({ offerLetterRequest: req });
  };
  updateAnnualIncentive = (event) => {
    let req = this.state.offerLetterRequest;
    req.annualIncentive = event.target.value;
    this.setState({ offerLetterRequest: req });
  };
  updateExperience = (event) => {
    let req = this.state.offerLetterRequest;
    req.experience = event.target.value;
    this.setState({ offerLetterRequest: req });
  };
  updateJoiningDate = (event) => {
    let req = this.state.offerLetterRequest;
    req.joiningDate = event.target.value;
    this.setState({ offerLetterRequest: req });
  };
  

  documentGeneration= (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
  
    documentGeneration(this.state.offerLetterRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.offerLetterRequest.employeeFirstName+".pdf";

        link.click(); /*

        alert("res"+res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        alert("url"+url);
        const link = document.createElement('a');
        alert("link"+link);
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
*/
    
        this.props.showAlert(
          "offerLetter generated successfully !",
          "success"
        );
        this.setState({ showLoading: false });
        this.setState({offerLetterRequest:''})
      this.props.history.push("/admin/dashboard/letterOfEmployment");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
 


  render() {
    const { holidayData } = this.state;
    return (
      <div className="row" >
        <div className="col-md-6">
        <h3>OfferLetter Generation</h3>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>OfferLetter Generation initiated</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                in progress
              </i>
            </div>
          ) : (
            <form onSubmit={(e) => this.documentGeneration(e)}>
              <div>
                <p className="card-title text-center">offerLetter Generation</p>
            
                <div className="mt-2">
                  <label htmlFor="employeeFirstName">employeeFirstName</label>
                  <input
                    type="text"
                    id="employeeFirstName"
                    className="form-control"
                    placeholder="Enter your employeeFirstName"
                    required
                    onChange={this.updateEmployeeFirstName}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="employeeLastName">employeeLastName</label>
                  <input
                    type="text"
                    id="employeeLastName"
                    className="form-control"
                    placeholder="Enter your employeeLastName"
                    required
                    onChange={this.updateEmployeeLastName}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="designation">designation</label>
                  <input
                    type="text"
                    id="designation"
                    className="form-control"
                    placeholder="Enter your Designation"
                    required
                    onChange={this.updateDesignation}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="offeredCTC">offeredCTC</label>
                  <input
                    type="text"
                    id="offeredCTC"
                    className="form-control"
                    placeholder="Enter offeredCTC"
                    required
                    onChange={this.updateOfferedCTC}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="AnnualIncentive">annualIncentive</label>
                  <input
                    type="text"
                    id="annualIncentive"
                    className="form-control"
                    placeholder="Enter annualIncentive"
                    required
                    onChange={this.updateAnnualIncentive}
                    autoComplete="on"
                  />
                </div><div className="mt-2">
                  <label htmlFor="experience">experience</label>
                  <input
                    type="text"
                    id="experience"
                    className="form-control"
                    placeholder="Enter Experience"
                    required
                    onChange={this.updateExperience}
                    autoComplete="on"
                  />
                </div>
                
                <div className="mt-2">
                  <label htmlFor="joiningdate">joiningDate</label>
                  <input
                    type="date"
                    id="joiningdate"
                    className="form-control"
                    placeholder="Enter a joiningdate"
                    required
                    onChange={this.updateJoiningDate}
                    autoComplete="on"
                  />
                </div>
              
                <br></br>
               
                <button
                  className="btn btn-primary btn-block text-uppercase mt-3"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          )}
        </div>
        </div>
        <div className="col-md-6">
        <SalaryStatement></SalaryStatement>
        </div>
        <div className="col-md-6">
        <ExperienceLetter props={this.props}></ExperienceLetter>
      
        </div>
      </div>
    );
  }
}

export default withRouter(OfferLetter);
