import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  illustration,
  greeting,
  socialMediaLinks,
} from "../content/PageContent";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DisplayLottie from "../splashScreen/displayLottie/DisplayLottie";
import landingPerson from "../../../assets/lottie/menandwomen.json";
import Business from "../business/Business";
import { COOKIE_PREF } from "../../../config/Config";

import oneone from "../../../assets/images/img/about/1.jpg";
import onetwo from "../../../assets/images/img/about/2.jpg";
import onethree from "../../../assets/images/img/about/3.jpg";
import onefour from "../../../assets/images/img/about/4.jpg";
import teamone from "../../../assets/images/img/team/1.jpg";
import teamtwo from "../../../assets/images/img/team/2.jpg";
import teamthree from "../../../assets/images/img/team/3.jpg";

class Contact extends Component {
  state = {
    showCookieModal: false,
    showChooseCookieModal: false,
    cookieRequest: {
      socialMedia: false,
      essential: false,
      commercial: false,
      fullCookie: false,
    },
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.title = "Home page";
  }
  componentDidUpdate() {
    if (this.props.notFound === true) {
      this.props.showAlert("Page not found ! Back to the home page.", "error");
    }
  }

  render() {
    return (
      <>
       <br></br>
       <section class="page-section" id="contact">
       <div class="container">
                <div class="text-center">
                    <h2 class="section-heading text-uppercase">Contact Us</h2>
                    <h3 class="section-subheading text-muted">To reach us email on business@yaazhtech.com .</h3>
                </div>
                <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                    <div class="row align-items-stretch mb-5">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" id="name" type="text" placeholder="Your Name *" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                            </div>
                            <div class="form-group">
                                <input class="form-control" id="email" type="email" placeholder="Your Email *" data-sb-validations="required,email" />
                                <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                                <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                            </div>
                            <div class="form-group mb-md-0">
                                <input class="form-control" id="phone" type="tel" placeholder="Your Phone *" data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-textarea mb-md-0">
                                <textarea class="form-control" id="message" placeholder="Your Message *" data-sb-validations="required"></textarea>
                                <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none" id="submitSuccessMessage">
                        <div class="text-center text-white mb-3">
                            <div class="fw-bolder">Form submission successful!</div>
                            To activate this form, sign up at
                            <br />
                            <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                        </div>
                    </div>
                    <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error sending message!</div></div>
                   
                    <div class="text-center"><button class="btn btn-primary btn-xl text-uppercase disabled" id="submitButton" type="submit">Send Message</button></div>
                </form>
            </div>
            </section>
      </>
    );
  }
}

export default withRouter(Contact);
