import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class IamViewProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      personalInfo: '',
      employment: '',
      experienceInfo: [],
      educationInfo: [],
      profileDetails: '',
      achievementList: [],
      certificationList: [],
      skillInfo: [],
      userName: '',
    }
    this.updateViewProfileRequest = this.updateViewProfileRequest.bind(this);
  };

  componentDidMount() {
    this.updateViewProfileRequest();
  }

  updateViewProfileRequest = () => {
    this.setState({ showLoading: true });
    let req = this.state;
    req.userName = this.props.location.viewProfileProps.employmentData.userName;
    req.employment = this.props.location.viewProfileProps.employmentData.employment;
    req.profileDetails = this.props.location.viewProfileProps.employmentData.employment.profileDetails;
    req.personalInfo = this.props.location.viewProfileProps.employmentData.employment.profileDetails.personalInfo;
    req.experienceInfo = this.props.location.viewProfileProps.employmentData.employment.profileDetails.experienceInfo;
    req.educationInfo = this.props.location.viewProfileProps.employmentData.employment.profileDetails.educationInfo;
    req.achievementList = this.props.location.viewProfileProps.employmentData.employment.profileDetails.achievementList;
    req.certificationList = this.props.location.viewProfileProps.employmentData.employment.profileDetails.certificationList;
    req.skillInfo = this.props.location.viewProfileProps.employmentData.employment.skillDetails?this.props.location.viewProfileProps.employmentData.employment.skillDetails.skillInfo:null;
    this.setState({ req });
  }

 

 backToHome() {
    this.props.history.push("/client/profiles/Iam");
}


  render() {
    const { experienceInfo, educationInfo, profileDetails, achievementList, certificationList, skillInfo } = this.state;

    return (

      <>
        <br></br><br></br>
        <div className="container">

          <div class="row gx-2 justify-content-center">
            <div class="col-lg-11 col-xl-9 col-xxl-8">
              <div class="d-flex align-items-center justify-content-between mb-4">
                <h2 class="text-dark fw-bolder mb-4">Profile Summary</h2>
                <button class="btn btn-primary px-4 py-3" onClick={this.backToHome}>
                  <div class="d-inline-block bi bi-download me-2"></div>
                  Back to dashboard
                </button>
              </div>
              <div class="card shadow border-0 rounded-4 mb-5">
                <div class="card-body p-5">
                  <div class="row align-items-center gx-5">
                    <div class="col-lg-12">
                      <div>
                        <p>{profileDetails.profileOverallSummary}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <h2 class="text-primary fw-bolder mb-0">Experience (<small class="text-muted">{profileDetails.experienceInMonths} months</small>)</h2>
                </div>
                {experienceInfo.map((item, i) =>
                  <div class="card shadow border-0 rounded-4 mb-5">
                    <div class="card-body p-5">
                      <div class="row align-items-center gx-5">
                        <div class="col text-center text-lg-start mb-4 mb-lg-0">
                          <div class="bg-light p-4 rounded-4">
                            <div class="text-primary fw-bolder mb-2"><span>Start date - {item.startDate}</span></div>
                            <div class="text-primary fw-bolder mb-2"><span>End date - {item.endDate}</span></div>
                            <div class="small fw-bolder">{item.designation}</div>
                            <div class="small text-muted">{item.project}</div>
                            <div class="small text-muted">Team:{item.teamSize} members</div>
                        
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div><h6>Roles and responabilities:</h6>
                            {item.rolesAndResponsibility}</div>
                          <div><h6>Techstack:</h6>
                            <p>{item.techStack}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <h2 class="text-dark fw-bolder mb-4">Education</h2>
                {educationInfo.map((item, i) =>
                  <div class="card shadow border-0 rounded-4 mb-5">
                    <div class="card-body p-5">
                      <div class="row align-items-center gx-5">
                        <div class="col text-center text-lg-start mb-4 mb-lg-0">
                          <div class="bg-light p-4 rounded-4">
                            <div class="text-primary fw-bolder mb-2"><span>Start date - {item.graduationStartDate}</span></div>
                            <div class="text-primary fw-bolder mb-2"><span>End date - {item.graduationEndDate}</span></div>
                            <div class="small fw-bolder">{item.graduation}&nbsp;{item.specialization}</div>
                            <div class="small text-muted">{item.percentage} Percentage</div>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div><h6>College/School name:</h6>
                            {item.institutionName}</div>
                          <div><h6>Place:</h6>
                            <p>{item.placeOfInstitution}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div class="card shadow border-0 rounded-4 mb-5">
                  <div class="card-body p-5">
                    <div class="d-flex align-items-center mb-4">
                      <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"><i class="bi bi-tools"></i></div>
                      <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">Achievements</span></h3>
                    </div>
                    <div class="row row-cols-1 row-cols-md-3 mb-4">
                      <div class="col"><div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                        <ul className='ml-4'>
                          {achievementList.map((item, i) =>
                            <li>{item}</li>
                          )}
                        </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card shadow border-0 rounded-4 mb-5">
                  <div class="card-body p-5">
                    <div class="d-flex align-items-center mb-4">
                      <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"><i class="bi bi-tools"></i></div>
                      <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">Certifications</span></h3>
                    </div>
                    <div class="row row-cols-1 row-cols-md-3 mb-4">
                      <div class="col"><div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                        <ul className='ml-3'>
                          {certificationList.map((item, i) =>
                            <li>{item}</li>
                          )}
                        </ul>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card shadow border-0 rounded-4 mb-5">
                  <div class="card-body p-5">
                    <div class="mb-5">
                      <div class="d-flex align-items-center mb-4">
                        <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3"><i class="bi bi-tools"></i></div>
                        <h3 class="fw-bolder mb-0"><span class="text-gradient d-inline">Professional Skills</span></h3>
                      </div>
                      {skillInfo?skillInfo.map((item, i) =>
                        <div class="row row-cols-1 row-cols-md-3 mb-4">
                          <div class="col mb-4 mb-md-0"><div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">{item.primarySkill} &nbsp;
                            <small className='mt-1'>
                              ({item.experienceSkillMonth}months)</small>
                          </div>
                          </div>
                        </div>
                      ):"NA"}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <button class="btn btn-primary px-4 py-3" onClick={this.backToHome}>
                    <div class="d-inline-block bi bi-download me-2"></div>
                    Back to dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }


}


export default withRouter(IamViewProfile);