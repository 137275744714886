import React, {Component} from 'react';
import TrackAssessmentsGridLine from "./TrackAssessmentsGridLine";
import ApplicantsAnswersGridLine from './ApplicantsAnswersGridLine';

class ApplicantsAnswersGrid extends Component {

    constructor(props){
        super(props);
        this.state = {
            
            students: props.content
        }
    }

    static getDerivedStateFromProps(props, state) {
        state = props.content;
        return state;
    }

    render(){

        // const {users} = this.state;

        let gridLines = [];
        if(this.state.students && this.state.students.length > 0){
            gridLines = Object.keys(this.state.students)
                .map(key => <ApplicantsAnswersGridLine showAlert={this.props.showAlert} key={key} student={this.state.students[key]}/>);
        }

        return(
            <div>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">View Answers</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email Id</th>
                        <th scope="col">Test Taken On</th>
                        <th scope="col">Mobile No</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        this.state.students!==undefined && this.state.students.length > 0
                        ?
                            gridLines
                        :
                            <div className="alert alert-danger" role="alert">
                                <strong>Oops !</strong> Nothing to show here...
                            </div>
                    }
                    </tbody>
                </table>
                <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
         
            </div>
        );
    }
}

export default ApplicantsAnswersGrid;