import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { candidateOnboardRequest } from "../../../../api/HrApi";
import { probationActionRequest } from "../../../../api/HrApi";

class ApplicantsGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboardRequest: {
        email: "",
        officialEmail: "",
        employmentStatus: "",
        joiningDate: "",
        designation: "",
      },
      probationRequestAction: "Download",
      username: "",
      showModalUserInfo: false,
      showModalActionInfo: false,
      user: props.user,
    };
    this.updateEmploymentStatus = this.updateEmploymentStatus.bind(this);
    this.candidateOnboard = this.candidateOnboard.bind(this);
    this.updateDesignation = this.updateDesignation.bind(this);
    this.updateOfficialEmail = this.updateOfficialEmail.bind(this);
    this.toggle = this.toggle.bind(this);
    this.action = this.action.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const user = props.user;
    state.user = user;
    return state;
  }

  toggle() {
    this.setState({
      showModalUserInfo: !this.state.showModalUserInfo,
    });
  }
  action() {
    this.setState({
      showModalActionInfo: !this.state.showModalActionInfo,
    });
  }
  downloadInternContract = () => {
    const link = document.createElement("a");
    link.href = this.state.user.internshipContractUrl;
    link.click();
  };
  downloadProbationContract = () => {
    const link = document.createElement("a");
    link.href = this.state.user.employmentContractUrl;
    link.click();
  };
  updateOfficialEmail = (event) => {
    let req = this.state.onboardRequest;
    req.officialEmail = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateEmploymentStatus = (event) => {
    let req = this.state.onboardRequest;
    req.employmentStatus = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateProbationAction = (e) => {
    let probationAction = e.target.value;
    this.setState({ probationRequestAction: probationAction });
  };
  updateJoiningDate = (event) => {
    let req = this.state.onboardRequest;
    req.joiningDate = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateDesignation = (event) => {
    let req = this.state.onboardRequest;
    req.designation = event.target.value;
    this.setState({ onboardRequest: req });
  };
splitByT(zonedDateTimeString){

  let dates=[];
  dates=zonedDateTimeString.split("T");
 
  return dates[0]?dates[0]:"NA";

}
  candidateOnboard = (event) => {
    event.preventDefault();
    let req = this.state.onboardRequest;
    req.email = this.state.user.email;
    this.setState({ onboardRequest: req });
    this.setState({ showLoading: true });

    candidateOnboardRequest(this.state.onboardRequest)
      .then((res) => {
        this.props.showAlert(
          "Employee onboard request has been accepted !" +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggle();
        this.action();
        this.props.history.push("/");
        this.props.history.push("hr/dashboard/applicants");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  probationLetterAction = (event) => {
    event.preventDefault();
    const probationActionData = {
      probationRequestAction: this.state.probationRequestAction,
      username: this.state.user.email,
    };
    {
      /*let req = this.state.probationAction;
  this.setState({ probationAction: req });*/
    }
    probationActionRequest(probationActionData)
      .then((res) => {
        if (this.state.probationRequestAction === "Download") {
          let binaryString = window.atob(res.data);

          let binaryLen = binaryString.length;

          let bytes = new Uint8Array(binaryLen);

          for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
          }

          let blob = new Blob([bytes], { type: "application/pdf" });

          let link = document.createElement("a");

          link.href = window.URL.createObjectURL(blob);
          link.download = this.state.user.email+".pdf";

          link.click(); /*

          alert("res"+res.data);
          var blob = new Blob([res.data], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    window.open(url);
        
        const url = window.URL.createObjectURL(new Blob([res.data]));
        //alert("url"+url);
        const link = document.createElement('a');
        //alert("link"+link);
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();*/
          this.props.showAlert(
            "Employee probation letter downloaded successfully",
            "success"
          );
        } else {
          this.props.showAlert(
            "Employee probation letter mail sent successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  render() {
    let roles;

    if (this.state.user && this.state.user.roles) {
      roles = Object.keys(this.state.user.roles).map(
        (key) => " " + this.state.user.roles[key]
      );
    }

    return (
      <tr>
        <th>
          <button className="btn btn-success" onClick={this.toggle} data-toggle="tooltip" data-placement="bottom" title="Action "  >
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        {/*<th scope="row">
          <button
            className="btn btn-light"
            onClick={this.downloadProbationContract}
          >
            <FontAwesomeIcon icon={faFilePdf} />
          </button>
        </th>*/}
        <th>
          <button className="btn btn-success" onClick={this.action} data-toggle="tooltip" data-placement="bottom" title="Probation">
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        <th scope="row">{this.state.user.mobile}</th>
        <th>{this.state.user.email}</th>
        <th>{this.state.user.name}</th>
        <th>{this.state.user.graduationYear}</th>
        <th>{this.state.user.subscriptionFor}</th>
        <th>{this.state.user.subscription?this.state.user.subscription[0].subscriptionType:"NA"}</th>
        <th>{this.splitByT(this.state.user.createdAt)}</th>

        <Modal
          isOpen={this.state.showModalUserInfo}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " +
                this.state.user.name +
                " { " +
                this.state.user.email +
                " }"}
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.candidateOnboard(e)}>
              <div>
                <p className="card-title text-center">Applicants Status change</p>
               {/** <div className="mt-2">
                  <label htmlFor="inputEmail">Official email</label>
                  <input
                    type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    required
                    onChange={this.updateOfficialEmail}
                    autoComplete="on"
                  />
                </div>*/} 
                <div className="mt-2">
                  <label htmlFor="employmentStatus">Employment Status</label>

                  <select
                    className="custom-select"
                    id="employmentStatus"
                    placeholder="Select employment status"
                    onChange={this.updateEmploymentStatus}
                  >
                    <option value="Employment Status">Employment Status</option>
                    <option value="probation">PROBATION</option>
                    <option value="intern">INTERN</option>
                    <option value="internal-employee">INTERNAL-EMPLOYEE</option>
                    <option value="external-employee">EXTERNAL-EMPLOYEE</option>
                    <option value="interview-backend">Interview-backend</option>
                    <option value="interview-frontend">
                      EXTERNAL-frontend
                    </option>
                    <option value="interview-fullstack">
                      Interview-fullstack
                    </option>
                    <option value="interview-devopsTesting">
                      EXTERNAL-devopsTesting
                    </option>
                    <option value="rejected">REJECTED</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label htmlFor="inputDate">Joining Date</label>
                  <input
                    type="Date"
                    id="inputDate"
                    className="form-control"
                    placeholder="actionDate"
                    required
                    onChange={this.updateJoiningDate}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-2">
                  <label htmlFor="inputDesignation">Designation</label>
                  <input
                    type="text"
                    id="inputDesignation"
                    className="form-control"
                    placeholder="Designation"
                    required
                    onChange={this.updateDesignation}
                    autoComplete="on"
                  />
                </div>

                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Onboard
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showModalActionInfo}
          toggle={this.action}
          className={this.props.className}
        >
          <ModalHeader toggle={this.action}>
            <div className="align-content-center align-items-center align-self-center text-center">
              <p>
              {/**  <small>
                  {" Applied For: " + this.state.user.jobApplication.appliedFor}{" "}
                  /{" "}
                  {this.state.user.subscription
                    ? this.state.user.subscription[0].subscriptionFor
                    : null}
                </small> */} 
              </p>
              <p>
                <small>{"Applied On: " + this.state.user.createdAt}</small>
              </p>
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.probationLetterAction(e)}>
              <div>
                <p className="card-title text-center">Probation Action</p>
                <div className="mt-2">
                  <label htmlFor="employmentStatus">Action</label>

                  <select
                    className="custom-select"
                    id="probationAction"
                    placeholder="Select probation action"
                    onChange={this.updateProbationAction}
                  >
                    <option value="Download">Download</option>
                    <option value="SendMail">Send Mail</option>
                  </select>
                </div>

                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </tr>
    );
  }
}

export default withRouter(ApplicantsGridLine);