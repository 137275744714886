import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ACCESS_TOKEN } from "../../../config/Config";
import { Nav, NavItem } from "reactstrap";
import AccountSettings from "./settings/account/AccountSettings";
import SecuritySettings from "./settings/security/SecuritySettings";
import PersonalDataSettings from "./settings/profile/PersonalDataSettings";
import ProfileDataSettings from "./settings/profile/ProfileDataSettings";
import AccountDataSettings from "./settings/profile/AccountDataSettings";
import SkillsDataSettings from "./settings/profile/SkillsDataSettings";
import DocumentDataSettings from "./settings/profile/DocumentDataSettings";

class Account extends Component {
  constructor(props) {
    super(props);

    if (!localStorage.getItem(ACCESS_TOKEN)) {
      this.props.showAlert(
        "You must be authenticated to access your account settings !",
        "error"
      );
      this.props.history.push("/login");
    }
  }

  render() {
    let render;
    let navItem;
    if (this.props.match.params.render === "account") {
      render = <AccountSettings showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/me/settings/account" className="nav-link active">
            Account
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/me/settings/security" className="nav-link">
            Security
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Profile
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "security") {
      render = <SecuritySettings showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/me/settings/account" className="nav-link">
            Account
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/me/settings/security" className="nav-link active">
            Security
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Profile
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "curriculum") {
      render = <PersonalDataSettings showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/me/settings/account" className="nav-link">
            Account
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/me/settings/security" className="nav-link">
            Security
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Profile
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link active">
            Personal Data
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link exact to="/me/settings/profile" className="nav-link">
            Profile Data
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/me/settings/accountdata" className="nav-link">
            Account Data
          </Link>
        </NavItem>,

        <NavItem key={7} className="menu-item">
          <Link exact to="/me/settings/profilepic" className="nav-link">
            Picture and Resume
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link exact to="/me/settings/skills" className="nav-link">
            Skills Data
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "profile") {
      render = <ProfileDataSettings showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/me/settings/account" className="nav-link">
            Account
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/me/settings/security" className="nav-link">
            Security
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Profile
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Personal Data
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link exact to="/me/settings/profile" className="nav-link active">
            Profile Data
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/me/settings/accountdata" className="nav-link">
            Account Data
          </Link>
        </NavItem>,

        <NavItem key={7} className="menu-item">
          <Link exact to="/me/settings/profilepic" className="nav-link">
            Picture and Resume
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link exact to="/me/settings/skills" className="nav-link">
            Skills Data
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "accountdata") {
      render = <AccountDataSettings showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/me/settings/account" className="nav-link">
            Account
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/me/settings/security" className="nav-link">
            Security
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Profile
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Personal Data
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link exact to="/me/settings/profile" className="nav-link">
            Profile Data
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/me/settings/accountdata" className="nav-link active">
            Account Data
          </Link>
        </NavItem>,

        <NavItem key={7} className="menu-item">
          <Link exact to="/me/settings/profilepic" className="nav-link">
            Picture and Resume
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link exact to="/me/settings/skills" className="nav-link">
            Skills Data
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "profilepic") {
      render = <DocumentDataSettings showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/me/settings/account" className="nav-link">
            Account
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/me/settings/security" className="nav-link">
            Security
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Profile
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Personal Data
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link exact to="/me/settings/profile" className="nav-link">
            Profile Data
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/me/settings/accountdata" className="nav-link">
            Account Data
          </Link>
        </NavItem>,

        <NavItem key={7} className="menu-item">
          <Link exact to="/me/settings/profilepic" className="nav-link active">
            Picture and Resume
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link exact to="/me/settings/skills" className="nav-link">
            Skills Data
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "skills") {
      render = <SkillsDataSettings showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/me/settings/account" className="nav-link">
            Account
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/me/settings/security" className="nav-link">
            Security
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Profile
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link exact to="/me/settings/curriculum" className="nav-link">
            Personal Data
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link exact to="/me/settings/profile" className="nav-link">
            Profile Data
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/me/settings/accountdata" className="nav-link">
            Account Data
          </Link>
        </NavItem>,

        <NavItem key={7} className="menu-item">
          <Link exact to="/me/settings/profilepic" className="nav-link">
            Picture and Resume
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link exact to="/me/settings/skills" className="nav-link active">
            Skills Data
          </Link>
        </NavItem>,
      ];
    }
    const token = localStorage.getItem(ACCESS_TOKEN);
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace("-", "+").replace("_", "/");
    const decodedToken = JSON.parse(window.atob(base64));

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <Nav vertical pills>
              {navItem}
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9">{render}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(Account);
