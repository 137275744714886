import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { candidateOnboardRequest } from "../../../../../api/HrApi";
import SubscriptionGridLine from "../SubscriptionGridLine";
import CandidatesGridLine from "../CandidatesGridLine";

class ProjectGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboardRequest: {
        email: "",
        officialEmail: "",
        employmentStatus: "",
        joiningDate: "",
        designation: "",
      },
      showModalCandidateInfo: false,
      showModalSubscriptionInfo:false,
      user: props.user,
    };
    this.updateEmploymentStatus = this.updateEmploymentStatus.bind(this);
    this.candidateOnboard = this.candidateOnboard.bind(this);
    this.updateDesignation = this.updateDesignation.bind(this);
    this.updateOfficialEmail = this.updateOfficialEmail.bind(this);
    this.toggleSubscriptionModal = this.toggleSubscriptionModal.bind(this);
    this.toggleCandidateModal = this.toggleCandidateModal.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const user = props.user;
    state.user = user;
    return state;
  }

  toggleCandidateModal() {
    this.setState({
      showModalCandidateInfo: !this.state.showModalCandidateInfo,
    });
  }
  toggleSubscriptionModal() {
    this.setState({
      showModalSubscriptionInfo: !this.state.showModalSubscriptionInfo,
    });
  }
  downloadInternContract = () => {
    const link = document.createElement("a");
    link.href = this.state.user.internshipContractUrl;
    link.click();
  };
  downloadProbationContract = () => {
    const link = document.createElement("a");
    link.href = this.state.user.employmentContractUrl;
    link.click();
  };
  updateOfficialEmail = (event) => {
    let req = this.state.onboardRequest;
    req.officialEmail = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateEmploymentStatus = (event) => {
    let req = this.state.onboardRequest;
    req.employmentStatus = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateJoiningDate = (event) => {
    let req = this.state.onboardRequest;
    req.joiningDate = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateDesignation = (event) => {
    let req = this.state.onboardRequest;
    req.designation = event.target.value;
    this.setState({ onboardRequest: req });
  };

  candidateOnboard = (event) => {
    event.preventDefault();
    let req = this.state.onboardRequest;
    req.email = this.state.user.email;
    this.setState({ onboardRequest: req });
    this.setState({ showLoading: true });

    candidateOnboardRequest(this.state.onboardRequest)
      .then((res) => {
        this.props.showAlert(
          "Employee onboard request has been accepted !" +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggle();
        this.props.history.push("/");
        this.props.history.push("hr/dashboard/applicants");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  render() {
    let roles;
    let subscribedData;
    let candidatesData;
    if (this.state.user && this.state.user.subscription!=null &&this.state.user.subscription.length > 0) {
      subscribedData = Object.keys(this.state.user.subscription).map((key) => (
        <SubscriptionGridLine
          showAlert={this.props.showAlert}
          key={key}
          subscription={this.state.user.subscription[key]}
        />
      ));
    }
    if (this.state.user && this.state.user.shortListedCandidatesList!=null && this.state.user.shortListedCandidatesList.length > 0) {
      candidatesData = Object.keys(this.state.user.subscription).map((key) => (
        <CandidatesGridLine
          showAlert={this.props.showAlert}
          key={key}
          candidatesData={this.state.user.shortListedCandidatesList[key]}
        />
      ));
    }

    if (this.state.user && this.state.user.roles) {
      roles = Object.keys(this.state.user.roles).map(
        (key) => " " + this.state.user.roles[key]
      );
    }

    return (
      <tr>
        <th>
          <button className="btn btn-success" onClick={this.toggleSubscriptionModal} data-toggle="tooltip" data-placement="bottom" title="Subscription">
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        <th>
          <button className="btn btn-success" onClick={this.toggleCandidateModal}data-toggle="tooltip" data-placement="bottom" title="Candidate List" >
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>

        <th scope="row">{this.state.user.name}</th>
        <th>{this.state.user.email}</th>
        <th>{this.state.user.mobile}</th>

        <Modal
        size="lg" style={{maxWidth: '700px', width: '100%'}}
          isOpen={this.state.showModalSubscriptionInfo}
          toggle={this.toggleSubscriptionModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleSubscriptionModal}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {
                " { " +
                this.state.user.email +
                " }"}
            </div>
          </ModalHeader>
          <ModalBody>
          <form>
              <div>
                <p className="card-title text-center">Subscription</p>
                <div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" style={{width: "25%"}}><small>Plan Type</small></th>
                        <th scope="col" style={{width: "25%"}}><small>Plan Cost</small></th>
                        <th scope="col" style={{width: "25%"}}><small>Subscribed For</small></th>
                        <th scope="col" style={{width: "25%"}}><small>Plan Date</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.user.subscription!=null && this.state.user.subscription.length !=null && this.state.user.subscription.length> 0 ? (
                        subscribedData
                      ) : (
                        <div className="alert alert-danger" role="alert">
                          <strong>Oops !</strong> Nothing to show here...
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal
         size="lg" style={{maxWidth: '700px', width: '100%'}}
          isOpen={this.state.showModalCandidateInfo}
          toggle={this.toggleCandidateModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCandidateModal}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {
                " { " +
                this.state.user.email +
                " }"}
            </div>
          </ModalHeader>
          <ModalBody>
            <form>
              <div>
                <p className="card-title text-center">Shortlisted Candidates</p>
                <div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                      <th scope="col-sm"><small>Email</small></th>
                        <th scope="col-sm"><small>Remarks</small></th>
                        <th scope="col-sm"><small>Category</small></th>
                        <th scope="col-sm"><small>OfferedTerm</small></th>
                        <th scope="col-sm"><small>Status</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.user.shortListedCandidatesList!=null && this.state.user.shortListedCandidatesList.length > 0 ? (
                        candidatesData
                      ) : (
                        <div className="alert alert-danger" role="alert">
                          <strong>Oops !</strong> Nothing to show here...
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </tr>
    );
  }
}

export default withRouter(ProjectGridLine);
