import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { createManualSubscription } from "../../../../api/FinanceApi";

class ManualSubscription extends Component {
  state = {
    subscriptionRequest: {
      userName: "",
      subscriberName: "",
      subscriptionFor: "",
      subscriptionType: "",
      subscriptionCost: "",
      totalBillingHours: "",
      totalBillingDay: "",
      paymentBillingFrom: "",
      paymentBillingTo: "",
      paymentInvoiceDate:"",
      paymentId: "",
      techStack: "",
      subscriptionId:"NEW"
    },
    showLoading: false,
  };

  componentDidMount() {
    document.title = "Manual Payment for existing subscription";
  }

  updatePaymentId = (event) => {
    let req = this.state.subscriptionRequest;
    req.paymentId = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateTechStack = (event) => {
    let req = this.state.subscriptionRequest;
    req.techStack = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateBillingFrom = (event) => {
    let req = this.state.subscriptionRequest;
    req.paymentBillingFrom = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateBillingTo = (event) => {
    let req = this.state.subscriptionRequest;
    req.paymentBillingTo = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateInvoiceDate = (event) => {
    let req = this.state.subscriptionRequest;
    req.paymentInvoiceDate = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  
  updateUserName = (event) => {
    let req = this.state.subscriptionRequest;
    req.userName = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateSubscriberName = (event) => {
    let req = this.state.subscriptionRequest;
    req.subscriberName = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateSubscriptionId = (event) => {
    let req = this.state.subscriptionRequest;
    req.subscriptionId = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateSubscriptionFor = (event) => {
    let req = this.state.subscriptionRequest;
    req.subscriptionFor = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateSubscriptionType = (event) => {
    let req = this.state.subscriptionRequest;
    req.subscriptionType = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateSubscriptionCost = (event) => {
    let req = this.state.subscriptionRequest;
    req.subscriptionCost = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updatePaymentBillingPeriod = (event) => {
    let req = this.state.subscriptionRequest;
    req.paymentBillingPeriod = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateTotalBillingHours = (event) => {
    let req = this.state.subscriptionRequest;
    req.totalBillingHours = event.target.value;
    this.setState({ subscriptionRequest: req });
  };
  updateTotalBillingDay = (event) => {
    let req = this.state.subscriptionRequest;
    req.totalBillingDay = event.target.value;
    this.setState({ subscriptionRequest: req });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ showLoading: true });
    createManualSubscription(this.state.subscriptionRequest)
      .then((res) => {
       // alert("res--" + JSON.stringify(res.data));
        let userList = res.data.content;
        let constructeClientDataList = [];

        this.setState({ showLoading: false });
      })
      .catch((error) => {
        if (error.message == 404) {
          this.props.showAlert(error.message, "error");
          console.log(error);
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ showLoading: false });
        console.log(error);
      });
  };

  render() {
    const { userName, category, amountPaid, payment } = this.state;
    return (
      <div>
        <h3>Manual Payment for existing subscription</h3>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>additional payment creation initiated</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                in progress
              </i>
            </div>
          ) : (
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <div className="col-md-7">

                <div className="mt-0">
                  <label htmlFor="userName">User Name/Email</label>
                  <input
                    type="text"
                    id="userName"
                    className="form-control"
                    placeholder="User Name"
                    required
                    onChange={this.updateUserName}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-0">
                  <label htmlFor="subscriberName">Subscriber Name</label>
                  <input
                    type="text"
                    id="subscriberName"
                    className="form-control"
                    placeholder="Subscriber Name"
                    required
                    onChange={this.updateSubscriberName}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="subscriberId">Subscription Id</label>
                  <input
                    type="text"
                    id="subscriberId"
                    className="form-control"
                    placeholder="Keep blank if new subscription"
                    required
                    onChange={this.updateSubscriptionId}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="paymentId">Payment Id</label>
                  <input
                    type="text"
                    id="paymentId"
                    className="form-control"
                    placeholder="New or Modify"
                    required
                    onChange={this.updatePaymentId}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="techStack">Project</label>
                  <input
                    type="text"
                    id="techStack"
                    className="form-control"
                    placeholder="Tech stack of project"
                    required
                    onChange={this.updateTechStack}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="subscriptionFor">Subscription For</label>
                  <input
                    type="text"
                    id="subscriptionFor"
                    className="form-control"
                    placeholder="subscriptionFor"
                    required
                    onChange={this.updateSubscriptionFor}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="subscriptionType"> Subscription Type</label>

                  <select
                    className="custom-select"
                    id="subscriptionType"
                    placeholder="Subscription Type"
                    onChange={this.updateSubscriptionType}
                  >
                    <option value="empty"></option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="oneTime">One Time</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div className="mt-0">
                  <label htmlFor="subscriptionCost">Subscription Cost</label>
                  <input
                    type="text"
                    id="subscriptionCost"
                    className="form-control"
                    placeholder="Subscription Cost"
                    required
                    onChange={this.updateSubscriptionCost}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-0">
                  <label htmlFor="inputBillingFrom">Billing From</label>
                  <input
                    type="Date"
                    id="inputBillingFrom"
                    className="form-control"
                    placeholder="inputBillingFrom"
                    required
                    onChange={this.updateBillingFrom}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="inputBillingTo">Billing To</label>
                  <input
                    type="Date"
                    id="inputBillingTo"
                    className="form-control"
                    placeholder="inputBillingTo"
                    required
                    onChange={this.updateBillingTo}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="invoiceDate">Invoice Date</label>
                  <input
                    type="Date"
                    id="invoiceDate"
                    className="form-control"
                    placeholder="invoiceDate"
                    required
                    onChange={this.updateInvoiceDate}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="totalNoOfDays">Total no of billable days</label>
                  <input
                    type="text"
                    id="totalNoOfDays"
                    className="form-control"
                    placeholder="total hours clocked/8"
                    required
                    onChange={this.updateTotalBillingDay}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="totalBillingHours">Total Billing Hours</label>
                  <input
                    type="text"
                    id="totalBillingHours"
                    className="form-control"
                    placeholder="Total Billing Hours"
                    required
                    onChange={this.updateTotalBillingHours}
                    autoComplete="on"
                  />
                </div>
               
                
                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </form>
          )}
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossorigin="anonymous"
        ></script>
      </div>
    );
  }
}

export default withRouter(ManualSubscription);
