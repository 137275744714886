import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ACCESS_TOKEN, COOKIE_PREF } from "../../../config/Config";
import { login, requestResetPassword,saveCookie,disable2FA } from "../../../api/Api";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

class Login extends Component {
  state = {
    loginRequest: {
      usernameOrEmail: "",
      password: "",
      twoFACode: 0,
      rememberMe: false
    },
    type: false,
    showLoading: false,
    showForgotPasswordModal: false,
    forgotPasswordEmail: "",
    twoFAEnabled:false
  };

  constructor(props) {
    super(props);

    this.showForgotPasswordModal = this.showForgotPasswordModal.bind(this);
    this.updateUsernameOrEmail = this.updateUsernameOrEmail.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateRememberMe = this.updateRememberMe.bind(this);
    this.updateTwoFACode = this.updateTwoFACode.bind(this);
    this.requestLogin = this.requestLogin.bind(this);
    this.updateForgotPasswordEmail = this.updateForgotPasswordEmail.bind(this);
    this.forgotPasswordSend = this.forgotPasswordSend.bind(this);
  }

  componentDidMount() {
    document.title = "Sign in";
    
  }

  updateUsernameOrEmail = (event) => {
    let req = this.state.loginRequest;
    req.usernameOrEmail = event.target.value;
    this.setState({ loginRequest: req });
  };

  updatePassword = (event) => {
    let req = this.state.loginRequest;
    req.password = event.target.value;
    this.setState({ loginRequest: req });
  };

  updateRememberMe = (event) => {
    let req = this.state.loginRequest;
    req.rememberMe = !req.rememberMe;
    this.setState({ loginRequest: req });
  };

  updateForgotPasswordEmail = (event) => {
    this.setState({ forgotPasswordEmail: event.target.value });
  };

  updateTwoFACode = (event) => {
    let req = this.state.loginRequest;
    req.twoFACode = parseInt(event.target.value, 10);
    this.setState({ loginRequest: req });
  };
  cancelEnable2FA() {
    disable2FA();
    
}
  saveCookieData(){
   
    if (localStorage.getItem(ACCESS_TOKEN)) {
      let token = localStorage.getItem(ACCESS_TOKEN);
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      token = JSON.parse(window.atob(base64));    
     
    saveCookie(localStorage.getItem(COOKIE_PREF),JSON.stringify(token.email))
      .then((res) => {
        
        this.props.showAlert(res.data.message);
        
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or password is incorrect. Please try again !",
            "error"
          );
        }  else {
          this.props.showAlert(
            error.message,
            "error"
          );
        }
        this.setState({ showLoading: false });
      });
  }
}
  requestLogin = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    login(this.state.loginRequest)
      .then((res) => {
        localStorage.setItem(ACCESS_TOKEN, res.accessToken?res.accessToken:null);
        if (
          res.message ===
          "Two-Factor authentication is enable for your account ! Please enter the code!!"
        ) {
          this.props.showAlert(
            "Two-Factor authentication is enable for your account ! Please enter the code generated by your mobile application.",
            "success"
          );
          this.setState({ twoFAEnabled: true });
          this.setState({ showLoading: false });
        }else{
          this.props.showAlert("You are now logged in !", "success");
          this.props.history.push("/");
        }
       
        
      //  this.saveCookieData();
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or password is incorrect. Please try again !",
            "error"
          );
        } else if (
          error.message ===
          "Two-Factor authentication is enable for your account ! Please enter the code generated by your mobile application."
        ) {
          this.props.showAlert(
            "Two-Factor authentication is enable for your account ! Please enter the code generated by your mobile application.",
            "success"
          );
          this.setState({ twoFAEnabled: true });
        } else if (
          error.message ===
          "Invalid 2FA authentication code ! Please try again later..."
        ) {
          this.props.showAlert(
            "Invalid 2FA authentication code ! Please try again later...",
            "error"
          );
        } else {
          this.props.showAlert(
            error.message,
            "error"
          );
        }
        this.setState({ showLoading: false });
      });
  };

  showForgotPasswordModal() {
    this.setState({
      showForgotPasswordModal: !this.state.showForgotPasswordModal,
    });
  }

  forgotPasswordSend = (event) => {
    event.preventDefault();

    if (this.state.forgotPasswordEmail) {
      this.setState({ showLoading: true });
      requestResetPassword(this.state.forgotPasswordEmail)
        .then((res) => {
          this.props.showAlert(
            "Your request has been accepted ! If this email address is correct, you will soon receive an email with a link to reset your password.",
            "success"
          );
          this.setState({ showLoading: false });
          this.showForgotPasswordModal();
        })
        .catch((error) => {
          if (error.status === 401) {
            this.props.showAlert(
              "Your username or password is incorrect. Please try again !",
              "error"
            );
          } else {
            this.props.showAlert(
              "Sorry! Something went wrong. Please try again!",
              "error"
            );
          }
          this.setState({ showLoading: false });
        });
    } else {
      this.props.showAlert("Please enter a valid email address.", "error");
    }
  };

  showPassword = () => this.setState(({ type }) => ({
    type : !type 
  }))

  render() {
    const { label } = this.props
    let token=localStorage.getItem(ACCESS_TOKEN);
    if (token!=="undefined" && token!==undefined && token!==null && token!=="null" && token!=='') {
      this.props.showAlert("You are already logged in !", "info");
      this.props.history.push("/");
      return <p>Hi ! Your are already logged in !</p>;
    } else {
      return (
        <><br></br><div className="container">
          <div className="mx-auto">
            {this.state.showLoading ? (
              <div className="card card-signin my-5">
                <div className="card-body">
                  <div className="align-content-center text-center">
                    <h5>Signing in</h5>
                    <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                      refresh
                    </i>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {!this.state.twoFAEnabled ? (<div><div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                  <form onSubmit={(e) => this.requestLogin(e)}>
                    <div className="card card-signin my-5">
                      <div className="card-body">
                        <h1 className="card-title text-center">Sign In</h1>
                        <div>
                          <label htmlFor="inputEmail">
                            Email address or username
                          </label>
                          <input
                            type="text"
                            id="inputEmail"
                            className="form-control"
                            placeholder="Email address or username"
                            required
                            onChange={this.updateUsernameOrEmail}
                            autoComplete="on" />
                        </div>

                        <div className="mb-4 mt-4">
                          <label htmlFor="inputPassword">Password</label>
                          <input
                            type  = {this.state.type ? "text" : "password"}
                            id="inputPassword"
                            className="form-control"
                            placeholder="Password"
                            required
                            autoComplete="on"
                            onChange={this.updatePassword} />
                            <span className="showpass" onClick={this.showPassword}>
                              {this.state.type ?  <VisibilityOff /> : <Visibility />}
                            </span>
                        </div>

                        <div className="mb-4 mt-4 custom-control custom-checkbox mr-sm-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="rememberMe"
                            onChange={this.updateRememberMe} />
                          <div
                            onClick={this.showForgotPasswordModal}
                            className="cursor-pointer"
                          >
                            <p className="text-muted">
                              <b>Forgot password ?</b>
                            </p>
                          </div>
                        </div>

                        <button
                          className="btn btn-lg btn-primary btn-block text-uppercase mb-4"
                          type="submit"
                        >
                          Sign in
                        </button>
                        <p className="text-muted">
                          Or{"   Sign up via careers or services."}
                          </p>
                        {/** <p className="text-muted">
                          Or{" "}
                          <Link to="/register" className="text-primary">
                            register now !
                          </Link>
                        </p>*/}
                      </div>
                    </div>
                  </form>
                </div></div>) : (<div><div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                  <form onSubmit={(e) => this.requestLogin(e)}>
                    <div className="card card-signin my-5">
                      <div className="card-body">
                        <h1 className="card-title text-center">
                          Sign In{" "}
                          <b className="text-muted">
                            (with Two-Factor authentication)
                          </b>
                        </h1>
                        {/*<div>
          <label htmlFor="input2FAEmail">
            Email address or username
          </label>
          <input
            type="text"
            id="input2FAEmail"
            className="form-control"
            placeholder="Email address or username"
            required
            onChange={this.updateUsernameOrEmail}
            autoComplete="on"
          />
        </div>

        <div className="mb-4 mt-4">
          <label htmlFor="input2FAPassword">Password</label>
          <input
            type="password"
            id="input2FAPassword"
            className="form-control"
            placeholder="Password"
            required
            autoComplete="on"
            onChange={this.updatePassword}
          />
        </div>*/}

                        <div className="mb-4 mt-4">
                          <label htmlFor="input2FACode">
                            Two-Factor Authentication code
                          </label>
                          <input
                            type="number"
                            id="input2FACode"
                            className="form-control"
                            placeholder="123456"
                            required
                            autoComplete="on"
                            onChange={this.updateTwoFACode} />
                        </div>

                        <div className="mb-4 mt-4 custom-control custom-checkbox mr-sm-2">
                          <div
                            onClick={this.showForgotPasswordModal}
                            className="cursor-pointer"
                          >
                            <p className="text">
                              <b>Forgot password ?</b>
                            </p>
                           
                          </div>

                        </div>

                        <button
                          className="btn btn-lg btn-primary btn-block text-uppercase mb-4"
                          type="submit"
                        >
                          Sign in
                        </button>
                        <p className="text-muted">
                          Or{"   Sign up via careers or services."}
                          </p>
                        {/**<p className="text-muted">
                          Or{" "}
                          <Link to="/register" className="text-primary">
                            register now !
                          </Link>
                        </p> */}
                      </div>
                    </div>
                  </form>
                </div></div>)}


                <Modal
                  isOpen={this.state.showForgotPasswordModal}
                  toggle={this.showForgotPasswordModal}
                >
                  <ModalHeader toggle={this.showForgotPasswordModal}>
                    Forgot password
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      To change your password please enter your email address
                    </p>
                    <form onSubmit={(e) => this.forgotPasswordSend(e)}>
                      <input
                        type="email"
                        autoComplete="on"
                        className="form-control"
                        id="enable2FAPasswordConfirm"
                        required
                        placeholder="Enter your email address"
                        onChange={this.updateForgotPasswordEmail} />
                      <button
                        type="submit"
                        className="btn btn-success btn-lg mt-4"
                      >
                        Continue
                      </button>
                    </form>
                  </ModalBody>
                </Modal>
              </div>
            )}
          </div>
          <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
        </div></>
      );
    }
  }
}

export default withRouter(Login);
