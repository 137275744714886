import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  candidateOnboardRequest,
  leaveUpdateRequest,
  performanceUpdate,requestUpdateCategory,
} from "../../../../api/HrApi";
import TimeSheetGridLine from "./TimeSheetGridLine";
import LeaveDataGridLine from "./LeaveDataGridLine";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";

class EmployeeGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category:"",
      onboardRequest: {
        email: "",
        officialEmail: "",
        employmentStatus: "",
        joiningDate: "",
        designation: "",
      },
      performanceInput: {
        email: "",
        performanceLevel: "",
        comments: "",
      },
      leaveRequest: {
        email: "",
        leaveType: "",
        leaveFrom: "",
        leaveTo: "",
        noOfDays: "",
        leaveSummary: "",
        flowFrom: "hrPage",
      },
      showModelTimeSheetInfo: false,
      showModalLeaveUpdate: false,
      showModalLevelUpdate: false,
      showModalProfileData: false,
      showModalCategoryChange:false,
      user: props.user,
      employment: {},
      performanceEvaluation: {},
      personalInfo: {},
      employeeLeaveData: {},
    };
    this.updateEmploymentStatus = this.updateEmploymentStatus.bind(this);
    this.candidateOnboard = this.candidateOnboard.bind(this);
    this.updateDesignation = this.updateDesignation.bind(this);
    this.updateOfficialEmail = this.updateOfficialEmail.bind(this);
    this.toggleLeaveUpdate = this.toggleLeaveUpdate.bind(this);
    this.toggleLevelUpdate = this.toggleLevelUpdate.bind(this);
    this.toggleViewProfile = this.toggleViewProfile.bind(this);
    this.toggleCategory=this.toggleCategory.bind(this);
    this.updateCategory=this.updateCategory.bind(this);
    this.toggleTimeSheetModal = this.toggleTimeSheetModal.bind(this);
    this.toggleRetrieveLeaves=this.toggleRetrieveLeaves.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const user = props.user;
    state.user = user;
    /*
    state.employment=user.employment;
    state.performanceEvaluation=user.employment.performanceEvaluation;
    state.personalInfo=user.employment.profileDetails?user.employment.profileDetails.personalInfo:null;
    state.employeeLeaveData=user.employment.employeeLeaveData;
    */
    return state;
  }

  toggleRetrieveLeaves() {
    this.setState({
      showModalLeaveInfo: !this.state.showModalLeaveInfo,
    });
  }
  toggleLeaveUpdate() {
    this.setState({
      showModalLeaveUpdate: !this.state.showModalLeaveUpdate,
    });
  }
  toggleTimeSheetModal() {
    this.setState({
      showModelTimeSheetInfo: !this.state.showModelTimeSheetInfo,
    });
  }
  toggleLevelUpdate() {
    this.setState({
      showModalLevelUpdate: !this.state.showModalLevelUpdate,
    });
  }
  toggleViewProfile() {
    this.setState({
      showModalProfileData: !this.state.showModalProfileData,
    });
  }
  toggleCategory() {
    this.setState({
      showModalCategoryChange: !this.state.showModalCategoryChange,
    });
  }
  updateOfficialEmail = (event) => {
    let req = this.state.onboardRequest;
    req.officialEmail = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateEmploymentStatus = (event) => {
    let req = this.state.onboardRequest;
    req.employmentStatus = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateJoiningDate = (event) => {
    let req = this.state.onboardRequest;
    req.joiningDate = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateDesignation = (event) => {
    let req = this.state.onboardRequest;
    req.designation = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateCategory = (event) =>{
    this.setState({category: event.target.value});
    }
  updateLeaveType = (event) => {
    let req = this.state.leaveRequest;
    req.leaveType = event.target.value;
    this.setState({ leaveRequest: req });
  };
  updateLeaveFrom = (event) => {
    let req = this.state.leaveRequest;
    req.leaveFrom = event.target.value;
    this.setState({ leaveRequest: req });
  };
  updateLeaveTo = (event) => {
    let req = this.state.leaveRequest;
    req.leaveTo = event.target.value;
    this.setState({ leaveRequest: req });
  };
  updateNoOfLeaveDay = (event) => {
    let req = this.state.leaveRequest;
    req.noOfDays = event.target.value;
    this.setState({ leaveRequest: req });
  };
  updateLeaveSummary = (event) => {
    let req = this.state.leaveRequest;
    req.leaveSummary = event.target.value;
    this.setState({ leaveRequest: req });
  };

  updatePerformanceLevel = (event) => {
    let req = this.state.performanceInput;
    req.performanceLevel = event.target.value;
    this.setState({ performanceInput: req });
  };
  updateComments = (event) => {
    let req = this.state.performanceInput;
    req.comments = event.target.value;
    this.setState({ performanceInput: req });
  };

  performanceUpdate = (event) => {
    event.preventDefault();
    let req = this.state.performanceInput;
    req.email = this.state.user.email;
    this.setState({ performanceInput: req });
    this.setState({ showLoading: true });

    performanceUpdate(this.state.performanceInput)
      .then((res) => {
        this.props.showAlert(
          "Employee performance update request has been accepted !" +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggleLevelUpdate();
        this.props.history.push("/");
        this.props.history.push("hr/dashboard/probation");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  leaveUpdate = (event) => {
    event.preventDefault();
    let req = this.state.leaveRequest;
    req.email = this.state.user.email;
    this.setState({ leaveRequest: req });
    this.setState({ showLoading: true });

    leaveUpdateRequest(this.state.leaveRequest)
      .then((res) => {
        this.props.showAlert(
          res.data.message +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggleLeaveUpdate();
        this.props.history.push("/");
        this.props.history.push("hr/dashboard/employee");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            error.message,
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  candidateOnboard = (event) => {
    event.preventDefault();
    let req = this.state.onboardRequest;
    req.email = this.state.user.email;
    this.setState({ onboardRequest: req });
    this.setState({ showLoading: true });

    candidateOnboardRequest(this.state.onboardRequest)
      .then((res) => {
        this.props.showAlert(
          "Employee onboard request has been accepted !" +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggle();
        this.props.history.push("/");
        this.props.history.push("hr/dashboard/probation");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  changeCategory = (event) => {
    event.preventDefault();

    this.setState({ showLoading: true });
    requestUpdateCategory(this.state.user.email, this.state.category)
      .then((res) => {
        this.props.showAlert(
          "Your request has been accepted ! Category got changed for the user with email of " +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggleCategory();
        this.props.history.push("/hr/dashboard/applicants");
        this.props.history.push("/hr/dashboard/probation");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  render() {
    let roles;

    if (this.state.user && this.state.user.roles) {
      roles = Object.keys(this.state.user.roles).map(
        (key) => " " + this.state.user.roles[key]
      );
    }
    let timeSheetData;
    if (this.state.user && this.state.user.timeSheetList!=null &&this.state.user.timeSheetList.length > 0) {
      timeSheetData = Object.keys(this.state.user.timeSheetList).map((key) => (
        <TimeSheetGridLine
          showAlert={this.props.showAlert}
          key={key}
          timeSheet={this.state.user.timeSheetList[key]}
        />
      ));
    }
    let leaveDataViewList;
    if (this.state.user && this.state.user.leaveDataList!=null &&this.state.user.leaveDataList.length > 0) {
      leaveDataViewList = Object.keys(this.state.user.leaveDataList).map((key) => (
        <LeaveDataGridLine
          showAlert={this.props.showAlert}
          key={key}
          leaveData={this.state.user.leaveDataList[key]}
        />
      ));
    }
    return (
      <tr>
        <th scope="row">{this.state.user.name}</th>   
        <th scope="row">{this.state.user.totalLeaveTaken}</th>
        <th scope="row">{this.state.user.totalEligibleLeaves}</th>
        <th>
          <button className="btn btn-success" onClick={this.toggleLeaveUpdate}data-toggle="tooltip" data-placement="bottom" title="Leave" >
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        <th scope="row">{this.state.user.leaveStatus}  {this.state.user.leaveStatus<0 ? (
          <small className="text-muted">₹ {this.state.user.leaveStatus*-500}</small>
              ) : (
                <small className="text-muted">₹ 0</small>
              )}</th>
        <th scope="row"> {this.state.user.leaveStatus<0 ? (
                <span className="badge badge-danger">Irregular</span>
              ) : (
                <span className="badge badge-success">Regular</span>
              )}</th>
        <th>
          <button className="btn btn-success" onClick={this.toggleTimeSheetModal}data-toggle="tooltip" data-placement="bottom" title="TimeSheet" >
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        <th>
          <button className="btn btn-success" onClick={this.toggleLevelUpdate} data-toggle="tooltip" data-placement="bottom" title="Level" >
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        <th>
          <button className="btn btn-success" onClick={this.toggleRetrieveLeaves} data-toggle="tooltip" data-placement="bottom" title="LeaveData" >
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        <th>
          <button className="btn btn-success" onClick={this.toggleViewProfile} data-toggle="tooltip" data-placement="bottom" title="View" >
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        <th>
          <button className="btn btn-success" onClick={this.toggleCategory} data-toggle="tooltip" data-placement="bottom" title="Update" >
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
      
        <Modal
        size="lg" style={{maxWidth: '700px', width: '100%'}}
          isOpen={this.state.showModelTimeSheetInfo}
          toggle={this.toggleTimeSheetModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleTimeSheetModal}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {
                " { " +
                this.state.user.email +
                " }"}
            </div>
          </ModalHeader>
          <ModalBody>
          <form>
              <div>
                <p className="card-title text-center">Time sheet data</p>
                <div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" style={{width: "25%"}}><small>Time sheet</small></th>
                       
                       
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.user.timeSheetList!=null && this.state.user.timeSheetList.length !=null && this.state.user.timeSheetList.length> 0 ? (
                        timeSheetData
                      ) : (
                        <div className="alert alert-danger" role="alert">
                          <strong>Oops !</strong> Nothing to show here...
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        
        <Modal
          isOpen={this.state.showModalLeaveUpdate}
          toggle={this.toggleLeaveUpdate}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleLeaveUpdate}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " + this.state.user.name + "  " + this.state.user.email}
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.leaveUpdate(e)}>
              <div>
                <p className="card-title text-center">Apply Leave</p>
                <div className="mt-2">
                  <label htmlFor="employmentStatus">Leave Type</label>

                  <select
                    className="custom-select"
                    id="employmentStatus"
                    placeholder="Select employment status"
                    onChange={this.updateLeaveType}
                  >
                    <option value="Leave Type">Leave Type</option>
                    <option value="sickLeave">Sick Leave</option>
                    <option value="vacationLeave">vacation Leave</option>
                    <option value="lopLeave">Lop Leave</option>
                    <option value="paternityLeave">Paternity Leave</option>
                    <option value="maternityLeave">Maternity Leave</option>
                  </select>
                </div>

                <div className="mt-2">
                  <label htmlFor="inputLeaveFrom">Leave From</label>
                  <input
                    type="Date"
                    id="inputLeaveFrom"
                    className="form-control"
                    placeholder="Leave from"
                    required
                    onChange={this.updateLeaveFrom}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-2">
                  <label htmlFor="inputLeaveTo">Leave To</label>
                  <input
                    type="Date"
                    id="inputLeaveTo"
                    className="form-control"
                    placeholder="Leave To"
                    required
                    onChange={this.updateLeaveTo}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="inputNoOfLeave">No of Days</label>
                  <input
                    type="number"
                    id="inputNoOfDays"
                    className="form-control"
                    placeholder="No of leaves"
                    required
                    onChange={this.updateNoOfLeaveDay}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="inputLeave">Leave summary</label>
                  <input
                    type="text"
                    id="inputLeave"
                    className="form-control"
                    placeholder="Leave reason"
                    required
                    onChange={this.updateLeaveSummary}
                    autoComplete="on"
                  />
                </div>
                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalLevelUpdate}
          toggle={this.toggleLevelUpdate}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleLevelUpdate}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " + this.state.user.name + " " + this.state.user.email}
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.performanceUpdate(e)}>
              <div>
                <p className="card-title text-center">Performance Update</p>

                <div className="mt-2">
                  <label htmlFor="inputPerformanceLevel">Probation Level</label>

                  <select
                    className="custom-select"
                    id="inputPerformanceLevel"
                    placeholder="Select probation level"
                    onChange={this.updatePerformanceLevel}
                  >
                    <option value="Employment Status">Probation Level</option>
                    <option value="danger">danger</option>
                    <option value="secondary">secondary</option>
                    <option value="info">info</option>
                    <option value="dark">dark</option>
                    <option value="primary">primary</option>
                    <option value="success">success</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label htmlFor="inputComments">Current Level comments</label>
                  <input
                    type="text"
                    id="inputComments"
                    className="form-control"
                    placeholder="Performance Comments"
                    required
                    onChange={this.updateComments}
                    autoComplete="on"
                  />
                </div>
                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
       
        <Modal
        size="lg" style={{maxWidth: '700px', width: '100%'}}
          isOpen={this.state.showModalLeaveInfo}
          toggle={this.toggleRetrieveLeaves}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleRetrieveLeaves}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {
                " { " +
                this.state.user.email +
                " }"}
            </div>
          </ModalHeader>
          <ModalBody>
          <form>
              <div>
                <p className="card-title text-center">Leave data</p>
                <div>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col" style={{width: "25%"}}><small>Leaves</small></th>
                       
                       
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.user.leaveDataList!=null && this.state.user.leaveDataList.length !=null && this.state.user.leaveDataList.length> 0 ? (
                        leaveDataViewList
                      ) : (
                        <div className="alert alert-danger" role="alert">
                          <strong>Oops !</strong> Nothing to show here...
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal
          size="lg" style={{maxWidth: '900px', width: '100%'}}
          isOpen={this.state.showModalProfileData}
          toggle={this.toggleViewProfile}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleViewProfile}>
          <p>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " + this.state.user.name + " " + this.state.user.email}
            </div>
            <div> 
            </div>
            
        </p>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <p className="card-title text-center">Employee Data</p>
              <div className="row">
              <div className="col-7">
                  <table className="table table-hover">
                    <thead>
                      <h1>
                        <span className="text-muted" style={{ fontSize: 11 }}>
                          {this.state.user.category} Data
                        </span>
                      </h1>
                    </thead>
                    <tbody>
                      <tr scope="row">
                        <th className={"w-25"}> Start Date</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.performanceEvaluation?this.state.user.performanceEvaluation.probationStartDate:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}> Next evaluation date</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.performanceEvaluation?this.state.user.performanceEvaluation.nextEvaluationDate:"NA"}
                        </th>{" "}
                      </tr>

                      <tr scope="row">
                        <th className={"w-25"}> End Date</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.performanceEvaluation?this.state.user.performanceEvaluation.probationEndDate:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}> Remaining Days </th>
                        <th className={"w-25"}>
                          {" "}
                          {
                            this.state.user.performanceEvaluation?this.state.user.performanceEvaluation
                              .remainingProbationDays:"NA"
                          }
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}> Performance Rating</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.performanceEvaluation?this.state.user.performanceEvaluation.performanceRating:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}> Official Email</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.employment?this.state.user.employment.employeeOfficialEmailId:"NA"}
                        </th>{" "}
                      </tr>

                      <tr scope="row">
                        <th className={"w-25"}>Employee Id</th>
                        <th className={"w-25"}> {this.state.user.employment?this.state.user.employment.employeeId:"NA"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-7">
                  {" "}
                  <table className="table table-hover">
                    <thead>
                      <h1>
                        <span className="text-muted" style={{ fontSize: 11 }}>
                          Personal Info
                        </span>
                      </h1>
                    </thead>
                    <tbody>
                      <tr scope="row">
                        <th className={"w-25"}>PanCard</th>
                        <th className={"w-25"}> {this.state.user.personalInfo?this.state.user.personalInfo.panNo:"NA"}</th>{" "}
                      </tr>{" "}
                      <tr scope="row">
                        <th className={"w-25"}>Emergency Contact No</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.personalInfo?this.state.user.personalInfo.emergencyContactNo:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Mobile</th>
                        <th className={"w-25"}> {this.state.user.personalInfo?this.state.user.personalInfo.phoneNo:"NA"}</th>
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Date Of Birth</th>
                        <th className={"w-25"}> {this.state.user.personalInfo?this.state.user.personalInfo.dateOfBirth:"NA"}</th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Permanent Address </th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.personalInfo.permanentAddress?this.state.user.personalInfo.permanentAddress:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Present Address</th>
                        <th className={"w-25"}> {this.state.user.personalInfo?this.state.user.personalInfo.presentAddress:"NA"}</th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Gender</th>
                        <th className={"w-25"}> {this.state.user.personalInfo?this.state.user.personalInfo.gender:"NA"}</th>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-7">
                  {" "}
                  <table className="table table-hover">
                    <thead>
                      <h1>
                        <span className="text-muted" style={{ fontSize: 11 }}>
                          Leave Data
                        </span>
                      </h1>
                    </thead>
                    <tbody>
                      <tr scope="row">
                        <th className={"w-25"}>Eligible Sick Leaves</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.employeeLeaveData
                            ? this.state.user.employeeLeaveData.accumulatedSickLeave
                            : "NA"}
                        </th>{" "}
                      </tr>{" "}
                      <tr scope="row">
                        <th className={"w-25"}>Availed Sick Leaves</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.employeeLeaveData
                            ? this.state.user.employeeLeaveData.availedSickLeave
                            : "NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Eligible Vacation Leaves</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.employeeLeaveData
                            ? this.state.user.employeeLeaveData
                                .accumulatedVacationLeave
                            : "NA"}
                        </th>
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Availed Vacation Leaves</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.user.employeeLeaveData
                            ? this.state.user.employeeLeaveData.availedVacationLeave
                            : "NA"}
                        </th>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalCategoryChange}
          toggle={this.toggleCategory}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleCategory}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " +
                this.state.user.name +
                " Role as { " +
                this.state.user.role +
                " }"}
            </div>
          </ModalHeader>
          <ModalBody>
            <p>
              <b>Email address:</b> {this.state.user.email}
            </p>
            <p>
              <b>Total leaves taken for current year:</b>
              {this.state.user.totalLeaves ? (
                <span className="badge badge-danger">Irregular</span>
              ) : (
                <span className="badge badge-success">Regular</span>
              )}
            </p>
            <p>
              To change category:
            </p>
            <form onSubmit={(e) => this.changeCategory(e)}>
            
              <select
               className="w3-select"
                name="option"
                value={this.state.category}
                onChange={(event) => this.updateCategory(event)}
              >
                <option  value="" disabled>
                  Choose your option
                </option>
                <option value="Employment Status">Employment Status</option>
                   <option value="interview-frontend">INTERVIEW-FRONTEND</option>
                    <option value="interview-backend">INTERVIEW-BACKEND</option>
                    <option value="intern">INTERN</option>
                    <option value="internal-employee">INTERNAL-EMPLOYEE</option>
                    <option value="external-employee">EXTERNAL-EMPLOYEE</option>
                    <option value="rejected">REJECTED</option>
              </select>
<br></br>
              <button type="submit" className="btn btn-success btn-lg mt-4">
                Change Category
              </button>
            </form>
          </ModalBody>
        </Modal>
        
        
      </tr>
    );
  }
}

export default withRouter(EmployeeGridLine);
