import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { jobContent } from "../../content/PageContent";
import { jobRequirements } from "../../content/PageContent";
import { training } from "../../content/PageContent";
import { NavLink, withRouter, Link } from "react-router-dom";
import { Alert } from 'reactstrap';
import { responsiveFontSizes } from "@material-ui/core";

class Training extends Component {
  state = {
    alertIsVisible: true,
    showLoading: false,
    showCamundaDeveloper: false,
    showFullStackDeveloper: false,
    showBackendDeveloper: false,
    showBackendDeveloper: false,
    showMobileDeveloper: false,
    showUIDeveloper: false,
    showHRManager: false,
    showApiTesting: false,
    showSQLDeveloper: false,
    showDigitalMarketing: false,
   
  };
  constructor(props) {
    super(props);

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.toggleCamundaDeveloper = this.toggleCamundaDeveloper.bind(this);
    this.toggleFullStackDeveloper = this.toggleFullStackDeveloper.bind(this);
    this.toggleBackendDeveloper = this.toggleBackendDeveloper.bind(this);
    this.toggleMobileDeveloper = this.toggleMobileDeveloper.bind(this);
    this.toggleUIDeveloper = this.toggleUIDeveloper.bind(this);
    this.toggleDevopsEngineer = this.toggleDevopsEngineer.bind(this);
    this.toggleHRManager = this.toggleHRManager.bind(this);
    this.toggleApiTesting = this.toggleApiTesting.bind(this);
    this.toggleSQLDeveloper = this.toggleSQLDeveloper.bind(this);
    this.toggleDigitalMarketing = this.toggleDigitalMarketing.bind(this);
  }

  toggleCamundaDeveloper() {
    this.setState({ showCamundaDeveloper: !this.state.showCamundaDeveloper });
  }
  toggleFullStackDeveloper() {
    this.setState({
      showFullStackDeveloper: !this.state.showFullStackDeveloper,
    });
  }
  toggleBackendDeveloper() {
    this.setState({ showBackendDeveloper: !this.state.showBackendDeveloper });
  }
  toggleMobileDeveloper() {
    this.setState({ showMobileDeveloper: !this.state.showMobileDeveloper });
  }
  toggleUIDeveloper() {
    this.setState({ showUIDeveloper: !this.state.showUIDeveloper });
  }
  toggleDevopsEngineer() {
    this.setState({ showDevopsEngineer: !this.state.showDevopsEngineer });
  }
  toggleHRManager() {
    this.setState({ showHRManager: !this.state.showHRManager });
  }
  toggleApiTesting() {
    this.setState({ showApiTesting: !this.state.showApiTesting });
  }
  toggleSQLDeveloper() {
    this.setState({ showSQLDeveloper: !this.state.showSQLDeveloper });
  }
  toggleDigitalMarketing() {
    this.setState({ showDigitalMarketing: !this.state.showDigitalMarketing });
  }


  componentDidMount() {
    document.title = "Train and Hire";
  }
  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }

  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false })
      }, 8000)
    });
  }

  render() {
    return (
      <div>
         <h4 className="text-center">{training.title1}</h4>
        <marquee width="100%" direction="left" height="50px" className="text-dark">
         
        <h4 className="alert-heading"> {training.title2}</h4>
       </marquee>
        <div>   
        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.uiDeveloper}</h4>
              <h4 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> ₹ 60,000 {" "} <small class="text-muted">/ course</small></small>{"          "}   
              ₹ 30,000{" "}
                <small class="text-muted">/ course</small>
              </h4>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img
                      src={require("../../../../assets/images/UI developer140x140.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">
                      {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                      {training.content3}
                    </p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleUIDeveloper}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showUIDeveloper}
                    toggle={this.toggleUIDeveloper}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleUIDeveloper}>
                        <small>{jobRequirements.header41}</small>
                      </ModalHeader>
                      <small>
                        {" "}
                        <p>{jobRequirements.description3}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header42}</small>{" "}
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.Responsibilities3}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header43}</small>{" "}
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.skils3}</p>
                      </small>
                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleUIDeveloper}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>
                  <small>
                    <p className="card-title text-left">
                      {training.eligibility}
                      <br></br>
                      {training.course}
                      <br></br>
                       {training.coursePlan}
                      <br></br>
                     
                      <small>
                        {" "}
                     {/* {training.type}
                      <br></br> */}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3} <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        subscriptionCost: "30000",
                        subscriptionFor: "UI Developer",
                        subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.fullStack}</h4>

              <h5 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 90,000 {" "}  <small class="text-muted">/ course</small></small> ₹ 45,000{" "}
                <small class="text-muted">/ course</small>
              </h5>
            </div>

            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img
                      src={require("../../../../assets/images/full-stack-developer140x140.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">{training.content}</p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleFullStackDeveloper}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showFullStackDeveloper}
                    toggle={this.toggleFullStackDeveloper}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleFullStackDeveloper}>
                        {" "}
                        <small>{jobRequirements.header11}</small>
                      </ModalHeader>
                      <p>
                        <small>{jobRequirements.description}</small>{" "}
                      </p>

                      <ModalHeader>
                        <small>{jobRequirements.header12}</small>
                      </ModalHeader>

                      <p>
                        <small>{jobRequirements.Responsibilities}</small>{" "}
                      </p>

                      <ModalHeader>
                        <small>{jobRequirements.header13}</small>
                      </ModalHeader>

                      <p>
                        <small>{jobRequirements.skills} </small>
                      </p>

                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleFullStackDeveloper}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>

                  <small>
                    <p className="card-title text-left">
                      {training.eligibility}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                       jobApplicationProps: {
                        subscriptionCost: "45000",
                        subscriptionFor: "Full Stack Developer",
                        subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.backend}</h4>
              <h4 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> {" "} ₹ 60,000 {" "} <small class="text-muted">/ course</small></small>{"          "}   
               ₹ 30,000{" "}
                <small class="text-muted">/ course</small>
              </h4>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/java.png")} />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">
                      {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                      {training.content1}
                    </p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleBackendDeveloper}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showBackendDeveloper}
                    toggle={this.toggleBackendDeveloper}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleBackendDeveloper}>
                        <small>{jobRequirements.header31}</small>
                      </ModalHeader>
                      <small>
                        <p>{jobRequirements.description1}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header32}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.Responsibilities1}</p>{" "}
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header33}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.skils1}</p>{" "}
                      </small>
                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleBackendDeveloper}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>
                  <small>
                    <p className="card-title text-left">
                      {training.eligibility}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: { 
                        subscriptionCost: "30000",
                        subscriptionFor: "Backend Developer",
                        subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.mobile}</h4>
              <h4 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> ₹ 1,20,000 {" "} <small class="text-muted">/ course</small></small>{"          "}   
                 ₹ 60,000{" "}
                <small class="text-muted">/ course</small>
              </h4>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img
                      src={require("../../../../assets/images/mobile-apps-circle-250sq140x140.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">
                      {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                      {training.content2}
                    </p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleMobileDeveloper}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showMobileDeveloper}
                    toggle={this.toggleMobileDeveloper}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleMobileDeveloper}>
                        <small>{jobRequirements.header31}</small>
                      </ModalHeader>
                      <small>
                        <p>{jobRequirements.description2}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header32}</small>
                      </ModalHeader>

                      <small>
                        <p>{jobRequirements.Responsibilities2}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header33}</small>
                      </ModalHeader>

                      <small>
                        <p>{jobRequirements.skils2}</p>
                      </small>

                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleMobileDeveloper}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>
                  <small>
                    <p className="card-title text-left">
                      {training.eligibility}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        subscriptionCost: "60000",
                        subscriptionFor: "Mobile App Developer (Android/IOs)",
                        subscriptionType:"",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.camunda}</h4>
              <h5 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> ₹ 1,20,000 {" "}  <small class="text-muted">/ course</small></small>{"          "}   
                ₹ 60,000{" "} 
                <small class="text-muted">/ course</small>
              </h5>
            </div>

            <div class="card-body">
              <div className="row">
                <div className="col-lg-3 my-4">
                  <Link to="/" className="nav-link">
                    <img
                      src={require("../../../../assets/images/camunda.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">{training.content0}</p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleCamundaDeveloper}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showCamundaDeveloper}
                    toggle={this.toggleCamundaDeveloper}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleCamundaDeveloper}>
                        {" "}
                        <small>{jobRequirements.header01}</small>
                      </ModalHeader>
                      <p>
                        <small>{jobRequirements.description0}</small>{" "}
                      </p>

                      <ModalHeader>
                        <small>{jobRequirements.header02}</small>
                      </ModalHeader>

                      <p>
                        <small>{jobRequirements.Responsibilities0}</small>{" "}
                      </p>

                      <ModalHeader>
                        <small>{jobRequirements.header03}</small>
                      </ModalHeader>

                      <p>
                        <small>{jobRequirements.skils0} </small>
                      </p>

                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleCamundaDeveloper}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>

                  <small>
                    <p className="card-title text-left">
                      {training.eligibility}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        subscriptionCost: "60000",
                        subscriptionFor: "CamundaDeveloper",
                        subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.devops}</h4>
              <h4 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 60,000 {" "}  <small class="text-muted">/ course</small></small>{"          "}   
                 ₹ 30,000{" "}
                <small class="text-muted">/ course</small>
              </h4>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img
                      src={require("../../../../assets/images/DevOpsAWS140x140.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">
                      {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                      {training.content4}
                    </p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleDevopsEngineer}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showDevopsEngineer}
                    toggle={this.toggleDevopsEngineer}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleDevopsEngineer}>
                        <small>{jobRequirements.header51}</small>
                      </ModalHeader>
                      <small>
                        {" "}
                        <p>{jobRequirements.description4}</p>
                      </small>

                      <ModalHeader>
                        {" "}
                        <small>{jobRequirements.header52}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.Responsibilities4}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header53}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.skils4}</p>
                      </small>

                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleDevopsEngineer}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>
                  <small>
                    <p className="card-title text-left">
                      {training.eligibility}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>

                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        subscriptionCost: "30000",
                        subscriptionFor: "AWS Cloud Dev Ops Engineer",
                       subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.hrManager}</h4>
              <h4 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 30,000 {" "}<small class="text-muted">/ course</small> </small>{"          "}   
                ₹ 15,000{" "}
                <small class="text-muted">/ course</small>
              </h4>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img
                      src={require("../../../../assets/images/hrmanager140x140.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">
                      {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                      {training.content5}
                    </p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleHRManager}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showHRManager}
                    toggle={this.toggleHRManager}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleHRManager}>
                        <small>{jobRequirements.header61}</small>
                      </ModalHeader>
                      <small>
                        {" "}
                        <p>{jobRequirements.description5}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header62}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.Responsibilities5}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header63}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.skils5}</p>
                      </small>

                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleHRManager}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>
                  <small>
                    <p className="card-title text-left">
                      {training.eligibility1}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        subscriptionCost: "15000",
                        subscriptionFor: "HR Manager",
                        subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.apiTesting}</h4>
              <h4 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 60,000 {" "}  <small class="text-muted">/ course</small> </small>{"          "}   
                 ₹ 30,000{" "}
                <small class="text-muted">/ course</small>
              </h4>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img
                      src={require("../../../../assets/images/apitest140x140.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">
                      {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                      {training.content6}
                    </p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleApiTesting}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showApiTesting}
                    toggle={this.toggleApiTesting}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleApiTesting}>
                        <small>{jobRequirements.header71}</small>
                      </ModalHeader>
                      <small>
                        {" "}
                        <p>{jobRequirements.description6}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header72}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.Responsibilities6}</p>{" "}
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header73}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.skils6}</p>
                      </small>

                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleApiTesting}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>

                  <small>
                    <p className="card-title text-left">
                      {training.eligibility}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        subscriptionCost: "30000",
                        subscriptionFor: "API Test Engineer",
                        subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>{jobContent.sqlDeveloper}</h4>
              <h4 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 60,000 {" "} <small class="text-muted">/ course</small></small>{"          "}   
                ₹ 30,000{" "}
                <small class="text-muted">/ course</small>
              </h4>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/sqln.png")} />
                  </Link>
                </div>
                <div className="col-lg-9"> 
                  <small>
                    <p className="card-title text-left">
                      {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                      {training.content7}
                    </p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleSQLDeveloper}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showSQLDeveloper}
                    toggle={this.toggleSQLDeveloper}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleSQLDeveloper}>
                        <small>{jobRequirements.header81}</small>
                      </ModalHeader>
                      <small>
                        <p>{jobRequirements.description6}</p>
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header82}</small>{" "}
                      </ModalHeader>

                      <small>
                        <p>{jobRequirements.Responsibilities6}</p>{" "}
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header83}</small>{" "}
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.skils6}</p>
                      </small>

                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleSQLDeveloper}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>
                  <small>
                    <p className="card-title text-left">
                      {training.eligibility}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        subscriptionCost: "30000",
                        subscriptionFor: "SQL Developer",
                        subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h5>{jobContent.digitalMarketing}</h5>
              <h5 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> ₹ 60,000 {" "}  <small class="text-muted">/ course</small> </small>{"          "}   
                ₹ 30,000{" "}
                <small class="text-muted">/ course</small>
              </h5>
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-lg-3">
                  <Link to="/" className="nav-link">
                    <img
                      src={require("../../../../assets/images/digitalmarketing140x140.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-9">
                  <small>
                    <p className="card-title text-left">
                      {/* <FontAwesomeIcon icon={faCode} />{" "} */}
                      {training.content8}
                    </p>
                  </small>

                  <small>
                    <p
                      className="alert-link page-link"
                      onClick={this.toggleDigitalMarketing}
                    >
                      Learn more
                    </p>
                  </small>
                  <Modal
                    isOpen={this.state.showDigitalMarketing}
                    toggle={this.toggleDigitalMarketing}
                  >
                    <ModalBody>
                      <ModalHeader toggle={this.toggleDigitalMarketing}>
                        <small>{jobRequirements.header91}</small>
                      </ModalHeader>
                      <small>
                        {" "}
                        <p>{jobRequirements.description8}</p>{" "}
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header92}</small>
                      </ModalHeader>

                      <small>
                        {" "}
                        <p>{jobRequirements.Responsibilities8}</p>{" "}
                      </small>

                      <ModalHeader>
                        <small>{jobRequirements.header93}</small>
                      </ModalHeader>

                      <small>
                        <p>{jobRequirements.skils8}</p>{" "}
                      </small>

                      <Button
                        color="secondary"
                        className="float-right"
                        onClick={this.toggleDigitalMarketing}
                      >
                        Close
                      </Button>
                    </ModalBody>
                  </Modal>
                  <small>
                    <p className="card-title text-left">
                      {training.eligibility1}
                      <br></br>
                      {training.course}
                      <br></br>
                      {training.coursePlan}
                      <br></br>
                      <small>
                        {" "}
                        {training.step1}
                        <br></br>
                        {training.step2}
                        <br></br>
                        {training.step3}
                        <br></br>
                        {training.step4}
                      </small>
                    </p>
                  </small>
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                        careerType: "intern",
                        subscriptionCost: "30000",
                        subscriptionFor: "Digital Marketing Expert",
                        subscriptionType:"TRAINING-hire",
                      },
                    }}
                    exact
                  >
                    <button
                      type="button"
                      class="btn btn-md  btn-block btn-primary w-50 mx-auto"
                    >
                      PROCEED
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      </div>
      </div>  
    );
  }
}

export default withRouter(Training);
