import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { skillData } from '../../../../../api/commonApi';

class SkillsDataSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            skillData: [{ primarySkill: '', experienceSkillMonth: '' }],
            showLoading: false,
        };

    }



    componentDidMount() {
        document.title = "My account skills data settings"
    }
    handleSkillAddClick = () => {
        this.setState({ skillData: [...this.state.skillData, {primarySkill: '', experienceSkillMonth: ''}] });
    };
    // handle click event of the onchange method
    handleSkillList = (e, index) => {
        const { name, value } = e.target;
        const list = [...this.state.skillData];
        list[index][name] = value;
        this.setState({ skillData: list })

    }
    // handle click event of the Remove button
    handleSkillRemoveClick = (index) => {
        const list = [...this.state.skillData];
        list.splice(index, 1);
        this.setState({ skillData: list })
    }

    fullskillList = (event) => {
        event.preventDefault();
        this.setState({ showLoading: true });
        const skill = this.state;
        
        skillData(skill)
            .then(response => {
                const data = response.data
                this.props.showAlert(data.message, "success");
                this.setState({ showLoading: false });
                
                this.setState({
                    skillData: [{ primarySkill: '', experienceSkillMonth: '' }],
                })

            })
            .catch(error => {
                this.props.showAlert(error);
                this.setState({ showLoading: false });
            })

    };

    render() {
        const { skillData } = this.state;
        return (
            <div>
                <h2>Skills Data Onboard</h2>

                <hr />
                <div>
                    {
                        this.state.showLoading
                            ?
                            <div className="align-content-center text-center">
                                <h3>Updating your Skills data</h3>
                                <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
                            </div>
                            :
                            <form onSubmit={(e) => this.fullskillList(e)}>
                                <div className="form-group">

                                    {
                                        skillData.map((x, i) => {
                                            return (
                                                <div>
                                                    <label htmlFor="primarySkill" className='mt-2'>Primary Skills</label>
                                                    <input type="text" className="form-control mt-2" id="primarySkill" name='primarySkill'
                                                        placeholder="Enter your primary skills " required autoComplete="on" value={x.primarySkill} onChange={e => this.handleSkillList(e, i)}
                                                    />
                                                    <label htmlFor="experienceSkillMonth" className='mt-2'>Experience Skills</label>
                                                    <input type="number" className="form-control" id="experienceSkillMonth" name='experienceSkillMonth'
                                                        required autoComplete="on" placeholder='Enter your experience skills in month' value={x.experienceSkillMonth} onChange={e => this.handleSkillList(e, i)}
                                                    />

                                                    {
                                                        skillData.length !== 1 &&
                                                        <button className='btn btn-danger mt-2' onClick={this.handleSkillRemoveClick}>Remove</button>
                                                    }
                                                    {
                                                        skillData.length - 1 === i &&
                                                        <button className='btn btn-primary mt-2 ml-2' onClick={this.handleSkillAddClick}>Add</button>
                                                    }
                                                </div>
                                            );
                                        }
                                        )}
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                    }

                </div>

            </div>
        );
    }
}

export default withRouter(SkillsDataSettings);