import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN } from "../../../../config/Config";
import { submitAnswers } from "../../../../api/AssessmentApi";

class ViewAnswers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
      imagePreviewUrl: "",
      user: "",
      showLoading: false,
      assessmentQuestionShortAnswersMap: "",
      assessmentQuestionsMultipleChoiceMap: "",
      userName: "",
      counter: 0,
      textIdx: 0,
      minutesPerQuestion: 0,
      answerList: [{}],
      testId: "",
    };
    this.onDismissAlert = this.onDismissAlert.bind(this);

    this.onShowAlert = this.onShowAlert.bind(this);
    this.updateRetrieveAssessmentData =
      this.updateRetrieveAssessmentData.bind(this);
    this.retrieveProfilePic = this.retrieveProfilePic.bind(this);
    this.backToHome = this.backToHome.bind(this);
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      this.props.showAlert(
        "Your session expired, You must be authenticated to access your online assessments !",
        "error"
      );
      this.props.history.push("/login");
    }
  }
  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 90000);
    });
  };
  componentDidMount() {
    this.updateRetrieveAssessmentData();
    this.onShowAlert();
  }

  updateRetrieveAssessmentData = () => {
    this.setState({ showLoading: true });
    let req = this.state;

    req.assessmentQuestionShortAnswersMap = this.props.location.assessmentProps
      ? this.props.location.assessmentProps.assessment.questionPaper
          .assessmentQuestionShortAnswersMap
      : null;
    req.assessmentQuestionsMultipleChoiceMap = this.props.location
      .assessmentProps
      ? this.props.location.assessmentProps.assessment.questionPaper
          .assessmentQuestionsMultipleChoiceMap
      : null;
    // req.minutesPerQuestion=this.props.location.assessmentProps?(this.props.location.assessmentProps.assessment.duration/req.questions.length):0;
    req.testId = this.props.location.assessmentProps
      ? this.props.location.assessmentProps.assessment.testId
      : 0;
    req.students = this.props.location.assessmentProps.assessment.students
      ? this.props.location.assessmentProps.assessment.students
      : null;

    this.setState({ req });
    //alert("ion" + JSON.stringify(req.students));
    // alert(JSON.stringify(this.state));
    /* useEffect(() => {
      function handleContextMenu(e) {
        e.preventDefault(); // prevents the default right-click menu from appearing
      }
      // add the event listener to the component's root element
      const rootElement = document.getElementById('my-component');
      rootElement.addEventListener('contextmenu', handleContextMenu);
      // remove the event listener when the component is unmounted
      return () => {
        rootElement.removeEventListener('contextmenu', handleContextMenu);
      };
    }, []);*/
  };

  onClickSubmitAnswers = () => {
    this.setState({ showLoading: true });
    let req = this.state;
    const assessmentSubmitAnswerRequest = {
      answers: "",
      testId: "",
    };

    assessmentSubmitAnswerRequest.answers = req.answerList.filter(
      (el) => Object.keys(el).length
    );
    assessmentSubmitAnswerRequest.testId = req.testId;

    // alert(JSON.stringify(assessmentSubmitAnswerRequest));
    submitAnswers(assessmentSubmitAnswerRequest)
      .then((res) => {
        this.props.showAlert(res.data.message, "success");
        this.setState({ showLoading: false });
        this.setState({ req: "" });
        this.props.history.push("/me/assessment/assessments");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  backToHome() {
    this.props.history.push("/hr/dashboard/trackAssessments");
  }
  retrieveProfilePic = () => {
    this.setState({ showLoading: true });
    retrieveProfileImage()
      .then((res) => {
        this.setState({ imagePreviewUrl: res.data });
        this.props.showAlert(
          "Profile image retrieved successfully ",
          "success"
        );
        this.setState({ showLoading: false });
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert("Your profile image does't exist !", "error");
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  handleAnswerList = (e, index) => {
    const { name, value } = e.target;
    const list = this.state.answerList;
    list[index] = value;
    this.setState({ answerList: list });
  };

  // handle click event of the Remove button
  handleAnswerRemoveClick = (index) => {
    const list = this.state.answerList;
    list.splice(index, 1);
    this.setState({ answerList: list });
  };

  // handle click event of the Add button
  handleAnswerAddClick = () => {
    this.setState({ answerList: [...this.state.answerList, {}] });
    this.setState({ formInput: "" });
  };

  render() {
    const {
      questions,
      counter,
      minutesPerQuestion,
      answerList,
      formInput,
      students,
      assessmentQuestionShortAnswersMap,
    } = this.state;
    const { imagePreviewUrl } = this.state;
    if (assessmentQuestionShortAnswersMap) {
      const assessmentMap = Object.entries(
        assessmentQuestionShortAnswersMap
      ).map(([key, value]) => {
        return (
          <>
            <div>
              <div class="card shadow border-0 rounded-4 mb-5">
                <div class="card-body p-5">
                  <div class="d-flex align-items-center mb-4">
                    <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                      <i class="bi bi-tools"></i>
                    </div>
                    <span class="text-gradient d-inline">{key}</span>
                  </div>
                  <div class="row row-cols-1 row-cols-md-3 mb-4">
                    <div class="col">
                      <div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                        <span className="text-muted">{value.toString()}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
                crossorigin="anonymous"
              ></script>
            </div>
          </>
        );
      });

      return (
        <>
          <br></br>
          <br></br>
          <div className="container">
            <h7>Question and Answers.</h7> {"    "}
            <button onClick={this.backToHome}>Back.</button>
            {assessmentMap}
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
        </>
      );
    } else {
      return (
        <>
          <br></br>
          <br></br>
          <div className="container">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <small class="text-dark fw-bolder mb-4">
                There is no one taken up the online assessment yet.
              </small>

              <button
                class="btn btn-primary px-4 py-3"
                onClick={this.backToHome}
              >
                <div class="d-inline-block bi bi-download me-2"></div>
                Back to dashboard.
              </button>
            </div>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
        </>
      );
    }
  }
}

export default withRouter(ViewAnswers);
