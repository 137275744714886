import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  HR_TOKEN,
  EMPLOYEE_TOKEN,
  CLIENT_TOKEN,
  FINANCIER_TOKEN,
} from "../../config/Config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserLock,
  faDoorOpen,
  faLessThan,
  faCogs,
  faFile,
  faUserCheck,
  faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./Sidebar";
import {
  faDyalog,
  faInternetExplorer,
} from "@fortawesome/free-brands-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FormatAlignRightOutlined } from "@material-ui/icons";
import axios from "axios";
import { getEndpoints } from "../../api/Api";
class AppHeader extends Component {
  state = {
    isOpen: false,
    isOpenSideBar: false,
    endpoints: [],
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
  }
  componentDidMount() {
    this.getEndpoints();
  }
  getEndpoints = () => {
    this.setState({ showLoading: true });

    getEndpoints()
      .then((res) => {
        if (res.data === null) {
          this.showAlert(res.message, "error");
        } else {
          this.setState({ endpoints: res.data });
        }

        this.setState({ showLoading: false });
      })

      .catch((error) => {
        if (error.status === 401) {
          this.showAlert(
            "Your are not eligible to access this endpoint",
            "error"
          );
        } 
        this.setState({ showLoading: false });
      });
  };
  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  toggleSideBar() {
    this.setState({ isOpenSideBar: !this.state.isOpenSideBar });
  }
  _getSlug(slug) {
    return slug.split("?")[0];
  }
  _createMenuItems() {
    return;
  }
  render() {
    let menuItemsLeft;
    let decodedToken;
    let subHeader = [];
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (
      token !== "undefined" &&
      token !== undefined &&
      token !== null &&
      token !== "null" &&
      token !== ""
    ) {
      //DECODE TOKEN

      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      decodedToken = JSON.parse(window.atob(base64));
      // alert(JSON.stringify(decodedToken))
      menuItemsLeft = [
        <UncontrolledDropdown nav inNavbar key={1}>
          <DropdownToggle nav caret>
            <span className="bg-primary-appheader">
              {" "}
              {`${decodedToken.name[0].toUpperCase()}`}{" "}
            </span>
            {/** <img
             alt={decodedToken.name}
              src={
                require("../../assets/images/logoFinal25x25.png")
              }
             
              className="rounded-circle"
              
            /> */}
            {/*{decodedToken.username}*/}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem disabled>
              <p className="nav-link text-center text-muted blue">
                Signed in as <b>{decodedToken.name}</b>
              </p>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <Link
                to="/me/settings/account"
                className="nav-link text-dark  blue"
                onClick={this.toggle}
              >
                <FontAwesomeIcon icon={faUserCheck} className="pr-1" />
                Account Settings
              </Link>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <Link
                to="/me/assessment/assessments"
                className="nav-link text-dark  blue"
                onClick={this.toggle}
              >
                <FontAwesomeIcon icon={faInternetExplorer} className="pr-1" />{" "}
                Evaluation
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link
                to="/publish/content"
                className="nav-link text-dark  blue"
                onClick={this.toggle}
              >
                <FontAwesomeIcon icon={faFile} className="pr-1" />{" "}
                PublishContent
              </Link>
            </DropdownItem>
           {/** <DropdownItem>
                  <Link
                    to="/client/dashboard/"
                    className="nav-link text-dark blue"
                    onClick={this.toggle}
                  >
                    <FontAwesomeIcon icon={faCogs} className="pr-1" /> Payment
                  </Link>
                </DropdownItem>*/} 

            {localStorage.getItem(ADMIN_TOKEN) ? (
              <DropdownItem>
                <Link
                  to="/admin/dashboard/index"
                  className="nav-link text-dark blue"
                  onClick={this.toggle}
                >
                  <FontAwesomeIcon icon={faCogs} className="pr-1" /> Admin
                  dashboard
                </Link>
              </DropdownItem>
            ) : null}
            {localStorage.getItem(HR_TOKEN) ? (
              <>
                <DropdownItem>
                  <Link
                    to="/hr/dashboard/applicants"
                    className="nav-link text-dark blue"
                    onClick={this.toggle}
                  >
                    <FontAwesomeIcon icon={faCogs} className="pr-1" /> Hr
                    dashboard
                  </Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <Link
                    to="/hr/email/bulkEmail"
                    className="nav-link text-dark  blue"
                    onClick={this.toggle}
                  >
                    <FontAwesomeIcon
                      icon={faInternetExplorer}
                      className="pr-1"
                    />{" "}
                    Bulk Emailing
                  </Link>
                </DropdownItem>
              </>
            ) : null}
            {localStorage.getItem(EMPLOYEE_TOKEN) ? (
              <DropdownItem>
                <Link
                  to="/employee/dashboard/time"
                  className="nav-link text-dark blue"
                  onClick={this.toggle}
                >
                  <FontAwesomeIcon icon={faCogs} className="pr-1" /> My
                  dashboard
                </Link>
              </DropdownItem>
            ) : null}
            {localStorage.getItem(CLIENT_TOKEN) ? (
              <>
                <DropdownItem>
                  <Link
                    to="/client/dashboard/hire"
                    className="nav-link text-dark blue"
                    onClick={this.toggle}
                  >
                    <FontAwesomeIcon icon={faCogs} className="pr-1" /> My
                    dashboard
                  </Link>
            </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <Link
                    to="/client/profiles/backend"
                    className="nav-link text-dark blue"
                    onClick={this.toggle}
                  >
                    <FontAwesomeIcon icon={faUserLock} className="pr-1" />{" "}
                    Profiles Pool
                  </Link>
                </DropdownItem>
              </>
            ) : null}
            {localStorage.getItem(FINANCIER_TOKEN) ? (
              <>
                <DropdownItem>
                  <Link
                    to="/financier/dashboard/clients"
                    className="nav-link text-dark blue"
                    onClick={this.toggle}
                  >
                    <FontAwesomeIcon icon={faCogs} className="pr-1" /> Financier
                    dashboard
                  </Link>
                </DropdownItem>
                <DropdownItem divider />
              </>
            ) : null}
            <DropdownItem divider />
            <DropdownItem
              onClick={this.props.logout}
              className="cursor-pointer"
            >
              <p className="nav-link text-dark blue">
                <FontAwesomeIcon icon={faDoorOpen} className="pr-1" /> Logout
              </p>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>,
      ];
    } else {
      menuItemsLeft = [
        <NavItem key={1}>
          <Link to="/about" className="nav-link" onClick={this.toggle}>
            About
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/services" className="nav-link" onClick={this.toggle}>
            Services
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/team" className="nav-link" onClick={this.toggle}>
            Team
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/contact" className="nav-link" onClick={this.toggle}>
            Contact
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/careers/training-jobs" className="nav-link" onClick={this.toggle}>
            Careers
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/login/" className="nav-link" onClick={this.toggle}>
            Sign in
          </Link>
        </NavItem>,
        /**<NavItem key={7}>
         <Link to="/banking/" className="nav-link" onClick={this.toggle}>
           Payment
         </Link>
       </NavItem>,

       <NavItem key={2}>
          <Link to="/register/" className="nav-link">
            Sign up
          </Link>
        </NavItem>,
          <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                  <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                  <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>
                  <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
        */
      ];
      /**   for (let i = 0; i < this.state.endpoints.length; i++) {
        let contentStateList = this.state.endpoints[i].contentStateList;
        let endpoint=contentStateList[i].endpoint;
       let currentCategory=this.state.endpoints[i].category;
      
       let category='';
       if(category===''){
        category=currentCategory;
       }
       
          subHeader.push(  <li key={i+ 25}>
             <Link
              to={"/" + endpoint}
              className="nav-link"
              onClick={this.toggle}
            >
             
              {this.state.endpoints[i].category}
             
            </Link>
          </li>)
        
        category=='';
      }*/

      {
        /* this.state.endpoints.map((endpointsData, index) =>
        menuItemsLeft.push(
          <NavItem key={index + 25}>
            <Link
              to={"/" + endpointsData.category}
              className="nav-link"
              onClick={this.toggle}
            >
              {endpointsData.category}
            </Link>
          </NavItem>
        )
      );
        */
      }
    }

    return (
      <div className="container-fluid">
        <Navbar dark expand="md" className="bg-primary-appheader fixed-top">
          <Link to="/" className="navBrand">
            <ul className="tech">
              <li className="text1">Y</li>
              <li className="text2">a</li>
              <li className="text3">a</li>
              <li className="text4">z</li>
              <li className="text5">h</li>
              <li className="text6">t</li>
              <li className="text7">e</li>
              <li className="text8">c</li>
              <li className="text9">h</li>
            </ul>
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <Link to="/" className="nav-link">
                <img src={require("../../assets/images/logoFinal20x20.png")} />
              </Link>
            </Nav>
            <Nav className="ml-auto" navbar>
              {menuItemsLeft}
            </Nav>
          </Collapse>
        </Navbar>
        <div class="mainContainerSubheader w-100" id="secondarySubHeader">
          {/*<div>
            <div class="leftIcon icon mt-1">
              <FontAwesomeIcon icon={faLessThan} style={{fontWeight:300}} />
            </div>
            <div class="rightIcon icon mt-1">
              <FontAwesomeIcon icon={faGreaterThan} style={{fontWeight:300}} />
            </div>
          </div>
    */}
          <ul
            class="containerSubhead"
            style={{ listStyle: "none", display: "flex", textTransform: "uppercase" }}
          >
            {this.state.endpoints.map((endpointsData, index) => (
              <li key={index + 25}>
                <Link
                  to={"/" + endpointsData.category+"-"+endpointsData.contentStateList[0].endpoint}
                  className="nav-link mt-1 link text-white"
                  onClick={this.toggle}
                >
                  {endpointsData.category}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/**<button
          aria-label="sub header slider next"
          class="header-main__slider-arrow previous"
        >
          <FontAwesomeIcon
            icon={faLessThan}
            className="text-secondary"
            style={{ fontWeight: 300 }}
          />
        </button>
        <div class="header-main__slider mt-5">
          <ul id="hslider" style={{ listStyle: "none", display: "flex" }}>
            <li>
              {this.state.endpoints.map((endpointsData, index) =>
                menuItemsLeft.push(
                  <NavItem key={index + 25}>
                    <Link
                      to={"/" + endpointsData.category}
                      className="nav-link"
                      onClick={this.toggle}
                    >
                      {endpointsData.category}
                    </Link>
                  </NavItem>
                )
              )}
            </li>
          </ul>
          <button
            aria-label="sub header slider next"
            class="header-main__slider-arrow next"
          >
            <FontAwesomeIcon
              icon={faGreaterThan}
              className="text-secondary"
              style={{ fontWeight: 300 }}
            />
          </button>
        </div>
 */}
      </div>
    );
  }
}
export default withRouter(AppHeader);
