import {
  ACCESS_TOKEN,
  config,
} from "../config/Config";
import axios from 'axios';
const sendRequest = (options) => {
  const headers = new Headers({
    "Content-Type": "application/json",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem(ACCESS_TOKEN)
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});

export function getProfilesPage(pageNumber, searchText,type) {
  if (!searchText) {
    if (
      pageNumber !== undefined &&
      pageNumber !== null &&
      !isNaN(+pageNumber)
    ) {
      return instance.get(
        "/secure/client/dashboard/"+`${type}`+"?page=" + pageNumber,
        {
          
          headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
          
        }
      );
    } else {
      return instance.get("/secure/client/dashboard/"+`${type}`+"?page=0", {
       headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null },
      });
    }
  } else {
    return instance.get(
      "/secure/client/dashboard/"+`${type}`+"?page=0&text=" + searchText,
      {
       headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null },
      }
    );
  }
}

export function shortlist(shortlistedCandidate) {
  return instance.post('/secure/client/shortlist', shortlistedCandidate, {
      headers: { "Content-type": "application/json",
        "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
    })
}
export function retrieveProfileImageByEmail(email) {
  // alert("email"+email)
   const url =  `/secure/client/image/${email}`
   return instance.get(url, {
       headers: { "Content-type": "application/json",
         "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
     })
 }
export function getProbationPage(pageNumber, searchText) {
    if(!searchText){
        if(pageNumber!==undefined && pageNumber!==null && !isNaN(+pageNumber)){
            return instance.get('/secure/admin/dashboard/users/probation?page='+pageNumber, {
                headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
              })
         
        } else {
            return instance.get('/secure/admin/dashboard/users/probation?page=0', {
                headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
              })
        }}
        else{
                return instance.get('/secure/admin/dashboard/users/probation?page=0&text='+searchText, {
                    headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
                  })   
            
        }
}
export function getInternPage(pageNumber, searchText) {
    if(!searchText){
        if(pageNumber!==undefined && pageNumber!==null && !isNaN(+pageNumber)){
            return instance.get('/secure/admin/dashboard/users/intern?page='+pageNumber, {
                headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
              })
         
        } else {
            return instance.get('/secure/admin/dashboard/users/intern?page=0', {
                headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
              })
        }}
        else{
                return instance.get('/secure/admin/dashboard/users/intern?page=0&text='+searchText, {
                    headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
                  })   
            
        }
}
export function getExternalPage(pageNumber, searchText) {
    if(!searchText){
        if(pageNumber!==undefined && pageNumber!==null && !isNaN(+pageNumber)){
            return instance.get('/secure/admin/dashboard/users/external-employee?page='+pageNumber, {
                headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
              })
         
        } else {
            return instance.get('/secure/admin/dashboard/users/external-employee?page=0', {
                headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
              })
        }}
        else{
                return instance.get('/secure/admin/dashboard/users/external-employee?page=0&text='+searchText, {
                    headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
                  })   
            
        }
}
export function getInternalPage(pageNumber, searchText) {
    if(!searchText){
        if(pageNumber!==undefined && pageNumber!==null && !isNaN(+pageNumber)){
            return instance.get('/secure/admin/dashboard/users/internal-employee?page='+pageNumber, {
                headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
              })
         
        } else {
            return instance.get('/secure/admin/dashboard/users/internal-employee?page=0', {
                headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
              })
        }}
        else{
                return instance.get('/secure/admin/dashboard/users/internal-employee?page=0&text='+searchText, {
                    headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
                  })   
            
        }
}
export function candidateOnboardRequest(onboardRequest) { 
      return instance.post('/secure/hr/employeeOnboard', onboardRequest, {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
  
}
export function leaveUpdateRequest(leaveRequest) {
    return instance.post('/secure/common/leaveUpdate', leaveRequest, {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}
export function performanceUpdate(performanceInput) {
    return instance.post('/secure/hr/performanceUpdate', performanceInput, {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })

}
export function documentGeneration(letterRequest) {
    return instance.post('/secure/hr/letterGeneration', letterRequest, {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}
export function pdfResume(userNameOrEmail) {
 // alert("emailId" +JSON.stringify(userNameOrEmail));
   const url =  `/public/pdf/resume`
   return instance.get(url, userNameOrEmail, {
       headers: { "Content-type": "application/json",
         "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
     })
 }
 export function holidayList(clientUserName) {
  //alert("userName!!!"+clientUserName)
  return instance.get("/secure/common/userData/"+clientUserName, {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
}