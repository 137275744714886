import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ACCESS_TOKEN } from "../../../config/Config";
import { Nav, NavItem, Alert } from "reactstrap";

import MyProfile from "./profile/myProfile/MyProfile";
import LeaveManagement from "./profile/lms/LeaveManagement";
import PolicyHub from "./profile/policyHub/PolicyHub";
import PublicHolidays from "./profile/leavePolicy/PublicHolidays";
import SickLeave from "./profile/leavePolicy/SickLeave";
import VacationLeave from "./profile/leavePolicy/VacationLeave";
import OtherLeaves from "./profile/leavePolicy/OtherLeaves";
import PaternityLeaves from "./profile/leavePolicy/PaternityLeaves";
import MaternityLeaves from "./profile/leavePolicy/MaternityLeaves";
import { retrieveProfileImage } from "../../../api/EmployeeApi";
import LeaveWithoutPay from "./profile/leavePolicy/LeaveWithoutPay";
import TimeSheet from "./profile/timesheet/TimeSheet";

class EmployeeDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
      imagePreviewUrl: "",
      user: "",
      showLoading: false,
    };
    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);

    if (!localStorage.getItem(ACCESS_TOKEN)) {
      this.props.showAlert(
        "You must be authenticated to access your account settings !",
        "error"
      );
      this.props.history.push("/login");
    }
  }
  componentDidMount() {
    document.title = "Employee dashboard";
    /* if (localStorage.getItem(ACCESS_TOKEN)) {
      //DECODE TOKEN
      const token = localStorage.getItem(ACCESS_TOKEN);
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      decodedToken = JSON.parse(window.atob(base64));
      // alert(JSON.stringify(decodedToken))
      this.setState({user:decodedToken});
     
    } */
    this.retrieveProfilePic();
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 5000);
    });
  };
  retrieveProfilePic = () => {
    this.setState({ showLoading: true });
    retrieveProfileImage()
      .then((res) => {
       // alert("profile"+JSON.stringify(res.data));
        this.setState({ imagePreviewUrl: res.data });
        this.props.showAlert(
          "Profile image retrieved successfully ",
          "success"
        );
        this.setState({ showLoading: false });
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert("Your profile image does't exist !", "error");
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  render() {
    const { imagePreviewUrl } = this.state;
    let render;
    let navItem;
    if (this.props.match.params.render === "profile") {
      render = <MyProfile showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link active">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link">
            Leave Policy
          </Link>
        </NavItem>,
          <NavItem key={5} className="menu-item">
          <Link to="/employee/dashboard/time" className="nav-link">
            Clock your time
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "time") {
      render = <TimeSheet showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link">
            Leave Policy
          </Link>
        </NavItem>,
         <NavItem key={5} className="menu-item">
         <Link to="/employee/dashboard/time" className="nav-link active">
           Clock your time
         </Link>
       </NavItem>,
      ];
    }else if (this.props.match.params.render === "lms") {
      render = <LeaveManagement showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link active">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link">
            Leave Policy
          </Link>
        </NavItem>,
          <NavItem key={5} className="menu-item">
          <Link to="/employee/dashboard/time" className="nav-link">
            Clock your time
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "policy") {
      render = <PolicyHub showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link active">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link">
            Leave Policy
          </Link>
        </NavItem>,
          <NavItem key={5} className="menu-item">
          <Link to="/employee/dashboard/time" className="nav-link">
            Clock your time
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "leaveCirriculum") {
      render = <PublicHolidays showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link ">
            Leave Policy
          </Link>
        </NavItem>,

        <NavItem key={5} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/publicHolidays"
            className="nav-link active"
          >
            Public Holidays
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/employee/dashboard/sickLeave" className="nav-link">
            Sick Leave
          </Link>
        </NavItem>,
        <NavItem key={7} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/vacationLeaves"
            className="nav-link"
          >
            Vacation Leaves
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/maternityLeaves"
            className="nav-link"
          >
            Maternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={9} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/paternityLeaves"
            className="nav-link"
          >
            Paternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={10} className="menu-item">
          <Link exact to="/employee/dashboard/otherLeaves" className="nav-link">
            Other Leaves
          </Link>
        </NavItem>,
        <NavItem key={11} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/leaveWithoutPay"
            className="nav-link"
          >
            Leave Without Pay
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "publicHolidays") {
      render = <PublicHolidays showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link ">
            Leave Policy
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/publicHolidays"
            className="nav-link active"
          >
            Public Holidays
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/employee/dashboard/sickLeave" className="nav-link">
            Sick Leave
          </Link>
        </NavItem>,
        <NavItem key={7} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/vacationLeaves"
            className="nav-link"
          >
            Vacation Leaves
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/maternityLeaves"
            className="nav-link"
          >
            Maternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={9} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/paternityLeaves"
            className="nav-link"
          >
            Paternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={10} className="menu-item">
          <Link exact to="/employee/dashboard/otherLeaves" className="nav-link">
            Other Leaves
          </Link>
        </NavItem>,
        <NavItem key={11} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/leaveWithoutPay"
            className="nav-link"
          >
            Leave Without Pay
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "sickLeave") {
      render = <SickLeave showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link ">
            Leave Policy
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/publicHolidays"
            className="nav-link "
          >
            Public Holidays
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/sickLeave"
            className="nav-link active"
          >
            Sick Leave
          </Link>
        </NavItem>,
        <NavItem key={7} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/vacationLeaves"
            className="nav-link"
          >
            Vacation Leaves
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/maternityLeaves"
            className="nav-link"
          >
            Maternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={9} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/paternityLeaves"
            className="nav-link"
          >
            Paternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={10} className="menu-item">
          <Link exact to="/employee/dashboard/otherLeaves" className="nav-link">
            Other Leaves
          </Link>
        </NavItem>,
        <NavItem key={11} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/leaveWithoutPay"
            className="nav-link"
          >
            Leave Without Pay
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "vacationLeaves") {
      render = <VacationLeave showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link ">
            Leave Policy
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/publicHolidays"
            className="nav-link "
          >
            Public Holidays
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/employee/dashboard/sickLeave" className="nav-link ">
            Sick Leave
          </Link>
        </NavItem>,
        <NavItem key={7} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/vacationLeaves"
            className="nav-link active "
          >
            Vacation Leaves
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/maternityLeaves"
            className="nav-link"
          >
            Maternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={9} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/paternityLeaves"
            className="nav-link"
          >
            Paternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={10} className="menu-item">
          <Link exact to="/employee/dashboard/otherLeaves" className="nav-link">
            Other Leaves
          </Link>
        </NavItem>,
        <NavItem key={11} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/leaveWithoutPay"
            className="nav-link"
          >
            Leave Without Pay
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "maternityLeaves") {
      render = <MaternityLeaves showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link ">
            Leave Policy
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/publicHolidays"
            className="nav-link "
          >
            Public Holidays
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/employee/dashboard/sickLeave" className="nav-link ">
            Sick Leave
          </Link>
        </NavItem>,
        <NavItem key={7} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/vacationLeaves"
            className="nav-link  "
          >
            Vacation Leaves
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/maternityLeaves"
            className="nav-link active "
          >
            Maternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={9} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/paternityLeaves"
            className="nav-link"
          >
            Paternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={10} className="menu-item">
          <Link exact to="/employee/dashboard/otherLeaves" className="nav-link">
            Other Leaves
          </Link>
        </NavItem>,
        <NavItem key={11} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/leaveWithoutPay"
            className="nav-link"
          >
            Leave Without Pay
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "paternityLeaves") {
      render = <PaternityLeaves showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link ">
            Leave Policy
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link exact to="/me/settings/publicHolidays" className="nav-link ">
            Public Holidays
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/employee/dashboard/sickLeave" className="nav-link ">
            Sick Leave
          </Link>
        </NavItem>,
        <NavItem key={7} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/vacationLeaves"
            className="nav-link  "
          >
            Vacation Leaves
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/maternityLeaves"
            className="nav-link  "
          >
            Maternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={9} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/paternityLeaves"
            className="nav-link active "
          >
            Paternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={10} className="menu-item">
          <Link exact to="/employee/dashboard/otherLeaves" className="nav-link">
            Other Leaves
          </Link>
        </NavItem>,
        <NavItem key={11} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/leaveWithoutPay"
            className="nav-link"
          >
            Leave Without Pay
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "otherLeaves") {
      render = <OtherLeaves showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link ">
            Leave Policy
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/publicHolidays"
            className="nav-link "
          >
            Public Holidays
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/employee/dashboard/sickLeave" className="nav-link ">
            Sick Leave
          </Link>
        </NavItem>,
        <NavItem key={7} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/vacationLeaves"
            className="nav-link  "
          >
            Vacation Leaves
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/maternityLeaves"
            className="nav-link  "
          >
            Maternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={9} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/paternityLeaves"
            className="nav-link  "
          >
            Paternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={10} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/otherLeaves"
            className="nav-link active"
          >
            Other Leaves
          </Link>
        </NavItem>,
        <NavItem key={11} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/leaveWithoutPay"
            className="nav-link"
          >
            Leave Without Pay
          </Link>
        </NavItem>,
      ];
    } else if (this.props.match.params.render === "leaveWithoutPay") {
      render = <LeaveWithoutPay showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/employee/dashboard/profile" className="nav-link">
            My Profile
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/employee/dashboard/lms" className="nav-link ">
            Leave Management
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/employee/dashboard/policy" className="nav-link">
            Policy Hub
          </Link>
        </NavItem>,
        <NavItem key={4} className="menu-item">
          <Link to="/employee/dashboard/leaveCirriculum" className="nav-link ">
            Leave Policy
          </Link>
        </NavItem>,
        <NavItem key={5} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/publicHolidays"
            className="nav-link "
          >
            Public Holidays
          </Link>
        </NavItem>,
        <NavItem key={6} className="menu-item">
          <Link exact to="/employee/dashboard/sickLeave" className="nav-link ">
            Sick Leave
          </Link>
        </NavItem>,
        <NavItem key={7} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/vacationLeaves"
            className="nav-link  "
          >
            Vacation Leaves
          </Link>
        </NavItem>,
        <NavItem key={8} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/maternityLeaves"
            className="nav-link  "
          >
            Maternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={9} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/paternityLeaves"
            className="nav-link  "
          >
            Paternity Leaves
          </Link>
        </NavItem>,
        <NavItem key={10} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/otherLeaves"
            className="nav-link "
          >
            Other Leaves
          </Link>
        </NavItem>,
        <NavItem key={11} className="menu-item">
          <Link
            exact
            to="/employee/dashboard/leaveWithoutPay"
            className="nav-link active"
          >
            Leave Without Pay
          </Link>
        </NavItem>,
      ];
    }
    

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <Nav vertical pills>
              {imagePreviewUrl ? (
                <img
                  src={`data:image/jpeg;base64,${imagePreviewUrl}`}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ maxWidth: 128 }}
                />
              ) : (
                <img
                  src={require("../../../assets/images/logoFinal180x180.png")}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ maxWidth: 128 }}
                />
              )}
              {navItem}
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9">
            {/**
            <Alert
              isOpen={this.state.alertIsVisible}
              toggle={this.onDismissAlert}
              className="bg-primary text-light"
            >
              <h4 className="alert-heading">
                Welcome to your personal dashboard.
              </h4>
              <hr />
              <p className="mb-0">
                Welcome to yaazhtech, please find your profile status If
                anything wrong please immediately report to HR at
                hr@yaazhtech.com.
              </p>
            </Alert>**/}
            {render}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmployeeDashboard);
