import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import {
  candidateOnboardRequest,
  retrieveProfileImageByEmail,
  shortlist,
} from "../../../../../api/ClientApi";

class BackendGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imagePreviewUrl: "",
      onboardRequest: {
        email: "",
        officialEmail: "",
        employmentStatus: "",
        joiningDate: "",
        designation: "",
      },
      shortlistRequest: {
        userNameOrEmail: "",
        status: "",
        offeredTerm: "",
        remarks: "",
        joiningDate: "",
      },
      showModalUserInfo: false,
      showModalVideo: false,
      user: props.user,
    };
    this.updateEmploymentStatus = this.updateEmploymentStatus.bind(this);
    this.candidateOnboard = this.candidateOnboard.bind(this);
    this.updateDesignation = this.updateDesignation.bind(this);
    this.updateOfficialEmail = this.updateOfficialEmail.bind(this);
    this.retrieveProfilePic = this.retrieveProfilePic.bind(this);
    this.downloadResume = this.downloadResume.bind(this);
    this.updateExpectedJoiningDate = this.updateExpectedJoiningDate.bind(this);
    this.updateOfferedTerm = this.updateOfferedTerm.bind(this);
    this.updateRemarks = this.updateRemarks.bind(this);
    this.toggleVideo = this.toggleVideo.bind(this);
    this.toggle = this.toggle.bind(this);

  }

  static getDerivedStateFromProps(props, state) {
    const user = props.user;
    state.user = user;
    return state;
  }

  toggle() {
    this.setState({
      showModalUserInfo: !this.state.showModalUserInfo,
    });
    if (!this.state.showModalUserInfo) {
      this.retrieveProfilePic();
    }
  }
  toggleVideo() {
    this.setState({
      showModalVideo: !this.state.showModalVideo,
    });
    if (!this.state.showModalVideo) {
      // this.retrieveProfilePic();
    }
  }
  downloadResume = () => {
    const link = document.createElement("a");
    link.href = this.state.user.resumeUrl;
    link.click();
  };
  downloadProbationContract = () => {
    const link = document.createElement("a");
    link.href = this.state.user.employmentContractUrl;
    link.click();
  };
  updateOfficialEmail = (event) => {
    let req = this.state.onboardRequest;
    req.officialEmail = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateEmploymentStatus = (event) => {
    let req = this.state.onboardRequest;
    req.employmentStatus = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateJoiningDate = (event) => {
    let req = this.state.onboardRequest;
    req.joiningDate = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateDesignation = (event) => {
    let req = this.state.onboardRequest;
    req.designation = event.target.value;
    this.setState({ onboardRequest: req });
  };
  updateExpectedJoiningDate = (event) => {
    let req = this.state.shortlistRequest;
    req.joiningDate = event.target.value;
    this.setState({ shortlistRequest: req });
  };

  updateOfferedTerm = (event) => {
    //alert(event.target.value);
    let req = this.state.shortlistRequest;
    req.offeredTerm = event.target.value;
    //alert(JSON.stringify(req))
    this.setState({ shortlistRequest: req });
  };
  updateRemarks = (event) => {
    let req = this.state.shortlistRequest;
    req.remarks = event.target.value;
    this.setState({ shortlistRequest: req });
  };
  shortlistCandidate = (event) => {
    event.preventDefault();
    let req = this.state.shortlistRequest;
    req.userNameOrEmail = this.state.user.email;
    this.setState({ shortlistRequest: req });
    this.setState({ showLoading: true });

    shortlist(this.state.shortlistRequest)
      .then((res) => {
        this.props.showAlert(
          "Employee shortlisted for further onboarding hr will connect with you. you can check the status in hire tab." +
          this.state.user.name,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggle();
        this.props.history.push("client/dashboard/hire");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  candidateOnboard = (event) => {
    event.preventDefault();
    let req = this.state.onboardRequest;
    req.email = this.state.user.email;
    this.setState({ onboardRequest: req });
    this.setState({ showLoading: true });

    candidateOnboardRequest(this.state.onboardRequest)
      .then((res) => {
        this.props.showAlert(
          "Employee onboard request has been accepted !" +
          this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggle();
        //this.props.history.push("/");
        this.props.history.push("/client/profiles/backend");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  viewprofile() {
    this.props.history.push("/client/dashboard/viewprofile");
  }


  retrieveProfilePic = () => {
    this.setState({ showLoading: true });
    retrieveProfileImageByEmail(this.state.user.email)
      .then((res) => {
        this.setState({ imagePreviewUrl: res.data });

        this.setState({ showLoading: false });
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert("Your profile image does't exist !", "error");
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  render() {
    let roles;

    if (this.state.user && this.state.user.roles) {
      roles = Object.keys(this.state.user.roles).map(
        (key) => " " + this.state.user.roles[key]
      );
    }

    return (
      <tr>
        <th>
          <button className="btn btn-success" onClick={this.toggle}>
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
        <th>{this.state.user.name}</th>
        <th scope="row">
          {this.state.user.employment.profileDetails ? this.state.user.employment.profileDetails.experienceInMonths : "NA"}{" "}
          <small class="text-muted">months</small>
        </th>
        <th scope="row">{this.state.user.employment.designation}</th>
        <th>{this.state.user.hireStatus ? this.state.user.hireStatus : "Open"}</th>
        <th>
          {
            this.state.user.employment.profileDetails ? this.state.user.employment.profileDetails.preferredLocation : "NA"
          }
        </th>{
          this.state.user?<th scope="row">
          <NavLink
            to={{
              pathname: "/client/profiles/backend/viewprofile",
              viewProfileProps: {
                employmentData: this.state.user,
              },
            }}
            exact
          >
            <button type="button" class="btn btn-light">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          </NavLink>
        </th>:"NA"
        }
        

        <Modal
          isOpen={this.state.showModalVideo}
          toggle={this.toggleVideo}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleVideo}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " +
                this.state.user.name +
                " { " +
                this.state.user.email +
                " }"}
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.candidateOnboard(e)}>
              <div>
                <p className="card-title text-center">Employee Onboard</p>
                <div className="mt-2">
                  <label htmlFor="inputEmail">Official email</label>
                  <input
                    type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    required
                    onChange={this.updateOfficialEmail}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="employmentStatus">Employment Status</label>

                  <select
                    className="custom-select"
                    id="employmentStatus"
                    placeholder="Select employment status"
                    onChange={this.updateEmploymentStatus}
                  >
                    <option value="Employment Status">Employment Status</option>
                    <option value="probation">PROBATION</option>
                    <option value="intern">INTERN</option>
                    <option value="internal-employee">INTERNAL-EMPLOYEE</option>
                    <option value="external-employee">EXTERNAL-EMPLOYEE</option>
                    <option value="rejected">REJECTED</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label htmlFor="inputDate">Joining Date</label>
                  <input
                    type="Date"
                    id="inputDate"
                    className="form-control"
                    placeholder="actionDate"
                    required
                    onChange={this.updateJoiningDate}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-2">
                  <label htmlFor="inputDesignation">Designation</label>
                  <input
                    type="text"
                    id="inputDesignation"
                    className="form-control"
                    placeholder="Designation"
                    required
                    onChange={this.updateDesignation}
                    autoComplete="on"
                  />
                </div>

                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Onboard
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showModalUserInfo}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {/** <img src={"http://cravatar.eu/helmavatar/" + this.state.user.username + "/50.png"}
                                 alt={this.state.user.username} className="img-fluid rounded align-self-center"/>*/}
              {this.state.imagePreviewUrl ? (
                <img
                  src={`data:image/jpeg;base64,${this.state.imagePreviewUrl}`}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ maxWidth: 128 }}
                />
              ) : (
                <img
                  src={require("../../../../../assets/images/logoFinal180x180.png")}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ maxWidth: 128 }}
                />
              )}
              {" " + this.state.user.name + " "}
            </div>
          </ModalHeader>
          <ModalBody>
            <p>
              <b>Availability:</b>
              {this.state.user.availability ? (
                <span className="badge badge-warning">in project</span>
              ) : (
                <span className="badge badge-success">Immediate joiner</span>
              )}
            </p>
            <p>
              <b>Tech Stack:</b>{" "}
              {
                (this.state.user.employment.profileDetails !== null && this.state.user.employment.profileDetails.experienceInfo !== null) ? this.state.user.employment.profileDetails.experienceInfo[0]
                  .techStack : "Java, Spring boot, DynamoDb with Aws"
              }
            </p>

            <p>
              <b>Billing range:</b> contact us business@yaazhtech.com
            </p>

            <p>
              <b>Evaluation Status:</b> Profile Evaluated.{/** <button className="btn btn-success" onClick={this.toggleVideo}>
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>*/}
            </p>
            <form onSubmit={(e) => this.shortlistCandidate(e)}>
              <div>


                <div className="mt-2">
                  <label htmlFor="offeredTerm">Offer Term:</label>

                  <select
                    className="custom-select"
                    id="offeredTerm"
                    placeholder="Select offer term"
                    onChange={this.updateOfferedTerm}
                  >
                    <option value="long-term">LONG-TERM</option>
                    <option value="short-term">SHORT-TERM</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label htmlFor="inputDate">Expected Joining Date</label>
                  <input
                    type="Date"
                    id="inputDate"
                    className="form-control"
                    placeholder="actionDate"
                    required
                    onChange={this.updateExpectedJoiningDate}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="inputRemarks">Remarks</label>
                  <input
                    type="text"
                    id="inputRemarks"
                    className="form-control"
                    placeholder="Type here about proposal and info to share."
                    required
                    onChange={this.updateRemarks}
                    autoComplete="on"
                  />
                </div>

                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Shortlist
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </tr>
    );
  }
}

export default withRouter(BackendGridLine);
