import React, { Component } from "react";
import { withRouter,NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { generateInvoice } from "../../../../api/commonApi";


class PaymentGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: props.subscription,
      invoiceDownloadRequest: {
      paymentId : props.showPaymentData.paymentId,
      subscriptionId : props.showPaymentData.subscriptionId,
      emailId:props.showPaymentData.userName,
      },
      
    };
   
  }

  static getDerivedStateFromProps(props, state) {
  const subscription = props.subscription;
  state.subscription = subscription;
  const dueDate = props.showPaymentData.dueDate;
  state.dueDate = dueDate
  const billingPeriodFrom = props.showPaymentData.billingPeriodFrom;
  state.billingPeriodFrom = billingPeriodFrom
  const billingPeriodTo = props.showPaymentData.billingPeriodTo;
  state.billingPeriodTo = billingPeriodTo
  const totalBillingDay = props.showPaymentData.totalBillingDay;
  state.totalBillingDay = totalBillingDay
  const totalBillingHours = props.showPaymentData.totalBillingHours;
  state.totalBillingHours = totalBillingHours
  const costPerDay = props.showPaymentData.costPerDay;
  state.costPerDay = costPerDay
  const projectRequirement = props.showPaymentData.projectRequirement;
  state.projectRequirement = projectRequirement
  const subscriptionType = props.showPaymentData.subscriptionType;
  state.subscriptionType = subscriptionType
  const paymentStatus = props.showPaymentData.paymentStatus;
  state.paymentStatus = paymentStatus
  const costExcludingGstPay = props.showPaymentData.costExcludingGstPay;
  state.costExcludingGstPay = costExcludingGstPay
  const costIncludingGstPay = props.showPaymentData.costIncludingGstPay;
  state.costIncludingGstPay = costIncludingGstPay
  const subscriptionId = props.showPaymentData.subscriptionId;
  state.subscriptionId = subscriptionId
  const paymentId = props.showPaymentData.paymentId;
  state.paymentId = paymentId
  const emailId=props.showPaymentData.userName;
  state.emailId=emailId;
    return state;
  }

 
  invoiceGenerator = (event) => {
    generateInvoice(this.state.invoiceDownloadRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        const currDate = new Date().toLocaleDateString();
        link.download = this.state.invoiceDownloadRequest.paymentId+"_"+currDate+".pdf";

        link.click();
        this.props.showAlert(
          "Your invoice is downloaded please check ",
          "success"
        );
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

 
  
  render() {
    const { totalBillingDay,dueDate, billingPeriodTo, billingPeriodFrom, subscriberName,paymentId,totalBillingHours,subscriptionId,emailId,costPerDay,projectRequirement,subscriptionType,paymentStatus,costIncludingGstPay,costExcludingGstPay} = this.state;
    //const {subscriptionFor} = this.state.subscriptionF;

    return (
      <tr>
       <th scope="row" style={{width: "25%"}} ><small>{subscriptionId+"Pay"+paymentId}</small></th>
      <th scope="row" style={{width: "25%"}} ><small>{projectRequirement}</small></th>
        <th scope="row" style={{width: "25%"}} ><small>{dueDate}</small></th>
        <th scope="row" style={{width: "25%"}}><small>{billingPeriodFrom}</small></th>  
        <th scope="row" style={{width: "25%"}}><small>{billingPeriodTo}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{totalBillingDay}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{totalBillingHours}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{costPerDay}</small></th>
       
        <th scope="row" style={{width: "25%"}}><small>{costExcludingGstPay}</small></th>
        <th scope="row" style={{width: "25%"}}><small>{costIncludingGstPay}</small></th>
        <th scope="row" style={{width: "25%"}}><small>{paymentStatus}</small></th>
        <th scope="row" style={{width: "25%"}} ><small>
          <button type="button" class="btn btn-light" onClick={(e) => this.invoiceGenerator(e)}>
              <FontAwesomeIcon icon={faDownload} />
            </button></small></th>
            <th scope="row"  style={{width: "25%"}}>
          <NavLink
            to={{
              pathname: "/client/payment/banking",
              paymentProps: {
                payAmount: costExcludingGstPay,
                paySubscriptionType: subscriptionType,
                payUsername: subscriberName,
                payCost: costIncludingGstPay,
                subscriptionId:subscriptionId,
                paymentId:paymentId,
                emailId:emailId
              },
            }}
            exact
          >
            <button type="button" class="btn btn-light">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          </NavLink>
        </th>
       
      </tr>
    );
  }
}

export default withRouter(PaymentGridLine);
