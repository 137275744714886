import React, { Component } from "react";
import { withRouter,NavLink } from "react-router-dom";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { requestChangeRole } from "../../../../api/AdminApi";

class AssessmentsGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalUserInfo: false,
      assessment: props.assessment,
      role: "",
      showLoading: false,
    };

    this.toggle = this.toggle.bind(this);
    this.updateRole = this.updateRole.bind(this);
    this.changeRole = this.changeRole.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const assessment = props.assessment;
    state.assessment = assessment;
    return state;
  }
  toggle() {
    this.setState({
      showModalUserInfo: !this.state.showModalUserInfo,
    });
  }

  updateRole = (event) =>{
  this.setState({role: event.target.value});
  }
  changeRole = (event) => {
    event.preventDefault();

    this.setState({ showLoading: true });
    requestChangeRole(this.state.user.email, this.state.role)
      .then((res) => {
        this.props.showAlert(
          "Your request has been accepted ! role got changed for the user with email of " +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggle();
        this.props.history.push("/admin/dashboard/index");
        this.props.history.push("/admin/dashboard/users");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  render() {
    /*let roles;

    if (this.state.user && this.state.user.roles) {
      roles = Object.keys(this.state.user.roles).map(
        (key) => " " + this.state.user.roles[key]
      );
    }*/

    return (
      <tr>
       {
          this.state.assessment?<th scope="row">
          <NavLink
            to={{
              pathname: "/me/assessment/onlineAssessment",
              assessmentProps: {
                assessment: this.state.assessment,
              },
            }}
            exact
          >
            <button type="button" class="btn btn-light">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          </NavLink>
        </th>:"NA"
        }
      <th>{this.state.assessment.skill}</th>
        <th scope="row">{this.state.assessment.duration}<small class="text-muted"> mins</small></th>
        <th>{this.state.assessment.totalScore}</th>
        <th>
              {
                this.state.assessment.minPass
              }
            </th>
            <th>
              {
                this.state.assessment.status
              }
            </th>   
            <th>
              {
                this.state.assessment.result?this.state.assessment.result:"pending"
              }
            </th>   
            <th>
              {
                this.state.assessment.scored?this.state.assessment.scored:"pending"
              }
            </th>   
      

        <Modal
          isOpen={this.state.showModalUserInfo}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " +
                this.state.assessment.skill +
                
                " "}
            </div>
          </ModalHeader>
          <ModalBody>
            <p>
              <b>Skill:</b> {this.state.assessment.skill}
            </p>
            <p>
              <b>Account creation date:</b> {this.state.assessment.totalScore}
            </p>
            
            <p>
              To change role:
            </p>
            <form onSubmit={(e) => this.changeRole(e)}>
            
              <select
               className="w3-select"
                name="option"
                value={this.state.role}
                onChange={(event) => this.updateRole(event)}
              >
                <option  value="" disabled>
                  Choose your option
                </option>
                <option value="HR">HR</option>
                <option value="ADMIN">ADMIN</option>
                <option value="USER">USER</option>
                <option value="CLIENT">CLIENT</option>
                <option value="EMPLOYEE">EMPLOYEE</option>
                <option value="EMPTY">EMPTY</option>
              </select>
<br></br>
              <button type="submit" className="btn btn-success btn-lg mt-4">
                Change Role
              </button>
            </form>
          </ModalBody>
        </Modal>
      </tr>
    );
  }
}

export default withRouter(AssessmentsGridLine);
