import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getClientsPage } from "../../../../../api/HrApi";
import WebAppGrid from "./WebAppGrid";
import AppPagination from "../../../../../generic/pagination/AppPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class WebApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      page: {},
      currentPage: 0,
      totalNumberOfElements: 0,
      pageSize: 0,
      loading: true,
      searchText: null,
    };

    this.changeCurrentPage = this.changeCurrentPage.bind(this);
    this.loadData = this.loadData.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
    this.setStatePromise = this.setStatePromise.bind(this);
  }

  componentDidMount() {
    document.title = "Users management";
    this.loadData(0, this.state.searchText);
  }

  componentDidUpdate() {
    this.render();
  }

  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  changeCurrentPage(currentPage) {
    this.setState({
      loading: true,
      currentPage,
    });
  }
  updateSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };
  loadData(page) {
    this.setState({ loading: true });
    getClientsPage(page, this.state.searchText)
      .then((res) => {
        let content = [];
        let user = {
          avatar: {},
          username: "",
          email: "",
          accountCreationDate: "",
          roles: [],
          usingTwoFA: false,
        };
        for (let i = 0; i < res.data.content.length; i++) {
          user = res.data.content[i];
          user.avatar = (
            <img
              src={"http://cravatar.eu/helmavatar/" + user.username + "/32.png"}
              alt={user.username}
              className="img-fluid"
            />
          );
          content.push(user);
        }

        this.setStatePromise({
          content: content,
          page: res.data,
          currentPage: res.data.pageNumber,
          totalNumberOfElements: res.data.totalNumberOfElements,
          pageSize: res.data.pageSize,
          loading: false,
        }).then(() => {
          this.forceUpdate();
        });
        this.setState({searchText:null});
      })
      .catch((error) => {
        if (error.message) {
          this.props.showAlert(error.message, "error");
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ loading: false });
        console.log(error);
      });
  }

  render() {
    const {
      content,
      pageSize,
      currentPage,
      totalNumberOfElements,
      loading,
      page,searchText
    } = this.state;

    if (loading) {
      return (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading. Please Wait...</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">
            refresh
          </i>
        </div>
      );
    } else {
      return (
        <div>
        <p> <h1>
            WebApp{" "}
            <span className="text-muted" style={{ fontSize: 11 }}>
              management
            </span>
          </h1>
          <span><form  onSubmit={(e) => this.loadData(e)}>
            <div>
              <input
                type="text"
                id="inputSearchText"
                className="form-search"
                placeholder="Search here"
                onChange={this.updateSearchText}
                autoComplete="on"
              />
                <button type="submit" >
            <FontAwesomeIcon icon={faSearch} />
          </button>
            </div>
            </form></span></p>
         
          <hr />
          
          <WebAppGrid showAlert={this.props.showAlert} content={content} />
          <AppPagination
            showAlert={this.props.showAlert}
            page={page}
            pageSize={pageSize}
            currentPage={currentPage}
            totalNumberOfElements={totalNumberOfElements}
            handleChangePage={this.loadData}
          />
        </div>
      );
    }
  }
}

export default withRouter(WebApp);
