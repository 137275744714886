import React, { Component } from "react";
import { clientContent } from "../content/PageContent";
import Button from "../button/Button";
import { NavLink, withRouter } from "react-router-dom";
import { Fade } from "react-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FindACoder from "../../../assets/images/findACoder.jpg";
import DevelopmentImage from "../../../assets/images/business-team-meeting-boardroom.jpg";
import {
  faCode,
  faCodeBranch,
  faHandshake,
  faSearchPlus,
  faSign,
  faTimesCircle,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faCodepen } from "@fortawesome/free-brands-svg-icons";

class Business extends Component {
  constructor(props) {
    super(props);

    this.findACoder = this.findACoder.bind(this);
    this.development = this.development.bind(this);
  }
  componentDidMount() {
    document.title = "Business";
  }

  componentDidUpdate() {
    if (this.props.notFound === true) {
      this.props.showAlert("Page not found ! Back to the home page.", "error");
    }
  }
  findACoder() {
    this.props.history.push("/hireSubscriptionPricing");
  }
  development() {
    this.props.history.push("/abc");
  }
  render() {
    return (
      <><br></br><br></br><div class="container">
        <div class="pricing-header px-2 py-2 pt-md-2.5 pb-md-2 mx-auto text-center">
          <h1 class="display-2">{clientContent.clientHeading}</h1>
          <p class="display-5">{clientContent.clientSubheading}</p>
        </div>
        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {clientContent.developCardHeader}
              </h4>
            </div>
            <div class="card-body">
              <p className="card-title text-left">

                <FontAwesomeIcon icon={faCode} />{" "}
                {clientContent.developAtYaazhtech}

              </p>
              <br></br>
              <p className="card-title text-left">

                <FontAwesomeIcon icon={faCode} />{" "}
                {clientContent.developAtYaazhtechODC}

              </p>
              <br></br>
              <p className="card-title text-left">

                <FontAwesomeIcon icon={faCode} />{" "}
                {clientContent.developAtYaazhtechApps}

              </p>

              <br></br>
              <p className="card-title text-left">

                {" "}
                <FontAwesomeIcon icon={faCode} />
                {clientContent.developAtYaazhtechPara}

              </p>
              <NavLink
                to={{
                  pathname: "/devSubscriptionPricing",
                  hirePricingProps: {
                    planType: "",
                    planCost: "0",
                    subscriptionFor: "projectDevelopment",
                  },
                }}
                exact
              >

                <button type="button" class="btn btn-lg btn-block btn-primary">
                  Start free web apps
                </button>
              </NavLink>

            </div>
          </div>
          <div class="card mb-6 box-shadow">
            <div class="card-header">
              <h4>
                {clientContent.findDeveloperCardHeader}
              </h4>
            </div>
            <div class="card-body">

              <p className="card-title text-left">

                <FontAwesomeIcon icon={faCode} />{" "}
                {clientContent.findDeveloperFull}

              </p>
              <br></br>
              <p className="card-title text-left">

                <FontAwesomeIcon icon={faCode} />{" "}
                {clientContent.findDeveloperPara}

              </p>

              <br></br>
              <p className="card-title text-left">
                <FontAwesomeIcon icon={faCode} />{" "}
                {clientContent.findDeveloperCost}

              </p>
              <br></br>
              <p className="card-title text-left">
                {" "}
                <FontAwesomeIcon icon={faCode} />{" "}
                {clientContent.findDeveloperCostReturn}

              </p>
              <NavLink
                to={{
                  pathname: "/hireSubscriptionPricing",
                  hirePricingProps: {
                    planType: "",
                    planCost: "0",
                    subscriptionFor: "hireDeveloper",
                  },
                }}
                exact
              >

                <button type="button" class="btn btn-lg btn-block btn-primary">
                  Hire A Coder
                </button>
              </NavLink>

            </div>
          </div>
        </div>
        <span className="text-muted">{clientContent.contactUs}</span>
        <hr></hr>
      </div></>
    );
  }
}

export default withRouter(Business);
