import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { holidayData } from "../../../../api/HrApi";

class Holiday extends Component {
    state = {
      year: '',
      holidayData: [{  holidayDate: '', holidayName: '' }],
  
      showLoading: false,
      
    };
  
    componentDidMount() {
      document.title = "Holiday Generation";
    }
    year = (event) => {
      this.setState({ year: event.target.value });
    };
   
    handleHolidayList = (e, index) => {
      const { name, value } = e.target;
      const list = [...this.state.holidayData];
      list[index][name] = value;
      this.setState({ holidayData: list })
  }
  handleHolidayAddClick = () => {
    this.setState({ holidayData: [...this.state.holidayData, { holidayDate: '', holidayName: ''}] });
  };
  handleHolidayRemoveClick = (index) => {
    const list = [...this.state.holidayData];
    list.splice(index, 1);
    this.setState({ holidayData: list })
  }
  
    holidayList = (event) => {
      event.preventDefault();  
      this.setState({ showLoading:true });
      const skill = this.state;
      // alert("Datas" + JSON.stringify(skill));
      holidayData(skill)
          .then(response => {
              const data = response.data
              this.props.showAlert(data.message, "success");
              this.setState({ showLoading: false });
              
              this.setState({
                   year: '',
                  holidayData: [{ holidayDate: '', holidayName: '' }],
              })
  
          })
          .catch(error => {
              this.props.showAlert(error);
              this.setState({ showLoading: false });
          })
  
  };
  
  
    render() {
      const { holidayData } = this.state;
      return (
       
          
          <div>
            <h3>HOLIDAY TYPE</h3>
            <hr />
            <div>
              {this.state.showLoading ? (
                <div className="align-content-center text-center">
                  <h3>Holiday generation initiated</h3>
                  <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                    in progress
                  </i>
                </div>
              ) : (
                <form onSubmit={(e) => this.holidayList(e)}>
                  <br></br>
                  <div className="form-group">
                  <label htmlFor="year" className='mt-2'>Enter year</label>
                          {/*  <input type="month" className="form-control mt-2" id="year" name='year'
                              placeholder="Enter the year " required autoComplete="on" value={x.year} onChange={e => this.handleHolidayList(e, i)}
                            />  */}
                            <select className="form-control mt-2 py-1" id="year" name="year" onChange={this.year}>
                                <option value="">year</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                           </select>
  
                    {
                      holidayData.map((x, i) => {
                        return (
                          <div>
                        
                            <label htmlFor="holidayDate" className='mt-2'>Holiday Date</label>
                            <input type="date" className="form-control" id="holidayDate" name='holidayDate'
                              required autoComplete="on" placeholder='Enter the date' value={x.holidayDate} onChange={e => this.handleHolidayList(e, i)}
                            />
                             <label htmlFor="holidayName" className='mt-2'>Holiday Name</label>
                            <input type="text" className="form-control" id="holidayName" name='holidayName'
                              required autoComplete="on" placeholder='Enter the holiday name' value={x.holidayName} onChange={e => this.handleHolidayList(e, i)}
                            />
  
                            {
                              holidayData.length !== 1 &&
                              <button className='btn btn-danger mt-2' onClick={this.handleHolidayRemoveClick}>Remove</button>
                            }
                            {
                              holidayData.length - 1 === i &&
                              <button className='btn btn-primary mt-2 ml-2' onClick={this.handleHolidayAddClick}>Add</button>
                            }
                          </div>
                        );
                      }
                      )}
                  </div>
                 
             <button type="submit" className="btn btn-primary btn-block text-uppercase">Submit</button> 
            
                </form>
              )}
            </div>
          </div>
        
      );
    }
  }
  
  export default withRouter(Holiday);