import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import ApplicantsAnswersGrid from "./ApplicantsAnswersGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

class ApplicantsAnswers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      page: {},
      currentPage: 0,
      totalNumberOfElements: 0,
      pageSize: 0,
      loading: true,
      searchText: null,
      category: "ds-programming",
      loadNext: 0,
      alertIsVisible: true,
      imagePreviewUrl: "",
      user: "",
      showLoading: false,
      assessmentQuestionShortAnswersMap: "",
      assessmentQuestionsMultipleChoiceMap: "",
      userName: "",
      counter: 0,
      textIdx: 0,
      minutesPerQuestion: 0,
      answerList: [{}],
      testId: "",
      students: "",
    };
    this.updateCategory = this.updateCategory.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
    this.loadData = this.loadData.bind(this);
    this.backToHome = this.backToHome.bind(this);
  }

  componentDidMount() {
    document.title = "ApplicantsAnswers management";
    this.loadData();
  }

  loadData = () => {
    this.setState({ showLoading: true });
    let req = this.state;

    req.assessmentQuestionShortAnswersMap = this.props.location.assessmentProps
      ? this.props.location.assessmentProps.assessment.questionPaper
          .assessmentQuestionShortAnswersMap
      : null;
    req.assessmentQuestionsMultipleChoiceMap = this.props.location
      .assessmentProps
      ? this.props.location.assessmentProps.assessment.questionPaper
          .assessmentQuestionsMultipleChoiceMap
      : null;
    // req.minutesPerQuestion=this.props.location.assessmentProps?(this.props.location.assessmentProps.assessment.duration/req.questions.length):0;
    req.testId = this.props.location.assessmentProps
      ? this.props.location.assessmentProps.assessment.testId
      : 0;
    req.students = this.props.location.assessmentProps.assessment.students
      ? this.props.location.assessmentProps.assessment.students
      : null;
    let student = {
      testId: "",
    };

    for (let i = 0; i < req.students.length; i++) {
      student = req.students[i];
      student.testId = req.testId;
      req.content.push(student);
    }
    this.setState({ req });
    this.setState({ loading: false });
  };
  updateSearchText = (event) => {
    this.setState({ searchText: event.target.value });
  };
  updateCategory = (event) => {
    this.setState({ category: event.target.value });
  };
  backToHome() {
    this.props.history.push("/hr/dashboard/trackAssessments");
  }
  render() {
    const {
      content,
      pageSize,
      currentPage,
      totalNumberOfElements,
      loading,
      page,
      category,
    } = this.state;

    if (loading) {
      return (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading. Please Wait...</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">
            refresh
          </i>
        </div>
      );
    } else {
      return (
        <>
          <br></br>
          <br></br>
          <div className="container">
            <h1>
              Validate{" "}
              <span className="text-muted" style={{ fontSize: 11 }}>
                Candidates
              </span>{" "}
              <button
                class="btn btn-primary px-4 py-3"
                onClick={this.backToHome}
              >
                <div class="d-inline-block bi bi-download me-2"></div>
                Back.
              </button>
            </h1>
            <hr />

            <ApplicantsAnswersGrid
              showAlert={this.props.showAlert}
              content={content}
            />
            <span>
              <form onSubmit={(e) => this.loadData(e)}>
                <div>
                  <button className="alert-link page-link" type="submit">
                    {" "}
                    load again {"  "}
                    <FontAwesomeIcon icon={faDownload} />
                  </button>
                </div>
              </form>
            </span>
          </div>
        </>
      );
    }
  }
}

export default withRouter(ApplicantsAnswers);
