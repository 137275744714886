import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { hirePricing } from "../content/PageContent";
import { Alert } from "reactstrap";

class HireSubscriptionPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
    };

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  componentDidMount() {
    document.title = "HireSubscriptionPricing";
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }

  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 5000);
    });
  };

  render() {
    return (
      <div class="container">
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossorigin="anonymous"
        ></script>

        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
          {/**
          <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light topalert" >
            <h4 className="alert-heading">One step ahead to view list of developers profile.</h4>
            <hr />
            <p className="mb-0">
              Please, choose your price and sign up.
            </p>
          </Alert> **/}
        </div>

        <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 class="display-4">Pricing</h1>
          <p class="lead">{hirePricing.header}</p>
        </div>
        <div class="card-deck mb-3 text-center">
          <div class="card mb-4 box-shadow">
            <div class="card-header">
              <h4 class="my-0 font-weight-normal">{hirePricing.standard}</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ₹ {hirePricing.free} <small class="text-muted">/ mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>{hirePricing.freeProfileAccess}</li>
                <li>{hirePricing.freeHireReturn}</li>
                <li>{hirePricing.freeHireSwap}</li>
                <li>{hirePricing.noCards}</li>
              </ul>
              <NavLink
                to={{
                  pathname: "/register",
                  hirePricingProps: {
                    subscriptionFor: hirePricing.standard,
                    subscriptionCost: "0",
                    subscriptionType: "PROJECT-hireDeveloper",
                  },
                }}
                exact
              >
                <button type="button" class="btn btn-lg btn-block btn-primary">
                  Sign up for free
                </button>
              </NavLink>
            </div>
          </div>
          <div class="card mb-4 box-shadow">
            <div class="card-header">
              <h4 class="my-0 font-weight-normal">{hirePricing.premium}</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ₹ {hirePricing.premiumCost}
                <small class="text-muted">/ mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>{hirePricing.premiumProfileAccess}</li>
                <li>{hirePricing.premiumHireReturn}</li>
                <li>{hirePricing.premiumHireSwap}</li>
                <li>{hirePricing.noCards}</li>
              </ul>
              <NavLink
                to={{
                  pathname: "/register",
                  hirePricingProps: {
                    subscriptionFor: hirePricing.premium,
                    subscriptionCost: hirePricing.premiumCost,
                    subscriptionType: "PROJECT-hireDeveloper",
                  },
                }}
                exact
              >
                <button type="button" class="btn btn-lg btn-block btn-primary">
                  Get started
                </button>
              </NavLink>
            </div>
          </div>
          <div class="card mb-4 box-shadow">
            <div class="card-header">
              <h4 class="my-0 font-weight-normal">
                {hirePricing.superPremium}
              </h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                ₹ {hirePricing.superPremiumCost}{" "}
                <small class="text-muted">/ mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>{hirePricing.superPremiumProfileAccess}</li>
                <li>{hirePricing.superPremiumHireReturn}</li>
                <li>{hirePricing.superPremiumHireSwap}</li>
                <li>{hirePricing.noCards}</li>
              </ul>
              <NavLink
                to={{
                  pathname: "/register",
                  hirePricingProps: {
                    subscriptionFor: hirePricing.superPremium,
                    subscriptionCost: hirePricing.superPremiumCost,
                    subscriptionType: "PROJECT-hireDeveloper",
                  },
                }}
                exact
              >
                <button type="button" class="btn btn-lg btn-block btn-primary">
                  Get started
                </button>
              </NavLink>
            </div>
          </div>
        </div>
        <div>
          <span className="text-muted" style={{ fontSize: 12 }}>
            {hirePricing.contactUs}
          </span>
          <br></br>
          <span class="text-muted" style={{ fontSize: 12 }}>
            {hirePricing.excludeGST}
          </span>
          <hr></hr>
        </div>
      </div>
    );
  }
}

export default HireSubscriptionPricing;
