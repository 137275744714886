import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/css/Footer.css";
import { faArrowCircleUp, faEnvelopeOpen, faMapMarked, faMapMarkedAlt, faMapMarker, faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faFacebookF, faGooglePlusG, faTelegram, faTwitter, faWhatsapp, faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";

class AppFooter extends Component {
  render() {
    return (
      <div>
        <footer class="footer-section">
          <div class="container">
            <div class="footer-cta pt-5 pb-5">
              <div class="row">
                <div class="col-xl-4 col-md-4 mb-30">
                  <div class="single-cta">
                      <div class="cta-text">
                      <h4><FontAwesomeIcon icon={faMapMarkerAlt} />  {"   "}Find us</h4>
                      <span>
                        44, Mecheri, Kukkalpatti, Salem Dt., Tamilnadu, India.
                        636453.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-30">
                  <div class="single-cta">
                    <div class="cta-text">
                      <h4>WhatsApp us</h4>
                      <FontAwesomeIcon icon={faWhatsapp} />
                      {"   "}
                      <span>+91 6382289060</span>
                      <br></br>
                      <FontAwesomeIcon icon={faWhatsapp} />
                      {"   "}
                      <span>+91 9342618513</span>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-30">
                  <div class="single-cta">
                    <div class="cta-text">
                      <h4>Mail us</h4>
                      <FontAwesomeIcon icon={faEnvelopeOpen} />
                      {"   "}
                      <span>business@yaazhtech.com</span>
                      <br></br>
                      <FontAwesomeIcon icon={faEnvelopeOpen} />
                      {"   "}
                      <span>hr@yaazhtech.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </footer>
        <footer class="footer py-4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 text-lg-start" style={{ fontSize: 13 }}> Copyright &copy; 2016-2023, All Rights Reserved by{" "}
                    <a href="https://www.yaazhtech.com/" style={{ fontSize: 13 }}>Yaazhtech.</a></div>
                    <div class="col-lg-4 my-3 my-lg-0">
                    <a class="btn btn-dark btn-social mx-2 text-center" href="#!" aria-label="Diana Petersen Twitter Profile"><i class="fab fa-twitter" style={{fontSize:16}}></i></a>
                    <a class="btn btn-dark btn-social mx-2 text-center" href="https://www.facebook.com/yaazhtech" aria-label="Diana Petersen Facebook Profile"><i class="fab fa-facebook-f" style={{fontSize:16}}></i></a>
                    <a class="btn btn-dark btn-social mx-2 text-center" href="https://www.linkedin.com/company/yaazhtech/mycompany/verification/" aria-label="Diana Petersen LinkedIn Profile"><i class="fab fa-linkedin-in" style={{fontSize:16}}></i></a>
                    </div>
                    <div class="col-lg-4 text-lg-end">
                        <a class="link-dark text-decoration-none me-3" style={{ fontSize: 13 }}><Link to="/privacy" >
                        Privacy Policy
                        </Link></a>{" "}
                        <a class="link-dark text-decoration-none me-3" style={{ fontSize:13 }}><Link to="/terms" >
                        Terms of Use
                        </Link></a>{" "}
                        <a class="link-dark text-decoration-none  me-3" style={{ fontSize: 13}}><Link to="/cancellationPolicy" >
                        Cancellation Policy
                        </Link></a>
                    </div>
                </div>
            </div>
        </footer>
      </div>
    );
  }
}

export default withRouter(AppFooter);
