import React, { Component } from "react";
import { withRouter } from "react-router-dom";


class LeaveDataGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaveData: props.leaveData,
    
    };
   
  }

  static getDerivedStateFromProps(props, state) {
    const leaveData = props.leaveData;
    state.leaveData = leaveData;
   
    
    return state;
  }

 
 
  
  render() {
 

    return (
      <tr>
       <th scope="row" style={{width: "25%"}}><small>{JSON.stringify(this.state.leaveData)}</small></th>  
      </tr>
    );
  }
}

export default withRouter(LeaveDataGridLine);
