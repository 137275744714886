import React, { Component } from "react";
import { withRouter } from "react-router-dom";


class SubscriptionGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: props.subscription,
    
    };
   
  }

  static getDerivedStateFromProps(props, state) {
    const subscription = props.subscription;
    state.subscription = subscription;
   
    
    return state;
  }

 
 
  
  render() {
 

    return (
      <tr>
        <th scope="row" style={{width: "25%"}}><small>{this.state.subscription.planType}</small></th>  
        <th scope="row" style={{width: "25%"}}><small>{this.state.subscription.planCost}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{this.state.subscription.subscriptionFor}</small></th>
        <th scope="row" style={{width: "25%"}} ><small>{this.state.subscription.startDate}</small></th>
      </tr>
    );
  }
}

export default withRouter(SubscriptionGridLine);
