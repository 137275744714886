import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
//import { candidateOnboardRequest } from "../../../../api/HrApi";
//import { probationActionRequest } from "../../../../api/HrApi";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { deleteEndPoint } from "../../../../api/AdminApi";
class DeleteContent extends Component {
    constructor(props) {
        super(props);
        console.log(this.props.item);
        this.state = {
            item: props.item,
            category:props.category,
            showModalUserInfo: false,
            showLoading: false,
        }
        this.toggle = this.toggle.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        state = props.item;
        state= props.category;
        return state;
    }

    toggle() {
        this.setState({
            showModalUserInfo: !this.state.showModalUserInfo,
        });
    }



    deleteEndpoints = (event) => {
        event.preventDefault();
        this.setState({ showloading: false });
        const removeEndPoint = this.state.item;
       // alert("data" + JSON.stringify(removeEndPoint));
        deleteEndPoint(removeEndPoint,this.state.category)
        .then((response) => {
          const data = response.data;
          this.props.showAlert(data.message, "success");
          this.setState({ showLoading: false });
          this.toggle();
          this.setState({
            item: '',
          });
          this.props.history.push("/");
          this.props.history.push("/publish/editContent");
        })
        .catch((error) => {
          this.props.showAlert(error, "error");
          this.setState({ showLoading: false });
        });
      
    }



    render() {
        const { item } = this.state;
        //alert("datacoming" + JSON.stringify(categoryPoint))
        //alert("datareceived" + JSON.stringify(endPointList))
        return (
            <>

                <button className="btn btn-light" onClick={this.toggle} data-toggle="tooltip" data-placement="bottom" title="Action "  >
                    <FontAwesomeIcon icon={faTrash} />
                </button>

                <Modal
                    isOpen={this.state.showModalUserInfo}
                    toggle={this.toggle}
                    className={this.props.className}
                >
                    <ModalHeader toggle={this.toggle}>
                        <div className="align-content-center align-items-center align-self-center text-center">
                            <p className="card-title text-center">{item}</p>

                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <form onSubmit={(e) => this.deleteEndpoints(e)}>
                            <div>

                                <p>Are you sure you want to delete your item({item})</p>

                            </div>
                            <br></br>
                            <button
                                className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                                type="submit"
                            >
                                Delete
                            </button>

                        </form>
                    </ModalBody>

                </Modal>








            </>


        );
    }
}

export default withRouter(DeleteContent);
