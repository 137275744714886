import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  ACCESS_TOKEN,
  ADMIN_TOKEN,
  HR_TOKEN,
  EMPLOYEE_TOKEN,
  CLIENT_TOKEN,
} from "../../config/Config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserLock,
  faDoorOpen,
  faHome,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";

class Sidebar extends Component {
  state = {
    isOpen: false,
    isOpenSideBar:false,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleSideBar = this.toggleSideBar.bind(this);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  toggleSideBar() {
    this.setState({ isOpenSideBar: !this.state.isOpenSideBar });
  }
  render() {
    let menuItemsLeft;
    let decodedToken;
    if (localStorage.getItem(ACCESS_TOKEN)) {
      //DECODE TOKEN
      const token = localStorage.getItem(ACCESS_TOKEN);
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      decodedToken = JSON.parse(window.atob(base64));
      // alert(JSON.stringify(decodedToken))
      menuItemsLeft = [
        <UncontrolledDropdown nav inNavbar key={1}>
          <DropdownToggle nav caret>
            <span className="navBrand">
              {" "}
              {`${decodedToken.name[0].toUpperCase()}`}{" "}
            </span>
            {/** <img
             alt={decodedToken.name}
              src={
                require("../../assets/images/logoFinal25x25.png")
              }
             
              className="rounded-circle"
              
            /> */}
            {/*{decodedToken.username}*/}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem disabled>
              <p className="nav-link text-center text-muted blue">
                Signed in as <b>{decodedToken.name}</b>
              </p>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              <Link
                to="/me/settings/account"
                className="nav-link text-dark  blue"
              >
                <FontAwesomeIcon icon={faUserLock} /> Account Settings
              </Link>
            </DropdownItem>

            {localStorage.getItem(ADMIN_TOKEN) ? (
              <DropdownItem>
                <Link
                  to="/admin/dashboard/index"
                  className="nav-link text-dark blue"
                >
                  <FontAwesomeIcon icon={faCogs} /> Admin dashboard
                </Link>
              </DropdownItem>
            ) : null}
            {localStorage.getItem(HR_TOKEN) ? (
              <DropdownItem>
                <Link
                  to="/hr/dashboard/applicants"
                  className="nav-link text-dark blue"
                >
                  <FontAwesomeIcon icon={faCogs} /> Hr dashboard
                </Link>
              </DropdownItem>
            ) : null}
            {localStorage.getItem(EMPLOYEE_TOKEN) ? (
              <DropdownItem>
                <Link
                  to="/employee/dashboard/profile"
                  className="nav-link text-dark blue"
                >
                  <FontAwesomeIcon icon={faCogs} /> My dashboard
                </Link>
              </DropdownItem>
            ) : null}
            {localStorage.getItem(CLIENT_TOKEN) ? (
              <>
                <DropdownItem>
                  <Link
                    to="/client/dashboard/hire"
                    className="nav-link text-dark blue"
                  >
                    <FontAwesomeIcon icon={faCogs} /> My dashboard
                  </Link>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <Link
                    to="/client/profiles/backend"
                    className="nav-link text-dark blue"
                  >
                    <FontAwesomeIcon icon={faUserLock} /> Profiles Pool
                  </Link>
                </DropdownItem>
              </>
            ) : null}
            <DropdownItem divider />
            <DropdownItem
              onClick={this.props.logout}
              className="cursor-pointer"
            >
              <p className="nav-link text-dark blue">
                <FontAwesomeIcon icon={faDoorOpen} /> Logout
              </p>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>,
      ];
    } else {
      menuItemsLeft = [
        <NavItem key={1}>
          <Link to="/about" className="nav-link">
            About
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/services" className="nav-link">
            Services
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/team" className="nav-link">
            Team
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/contact" className="nav-link">
            Contact
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/careers/jobfresher" className="nav-link">
            Careers
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/login/" className="nav-link">
            Sign in
          </Link>
        </NavItem>,

        /** <NavItem key={2}>
          <Link to="/register/" className="nav-link">
            Sign up
          </Link>
        </NavItem>,
          <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                  <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                  <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>
                  <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
        */
      ];
    }

    return (
      <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div class="sb-sidenav-menu">
                        <div class="nav">
                            <div class="sb-sidenav-menu-heading">Core</div>
                            <a class="nav-link" href="index.html">
                                <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                                Dashboard
                            </a>
                            <div class="sb-sidenav-menu-heading">Interface</div>
                            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                Layouts
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" href="layout-static.html">Static Navigation</a>
                                    <a class="nav-link" href="layout-sidenav-light.html">Light Sidenav</a>
                                </nav>
                            </div>
                            <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                <div class="sb-nav-link-icon"><i class="fas fa-book-open"></i></div>
                                Pages
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">
                                        Authentication
                                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                    </a>
                                    <div class="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                        <nav class="sb-sidenav-menu-nested nav">
                                            <a class="nav-link" href="login.html">Login</a>
                                            <a class="nav-link" href="register.html">Register</a>
                                            <a class="nav-link" href="password.html">Forgot Password</a>
                                        </nav>
                                    </div>
                                    <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">
                                        Error
                                        <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                    </a>
                                    <div class="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                        <nav class="sb-sidenav-menu-nested nav">
                                            <a class="nav-link" href="401.html">401 Page</a>
                                            <a class="nav-link" href="404.html">404 Page</a>
                                            <a class="nav-link" href="500.html">500 Page</a>
                                        </nav>
                                    </div>
                                </nav>
                            </div>
                            <div class="sb-sidenav-menu-heading">Addons</div>
                            <a class="nav-link" href="charts.html">
                                <div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
                                Charts
                            </a>
                            <a class="nav-link" href="tables.html">
                                <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                                Tables
                            </a>
                        </div>
                    </div>
                   
                </nav>
            </div>
           
        </div>
    );
  }
}
export default withRouter(Sidebar);
