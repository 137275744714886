import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import JobFresher from "../careers/jobfresher/JobFresher";
import Training from "../careers/training/Training";
import JobExperienced from "../careers/jobexperienced/JobExperienced";
import { Alert } from "reactstrap";
import Education from "../careers/education/Education";


class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertIsVisible: true,
    };

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  componentDidMount() {
    document.title = "Careers";
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }

  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 5000);
    });
  };

  render() {
    let render;
    let navItem;
     if (this.props.match.params.render === "training-jobs") {
      render = <Training showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/careers/training-jobs" className="nav-link active">
            Train And Hire
          </Link>
        </NavItem>,
         <NavItem key={2} className="menu-item">
         <Link to="/careers/jobs" className="nav-link">
          Direct Hire
         </Link>
       </NavItem>,
        <NavItem key={3} className="menu-item">
        <Link to="/careers/education" className="nav-link">
         Education
        </Link>
      </NavItem>,
      ];
    }
    else if (this.props.match.params.render === "jobs") {
      render = <JobExperienced showAlert={this.props.showAlert} />;
      navItem = [
       
        <NavItem key={1} className="menu-item">
          <Link to="/careers/training-jobs" className="nav-link">
            Train And Hire
          </Link>
        </NavItem>,
         <NavItem key={2} className="menu-item">
         <Link to="/careers/jobs" className="nav-link active">
          Direct Hire
         </Link>
       </NavItem>,
         <NavItem key={3} className="menu-item">
         <Link to="/careers/education" className="nav-link">
          Education
         </Link>
       </NavItem>,
      ];
    }
    else if (this.props.match.params.render === "education") {
      render = <Education showAlert={this.props.showAlert} />;
      navItem = [
       
        <NavItem key={1} className="menu-item">
          <Link to="/careers/training-jobs" className="nav-link">
            Train And Hire
          </Link>
        </NavItem>,
         <NavItem key={2} className="menu-item">
         <Link to="/careers/jobs" className="nav-link">
          Direct Hire
         </Link>
       </NavItem>,
         <NavItem key={3} className="menu-item">
         <Link to="/careers/education" className="nav-link active">
          Education
         </Link>
       </NavItem>,
      ];
    }
   
    /* else if (this.props.match.params.render === "training") {
      render = <JobFresher showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/careers/jobexperienced" className="nav-link">
            Jobs - Experienced
          </Link>
        </NavItem>,
        <NavItem key={2} className="menu-item">
          <Link to="/careers/jobfresher" className="nav-link">
            Jobs - Fresher
          </Link>
        </NavItem>,
        <NavItem key={3} className="menu-item">
          <Link to="/careers/training" className="nav-link active">
            Training
          </Link>
        </NavItem>,
      ];
    }
*/
    return (
      <div className="container">
        {/**    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
          <Alert
            isOpen={this.state.alertIsVisible}
            toggle={this.onDismissAlert}
            className="bg-primary text-light topalert"
          >
            <h4 className="alert-heading">
              We provide job and training!! Please checkout.
            </h4>
            <hr />
            <p className="mb-0">
              Apply here!!.
            </p>
          </Alert>
        </div>
      */}

        <div className="row pt-5">
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <Nav vertical pills>
              {navItem}
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9">{render}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(Careers);
