import React, {Component} from 'react';
import EmployeeGridLine from "./EmployeeGridLine";

class EmployeeGrid extends Component {

    constructor(props){
        super(props);

        this.state = {
            users: props.content
        }
    }

    static getDerivedStateFromProps(props, state) {
        state = props.content;
        return state;
    }

    render(){

        // const {users} = this.state;

        let gridLines = [];

        if(this.state.users && this.state.users.length > 0){
         
            gridLines = Object.keys(this.state.users)
                .map(key => <EmployeeGridLine showAlert={this.props.showAlert} key={key} user={this.state.users[key]}/>);
        }

        return(
            <div>
                <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                    <tr>
                     
                        <th scope="col" style={{width:'10%'}}>Name</th>
                        <th scope="col" style={{width:'10%'}}>Leaves Taken</th>
                        <th scope="col" style={{width:'10%'}}>Eligible leaves</th>
                        <th scope="col" style={{width:'10%'}}>Leave</th>
                        <th scope="col" style={{width:'10%'}}>Difference</th>
                        <th scope="col" style={{width:'10%'}}>Status</th>
                        <th scope="col" style={{width:'10%'}}>TimeSheet</th>
                        <th scope="col" style={{width:'10%'}}>Level</th>
                        <th scope="col" style={{width:'10%'}}>Leave Data</th>
                        <th scope="col" style={{width:'10%'}}>View</th>
                        <th scope="col" style={{width:'10%'}}>Update</th>
                        
                    </tr>
                    </thead>
                    <tbody>

                    {
                        this.state.users.length > 0
                        ?
                            gridLines
                        :
                            <div className="alert alert-danger" role="alert">
                                <strong>Oops !</strong> Nothing to show here...
                            </div>
                    }
                    </tbody>
                </table>
                </div>
            </div>
        );
    }
}

export default EmployeeGrid;