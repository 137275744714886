import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { register } from "../../../api/Api";
import {
  ACCESS_TOKEN,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  MOBILE_EXACT_LENGTH,
  NAME_MIN_LENGTH
} from "../../../config/Config";
import { Alert } from 'reactstrap';

class Register extends Component {
  constructor(props) {
    super(props);
    console.log(props.location.hirePricingProps);
  this.state = {
    registerRequest: {
      mobile: "",
      email: "",
      password: "",
      name: "",
      subscriptionFor:"",
      projectRequirement:"",    
      graduationYear:"", 
      department:"",
      arrears:"",
      subscriptionType:"",
      subscriptionCost:"",
      clientGst:"",
      clientOrgName:"",
      addressNoStreet:"",
      addressCityPinCode:""
    },

    alertIsVisible: true,
    showLoading: false,
    passwordPattern: {
      number: false,
      lowerCase: false,
      upperCase: false,
      charsLength: false,
    },
  };
  this.onDismissAlert = this.onDismissAlert.bind(this);
  this.onShowAlert = this.onShowAlert.bind(this);
}

  componentDidMount() {
    document.title = "Subscribe";
    this.updateSubscription();
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }

  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false })
      }, 5000)
    });
  }
  
  updateClientGst = (event) => {
    let req = this.state.registerRequest;
    req.clientGst = event.target.value;
    this.setState({ registerRequest: req });
  };
  updateAddressCityPinCode = (event) => {
    let req = this.state.registerRequest;
    req.addressCityPinCode = event.target.value;
    this.setState({ registerRequest: req });
  };
  updateAddressNoStreet = (event) => {
    let req = this.state.registerRequest;
    req.addressNoStreet = event.target.value;
    this.setState({ registerRequest: req });
  };
  updateClientOrgName = (event) => {
    let req = this.state.registerRequest;
    req.clientOrgName = event.target.value;
    this.setState({ registerRequest: req });
  };

  updateMobile = (event) => {
    let req = this.state.registerRequest;
    req.mobile = event.target.value;
    this.setState({ registerRequest: req });
  };
  updateSubscription = () => {
    let req = this.state.registerRequest;
    if(this.props.location.hirePricingProps){
    req.subscriptionType = this.props.location.hirePricingProps?this.props.location.hirePricingProps.subscriptionType:'';
    req.subscriptionCost = this.props.location.hirePricingProps?this.props.location.hirePricingProps.subscriptionCost:'';
    req.subscriptionFor = this.props.location.hirePricingProps?this.props.location.hirePricingProps.subscriptionFor:'';
    }else if(this.props.location.jobApplicationProps){
    req.subscriptionType = this.props.location.jobApplicationProps?this.props.location.jobApplicationProps.subscriptionType:'';
    req.subscriptionCost = this.props.location.jobApplicationProps?this.props.location.jobApplicationProps.subscriptionCost:'';
    req.subscriptionFor = this.props.location.jobApplicationProps?this.props.location.jobApplicationProps.subscriptionFor:'';
    }
    
    
   
    this.setState({ registerRequest: req });
    //alert("register"+JSON.stringify(this.state.registerRequest));
  };
  updateName = (event) => {
    let req = this.state.registerRequest;
    req.name = event.target.value;
    this.setState({ registerRequest: req });
  };
  updateEmail = (event) => {
    let req = this.state.registerRequest;
    req.email = event.target.value;
    this.setState({ registerRequest: req });
  };

  updatePassword = (event) => {
    let req = this.state.registerRequest;
    req.password = event.target.value;
    this.setState({ registerRequest: req });

    let passwordPattern = this.state.passwordPattern;

    const lowerCase = /[a-z]/g;
    if (req.password.match(lowerCase)) {
      document.getElementById("lowerCase").classList.remove("text-danger");
      document.getElementById("lowerCase").classList.add("text-success");
      passwordPattern.lowerCase = true;
    } else {
      document.getElementById("lowerCase").classList.remove("text-success");
      document.getElementById("lowerCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const upperCase = /[A-Z]/g;
    if (req.password.match(upperCase)) {
      document.getElementById("upperCase").classList.remove("text-danger");
      document.getElementById("upperCase").classList.add("text-success");
      passwordPattern.upperCase = true;
    } else {
      document.getElementById("upperCase").classList.remove("text-success");
      document.getElementById("upperCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const number = /[0-9]/g;
    if (req.password.match(number)) {
      document.getElementById("number").classList.remove("text-danger");
      document.getElementById("number").classList.add("text-success");
      passwordPattern.number = true;
    } else {
      document.getElementById("number").classList.remove("text-success");
      document.getElementById("number").classList.add("text-danger");
      passwordPattern.number = false;
    }

    if (
      req.password.length > PASSWORD_MIN_LENGTH &&
      req.password.length < PASSWORD_MAX_LENGTH
    ) {
      document.getElementById("chars").classList.remove("text-danger");
      document.getElementById("chars").classList.add("text-success");
      passwordPattern.charsLength = true;
    } else {
      document.getElementById("chars").classList.remove("text-success");
      document.getElementById("chars").classList.add("text-danger");
      passwordPattern.charsLength = false;
    }
    this.setState({ passwordPattern: passwordPattern });
  };

  requestRegister = (event) => {
   
    event.preventDefault();
    this.updateSubscription();
    
    const req = this.state.registerRequest;

    if (req.mobile.length !== MOBILE_EXACT_LENGTH) {
      this.props.showAlert(
        "Your mobile number must contain exactly " +
          MOBILE_EXACT_LENGTH +
          " and " +
          "no special characters. (+)",
        "error"
      );
      return null;
    }
    if (req.name.length < NAME_MIN_LENGTH) {
      this.props.showAlert(
        "Name length should be greater than " + NAME_MIN_LENGTH + ".",
        "error"
      );
      return null;
    }

    if (
      req.password.length < PASSWORD_MIN_LENGTH ||
      req.password.length > PASSWORD_MAX_LENGTH
    ) {
      this.props.showAlert(
        "Your password must contain between " +
          PASSWORD_MIN_LENGTH +
          " and " +
          PASSWORD_MAX_LENGTH +
          " characters !",
        "error"
      );
      return null;
    }

    const passwordPattern = this.state.passwordPattern;
    if (
      passwordPattern.upperCase &&
      passwordPattern.lowerCase &&
      passwordPattern.charsLength &&
      passwordPattern.number
    ) {
      this.setState({ showLoading: true });
      register(this.state.registerRequest)
        .then((res) => {
          this.props.showAlert(
            res.message,
            "success"
          );
          this.props.history.push("/login");
        })
        .catch((error) => {
          if (error.message) {
            this.props.showAlert(error.message, "error");
          } else {
            this.props.showAlert(
              "Sorry! Something went wrong. Please try again!",
              "error"
            );
          }
          this.setState({ showLoading: false });
          console.log(error);
        });
    } else {
      this.props.showAlert(
        "Invalid password : please take care of password constraints !",
        "error"
      );
    }
  };

  projectRequirement=(event)=>{
    let req = this.state.registerRequest;
    req.projectRequirement = event.target.value;
    this.setState({ registerRequest: req });
  };
  graduationYear=(event)=>{
    let req = this.state.registerRequest;
    req.graduationYear = event.target.value;
    this.setState({ registerRequest: req });
  };
  

  department=(event)=>{
    let req = this.state.registerRequest;
    req.department = event.target.value;
    this.setState({ registerRequest: req });
  };
  
  arrears=(event)=>{
    let req = this.state.registerRequest;
    req.arrears = event.target.value;
    this.setState({ registerRequest: req });
  };
  render() {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      this.props.showAlert("You are already logged in !", "info");
      this.props.history.push("/");
      return <p>Oops ! Your are already logged in !</p>;
    } else {
      return (
        <div className="container">

{/** {((this.state.showLoading !== null) && (this.state.registerRequest.subscriptionFor !== null &&
            this.state.registerRequest.subscriptionFor === 'webAppDev'))
            ? (
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
                <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light topalert" >
                  <h4 className="alert-heading">Welcome to yaazhtech!!</h4>
                  <hr />
                  <p className="mb-0">
                    Please, Signup here our team will reach you for further.
                  </p>
                </Alert>
              </div>
            ) : (
              ((this.state.showLoading !== null) && (this.state.registerRequest.subscriptionFor !== null &&
                this.state.registerRequest.subscriptionFor === 'hireDeveloper'))
                ? (
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
                    <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light topalert" >
                      <h4 className="alert-heading">Welcome to yaazhtech!!</h4>
                      <hr />
                      <p className="mb-0">
                        Once you sign up, your dedicated dashboard will be launched.
                      </p>
                    </Alert>
                  </div>
                ) :  (
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
                         <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light topalert" >
                            <h4 className="alert-heading">Welcome to  Yaazhtech!!</h4>
                            <hr />
                            <p className="mb-0">
                              Choose your services/careers before register.
                            </p>
                          </Alert> 
                        </div>
                      )  
                  )
            } **/}

          <form onSubmit={(e) => this.requestRegister(e)}>
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card card-signin my-5">
                <div className="card-body">
                  {this.state.showLoading ? (
                    <div className="align-content-center text-center">
                      <h5>Signing up</h5>
                      <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                        refresh
                      </i>
                    </div>
                  ) : (
                    <div>
                    
                      {
                        ((this.state.showLoading !== null) && (this.state.registerRequest.subscriptionType !== null && this.state.registerRequest.subscriptionType === 'PROJECT-webAppDev'))
                        ? (

                          <div>
                            <h1 className="card-title text-center">Sign Up</h1>
                            <div className="mt-2">
                              <label htmlFor="projectOneline">One Line About Project </label>
                              <input
                                type="text"
                                id="projectOneLine"
                                className="form-control"
                                placeholder="One line about project"
                                optional
                                onChange={this.projectRequirement}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputName">Name</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                placeholder="Name"
                                required
                                onChange={this.updateName}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputEmail">Email address</label>
                              <input
                                type="email"
                                id="inputEmail"
                                className="form-control"
                                placeholder="Email address"
                                required
                                onChange={this.updateEmail}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputMobile">Mobile</label>
                              <input
                                type="text"
                                id="inputMobile"
                                className="form-control"
                                placeholder="Mobile"
                                optional
                                onChange={this.updateMobile}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="orgName">Your Organization Name</label>
                              <input
                                type="text"
                                id="orgName"
                                className="form-control"
                                placeholder="Name"
                                required
                                onChange={this.updateClientOrgName}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="addressNoStreet">Office address: No and Street</label>
                              <input
                                type="text"
                                id="addressNoStreet"
                                className="form-control"
                                placeholder="addressNoStreet"
                                required
                                onChange={this.updateAddressNoStreet}
                                autoComplete="on"
                              />
                            </div>
                            
                            <div className="mt-2">
                              <label htmlFor="addressCityPinCode">Office address: City and PinCode</label>
                              <input
                                type="text"
                                id="addressCityPinCode"
                                className="form-control"
                                placeholder="addressCityPinCode"
                                required
                                onChange={this.updateAddressCityPinCode}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="clientGst">Your Organization Gst</label>
                              <input
                                type="text"
                                id="clientGst"
                                className="form-control"
                                placeholder="clientGst"
                                required
                                onChange={this.updateClientGst}
                                autoComplete="on"
                              />
                            </div>
                            

                            <div className="mb-2 mt-2">
                              <label htmlFor="inputPassword">Password</label>
                              <input
                                type="password"
                                id="inputPassword"
                                className="form-control"
                                placeholder="Password"
                                required
                                autoComplete="on"
                                onChange={this.updatePassword}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                              />
                              <small className="text-muted">
                                Your password must contain : <a className="text-danger" id="number">
                                  * One number
                                </a>
                                <a className="text-danger" id="lowerCase">
                                  * One lower case
                                </a>
                                <a className="text-danger" id="upperCase">
                                  * One upper case
                                </a>
                                <a className="text-danger" id="chars">
                                  * Between 6 and 20 chars
                                </a>
                              </small>

                            </div>
                            <p className="text-muted">
                              Already registered ?{" "}
                              <Link to="/login" className="text-primary">
                                Login now !
                              </Link>
                            </p>
                            <button
                              className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                              type="submit"
                            >
                              Create account
                            </button>
                            <small className="text-muted">
                              By clicking "Create account" or "Continue with Google",
                              you agree to the
                              <Link to="#" className="text-primary">
                                {" "} YaazhTech Software Development Lab
                              </Link>{" "}
                              and <Link to="/privacy" className="text-primary">Privacy Policy.</Link>
                            </small>
                            <small id="emailHelp" className="form-text text-muted">
                              We'll never share your information with anyone else.
                            </small>
                          </div>
                        ) :(
                         <div>
                        {((this.state.showLoading !== null) && (this.state.registerRequest.subscriptionType !== null && this.state.registerRequest.subscriptionType === 'PROJECT-hireDeveloper'))
                        ? (
                          
                          <div>
                            <h1 className="card-title text-center">Sign Up</h1>
                            <div className="mt-2">
                              <label htmlFor="projectRequirement">About your requirement </label>
                              <input
                                type="text"
                                id="projectRequirement"
                                className="form-control"
                                placeholder="One line about your requirement"
                                optional
                                onChange={this.projectRequirement}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputName">Name</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                placeholder="Name"
                                required
                                onChange={this.updateName}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputEmail">Email address</label>
                              <input
                                type="email"
                                id="inputEmail"
                                className="form-control"
                                placeholder="Email address"
                                required
                                onChange={this.updateEmail}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputMobile">Mobile</label>
                              <input
                                type="text"
                                id="inputMobile"
                                className="form-control"
                                placeholder="Mobile"
                                optional
                                onChange={this.updateMobile}
                                autoComplete="on"
                              />
                            </div>

                            <div className="mt-2">
                              <label htmlFor="orgName">Your Organization Name</label>
                              <input
                                type="text"
                                id="orgName"
                                className="form-control"
                                placeholder="Name"
                                required
                                onChange={this.updateClientOrgName}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="addressNoStreet">Office address: No and Street</label>
                              <input
                                type="text"
                                id="addressNoStreet"
                                className="form-control"
                                placeholder="addressNoStreet"
                                required
                                onChange={this.updateAddressNoStreet}
                                autoComplete="on"
                              />
                            </div>
                            
                            <div className="mt-2">
                              <label htmlFor="addressCityPinCode">Office address: City and PinCode</label>
                              <input
                                type="text"
                                id="addressCityPinCode"
                                className="form-control"
                                placeholder="addressCityPinCode"
                                required
                                onChange={this.updateAddressCityPinCode}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="clientGst">Your Organization Gst</label>
                              <input
                                type="text"
                                id="clientGst"
                                className="form-control"
                                placeholder="clientGst"
                                required
                                onChange={this.updateClientGst}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mb-2 mt-2">
                              <label htmlFor="inputPassword">Password</label>
                              <input
                                type="password"
                                id="inputPassword"
                                className="form-control"
                                placeholder="Password"
                                required
                                autoComplete="on"
                                onChange={this.updatePassword}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                              />
                              <small className="text-muted">
                                Your password must contain : <a className="text-danger" id="number">
                                  * One number
                                </a>
                                <a className="text-danger" id="lowerCase">
                                  * One lower case
                                </a>
                                <a className="text-danger" id="upperCase">
                                  * One upper case
                                </a>
                                <a className="text-danger" id="chars">
                                  * Between 6 and 20 chars
                                </a>
                              </small>

                            </div>
                            <p className="text-muted">
                              Already registered ?{" "}
                              <Link to="/login" className="text-primary">
                                Login now !
                              </Link>
                            </p>
                            <button
                              className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                              type="submit"
                            >
                              Create account
                            </button>
                            <small className="text-muted">
                              By clicking "Create account" or "Continue with Google",
                              you agree to the
                              <Link to="#" className="text-primary">
                                {" "} YaazhTech Software Development Lab
                              </Link>{" "}
                              and <Link to="/privacy" className="text-primary">Privacy Policy.</Link>
                            </small>
                            <small id="emailHelp" className="form-text text-muted">
                              We'll never share your information with anyone else.
                            </small>
                          </div>
                        )
                        
                        : (
                          <div>
                        {((this.state.showLoading !== null) && (this.state.registerRequest.subscriptionType !== null && this.state.registerRequest.subscriptionType === 'TRAINING-education'))
                        ? (
                          <div>
                          <h1 className="card-title text-center">Sign Up</h1>
                         
                          <div className="mt-2">
                            <label htmlFor="inputName">Name</label>
                            <input
                              type="text"
                              id="inputName"
                              className="form-control"
                              placeholder="Name"
                              required
                              onChange={this.updateName}
                              autoComplete="on"
                            />
                          </div>
                          <div className="mt-2">
                            <label htmlFor="inputEmail">Email address</label>
                            <input
                              type="email"
                              id="inputEmail"
                              className="form-control"
                              placeholder="Email address"
                              required
                              onChange={this.updateEmail}
                              autoComplete="on"
                            />
                          </div>
                          <div className="mt-2">
                            <label htmlFor="inputMobile">Mobile</label>
                            <input
                              type="text"
                              id="inputMobile"
                              className="form-control"
                              placeholder="Mobile"
                              optional
                              onChange={this.updateMobile}
                              autoComplete="on"
                            />
                          </div>
                     <div className="mt-2">
                            <label htmlFor="department">Department Name </label>
                            <input
                              type="text"
                              id="department"
                              className="form-control"
                              placeholder="Enter Your Department Name"
                              required
                              onChange={this.department}
                              autoComplete="on"
                            />
                          </div> 
                          <div className="mt-2">
                            <label htmlFor="arrears">No.of.arrears </label>
                            <input
                              type="text"
                              id="arrears"
                              className="form-control"
                              placeholder="Enter Your Arrears"
                              required
                              onChange={this.arrears}
                              autoComplete="on"
                            />
                          </div>  

                          <div className="mb-2 mt-2">
                            <label htmlFor="inputPassword">Password</label>
                            <input
                              type="password"
                              id="inputPassword"
                              className="form-control"
                              placeholder="Password"
                              required
                              autoComplete="on"
                              onChange={this.updatePassword}
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                            />
                            <small className="text-muted">
                              Your password must contain : <a className="text-danger" id="number">
                                * One number
                              </a>
                              <a className="text-danger" id="lowerCase">
                                * One lower case
                              </a>
                              <a className="text-danger" id="upperCase">
                                * One upper case
                              </a>
                              <a className="text-danger" id="chars">
                                * Between 6 and 20 chars
                              </a>
                            </small>

                          </div>
                          <p className="text-muted">
                            Already registered ?{" "}
                            <Link to="/login" className="text-primary">
                              Login now !
                            </Link>
                          </p>
                          <button
                            className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                            type="submit"
                          >
                            Create account
                          </button>
                          <small className="text-muted">
                            By clicking "Create account" or "Continue with Google",
                            you agree to the
                            <Link to="#" className="text-primary">
                              {" "} YaazhTech Software Development Lab
                            </Link>{" "}
                            and <Link to="/privacy" className="text-primary">Privacy Policy.</Link>
                          </small>
                          <small id="emailHelp" className="form-text text-muted">
                            We'll never share your information with anyone else.
                          </small>
                        </div>
                          
                         
                        )
                        
                        : (
                          <div>
                          {((this.state.showLoading !== null) && (this.state.registerRequest.subscriptionType !== null && this.state.registerRequest.subscriptionType === 'TRAINING-hire' || 'TRAINING-interview'))
                          ? (
                            <div>
                            <h1 className="card-title text-center">Sign Up</h1>
                           
                            <div className="mt-2">
                              <label htmlFor="inputName">Name</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                placeholder="Name"
                                required
                                onChange={this.updateName}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputEmail">Email address</label>
                              <input
                                type="email"
                                id="inputEmail"
                                className="form-control"
                                placeholder="Email address"
                                required
                                onChange={this.updateEmail}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputMobile">Mobile</label>
                              <input
                                type="text"
                                id="inputMobile"
                                className="form-control"
                                placeholder="Mobile"
                                optional
                                onChange={this.updateMobile}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="graduationYear">Graduation Year </label>
                              <input
                                type="text"
                                id="graduationYear"
                                className="form-control"
                                placeholder="Enter Your Graduation Year"
                                optional
                                onChange={this.graduationYear}
                                autoComplete="on"
                              />
                            </div>

                            <div className="mb-2 mt-2">
                              <label htmlFor="inputPassword">Password</label>
                              <input
                                type="password"
                                id="inputPassword"
                                className="form-control"
                                placeholder="Password"
                                required
                                autoComplete="on"
                                onChange={this.updatePassword}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                              />
                              <small className="text-muted">
                                Your password must contain : <a className="text-danger" id="number">
                                  * One number
                                </a>
                                <a className="text-danger" id="lowerCase">
                                  * One lower case
                                </a>
                                <a className="text-danger" id="upperCase">
                                  * One upper case
                                </a>
                                <a className="text-danger" id="chars">
                                  * Between 6 and 20 chars
                                </a>
                              </small>

                            </div>
                            <p className="text-muted">
                              Already registered ?{" "}
                              <Link to="/login" className="text-primary">
                                Login now !
                              </Link>
                            </p>
                            <button
                              className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                              type="submit"
                            >
                              Create account
                            </button>
                            <small className="text-muted">
                              By clicking "Create account" or "Continue with Google",
                              you agree to the
                              <Link to="#" className="text-primary">
                                {" "} YaazhTech Software Development Lab
                              </Link>{" "}
                              and <Link to="/privacy" className="text-primary">Privacy Policy.</Link>
                            </small>
                            <small id="emailHelp" className="form-text text-muted">
                              We'll never share your information with anyone else.
                            </small>
                          </div>
                           
                          ) :( 
                             <div>
                            <h1 className="card-title text-center">Sign Up</h1>
                            <div className="mt-2">
                              <label htmlFor="inputName">Name</label>
                              <input
                                type="text"
                                id="inputName"
                                className="form-control"
                                placeholder="Name"
                                required
                                onChange={this.updateName}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputEmail">Email address</label>
                              <input
                                type="email"
                                id="inputEmail"
                                className="form-control"
                                placeholder="Email address"
                                required
                                onChange={this.updateEmail}
                                autoComplete="on"
                              />
                            </div>
                            <div className="mt-2">
                              <label htmlFor="inputMobile">Mobile</label>
                              <input
                                type="text"
                                id="inputMobile"
                                className="form-control"
                                placeholder="Mobile"
                                optional
                                onChange={this.updateMobile}
                                autoComplete="on"
                              />
                            </div>
      
                            <div className="mb-2 mt-2">
                              <label htmlFor="inputPassword">Password</label>
                              <input
                                type="password"
                                id="inputPassword"
                                className="form-control"
                                placeholder="Password"
                                required
                                autoComplete="on"
                                onChange={this.updatePassword}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                              />
                              <small className="text-muted">
                                Your password must contain : <a className="text-danger" id="number">
                                    * One number
                                  </a>
                                  <a className="text-danger" id="lowerCase">
                                    * One lower case
                                  </a>
                                  <a className="text-danger" id="upperCase">
                                    * One upper case
                                  </a>
                                  <a className="text-danger" id="chars">
                                    * Between 6 and 20 chars
                                  </a>
                              </small>
                             
                            </div>
                            <p className="text-muted">
                              Already registered ?{" "}
                              <Link to="/login" className="text-primary">
                                Login now !
                              </Link>
                            </p>
                            <button
                              className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                              type="submit"
                            >
                              Create account
                            </button>
                            <small className="text-muted">
                              By clicking "Create account" or "Continue with Google",
                              you agree to the
                              <Link to="#" className="text-primary">
                               {" "} YaazhTech Software Development Lab
                              </Link>{" "}
                              and <Link to="/privacy" className="text-primary">Privacy Policy.</Link>
                            </small>
                            <small id="emailHelp" className="form-text text-muted">
                              We'll never share your information with anyone else.
                            </small>
                          </div>
                          )}
                </div>
                  )}
                </div>
                  )}
                  
              </div>
              
                  )}
                </div>  
                )}
              </div>
            </div>
            </div>
           
          </form>
          <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          
        </div>
      );
    }
  }
}

export default withRouter(Register);
