import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {  education } from '../../content/PageContent';
import { NavLink, withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode
} from "@fortawesome/free-solid-svg-icons";

class Education extends Component {
  state = {

    showLoading: false,
   
    showMaths: false,
    showComputerScience: false,
    showAutoMobile: false,
    showMechanical: false,
   

  };
  constructor(props) {
    super(props);

    this.toggleMaths = this.toggleMaths.bind(this);
    this.toggleComputerScience = this.toggleComputerScience.bind(this);
    this.toggleAutoMobile = this.toggleAutoMobile.bind(this);
    this.toggleMechanical = this.toggleMechanical.bind(this);
    
  }

  
  toggleMaths() {
    this.setState({ showMaths: !this.state.showMaths });
  }
  toggleComputerScience() {
    this.setState({ showComputerScience: !this.state.showComputerScience });
  }
  toggleAutoMobile() {
    this.setState({ showAutoMobile: !this.state.showAutoMobile });
  }
  toggleMechanical() {
    this.setState({ showMechanical: !this.state.showMechanical });
  }
 
  componentDidMount() {
    document.title = "Education";
  }

  render() {
    return (
      <div>
        
        <marquee width="100%" direction="left" height="50px" className="text-dark">
         
        <h4 className="alert-heading">{education.title2}</h4>
       </marquee>
       
        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
          <div class="card-header">
           <h4>
                {education.header1}
              </h4>
               <h5 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 25,000 {" "}  <small class="text-muted">/ Paper</small></small> ₹ 12,500{" "}
                <small class="text-muted">/ Paper</small>
              </h5>

            </div>

            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img src={require("../../../../assets/images/math.png")} />

                  </Link>
                </div>
                <div className='col-lg-9'>
                <small>   <p className="card-title text-left">
                    {education.content1}


                  </p></small>
                  <br></br>

                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                         subscriptionCost: "12500",
                        subscriptionFor: "Maths",
                        subscriptionType:"TRAINING-education",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>


                </div>
              </div>


            </div>
          </div>

        </div>
        <br></br>

        <div class="card-deck mb-2 text-center">
          <div class="card mb-6 box-shadow">
          <div class="card-header">
           <h4>
                {education.header2}
              </h4>

              <h5 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 25,000 {" "}  <small class="text-muted">/ Paper</small></small> ₹ 12,500{" "}
                <small class="text-muted">/ Paper</small>
              </h5>

            </div>

            <div class="card-body">
              <div className='row'>
                <div className='col-lg-3 my-4'>

                  <Link to="/" className="nav-link">
                    <img className="rounded-circle" src={require("../../../../assets/images/cse.png")} />

                  </Link>
                </div>
                <div className='col-lg-9'>
                <small>   <p className="card-title text-left">
                    {education.content2}


                  </p></small>

                {/* <p className="alert-link page-link"
                    onClick={this.toggleComputerScience}>
                    Learn more
                  </p>
                <Modal isOpen={this.state.showMaths}
                    toggle={this.toggleMaths}>

                    <ModalBody>
                      <ModalHeader toggle={this.toggleMaths}> <small>{jobRequirements.header11}</small></ModalHeader>
                      <p><small>
                        {jobRequirements.description}
                      </small>  </p>

                      <ModalHeader><small>{jobRequirements.header12}</small></ModalHeader>

                      <p><small>
                        {jobRequirements.Responsibilities}
                      </small>  </p>

                      <ModalHeader><small>{jobRequirements.header13}</small></ModalHeader>

                      <p><small>
                        {jobRequirements.skils}  </small>
                      </p>

                      <Button color="secondary" className="float-right"
                        onClick={this.toggleMaths}>Close</Button>
                    </ModalBody>
                  </Modal>  */} 

                {/*   <p className="card-title text-left">
                  <small>{experienced.eligibility}
                    <br></br>
                    {experienced.location}
                    <br></br>
                    {experienced.process}
                    <br></br>

                   {experienced.round1}
                      <br></br>
                      {experienced.round2}
                      <br></br>
                      {experienced.round3}
                      <br></br>
                      {experienced.round4}
                      <br></br>
                      {experienced.round5} 
                    <br></br>
                    <FontAwesomeIcon icon={faCode} /><small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> {" "}  { experienced.price} </small></small> {"          "}   Free!!
                  </p> */}
                  <br></br>
                  <NavLink
                    to={{
                      pathname: "/register",
                      jobApplicationProps: {
                         subscriptionCost: "12500",
                        subscriptionFor: "Computer Science",
                        subscriptionType:"TRAINING-education",
                      },
                    }}
                    exact
                  >

                    <button type="button" class="btn btn-md btn-block btn-primary w-50 mx-auto">
                      PROCEED
                    </button>
                  </NavLink>


                </div>
              </div>


            </div>
          </div>

        </div>
        <br></br>
         <div class="card-deck mb-2 text-center">
        <div class="card mb-6 box-shadow">
        <div class="card-header">
         <h4>
              {education.header4}
            </h4>
            <h5 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 25,000 {" "}  <small class="text-muted">/ Paper</small></small> ₹ 12,500{" "}
                <small class="text-muted">/ Paper</small>
              </h5>

          </div>

          <div class="card-body">
            <div className='row'>
              <div className='col-lg-3 my-4'>

                <Link to="/" className="nav-link">
                  <img className="rounded-circle" src={require("../../../../assets/images/mech1.png")} />

                </Link>
              </div>
              <div className='col-lg-9'>
              <small>   <p className="card-title text-left">
                  {education.content4}


                </p></small>

             {/*  <p className="alert-link page-link"
                  onClick={this.toggleMechanical}>
                  Learn more
                </p>
              <Modal isOpen={this.state.showMaths}
                  toggle={this.toggleMaths}>

                  <ModalBody>
                    <ModalHeader toggle={this.toggleMaths}> <small>{jobRequirements.header11}</small></ModalHeader>
                    <p><small>
                      {jobRequirements.description}
                    </small>  </p>

                    <ModalHeader><small>{jobRequirements.header12}</small></ModalHeader>

                    <p><small>
                      {jobRequirements.Responsibilities}
                    </small>  </p>

                    <ModalHeader><small>{jobRequirements.header13}</small></ModalHeader>

                    <p><small>
                      {jobRequirements.skils}  </small>
                    </p>

                    <Button color="secondary" className="float-right"
                      onClick={this.toggleMaths}>Close</Button>
                  </ModalBody>
                </Modal>  */} 

              {/*   <p className="card-title text-left">
                <small>{experienced.eligibility}
                  <br></br>
                  {experienced.location}
                  <br></br>
                  {experienced.process}
                  <br></br>

                 {experienced.round1}
                    <br></br>
                    {experienced.round2}
                    <br></br>
                    {experienced.round3}
                    <br></br>
                    {experienced.round4}
                    <br></br>
                    {experienced.round5} 
                  <br></br>
                  <FontAwesomeIcon icon={faCode} /><small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> {" "}  { experienced.price} </small></small> {"          "}   Free!!
                </p> */}
                <br></br>
                <NavLink
                  to={{
                    pathname: "/register",
                    jobApplicationProps: {
                      subscriptionCost: "12500",
                      subscriptionFor: "Mechanical",
                      subscriptionType:"TRAINING-education",
                    },
                  }}
                  exact
                >

                  <button type="button" class="btn btn-md btn-block btn-primary w-50 mx-auto">
                    PROCEED
                  </button>
                </NavLink>


              </div>
            </div>


          </div>
        </div>

      </div>
      <br></br>


        <div class="card-deck mb-2 text-center">
        <div class="card mb-6 box-shadow">
        <div class="card-header">
         <h4>
              {education.header3}
            </h4>

            <h5 class="card-title pricing-card-title">
              <small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>  ₹ 25,000 {" "}  <small class="text-muted">/ Paper</small></small> ₹ 12,500{" "}
                <small class="text-muted">/ Paper</small>
              </h5>

          </div>

          <div class="card-body">
            <div className='row'>
              <div className='col-lg-3 my-4'>

                <Link to="/" className="nav-link">
                  <img className="rounded-circle" src={require("../../../../assets/images/auto.png")} />

                </Link>
              </div>
              <div className='col-lg-9'>
              <small>   <p className="card-title text-left">
                  {education.content3}

                </p> </small>

             {/*  <p className="alert-link page-link"
                  onClick={this.toggleAutoMobile}>
                  Learn more
                </p>
              <Modal isOpen={this.state.showMaths}
                  toggle={this.toggleMaths}>

                  <ModalBody>
                    <ModalHeader toggle={this.toggleMaths}> <small>{jobRequirements.header11}</small></ModalHeader>
                    <p><small>
                      {jobRequirements.description}
                    </small>  </p>

                    <ModalHeader><small>{jobRequirements.header12}</small></ModalHeader>

                    <p><small>
                      {jobRequirements.Responsibilities}
                    </small>  </p>

                    <ModalHeader><small>{jobRequirements.header13}</small></ModalHeader>

                    <p><small>
                      {jobRequirements.skils}  </small>
                    </p>

                    <Button color="secondary" className="float-right"
                      onClick={this.toggleMaths}>Close</Button>
                  </ModalBody>
                </Modal>  */} 

              {/*   <p className="card-title text-left">
                <small>{experienced.eligibility}
                  <br></br>
                  {experienced.location}
                  <br></br>
                  {experienced.process}
                  <br></br>

                 {experienced.round1}
                    <br></br>
                    {experienced.round2}
                    <br></br>
                    {experienced.round3}
                    <br></br>
                    {experienced.round4}
                    <br></br>
                    {experienced.round5} 
                  <br></br>
                  <FontAwesomeIcon icon={faCode} /><small style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}> {" "}  { experienced.price} </small></small> {"          "}   Free!!
                </p> */}
                <br></br>
                <NavLink
                  to={{
                    pathname: "/register",
                    jobApplicationProps: {
                      subscriptionCost: "12500",
                      subscriptionFor: "Auto Mobile",
                      subscriptionType:"TRAINING-education",
                    },
                  }}
                  exact
                >

                  <button type="button" class="btn btn-md btn-block btn-primary w-50 mx-auto">
                    PROCEED
                  </button>
                </NavLink>


              </div>
            </div>


          </div>
        </div>

      </div>
      <br></br>

       
       
      </div>


    );
  }
}

export default withRouter(Education);