import React from "react";
import "./SplashScreen.css";
import DisplayLottie from "./displayLottie/DisplayLottie"
import {greeting, splashScreenContent} from "../content/PageContent";

export default function SplashScreen() {
  return (
    <div className={"splash-container"}>
      <div className="splash-animation-container">
        <DisplayLottie animationData={splashScreenContent.animation} />
      </div>
      <div className="splash-title-container">
        <span className="splash-title">{greeting.username}</span>
        
      </div>
    </div>
  );
}
