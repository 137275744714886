import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  illustration,
  greeting,
  socialMediaLinks,
} from "../content/PageContent";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DisplayLottie from "../splashScreen/displayLottie/DisplayLottie";
import landingPerson from "../../../assets/lottie/menandwomen.json";
import Business from "../business/Business";
import { COOKIE_PREF } from "../../../config/Config";

import oneone from "../../../assets/images/img/about/1.jpg";
import onetwo from "../../../assets/images/img/about/2.jpg";
import onethree from "../../../assets/images/img/about/3.jpg";
import onefour from "../../../assets/images/img/about/4.jpg";
import teamone from "../../../assets/images/img/team/1.jpg";
import teamtwo from "../../../assets/images/img/team/2.jpg";
import teamthree from "../../../assets/images/img/team/3.jpg";

class Services extends Component {
  state = {
    showCookieModal: false,
    showChooseCookieModal: false,
    cookieRequest: {
      socialMedia: false,
      essential: false,
      commercial: false,
      fullCookie: false,
    },
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.title = "Home page";
  }
  componentDidUpdate() {
    if (this.props.notFound === true) {
      this.props.showAlert("Page not found ! Back to the home page.", "error");
    }
  }


  render() {
    return (
      <>
      <hr></hr>
      <div className="container">
        <div class="text-center">
          <h2 class="section-heading text-uppercase">Services</h2>
          <h3 class="section-subheading text-muted">WE SPECIALIZE IN FULLSTACK APP DEVELOPMENT AND BUSINESS PROCESS MANAGEMENT AUTOMATION.</h3>
        </div>
        <div class="row text-center">
          <div class="col-md-4">
          <br></br>
            <span class="fa-stack fa-6x">
              <i class="fas fa-circle fa-stack-2x bg-primary-circle"></i>
              <i class="fas fa-business-time fa-stack-1x fa-inverse"></i>
            </span>
            <h4 class="my-3">Mobile Friendly Webapp</h4>
            <p class="text-muted">Start your mobile friendly free web application for any kind of business with secured user subscription management dashboard.</p>
            <Link to="/devSubscriptionPricing" className="btn btn-primary btn-xl text-uppercase  my-md-4">Start your Webapp Free</Link>  
          </div>
          <div class="col-md-4">
          <br></br>
            <span class="fa-stack fa-6x">
              <i class="fas fa-circle fa-stack-2x bg-primary-circle" ></i>
              <i class="fas fa-code fa-stack-1x fa-inverse"></i>
            </span>
            <h4 class="my-3">Hire a coder</h4>
            <p class="text-muted">Customize your dream team from our cherry picked developers pool.Skilled immediate joiners are available in flexible contract.</p>
            <Link to="/hireSubscriptionPricing" className="btn btn-primary btn-xl text-uppercase my-md-4">
              Hire a coder
            </Link>
          </div>
          <div class="col-md-4">
          <br></br>
            <span class="fa-stack fa-6x">
              <i class="fas fa-circle fa-stack-2x bg-primary-circle"></i>
              <i class="fas fa-lock fa-stack-1x fa-inverse"></i>
            </span>
            <h4 class="my-3">Get your secured ODC</h4>
            <p class="text-muted">We have secured odc to solve your business problems.We also undertake projects of flex plm PTC development, android, ios mobile apps and BPMN process automation micro services.</p>
            <Link to="/contact" className="btn btn-primary btn-xl text-uppercase">Get Secured ODC</Link>  
          </div>
        </div>
        </div>
      </>
    );
  }
}

export default withRouter(Services);
