import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { documentData } from "../../../../../api/commonApi";
import { CustomInput, FormGroup } from 'reactstrap';
class DocumentDataSettings extends Component {
  state = {
      profilePic: null,
      resumeFile: null,
      profileFileName:'',
      invalidProfileFile:false,
      resumeFileName:'',
      invalidProfileFile:false,
      showLoading: false,
  };

  componentDidMount() {
    document.title = "My account document data settings";
  }
  handleProfileFileChange = this.handleProfileFileChange.bind(this);
  handleProfileFileChange({target: {files}}) {
    const cancel = !files.length;
    if (cancel) return;
    const [{ size, name }] = files;
    const maxSize = 100000;
    if (size < maxSize) {
      this.setState({ profileFileName: name, invalidProfileFile: false });
      this.setState({ profilePic: files[0]});
    } else {
      this.setState({ profileFileName: '', invalidProfileFile: true });
      this.props.showAlert("image size should not exceed 100kb", "error");
        this.setState({ showLoading: false });
        this.props.history.push("/me/settings/profilepic");
    }
  }
  handleResumeFileChange = this.handleResumeFileChange.bind(this);
  handleResumeFileChange({target: {files}}) {
    const cancel = !files.length;
    if (cancel) return;
    const [{ size, name }] = files;
    const maxSize = 100000;
    if (size < maxSize) {
      this.setState({ resumeFileName: name, invalidResumeFile: false });
      this.setState({ resumeFile: files[0]});
    } else {
      this.setState({ resumeFileName: '', invalidResumeFile: true });
      this.props.showAlert("Resume size should not exceed 100kb", "error");
      this.setState({ showLoading: false });
      this.props.history.push("/");
        this.props.history.push("me/settings/profilepic");
    }
  }

  handleDocumentSubmit = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    
    const formData = new FormData();
    formData.append("profile", this.state.profilePic);
    formData.append("resume", this.state.resumeFile);
    documentData(formData)
      .then((response) => {
        const data = response.data;
        this.props.showAlert(data.message, "success");
        this.setState({ showLoading: false });

        this.setState({
          profilePic: null,
          resumeFile: null,
          resumeFileName:'',
          profileFileName:''
        });
        this.props.history.push("/");
        this.props.history.push("me/settings/profilepic");
      })
      .catch((error) => {
        this.props.showAlert(error.message,"error");
        this.setState({ showLoading: false });
      });
  };

  render() {
    const { profileFileName, invalidProfileFile,resumeFileName,invalidResumeFile } = this.state;
    return (
      <div>
        <h2>ProfilePic and Resume Upload</h2>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Updating your profile pic and resume</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                refresh 
              </i>
            </div>
          ) : (
            <form onSubmit={this.handleDocumentSubmit}>
              <div className="form-group">
                <label htmlFor="profilePic">Profile Picture</label>

                <FormGroup>
                  <CustomInput
                    type="file"
                    id="exampleCustomFileBrowser"
                    name="customFile"
                    label={profileFileName || "choose an image file size less than 100kb"}
                    onChange={this.handleProfileFileChange}
                    invalid={invalidProfileFile}
                  />
                </FormGroup>
              </div>
              <div className="form-group">
                <label htmlFor="resume">Latest Resume</label>
                <FormGroup>
                  <CustomInput
                    type="file"
                    id="exampleCustomFileBrowser"
                    name="customFile"
                    label={resumeFileName || "choose an pdf or doc file size less than 100kb"}
                    onChange={this.handleResumeFileChange}
                    invalid={invalidResumeFile}
                  />
                </FormGroup>
              </div>
              <button className="btn btn-primary ms-auto">Submit</button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(DocumentDataSettings);
