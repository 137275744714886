import React, { Component } from "react";

import { cookiePolicy } from "../content/PrivacyContent";
import { Fade } from "react-reveal";


class CookiePolicy extends Component {
    render() {
        return (
            <>
                <Fade bottom duration={1000} distance="40px">
                    <div className="container">
                        <div className="main contact-margin-top" id="contact">
                            <div className="contact-div-main">
                                <div className="contact-header">
<label></label>
                                    <h1 className={"pages-mainheader-text"}>{cookiePolicy.Title}</h1>
                                    <h1 className={"pages-subhead-text"}> <strong> {cookiePolicy.Subtitle}</strong></h1>
                                    <p className={"pages-content-text"}> {cookiePolicy.Definition}</p>
                                    <p className={"pages-content-text"} >{cookiePolicy.Content}</p>
                                    <p className={"pages-content-text"}>{cookiePolicy.Content1}</p>
                                    <p className={"pages-content-text"}>{cookiePolicy.Content2}</p>
                                    <p className={"pages-content-text"}> {cookiePolicy.Definition1}</p>
                                    <p className={"pages-content-text"}>{cookiePolicy.Content3}</p>
                                    <p className={"pages-content-text"}>{cookiePolicy.Content4}</p>
                                    <p className={"pages-content-text"}> {cookiePolicy.Definition2}</p>
                                    <p className={"pages-content-text"}>{cookiePolicy.Content5}</p>

                                </div>
                            </div>
                        </div>
                        <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
         
                    </div>
                </Fade>
            </>
        )
    }
}
 export default CookiePolicy;