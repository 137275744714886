import React, { Component } from "react";

import { Terms } from "../content/PrivacyContent";
import { Fade } from "react-reveal";


class Term extends Component {
    render() {
        return (
            <>
                <Fade bottom duration={1000} distance="40px">
                    <div className="container">
                        <div className="main contact-margin-top" id="contact">
                            <div className="contact-div-main">
                                <div className="contact-header">
                                   <label></label>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent}</p>
                                <p className={"pages-content-text"} >{Terms.COntent1}</p>

                                   <p>
                                        <li className={"pages-content-text"}>{Terms.COntent2}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent3}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent4}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent5}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent6}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent7}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent8}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent9}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent10}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent11}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent12}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent13}</li>
                                        <li className={"pages-content-text"}>{Terms.COntent14}</li>
                                    </p>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle2}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent15}</p>
                                    <p className={"pages-content-text"} >{Terms.COntent16}</p>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle3}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent17}</p>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle4}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent18}</p>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle5}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent19}</p>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle6}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent20}</p>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle7}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent21}</p>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle8}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent22}</p>
                                    <h1 className={"pages-mainheader-text"}>{Terms.TItle9}</h1>
                                    <p className={"pages-content-text"} >{Terms.COntent23}</p>
                                    
                                </div>
                            </div>
                        </div>
                        <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
                    </div>
                </Fade>
            </>
        )
    }
}
 export default Term;