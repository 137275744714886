import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH,
} from "../../../../../config/Config";
import { retrieveProfileData } from "../../../../../api/Api";

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      employment: "",
      performanceEvaluation: "",
      personalInfo: "",
      employeeLeaveData:"",
    };
  }

  componentDidMount() {
    document.title = "My Profile Data";
    this.retrieveProfile();
  }

  updateOldPassword = (event) => {
    let req = this.state.updatePasswordRequest;
    req.oldPassword = event.target.value;
    this.setState({ updatePasswordRequest: req });
  };

  updateNewPassword = (event) => {
    let req = this.state.updatePasswordRequest;
    req.newPassword = event.target.value;
    this.setState({ updatePasswordRequest: req });

    let passwordPattern = this.state.passwordPattern;

    const lowerCase = /[a-z]/g;
    if (req.newPassword.match(lowerCase)) {
      document.getElementById("lowerCase").classList.remove("text-danger");
      document.getElementById("lowerCase").classList.add("text-success");
      passwordPattern.lowerCase = true;
    } else {
      document.getElementById("lowerCase").classList.remove("text-success");
      document.getElementById("lowerCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const upperCase = /[A-Z]/g;
    if (req.newPassword.match(upperCase)) {
      document.getElementById("upperCase").classList.remove("text-danger");
      document.getElementById("upperCase").classList.add("text-success");
      passwordPattern.upperCase = true;
    } else {
      document.getElementById("upperCase").classList.remove("text-success");
      document.getElementById("upperCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const number = /[0-9]/g;
    if (req.newPassword.match(number)) {
      document.getElementById("number").classList.remove("text-danger");
      document.getElementById("number").classList.add("text-success");
      passwordPattern.number = true;
    } else {
      document.getElementById("number").classList.remove("text-success");
      document.getElementById("number").classList.add("text-danger");
      passwordPattern.number = false;
    }

    if (
      req.newPassword.length > PASSWORD_MIN_LENGTH &&
      req.newPassword.length < PASSWORD_MAX_LENGTH
    ) {
      document.getElementById("chars").classList.remove("text-danger");
      document.getElementById("chars").classList.add("text-success");
      passwordPattern.charsLength = true;
    } else {
      document.getElementById("chars").classList.remove("text-success");
      document.getElementById("chars").classList.add("text-danger");
      passwordPattern.charsLength = false;
    }
    this.setState({ passwordPattern: passwordPattern });

    if (req.newPassword === req.confirmNewPassword) {
      document.getElementById("inputNewPassword").classList.add("is-valid");
      document
        .getElementById("inputConfirmNewPassword")
        .classList.add("is-valid");
      document
        .getElementById("inputNewPassword")
        .classList.remove("is-invalid");
      document
        .getElementById("inputConfirmNewPassword")
        .classList.remove("is-invalid");
    } else {
      document.getElementById("inputNewPassword").classList.add("is-invalid");
      document
        .getElementById("inputConfirmNewPassword")
        .classList.add("is-invalid");
      document.getElementById("inputNewPassword").classList.remove("is-valid");
      document
        .getElementById("inputConfirmNewPassword")
        .classList.remove("is-valid");
    }
  };

  updateConfirmNewPassword = (event) => {
    let req = this.state.updatePasswordRequest;
    req.confirmNewPassword = event.target.value;
    this.setState({ updatePasswordRequest: req });

    let passwordPattern = this.state.passwordPattern;

    const lowerCase = /[a-z]/g;
    if (req.confirmNewPassword.match(lowerCase)) {
      document.getElementById("lowerCase").classList.remove("text-danger");
      document.getElementById("lowerCase").classList.add("text-success");
      passwordPattern.lowerCase = true;
    } else {
      document.getElementById("lowerCase").classList.remove("text-success");
      document.getElementById("lowerCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const upperCase = /[A-Z]/g;
    if (req.confirmNewPassword.match(upperCase)) {
      document.getElementById("upperCase").classList.remove("text-danger");
      document.getElementById("upperCase").classList.add("text-success");
      passwordPattern.upperCase = true;
    } else {
      document.getElementById("upperCase").classList.remove("text-success");
      document.getElementById("upperCase").classList.add("text-danger");
      passwordPattern.lowerCase = false;
    }

    const number = /[0-9]/g;
    if (req.confirmNewPassword.match(number)) {
      document.getElementById("number").classList.remove("text-danger");
      document.getElementById("number").classList.add("text-success");
      passwordPattern.number = true;
    } else {
      document.getElementById("number").classList.remove("text-success");
      document.getElementById("number").classList.add("text-danger");
      passwordPattern.number = false;
    }

    if (
      req.confirmNewPassword.length > PASSWORD_MIN_LENGTH &&
      req.confirmNewPassword.length < PASSWORD_MAX_LENGTH
    ) {
      document.getElementById("chars").classList.remove("text-danger");
      document.getElementById("chars").classList.add("text-success");
      passwordPattern.charsLength = true;
    } else {
      document.getElementById("chars").classList.remove("text-success");
      document.getElementById("chars").classList.add("text-danger");
      passwordPattern.charsLength = false;
    }
    this.setState({ passwordPattern: passwordPattern });

    if (req.newPassword === req.confirmNewPassword) {
      document.getElementById("inputNewPassword").classList.add("is-valid");
      document
        .getElementById("inputConfirmNewPassword")
        .classList.add("is-valid");
      document
        .getElementById("inputNewPassword")
        .classList.remove("is-invalid");
      document
        .getElementById("inputConfirmNewPassword")
        .classList.remove("is-invalid");
    } else {
      document.getElementById("inputNewPassword").classList.add("is-invalid");
      document
        .getElementById("inputConfirmNewPassword")
        .classList.add("is-invalid");
      document.getElementById("inputNewPassword").classList.remove("is-valid");
      document
        .getElementById("inputConfirmNewPassword")
        .classList.remove("is-valid");
    }
  };

  sendUpdatePasswordRequest = (event) => {
    event.preventDefault();

    this.setState({ showLoading: true });
    const req = this.state.updatePasswordRequest;

    if (
      req.confirmNewPassword.length < USERNAME_MIN_LENGTH ||
      req.confirmNewPassword.length > USERNAME_MAX_LENGTH
    ) {
      this.props.showAlert(
        "Your username must contain between " +
          USERNAME_MIN_LENGTH +
          " and " +
          USERNAME_MAX_LENGTH +
          " characters !",
        "error"
      );
      return null;
    }

    if (
      req.confirmNewPassword.length < PASSWORD_MIN_LENGTH ||
      req.confirmNewPassword.length > PASSWORD_MAX_LENGTH
    ) {
      this.props.showAlert(
        "Your password must contain between " +
          PASSWORD_MIN_LENGTH +
          " and " +
          PASSWORD_MAX_LENGTH +
          " characters !",
        "error"
      );
      return null;
    }

    if (req.newPassword === req.confirmNewPassword) {
      const passwordPattern = this.state.passwordPattern;
      if (
        passwordPattern.upperCase &&
        passwordPattern.lowerCase &&
        passwordPattern.charsLength &&
        passwordPattern.number
      ) {
        updatePassword(this.state.updatePasswordRequest)
          .then((res) => {
            this.props.showAlert("Your password has been changed !", "success");
            this.props.history.push("/me/settings/account");
            this.setState({ showLoading: false });
          })
          .catch((error) => {
            if (error.message) {
              this.props.showAlert(error.message, "error");
            } else {
              this.props.showAlert(
                "Sorry! Something went wrong. Please try again!",
                "error"
              );
            }
            this.setState({ showLoading: false });
            console.log(error);
          });
      } else {
        this.props.showAlert(
          "Invalid password : please take care of password constraints !",
          "error"
        );
      }
    } else {
      this.props.showAlert("Passwords does not match !", "error");
    }
  };
  nullCheck(object){
    if(object!==undefined && object!==null){
      return object;
    }
    return "NA";
  }
  retrieveProfile = () => {
    this.setState({ showLoading: true });
    retrieveProfileData()
      .then((res) => {
        this.setState({ user: res });
        this.setState({ employment: res.employment });
        this.setState({
          performanceEvaluation: res.employment.performanceEvaluation,
        });
        this.setState({
          personalInfo: res.employment.profileDetails.personalInfo,
        });
        this.setState({
          employeeLeaveData: res.employment.employeeLeaveData,
        });
        
        this.props.showAlert("Profile data retrieved successfully ", "success");
        this.setState({ showLoading: false });
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert("Your profile image does't exist !", "error");
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  render() {
    return (
      <div className="container">
        <p>
          {" "}
          <h1>
            {this.state.user.name}{" "}
            <span className="text-muted" style={{ fontSize: 11 }}>
              {this.state.employment.designation}
            </span>
          </h1>
          <span className="text" style={{ fontSize: 11 }}>
            Performance Level :
            <ProgressBar
              variant={this.state.performanceEvaluation?this.state.performanceEvaluation.performanceLevel:"NA"}
              now={this.state.performanceEvaluation?this.state.performanceEvaluation.performancePercent:"NA"}
              key={1}
            />
          </span>
        </p>
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Profile loading inprogress.....</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                Please wait
              </i>
            </div>
          ) : (
            <div>
              <div>
                <table className="table table-hover">
                  <thead>
                    <h1>
                      <span className="text-muted" style={{ fontSize: 17 }}>
                        {this.state.user.category} Data
                      </span>
                    </h1>
                  </thead>
                  <tbody>
                    <tr scope="row">
                      <th> Start Date</th>
                      <th>
                        {" "}
                        {this.state.performanceEvaluation?this.state.performanceEvaluation.probationStartDate:"NA"}
                      </th>{" "}
                    </tr>
                    <tr scope="row">
                      <th> Next evaluation date</th>
                      <th>
                        {" "}
                        {this.state.performanceEvaluation?this.state.performanceEvaluation.nextEvaluationDate:"NA"}
                      </th>{" "}
                    </tr>

                    <tr scope="row">
                      <th> End Date</th>
                      <th>
                        {" "}
                        {this.state.performanceEvaluation?this.state.performanceEvaluation.probationEndDate:"NA"}
                      </th>{" "}
                    </tr>
                    <tr scope="row">
                      <th> Remaining Days </th>
                      <th>
                        {" "}
                        {
                          this.state.performanceEvaluation?this.state.performanceEvaluation.remainingProbationDays:"NA"
                        }
                      </th>{" "}
                    </tr>
                    <tr scope="row">
                      <th> Performance Rating</th>
                      <th>
                        {" "}
                        {this.state.performanceEvaluation?this.state.performanceEvaluation.performanceRating:"NA"}
                      </th>{" "}
                    </tr>
                    <tr scope="row">
                      <th> Official Email</th>
                      <th>
                        {" "}
                        {this.state.employment.employeeOfficialEmailId}
                      </th>{" "}
                    </tr>

                    <tr scope="row">
                      <th>Employee Id</th>
                      <th> {this.state.employment.employeeId}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                {" "}
                <table className="table table-hover">
                  <thead>
                    
                    <h1>
                      <span className="text-muted" style={{ fontSize: 17 }}>
                        Personal Info
                      </span>
                    </h1>
                  </thead>
                  <tbody>
                  <tr scope="row">
                      <th>PanCard</th>
                      <th> {this.state.personalInfo===null?'NA':this.state.personalInfo.panNo}</th>{" "}
                    </tr>
                    {" "}
                    <tr scope="row">
                      <th>Emergency Contact No</th>
                      <th>
                        {" "}
                        {this.state.personalInfo===null?'NA':this.state.personalInfo.emergencyContactNo}
                      </th>{" "}
                    </tr>
                    <tr scope="row">
                      <th>Mobile</th>
                      <th> {this.state.personalInfo===null?'NA':this.state.personalInfo.phoneNo}</th>
                    </tr>
                    <tr scope="row">
                      <th>Date Of Birth</th>
                      <th> {this.state.personalInfo===null?'NA':this.state.personalInfo.dateOfBirth}</th>{" "}
                    </tr>
                    <tr scope="row">
                      <th>Permanent Address </th>
                      <th> {this.state.personalInfo===null?'NA':this.state.personalInfo.permanentAddress}</th>{" "}
                    </tr>
                    <tr scope="row">
                      <th>Present Address</th>
                      <th> {this.state.personalInfo===null?'NA':this.state.personalInfo.presentAddress}</th>{" "}
                    </tr>
                    <tr scope="row">
                      <th>Gender</th>
                      <th> {this.state.personalInfo===null?'NA':this.state.personalInfo.gender}</th>{" "}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                {" "}
                <table className="table table-hover">
                  <thead>
                    
                    <h1>
                      <span className="text-muted" style={{ fontSize: 17 }}>
                        Leave Data
                      </span>
                    </h1>
                  </thead>
                  <tbody>
                  <tr scope="row">
                      <th>Remaining Sick Leaves</th>
                      <th> {this.state.employeeLeaveData?this.state.employeeLeaveData.sickLeavesAvailable:"NA"}</th>{" "}
                    </tr>
                    {" "}
                    <tr scope="row">
                      <th>Availed Sick Leaves</th>
                      <th>
                        {" "}
                        {this.state.employeeLeaveData?this.state.employeeLeaveData.availedSickLeave:"NA"}
                      </th>{" "}
                    </tr>
                    <tr scope="row">
                      <th>Remaining Vacation Leaves</th>
                      <th> {this.state.employeeLeaveData?this.state.employeeLeaveData.vacationLeavesAvailable:"NA"}</th>
                    </tr>
                    <tr scope="row">
                      <th>Availed Vacation Leaves</th>
                      <th> {this.state.employeeLeaveData?this.state.employeeLeaveData.availedVacationLeave:"NA"}</th>{" "}
                    </tr>
                 
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(MyProfile);
