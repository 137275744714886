import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  illustration,
  greeting,
  socialMediaLinks,
} from "../content/PageContent";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DisplayLottie from "../splashScreen/displayLottie/DisplayLottie";
import landingPerson from "../../../assets/lottie/menandwomen.json";
import Business from "../business/Business";
import { COOKIE_PREF } from "../../../config/Config";

import oneone from "../../../assets/images/img/about/1.jpg";
import onetwo from "../../../assets/images/img/about/2.jpg";
import onethree from "../../../assets/images/img/about/3.jpg";
import onefour from "../../../assets/images/img/about/4.jpg";
import teamone from "../../../assets/images/img/team/1.jpg";
import teamtwo from "../../../assets/images/img/team/2.jpg";
import teamthree from "../../../assets/images/img/team/3.jpg";

class About extends Component {
  state = {
    showCookieModal: false,
    showChooseCookieModal: false,
    cookieRequest: {
      socialMedia: false,
      essential: false,
      commercial: false,
      fullCookie: false,
    },
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.title = "Home page";
  }
  componentDidUpdate() {
    if (this.props.notFound === true) {
      this.props.showAlert("Page not found ! Back to the home page.", "error");
    }
  }

  render() {
    return (
      <>
       <br></br>
              <div class="text-center">
                <h2 class="section-heading text-uppercase">About</h2>
                <h3 class="section-subheading text-muted">
                  All roads lead to experience.
                </h3>
              </div>
              <ul class="timeline">
                <li>
                  <div class="timeline-image">
                    <img
                      class="rounded-circle img-fluid"
                      src={oneone}
                      alt="..."
                    />
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h4>2016-2022</h4>
                      <h4 class="subheading">Our Humble Beginnings</h4>
                    </div>
                    <div class="timeline-body">
                      <p class="text-muted">
                        our company originated with young minds with prematured
                        baby steps into this software development world with the motive of
                        providing world class software service from small scale to large
                        scale industries with enhanced trainees.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted">
                  <div class="timeline-image">
                    <img
                      class="rounded-circle img-fluid"
                      src={onetwo}
                      alt="..."
                    />
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h4>January 2022</h4>
                      <h4 class="subheading">An Agency is Born</h4>
                    </div>
                    <div class="timeline-body">
                      <p class="text-muted">Our company has changed to
                      reasearch and development industry with enough development tools to handle
                      development of an application.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="timeline-image">
                    <img
                      class="rounded-circle img-fluid"
                      src={onethree}
                      alt="..."
                    />
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h4>August 2022</h4>
                      <h4 class="subheading">Transition to Full Service</h4>
                    </div>
                    <div class="timeline-body">
                      <p class="text-muted">
                        Our development services started formally with internal application
                        and grown our employee count to multiple of tens with
                        successful implementations handled during the training
                        sessions.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted">
                  <div class="timeline-image">
                    <img
                      class="rounded-circle img-fluid"
                      src={onefour}
                      alt="..."
                    />
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h4>June 2023</h4>
                      <h4 class="subheading">Phase Two Expansion</h4>
                    </div>
                    <div class="timeline-body">
                      <p class="text-muted">
                        We launched mobile friendly webapps to the market
                        with the help of our enhanced development team and expanded our
                        development to multiple categories namely, backend, frontend,
                         fullstack and business process automation.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="timeline-inverted">
                  <div class="timeline-image">
                    <h4>
                      Be Part
                      <br />
                      Of Our
                      <br />
                      Story!
                    </h4>
                  </div>
                </li>
              </ul>
       
      </>
    );
  }
}

export default withRouter(About);
