import React, { Component,useState } from "react";
import { withRouter, Link } from "react-router-dom";
import {updateTransactionStatus } from "../../../api/Api";
import { transactionTrackingId } from "../../../config/Config";


class PaymentGatewaySuccessPage extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      merchantTransactionId:'',
      xVerify:'',
      paymentStatus:'',
      transactionReference:'',
      defaultRender: false,
      
    };
    this.paymentGateWaySuccess=this.paymentGateWaySuccess.bind(this)
    this.paymentGateWaySuccess();
  }

  componentDidMount() {
    document.title = "Payment status";
    this.paymentGateWaySuccess();
  }


  async hash(string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  }

  async getTransaction(){
    getTransactionId()
  .then((response) => {
    let res = response.data;
     let data=res;

     this.setState({merchantTransactionId:data});
     return data;
}).catch((error) => {
  this.props.showAlert(error);
  this.setState({ showLoading: false });
});
  }

  paymentGateWaySuccess = (event) => {
       updateTransactionStatus(localStorage.getItem(transactionTrackingId))
    .then((response) => {
      localStorage.removeItem(transactionTrackingId);
      let res = response.data;
       this.setState({paymentStatus:res.transactionCode});
       this.setState({transactionReference:res.transactionId});
  })
     
     .catch((error) => {
      this.props.showAlert(error);
      this.setState({ showLoading: false });
    });
    
     
    
  };

  render() {
    alert("before success page render");
    if(this.state.paymentStatus=="PAYMENT_SUCCESS"){
      return (
        <>
        <hr></hr>
        <div className="container">
          <div class="text-center">
            <h3 class="section-subheading text-muted">Thanks You.
            <br/>
            YOUR PAYMENT IS SUCCESSFUL.</h3>
            <h3 class="section-subheading text-muted">Your transaction reference number is {this.state.transactionReference}</h3>
          </div>
          </div>
        </>
      );
    }
    else{
      if(this.state.defaultRender){
      return (
        <>
        <hr></hr>
        <div className="container">
          <div class="text-center">
            <h3 class="section-subheading text-muted">Payment is failed.
            <br/>
            please retry.</h3>
          </div>
          </div>
        </>
      );
    }
  }
  }
}

export default withRouter(PaymentGatewaySuccessPage);
