import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { requestChangeRole } from "../../../../api/AdminApi";

class UserGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalUserInfo: false,
      user: props.user,
      role: "",
      showLoading: false,
    };

    this.toggle = this.toggle.bind(this);
    this.updateRole = this.updateRole.bind(this);
    this.changeRole = this.changeRole.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const user = props.user;
    state.user = user;
    return state;
  }
  toggle() {
    this.setState({
      showModalUserInfo: !this.state.showModalUserInfo,
    });
  }

  updateRole = (event) => {
    this.setState({ role: event.target.value });
  };
  changeRole = (event) => {
    event.preventDefault();

    this.setState({ showLoading: true });
    requestChangeRole(this.state.user.email, this.state.role)
      .then((res) => {
        this.props.showAlert(
          "Your request has been accepted ! role got changed for the user with email of " +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.toggle();
        this.props.history.push("/admin/dashboard/index");
        this.props.history.push("/admin/dashboard/users");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  render() {
    let roles;

    if (this.state.user && this.state.user.roles) {
      roles = Object.keys(this.state.user.roles).map(
        (key) => " " + this.state.user.roles[key]
      );
    }

    return (
      <tr>
        <th>
          <button className="btn btn-dark" onClick={this.toggle}>
            <FontAwesomeIcon icon={faSearchPlus} />
          </button>
        </th>
        <th>{this.state.user.role}</th>
        <th scope="row">{this.state.user.mobile}</th>
        <th>{this.state.user.email}</th>
        <th>
          {this.state.user.employment.profileDetails
            ? this.state.user.employment.profileDetails.preferredLocation
            : "NA"}
        </th>
        <th>
          {this.state.user.employment.profileDetails
            ? this.state.user.employment.profileDetails.additionalComments
            : "NA"}
        </th>

        <Modal
          isOpen={this.state.showModalUserInfo}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " +
                this.state.user.name +
                " Role as { " +
                this.state.user.role +
                " }"}
            </div>
          </ModalHeader>
          <ModalBody>
            <p>
              <b>Email address:</b> {this.state.user.email}
            </p>
            <p>
              <b>Account creation date:</b> {this.state.user.createdAt}
            </p>
            <p>
              <b>2FA status:</b>
              {this.state.user.usingTwoFA ? (
                <span className="badge badge-success">enabled</span>
              ) : (
                <span className="badge badge-danger">disabled</span>
              )}
            </p>
            <p>To change role:</p>
            <form onSubmit={(e) => this.changeRole(e)}>
              <select
                className="w3-select"
                name="option"
                value={this.state.role}
                onChange={(event) => this.updateRole(event)}
              >
                <option value="" disabled>
                  Choose your option
                </option>
                <option value="HR">HR</option>
                <option value="ADMIN">ADMIN</option>
                <option value="USER">USER</option>
                <option value="CLIENT">CLIENT</option>
                <option value="EMPLOYEE">EMPLOYEE</option>
                <option value="FINANCIER">FINANCIER</option>
                <option value="EMPTY">EMPTY</option>
              </select>
              <br></br>
              <button type="submit" className="btn btn-success btn-lg mt-4">
                Change Role
              </button>
            </form>
          </ModalBody>
        </Modal>
      </tr>
    );
  }
}

export default withRouter(UserGridLine);
