import React, { Component } from "react";
import { withRouter } from "react-router-dom";


class SickLeave extends Component {
    render() {
        return (
            <>
                <h3 className={"pages-mainheader-text"}> Sick Leave </h3>
                <label></label>
                <p>
                    <li className={"pages-content-text"}>Employees are entitled to 6 days/year of paid sick leave for self.</li>
                    <li className={"pages-content-text"} >Intervening holidays like weekends and public holidays falling within the leave period will be taken into account.</li>
                    <li className={"pages-content-text"} >Employee will be entitled for normal salary during sick leave for full salary 6 days, post which employee have to pay 500INR per day in order to avail.</li>
                    <li className={"pages-content-text"} >A condition is that the employee would be issued a certificate of temporary incapacity for work by a doctor for consecutive sick leaves.</li>
                    <li className={"pages-content-text"} >The certificate is issued upon the end of the sick leave.</li>
                    
                </p>
                
            </>
        )

    }




}
export default withRouter(SickLeave);