import React, {Component} from 'react';
import TrackAssessmentsGridLine from "./TrackAssessmentsGridLine";

class TrackAssessmentsGrid extends Component {

    constructor(props){
        super(props);

        this.state = {
            assessments: props.content
        }
    }

    static getDerivedStateFromProps(props, state) {
        state = props.content;
        return state;
    }

    render(){

        // const {users} = this.state;

        let gridLines = [];

        if(this.state.assessments && this.state.assessments.length > 0){
            gridLines = Object.keys(this.state.assessments)
                .map(key => <TrackAssessmentsGridLine showAlert={this.props.showAlert} key={key} assessment={this.state.assessments[key]}/>);
        }

        return(
            <div>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Validate</th>
                        <th scope="col">QA</th>
                        <th scope="col">Skill</th>
                        <th scope="col">Total Score</th>
                        <th scope="col">Min Pass</th>
                        <th scope="col">No of attendees</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        this.state.assessments.length > 0
                        ?
                            gridLines
                        :
                            <div className="alert alert-danger" role="alert">
                                <strong>Oops !</strong> Nothing to show here...
                            </div>
                    }
                    </tbody>
                </table>
                <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
            </div>
        );
    }
}

export default TrackAssessmentsGrid;