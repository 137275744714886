
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, Alert } from 'reactstrap';
import { FormGroup, CustomInput } from 'reactstrap';
import { getContent } from '../../../../api/Api';
import { contentPutData } from '../../../../api/commonApi';

class UpdateContent extends Component {


    constructor(props) {
        //console.log("ABC" + props.location.viewContentProps);
        super(props);
        this.state = {
            endpoint: "",
            primaryHeader: "",
            category: "",
            createdAt: "",
            publishedBy: "",
            viewCount: "",
            contentInfoData: [
                {
                    contentHeader: "",
                    contentBody: "",
                    codeSnippet: "",
                    contentImageBody: null,
                    contentImageName: "",
                    invalidContentFile: false,

                },
            ],
        }
        this.updateViewContentRequest = this.updateViewContentRequest.bind(this);
        this.updateViewContentResponse = this.updateViewContentResponse.bind(this);
        this.backToHome = this.backToHome.bind(this);
    };

    componentDidMount() {
        this.updateViewContentRequest();
        this.updateViewContentResponse();
    }

    updateViewContentRequest = () => {
        this.setState({ showLoading: true });
        let state = this.state;
        state.endpoint = this.props.location.viewContentProps ? this.props.location.viewContentProps.contentEndPoint : "";
        state.category=this.props.location.viewContentProps ? this.props.location.viewContentProps.category : "";
        this.setState({ state });
    }
    updateViewContentResponse = () => {
        let data = this.state.endpoint;
        getContent(data,this.state.category)
            .then((res) => {
                //alert("datashow" + JSON.stringify(res.data));
                if (res.data === null) {
                    this.showAlert(res.message, "error");
                } else {
                    this.setState({ primaryHeader: res.data.primaryHeader });
                    this.setState({ contentInfoData: res.data.contentList });
                    this.setState({ category: res.data.category })
                    this.setState({ endpoint: res.data.endpoint })
                    this.setState({ createdAt: res.data.createdAt })
                    this.setState({ publishedBy: res.data.publishedBy})
                    this.setState({viewCount: res.data.viewCount})
                    // this.setState({ contentList: res.data.contentList });
                    // alert("contentview"+JSON.stringify(this.state.contentList))
                }

                this.setState({ showLoading: false });
            })
            .catch((error) => {
                if (error.status === 401) {
                    this.showAlert(
                        "Your are not eligible to access this endpoint",
                        "error"
                    );
                }
                this.setState({ showLoading: false });
            });

    }
    primaryHeader = (event) => {
        this.setState({ primaryHeader: event.target.value });
    };

    handleInfoAddClick = () => {
        this.setState({
            contentInfoData: [
                ...this.state.contentInfoData,
                {
                    contentHeader: "",
                    contentBody: "",
                    codeSnippet: "",
                    contentImageBody: null,
                    contentImageName: "",
                    invalidContentFile: false,
                },
            ],
        });
    };

    toBase64 = fileData => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    async fileDataConverter(fileData) {
        try {
            const result = await this.toBase64(fileData);
            return result
        } catch (error) {
            console.error(error);
            return;
        }

    }
    // handle click event of the onchange method
    handleContentInfo = (e, index) => {
        const { name, value } = e.target;
        if (e.target.name === "contentImageBody") {
            const files = e.target.files;
            if ({ target: { files } }) {
                const cancel = !files.length;
                if (cancel) return;
                const [{ size, name }] = files;
                const maxSize = 100000;
                if (size < maxSize) {
                    const list = [...this.state.contentInfoData];
                    list[index].contentImageName = name;
                    list[index].invalidContentFile = false;
                    //alert("files[0["+files[0])
                    this.fileDataConverter(files[0]).then((response) => {
                        list[index].contentImageBody = response;
                        //alert("response"+response)
                        this.setState({ contentInfoData: list });
                    })
                } else {
                    const list = [...this.state.contentInfoData];
                    list[index].contentImageName = "";
                    list[index].invalidContentFile = true;
                    this.setState({ contentInfoData: list });
                    this.props.showAlert("image size should not exceed 100kb", "error");
                    this.setState({ showLoading: false });
                    this.props.history.push("/publish/editcontent");
                }
            }
        }
        const list = [...this.state.contentInfoData];
        list[index][name] = value;
        this.setState({ contentInfoData: list });
    };

    // handle click event of the Remove button
    handleInfoRemoveClick = (index) => {
        const list = [...this.state.contentInfoData];
        list.splice(index, 1);
        this.setState({ contentInfoData: list });
    };
    publishContentEditUpdate = (event) => {
        event.preventDefault();
        this.setState({ showLoading: true });
        const contentUpdate = this.state;
        //alert("content details" + JSON.stringify(contentUpdate));
        contentPutData(contentUpdate)
            .then((response) => {
                const data = response.data;
                this.props.showAlert(data.message, "success");
                this.setState({ showLoading: false });

                this.setState({
                    primaryHeader: "",
                    category: "",
                    publishedBy: "",
                    createdAt: "",
                    viewCount: "",
                    endpoint: "",
                    contentInfoData: [
                        {
                            contentHeader: "",
                            contentBody: "",
                            codeSnippet: "",
                            contentImageBody: null,
                            contentImageName: "",
                            invalidContentFile: false,
                        },
                    ],
                    showLoading: false,
                });
                this.props.history.push("/publish/editContent");

            })
            .catch((error) => {
                this.props.showAlert(error, "error");
                this.setState({ showLoading: false });
            });
    };



    backToHome() {
        this.props.history.push("/publish/editContent");
    }


    render() {
        const { endpoint, contentInfoData, primaryHeader } = this.state;
        var styles = { color: "purple", fontSize: 40, border: "2px solid purple", width: 170, height: 150, }
        //alert("vieupdate" + JSON.stringify(endpoint));
        return (

            <>
                <br></br><br></br>
                <div className="container">





                    <div class="row gx-2 justify-content-center">
                        <div class="col-lg-11 col-xl-9 col-xxl-8">
                            <div class="d-flex align-items-center justify-content-between mb-4">
                                <h2 class="text-dark fw-bolder mb-4">{endpoint}</h2>
                                <button class="btn btn-primary px-4 py-3" onClick={this.backToHome}>
                                    <div class="d-inline-block bi bi-download me-2"></div>
                                    Back to edit content
                                </button>


                            </div>
                            <form onSubmit={this.publishContentEditUpdate}>


                                <div className="form-group">
                                    <label htmlFor="primaryHeader">Primary Header</label>
                                    <input type="text" className="form-control" id="primaryHeader" name='primaryHeader'
                                        placeholder="primary header" value={primaryHeader} required autoComplete="on" onChange={this.primaryHeader}

                                    />
                                </div>

                                <div className="form-group">
                                    <h4 htmlFor="contentInfoData">Content Info</h4>
                                    {contentInfoData.map((x, i) => {
                                        return (
                                            <div>
                                                <label htmlFor="contentHeader" className="mt-2">
                                                    Content Header
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control mt-2"
                                                    id="contentHeader"
                                                    name="contentHeader"
                                                    placeholder="content header"
                                                    required
                                                    autoComplete="on"
                                                    value={x.contentHeader}
                                                    onChange={(e) => this.handleContentInfo(e, i)}
                                                />
                                                <label htmlFor="contentBody" className="mt-2">
                                                    Content Body
                                                </label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    id="contentBody"
                                                    name="contentBody"
                                                    required
                                                    autoComplete="on"
                                                    placeholder="content body"
                                                    value={x.contentBody}
                                                    onChange={(e) => this.handleContentInfo(e, i)}
                                                />
                                                <label htmlFor="codeSnippet" className="mt-2">
                                                    Code Snippet
                                                </label>
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    id="codeSnippet"
                                                    name="codeSnippet"
                                                    //required
                                                    autoComplete="on"
                                                    placeholder="code snippet"
                                                    value={x.codeSnippet}
                                                    onChange={(e) => this.handleContentInfo(e, i)}
                                                />


                                                <label htmlFor="profilePic">content Image Body</label>
                                                <div className='row'>
                                                    <div className='col-lg-8 my-md-auto'>
                                                        <FormGroup>
                                                            <CustomInput
                                                                type="file"
                                                                id="exampleCustomFileBrowser"
                                                                name="contentImageBody"
                                                                label={
                                                                    x.contentImageName ||
                                                                    "choose an image file size less than 100kb"
                                                                }
                                                                onChange={(e) => this.handleContentInfo(e, i)}
                                                                invalid={x.invalidContentFile}
                                                            />
                                                        </FormGroup>

                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <img src={x.contentImageBody} className='img-fluid' style={styles} />

                                                    </div>
                                                </div>


                                                {contentInfoData.length !== 1 && (
                                                    <button
                                                        className="btn btn-danger mt-2"
                                                        onClick={this.handleInfoRemoveClick}
                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                                {contentInfoData.length - 1 === i && (
                                                    <button
                                                        className="btn btn-primary mt-2 ml-2"
                                                        onClick={this.handleInfoAddClick}
                                                    >
                                                        Add
                                                    </button>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                                <button type="submit" className="btn btn-primary ms-auto">
                                    Submit
                                </button>
                            </form>


                        </div>
                    </div>
                </div>




            </>
        );
    }


}


export default withRouter(UpdateContent);