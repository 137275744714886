import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN } from "../../../../config/Config";
import { submitResultRequest } from "../../../../api/AssessmentApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

class StudentAnswer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assessmentResultRequest: {
        email: "",
        score: "",
        result: "",
        testId:"",
      },
      alertIsVisible: true,
      imagePreviewUrl: "",
      user: "",
      showLoading: false,
      student: "",
      assessmentQuestionsMultipleChoiceMap: "",
      assessmentQuestionShortAnswersMap: "",
      userName: "",
      counter: 0,
      textIdx: 0,
      minutesPerQuestion: 0,
      answerList: [{}],
      testId: "",
      showModalUserInfo: false,
    };
    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.updateResult = this.updateResult.bind(this);
    this.toggle = this.toggle.bind(this);
    this.submitResult = this.submitResult.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.updateRetrieveAssessmentData =
      this.updateRetrieveAssessmentData.bind(this);
    this.retrieveProfilePic = this.retrieveProfilePic.bind(this);
    this.backToHome = this.backToHome.bind(this);
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      this.props.showAlert(
        "Your session expired, You must be authenticated to access your online assessments !",
        "error"
      );
      this.props.history.push("/login");
    }
  }
  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 90000);
    });
  };
  componentDidMount() {
    this.updateRetrieveAssessmentData();
    this.onShowAlert();
  }

  updateRetrieveAssessmentData = () => {
    this.setState({ showLoading: true });
    let req = this.state;

    req.student = this.props.location.qaShortAnswersProps
      ? this.props.location.qaShortAnswersProps.student
      : null;
    req.assessmentQuestionShortAnswersMap = req.student
      ? req.student.assessmentQuestionShortAnswersMap
      : null;

    this.setState({ req });
    //alert("qa ans list" + JSON.stringify(req.student));
    // alert(JSON.stringify(this.state));
    /* useEffect(() => {
      function handleContextMenu(e) {
        e.preventDefault(); // prevents the default right-click menu from appearing
      }
      // add the event listener to the component's root element
      const rootElement = document.getElementById('my-component');
      rootElement.addEventListener('contextmenu', handleContextMenu);
      // remove the event listener when the component is unmounted
      return () => {
        rootElement.removeEventListener('contextmenu', handleContextMenu);
      };
    }, []);*/
  };

  onClickSubmitAnswers = () => {
    this.setState({ showLoading: true });
    let req = this.state;
    const assessmentSubmitAnswerRequest = {
      answers: "",
      testId: "",
    };

    assessmentSubmitAnswerRequest.answers = req.answerList.filter(
      (el) => Object.keys(el).length
    );
    assessmentSubmitAnswerRequest.testId = req.testId;

    // alert(JSON.stringify(assessmentSubmitAnswerRequest));
    submitAnswers(assessmentSubmitAnswerRequest)
      .then((res) => {
        this.props.showAlert(res.data.message, "success");
        this.setState({ showLoading: false });
        this.setState({ req: "" });
        this.props.history.push("/me/assessment/assessments");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  backToHome() {
    this.props.history.push("/hr/dashboard/trackAssessments");
  }
  submitResult = (event) => {
    event.preventDefault();
    let req = this.state.assessmentResultRequest;
    req.email = this.state.student.studentMailId;
    req.testId = this.state.student.testId;
    this.setState({ assessmentResultRequest: req });
    this.setState({ showLoading: true });
   // alert(JSON.stringify(this.state.assessmentResultRequest));
    submitResultRequest(this.state.assessmentResultRequest)
      .then((res) => {
        this.props.showAlert(
          "Candidate result updated !",
          "success"
        );
        this.setState({ showLoading: false });
        this.toggle();
        this.backToHome();
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  retrieveProfilePic = () => {
    this.setState({ showLoading: true });
    retrieveProfileImage()
      .then((res) => {
        this.setState({ imagePreviewUrl: res.data });
        this.props.showAlert(
          "Profile image retrieved successfully ",
          "success"
        );
        this.setState({ showLoading: false });
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert("Your profile image does't exist !", "error");
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  handleAnswerList = (e, index) => {
    const { name, value } = e.target;
    const list = this.state.answerList;
    list[index] = value;
    this.setState({ answerList: list });
  };

  // handle click event of the Remove button
  handleAnswerRemoveClick = (index) => {
    const list = this.state.answerList;
    list.splice(index, 1);
    this.setState({ answerList: list });
  };

  // handle click event of the Add button
  handleAnswerAddClick = () => {
    this.setState({ answerList: [...this.state.answerList, {}] });
    this.setState({ formInput: "" });
  };
  toggle() {
    this.setState({
      showModalUserInfo: !this.state.showModalUserInfo,
    });
  }
  updateScore = (event) => {
    let req = this.state.assessmentResultRequest;
    req.score = event.target.value;
    this.setState({ assessmentResultRequest: req });
  };
  updateResult = (event) => {
    let req = this.state.assessmentResultRequest;
    req.result = event.target.value;
    this.setState({ assessmentResultRequest: req });
  };

  render() {
    const {
      questions,
      counter,
      minutesPerQuestion,
      answerList,
      formInput,
      students,
      student,
    } = this.state;
    const { imagePreviewUrl } = this.state;
    if (student) {
      return (
        <>
          <br></br> <br></br>
          <div className="container">
            <div>
              <div class="card shadow border-0 rounded-4 mb-5">
                <div class="card-body p-5">
                  <div class="d-flex align-items-center mb-4">
                    <div class="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                      <i class="bi bi-tools"></i>
                    </div>
                    <h3 class="fw-bolder mb-0">
                      <span class="text-gradient d-inline">
                        {student.studentName}
                      </span>
                      {"                "}
                      <button
                        class="btn btn-primary"
                        onClick={this.backToHome}
                      >
                        
                        Back.
                      </button>
                      {"                "}
                      <button className="btn btn-success" onClick={this.toggle}>
                        Action <FontAwesomeIcon icon={faArrowAltCircleUp} />
                      </button>
                    </h3>
                  </div>
                  <div class="row row-cols-1 row-cols-md-3 mb-4">
                    <div class="col">
                      <div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                        <ul className="ml-4">
                          {student.assessmentShortAnswers.map((item, i) => (
                            <li>
                              {" "}
                              {i + 1}
                              <div class="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                {item}
                              </div>
                              <br></br>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <Modal
            isOpen={this.state.showModalUserInfo}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>
              <div className="align-content-center align-items-center align-self-center text-center">
                {" " +
                  this.state.student.studentName +
                  " { " +
                  this.state.student.studentMailId +
                  " }"}
              </div>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={(e) => this.submitResult(e)}>
                <div>
                  <p className="card-title text-center">
                    Candidate Score Update
                  </p>
                  <div className="mt-2">
                    <label htmlFor="inputScore">Score</label>
                    <input
                      type="text"
                      id="inputScore"
                      className="form-control"
                      placeholder="Score"
                      required
                      onChange={this.updateScore}
                      autoComplete="on"
                    />
                  </div>
                  <div className="mt-2">
                    <label htmlFor="result">Overall Result</label>

                    <select
                      className="custom-select"
                      id="result"
                      placeholder="Select employment status"
                      onChange={this.updateResult}
                    >
                      <option value="Employment Status">
                        Assessment Status
                      </option>
                      <option value="pass">pass</option>
                      <option value="failed">failed</option>
                      <option value="re-take">re-take</option>
                      <option value="in-progress">in-progress</option>
                    </select>
                  </div>

                  <br></br>
                  <button
                    className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                    type="submit"
                  >
                    Update Result
                  </button>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </>
      );
    } else {
      return (
        <>
          <br></br>
          <br></br>
          <div className="container">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <small class="text-dark fw-bolder mb-4">
                There is no one taken up the online assessment yet.
              </small>

              <button
                class="btn btn-primary px-4 py-3"
                onClick={this.backToHome}
              >
                <div class="d-inline-block bi bi-download me-2"></div>
                Back to dashboard.
              </button>
            </div>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
         
          </div>
        </>
      );
    }
  }
}

export default withRouter(StudentAnswer);
