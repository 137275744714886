import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { profileData } from "../../../../../api/commonApi";

class ProfileDataSettings extends Component {
  state = {
    profileOverallSummary: "",
    experience: "",
    experienceData: [
      {
        companyName: "",
        startDate: "",
        endDate: "",
        project: "",
        rolesAndResponsibility: "",
        techStack: "",
        designation: "",
        teamSize: "",
        isCurrentExperience: "",
      },
    ],
    preferredLocation: "anywhere-india",
    additionalComments:"",
    educationData: [
      {
        graduation: "",
        institutionName: "",
        placeOfInstitution: "",
        graduationStartDate: "",
        graduationEndDate: "",
        specialization: "",
        percentage: "",
      },
    ],
    achievementList: [{}],
    certificationList: [{}],
    showLoading: false,
  };

  componentDidMount() {
    document.title = "My account profile data settings";
  }

  updateProfileSummary = (event) => {
    let req = this.state;
    req.profileOverallSummary = event.target.value;
    this.setState({ state: req });
  };
  updateExperience = (event) => {
    let req = this.state;
    req.experience = event.target.value;
    this.setState({ state: req });
  };
  updateAdditionalComments = (event) => {
    let req = this.state;
    req.additionalComments = event.target.value;
    this.setState({ state: req });
  };
  updatePreferred = (event) =>{
    let req = this.state;
    req.preferredLocation = event.target.value;
    this.setState({ state: req });
    }
  //Experience
  // handle click event of the Add button
  handleExperienceAddClick = () => {
    this.setState({
      experienceData: [
        ...this.state.experienceData,
        {
          companyName: "",
          startDate: "",
          endDate: "",
          project: "",
          rolesAndResponsibility: "",
          techStack: "",
          designation: "",
          teamSize: "",
          isCurrentExperience: "",
        },
      ],
    });
  };
  // handle click event of the onchange method
  handleExperienceData = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.experienceData];
    list[index][name] = value;
    this.setState({ experienceData: list });
  };
  // handle click event of the Remove button
  handleExperienceRemoveClick = (index) => {
    const list = [...this.state.experienceData];
    list.splice(index, 1);
    this.setState({ experienceData: list });
  };
  //Education
  // handle click event of the Add button
  handleEducationAddClick = () => {
    this.setState({
      educationData: [
        ...this.state.educationData,
        {
          graduation: "",
          institutionName: "",
          placeOfInstitution: "",
          graduationStartDate: "",
          graduationEndDate: "",
          specialization: "",
          percentage: "",
        },
      ],
    });
  };
  // handle click event of the onchange method
  handleEducationData = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.educationData];
    list[index][name] = value;
    this.setState({ educationData: list });
  };
  // handle click event of the Remove button
  handleEducationRemoveClick = (index) => {
    const list = [...this.state.educationData];
    list.splice(index, 1);
    this.setState({ educationData: list });
  };

  //Certification
  // handle click event of the Add button
  handleCertificationAddClick = () => {
    this.setState({ certificationList: [...this.state.certificationList, {}] });
  };
  // handle click event of the onchange method
  handleCertificationList = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.certificationList];
    list[index][name] = value;
    this.setState({ certificationList: list });
  };
  // handle click event of the Remove button
  handleCertificationRemoveClick = (index) => {
    const list = [...this.state.certificationList];
    list.splice(index, 1);
    this.setState({ certificationList: list });
  };
  //Acheivement
  // handle click event of the Add button
  handleAchievementAddClick = () => {
    this.setState({ achievementList: [...this.state.achievementList, {}] });
  };
  // handle click event of the onchange method
  handleAchievementList = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.achievementList];
    list[index][name] = value;
    this.setState({ achievementList: list });
  };
  // handle click event of the Remove button
  handleAchievementRemoveClick = (index) => {
    const list = [...this.state.achievementList];
    list.splice(index, 1);
    this.setState({ achievementList: list });
  };

  //handle the onsubmit button
  handleOnboardProfile = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    const profile = this.state;
    //alert("Datas" + JSON.stringify(profile));
    profileData(profile)
      .then((response) => {
        const data = response.data;
        this.props.showAlert(data.message, "success");

        this.setState({ showLoading: false });

        this.setState({
          profileOverallSummary: "",
          experience: "",
          experienceData: [
            {
              companyName: "",
              startDate: "",
              endDate: "",
              project: "",
              rolesAndResponsibility: "",
              techStack: "",
              designation: "",
              teamSize: "",
              isCurrentExperience: "",
            },
          ],
          educationPersuing: "",
          educationData: [
            {
              graduation: "",
              institutionName: "",
              placeOfInstitution: "",
              graduationStartDate: "",
              graduationEndDate: "",
              specialization: "",
              percentage: "",
            },
          ],
          achievementList: [{}],
          certificationList: [{}],
        });
        this.props.history.push("/me/settings/profile");
      })
      .catch((error) => {
        this.props.showAlert(error);
        this.setState({ showLoading: false });
      });
  };

  render() {
    const {
      achievementList,
      certificationList,
      educationData,
      experienceData,
    } = this.state;

    return (
      <div>
        <h2>Profile Data Onboard</h2>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Updating your profile data</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                refresh
              </i>
            </div>
          ) : (
            <form onSubmit={this.handleOnboardProfile}>
              <div className="form-group">
                <label htmlFor="profileOverallSummary">Profile Summary</label>
                <textarea
                  type="text"
                  className="form-control"
                  name="profileOverallSummary"
                  placeholder="Enter your Profile Overall Summary"
                  required
                  autoComplete="on"
                  onChange={this.updateProfileSummary}
                />
              </div>
              <div className="form-group">
              <label htmlFor="preferredLocation"> Preferred Location</label>
              <select
                  className="w3-select"
                  name="option"
                  value={this.state.preferredLocation}
                  onChange={(event) => this.updatePreferred(event)}
                >
                  <option value="anywhere-india">anywhere-india</option> 
                  <option value="anywhere-south-india">anywhere-south-india</option>
                  <option value="anywhere-north-india">anywhere-north-india</option>
                  <option value="coimbatore-chennai-bangalore">coimbatore-chennai-bangalore</option>
                  <option value="hybrid-anywhere-india">hybrid-anywhere-india</option>
                  <option value="anywhere-worldwide">anywhere-worldwide</option>
                  <option value="wfh-only">wfh-only</option>
                </select>
                </div>
                <div>
                <label htmlFor="additionalComments">Additional Comments</label>
                <input
                  type="text"
                  className="form-control"
                  name="additionalComments"
                  placeholder="Please provide if any additional info share regarding job location."
                  required
                  autoComplete="on"
                  onChange={this.updateAdditionalComments}
                />
                
              </div>
              <div className="form-group">
                <label htmlFor="experience">Experience</label>
                <input
                  type="number"
                  className="form-control"
                  name="experience"
                  placeholder="Enter Your Month Experience"
                  required
                  autoComplete="on"
                  onChange={this.updateExperience}
                />
              </div>

              <div className="form-group">
                <label htmlFor="experiencedetails">Experience in Details</label>
                {experienceData.map((x, i) => {
                  return (
                    <div>
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="companyName"
                        name="companyName"
                        placeholder="Organisation Name"
                        required
                        autoComplete="on"
                        value={x.companyName}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      <label htmlFor="inputDate">Start Date</label>
                      <input
                        type="date"
                        className="form-control mt-2"
                        id="startDate"
                        name="startDate"
                        placeholder="Start Date"
                        required
                        autoComplete="on"
                        value={x.startDate}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      <label htmlFor="inputDate">End Date</label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="endDate"
                        name="endDate"
                        placeholder="mm/dd/yyy or till date"
                        required
                        autoComplete="on"
                        value={x.endDate}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="project"
                        name="project"
                        placeholder="Project"
                        required
                        autoComplete="on"
                        value={x.project}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="rolesAndResponsibility"
                        name="rolesAndResponsibility"
                        placeholder="Roles and Responsbility"
                        required
                        autoComplete="on"
                        value={x.rolesAndResponsibility}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="techStack"
                        name="techStack"
                        placeholder="Tech Stack"
                        required
                        autoComplete="on"
                        value={x.techStack}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="designation"
                        name="designation"
                        placeholder="Designation"
                        required
                        autoComplete="on"
                        value={x.designation}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="teamSize"
                        name="teamSize"
                        placeholder="Team Size"
                        required
                        autoComplete="on"
                        value={x.teamSize}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="isCurrentExperience"
                        name="isCurrentExperience"
                        placeholder="Is Current Experience"
                        required
                        autoComplete="on"
                        value={x.isCurrentExperience}
                        onChange={(e) => this.handleExperienceData(e, i)}
                      />
                      {experienceData.length !== 1 && (
                        <button
                          className="btn btn-danger mt-2"
                          onClick={this.handleExperienceRemoveClick}
                        >
                          Remove
                        </button>
                      )}
                      {experienceData.length - 1 === i && (
                        <button
                          className="btn btn-primary mt-2 ml-2"
                          onClick={this.handleExperienceAddClick}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="form-group">
                <label htmlFor="educationData">Education Details</label>
                {educationData.map((x, i) => {
                  return (
                    <div>
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="graduation"
                        name="graduation"
                        placeholder="Graduation"
                        required
                        autoComplete="on"
                        value={x.graduation}
                        onChange={(e) => this.handleEducationData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="institutionName"
                        name="institutionName"
                        placeholder="Institution Name"
                        required
                        autoComplete="on"
                        value={x.institutionName}
                        onChange={(e) => this.handleEducationData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="placeOfInstitution"
                        name="placeOfInstitution"
                        placeholder="Place Of Institution"
                        required
                        autoComplete="on"
                        value={x.placeOfInstitution}
                        onChange={(e) => this.handleEducationData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="specialization"
                        name="specialization"
                        placeholder="specialization"
                        required
                        autoComplete="on"
                        value={x.specialization}
                        onChange={(e) => this.handleEducationData(e, i)}
                      />
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="percentage"
                        name="percentage"
                        placeholder="Percentage"
                        required
                        autoComplete="on"
                        value={x.percentage}
                        onChange={(e) => this.handleEducationData(e, i)}
                      />
                      <label htmlFor="graduationStartDate">
                        Graduation Start date
                      </label>
                      <input
                        type="date"
                        className="form-control mt-2"
                        id="graduationStartDate"
                        name="graduationStartDate"
                        placeholder="Graduation Start date"
                        required
                        autoComplete="on"
                        value={x.graduationStartDate}
                        onChange={(e) => this.handleEducationData(e, i)}
                      />{" "}
                      <label htmlFor="graduationStartDate">
                        Graduation End date
                      </label>
                      <input
                        type="date"
                        className="form-control mt-2"
                        id="graduationEndDate"
                        name="graduationEndDate"
                        placeholder="Graduation End date"
                        required
                        autoComplete="on"
                        value={x.graduationEndDate}
                        onChange={(e) => this.handleEducationData(e, i)}
                      />
                      {educationData.length !== 1 && (
                        <button
                          className="btn btn-danger mt-2"
                          onClick={this.handleEducationRemoveClick}
                        >
                          Remove
                        </button>
                      )}
                      {educationData.length - 1 === i && (
                        <button
                          className="btn btn-primary mt-2 ml-2"
                          onClick={this.handleEducationAddClick}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="form-group">
                <label htmlFor="achievements">Achievements</label>
                {achievementList.map((x, i) => {
                  return (
                    <div>
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="achievement"
                        name="achievement"
                        placeholder="Achievement"
                        required
                        autoComplete="on"
                        value={x.achievement}
                        onChange={(e) => this.handleAchievementList(e, i)}
                      />
                      {achievementList.length !== 1 && (
                        <button
                          className="btn btn-danger mt-2"
                          onClick={this.handleAchievementRemoveClick}
                        >
                          Remove
                        </button>
                      )}
                      {achievementList.length - 1 === i && (
                        <button
                          className="btn btn-primary mt-2 ml-2"
                          onClick={this.handleAchievementAddClick}
                        >
                          add
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="form-group">
                <label htmlFor="certifications">Certifications</label>
                {certificationList.map((x, i) => {
                  return (
                    <div>
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="certification"
                        name="certification"
                        placeholder="Certification"
                        required
                        autoComplete="on"
                        value={x.certification}
                        onChange={(e) => this.handleCertificationList(e, i)}
                      />
                      {certificationList.length !== 1 && (
                        <button
                          className="btn btn-danger mt-2"
                          onClick={this.handleCertificationRemoveClick}
                        >
                          Remove
                        </button>
                      )}
                      {certificationList.length - 1 === i && (
                        <button
                          className="btn btn-primary mt-2 ml-2"
                          onClick={this.handleCertificationAddClick}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>

              <button className="btn btn-primary ms-auto">Submit</button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ProfileDataSettings);
