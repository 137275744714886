import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { timeSheetUpdateRequest } from "../../../../../api/HrApi";

import { ACCESS_TOKEN } from "../../../../../config/Config";

class TimeSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSheetRequest: {
        date: "",
        hoursWorked: "",
      },
      user: props.user,
    };
    this.timeUpdate = this.timeUpdate.bind(this);
  }

  componentDidMount() {
    document.title = "Apply Leaves";
    if (localStorage.getItem(ACCESS_TOKEN)) {
      //DECODE TOKEN
      const token = localStorage.getItem(ACCESS_TOKEN);
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      let decodedToken = JSON.parse(window.atob(base64));
      this.setState({ user: decodedToken });
    }
  }

  updateDateWorked = (event) => {
    let req = this.state.timeSheetRequest;
    req.date = event.target.value;
    this.setState({ timeSheetRequest: req });
  };

  updateHoursWorked = (event) => {
    let req = this.state.timeSheetRequest;
    req.hoursWorked = event.target.value;
    this.setState({ timeSheetRequest: req });
  };

  timeUpdate = (event) => {
    event.preventDefault();
    let req = this.state.timeSheetRequest;
    req.email = this.state.user.email;
    this.setState({ timeSheetRequest: req });
    this.setState({ showLoading: true });

    timeSheetUpdateRequest(this.state.timeSheetRequest)
      .then((res) => {
        this.props.showAlert(
          "Employee timeSheet request has been accepted !" +
            this.state.user.email,
          "success"
        );
        this.setState({ showLoading: false });
        this.props.history.push("/employee/dashboard/profile");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  render() {
    return (
      <div>
        <h1>Clock your Time Every day</h1>

        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Time sheet update...</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                refresh
              </i>
            </div>
          ) : (
            <form onSubmit={(e) => this.timeUpdate(e)}>
              <div>
                <p className="card-title text-center">Time sheet</p>

                <div className="mt-2">
                  <label htmlFor="inputWorkedDate">Date</label>
                  <input
                    type="Date"
                    id="inputWorkedDate"
                    className="form-control"
                    placeholder="Date worked"
                    required
                    onChange={this.updateDateWorked}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-2">
                  <label htmlFor="hoursWorked">Hours worked</label>
                  <input
                    type="number"
                    id="hoursWorked"
                    className="form-control"
                    placeholder="hours worked (Ex: 8 or 0)"
                    required
                    onChange={this.updateHoursWorked}
                    autoComplete="on"
                  />
                </div>

                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          )}
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossorigin="anonymous"
        ></script>
      </div>
    );
  }
}

export default withRouter(TimeSheet);
