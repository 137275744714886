import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Houston from "../../assets/images/404-1.jpeg";

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.backToHome = this.backToHome.bind(this);
  }

  componentDidMount() {
    document.title = "404 - Page not found";
  }

  backToHome() {
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="container">
        <br></br>
        <h1>
          <b className="text-muted">404</b> Hello, we've had a problem ...
        </h1>
        <hr />
        <div className="row">
          <div className="col-6">
            <img
              src={Houston}
              alt="Houston, we've had a problem !"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-6">
            <p>
              "<b>Hello, we have a problem</b>" We are sorry, the page you
              requested could not be found.{" "}
            </p>
            <p>
              Please go back to the homepage or contact us at
              business@yaazhtech.com.
            </p>
            <button className="btn btn-dark" onClick={this.backToHome}>
              Back to home
            </button>
          </div>
        </div>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
          crossorigin="anonymous"
        ></script>
      </div>
    );
  }
}

export default withRouter(NotFound);
