import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { accountData } from "../../../../../api/commonApi";


class AccountDataSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountDetails: {
                accountNo: "",
                ifscCode: "",
                bankName: "",
                upiId: ""
            },
            showLoading: false,

        };


    }

    componentDidMount() {
        document.title = "My account data settings"
    }


    accountNo = (event) => {
        let req = this.state.accountDetails;
        req.accountNo = event.target.value;
        this.setState({ accountDetails: req })

    }
    ifscCode = (event) => {

        let req = this.state.accountDetails;
        req.ifscCode = event.target.value;
        this.setState({ accountDetails: req })

    }
    bankName = (event) => {
        let req = this.state.accountDetails;
        req.bankName = event.target.value;
        this.setState({ accountDetails: req })

    }
    upiId = (event) => {
        let req = this.state.accountDetails;
        req.upiId = event.target.value;
        this.setState({ accountDetails: req })

    }
    accountDataOnboard = (event) => {
        event.preventDefault();
        this.setState({ showLoading: true });
        const account = this.state.accountDetails;
        //alert("account details" + JSON.stringify(account));
        accountData(account)
            .then(response => {
                const data = response.data
                this.props.showAlert(data.message, "success");
                this.setState({ showLoading: false });

                this.setState({
                    accountNo: '', ifscCode: '', bankName: '',
                    upiId: ''
                })

            })
            .catch(error => {
                this.props.showAlert(error,"error");
                this.setState({ showLoading: false });
            })

    };


    render() {
        return (
            <div>
                
                <h2>Account Data Form</h2>
                <hr />
                <div>
                    {
                        this.state.showLoading
                            ?
                            <div className="align-content-center text-center">
                                <h3>Updating your account data</h3>
                                <i className="material-icons w3-xxxlarge w3-spin align-content-center">refresh</i>
                            </div>
                            :
                            <form onSubmit={this.accountDataOnboard}>

                                <div className="form-group">
                                    <label htmlFor="accountNo">Bank Account No</label>
                                    <input type="text" className="form-control" id="accountNo" name='accountNo'
                                        placeholder="Enter your Account No" required autoComplete="on" onChange={this.accountNo}

                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="ifscCode">IFSC code</label>
                                    <input type="text" className="form-control" id="ifscCode" name='ifscCode'
                                        placeholder="Enter your IFSC code" required autoComplete="on" onChange={this.ifscCode}

                                    />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="bankName">Bank Name</label>
                                    <input type="text" className="form-control" id="bankName" name='bankName'
                                        placeholder="Enter your Bank Name" required autoComplete="on" onChange={this.bankName}

                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="upiId">UPI Id</label>
                                    <input type="text" className="form-control" id="upiId" name='upiId'
                                        placeholder="Enter your UPI id" required autoComplete="on" onChange={this.upiId}

                                    />
                                </div>
                                <button type="submit" className="btn btn-primary ms-auto">Next</button>
                            </form>
                    }

                </div>

            </div>
        );
    }
}

export default withRouter(AccountDataSettings);