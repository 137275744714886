import React, {Component} from 'react';
import EditContentGridLine from './EditContentGridLine';
//import ApplicantsGridLine from "./ApplicantsGridLine";

class EditContentGrid extends Component {
    
    constructor(props){
        super(props);
        console.log(this.props.publishContent);
        this.state = {
            content: props.publishContent
        }
    }
    

    static getDerivedStateFromProps(props, state) {
        state = props.publishContent;
        return state;
    }

    render()
    {

        const {content} = this.state;
        //alert("datasdsfsd"+JSON.stringify(content));

        let gridLines = [];

        if(this.state.content && this.state.content.length > 0){
            gridLines = Object.keys(this.state.content)
                .map(key => <EditContentGridLine showAlert={this.props.showAlert} key={key} categoryPoint={this.state.content[key]}/>);
        }

        return(
            <div>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">category</th>
                        <th scope="col">publishedBy</th>
                        <th scope="col">endpoint</th>
                        <th scope="col">update</th>
                        <th scope="col">delete</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                        this.state.content.length > 0
                        ?
                            gridLines
                        :
                            <div className="alert alert-danger" role="alert">
                                <strong>Oops !</strong> Nothing to show here...
                            </div>
                    }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default EditContentGrid;