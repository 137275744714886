import React, { Component } from "react";
import ClientDataGridLine from "./ClientDataGridLine";

class ClientDataGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      clientData: props.content,
    };
  }

  static getDerivedStateFromProps(props, state) {
    //alert("props contect"+JSON.stringify(props.content));
    state = props.content;
    return state;
  }

  render() {
    const { clientData } = this.state;

    let showClientData = [];

    if (true) {
      showClientData = Object.keys(this.state.clientData).map((key) => (
        <ClientDataGridLine
          showAlert={this.props.showAlert}
          key={key}
          showClientData={this.state.clientData[key]}
        />
      ));
    }

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <tr className="overflow-x-auto">
              <th scope="col">Name</th>
              <th scope="col">UserName</th>
              <th scope="col">Contact No</th>
              <th scope="col">Status</th>
              <th scope="col">No of Subscriptions</th>
              <th scope="col">View Client</th>
              {/*<th scope="col">Payment Invoice</th>*/}
              <th scope="col">Subscribed From</th>
            </tr>
          </thead>
          <tbody>
            {true ? (
              showClientData
            ) : (
              <div className="alert alert-danger" role="alert">
                <strong>Oops !</strong> Nothing to show here...
              </div>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ClientDataGrid;
