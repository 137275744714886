import React, { Component } from 'react';
import { Link, Router, withRouter } from 'react-router-dom';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from 'reactstrap';
import kannaiyan from "../../../assets/images/img/team/kannaiyanPic.jpg";
import venkat from "../../../assets/images/img/team/venkat.jpg";
import priya from "../../../assets/images/img/team/gokulapriya.jpg";
import dipthi from "../../../assets/images/img/team/Dipthigadekar.jpg";
import divya from "../../../assets/images/img/team/DivyaPaiyannan.jpg";
import esther from "../../../assets/images/img/team/estherjenita.jpg";
import gokulan from "../../../assets/images/img/team/Gokulan.jpg";
import lalitha from "../../../assets/images/img/team/lalithambigai.jpg";
import meiyarasan from "../../../assets/images/img/team/Meiyarasan.jpg";
import pavithra from "../../../assets/images/img/team/Pavithra.jpg";
//import pramodh from "../../../assets/images/img/team/pramodh.jpg";
import rajappan from "../../../assets/images/img/team/RajappanSellamuthu.jpg";
import suneel from "../../../assets/images/img/team/Suneelmunthala.jpg";
import varshasri from "../../../assets/images/img/team/varshasri.jpg";
import maheshkumar from "../../../assets/images/img/team/MaheshKumar.jpg";
import venu from "../../../assets/images/img/team/venu.jpg";
import ezhil from "../../../assets/images/img/team/EzhilArasi.R.jpeg";
import hemalatha from "../../../assets/images/img/team/Hemalatha.jpeg";
import rupavarshini from "../../../assets/images/img/team/Rupavarshini.jpg";
import annalakshmi from "../../../assets/images/img/team/Annalakshmi.jpg";

const items = [
    {
        header: <><div class="row">
            
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={venkat} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Venkatesan Vadivel</h4>
                    <p class="text-muted">Technical Architect</p>
                    <ul class="skills  mx-auto">
                        <li>Javascript</li>
                        <li>React Js</li>
                        <li>Springboot-Java</li>
                        <li>FlexPLM</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={ezhil} alt="..." style={{ maxWidth: 256 }} />
                    <h4>EzhilArasi R</h4>
                    <p class="text-muted">Senior consultant</p>
                    <ul class="skills  mx-auto">
                        <li>JavaScript</li>
                        <li>React Js</li>
                        <li>FlexPLM</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>
        </div></>,
        key: 1,
    },

    {
        header: <div class="row">
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={divya} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Divya Paiyannan</h4>
                    <p class="text-muted"> Senior consultant</p>
                    <ul class="skills  mx-auto">
                        <li>JavaScript</li>
                        <li>React Js</li>
                        <li>FlexPLM</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={rajappan} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Rajappan S</h4>
                    <p class="text-muted"> Senior consultant</p>
                    <ul class="skills  mx-auto">
                        <li>JavaScript</li>
                        <li>React Js</li>
                        <li>FlexPLM</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>

        </div>,
        key: 2,
    },
    {
        header: <div class="row">
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={pavithra} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Pavithra</h4>
                    <p class="text-muted">Consultant</p>
                    <ul class="skills  mx-auto">
                        <li>JavaScript</li>
                        <li>React Js</li>
                        <li>FlexPLM</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>

                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={hemalatha} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Hemalatha</h4>
                    <p class="text-muted">Consultant</p>
                    <ul class="skills  mx-auto">
                        <li>JavaScript</li>
                        <li>React Js</li>
                        <li>FlexPLM</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>
        </div>,

        key: 3,
    },
    {
        header: <div class="row">
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={varshasri} alt="..." style={{ maxWidth: 256 }} />
                    <h4>varshasri</h4>
                    <p class="text-muted">Tech Lead L1</p>
                    <ul class="skills  mx-auto">
                        <li>Springboot-Java</li>
                        <li>FlexPLM</li>
                        <li>Postman</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={esther} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Esther Jenita</h4>
                    <p class="text-muted">Tech Lead L1</p>
                    <ul class="skills  mx-auto">
                        <li>Springboot-Java</li>
                        <li>FlexPLM</li>
                        <li>Postman</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>

        </div>,
        key: 4,
    },
    {
        header: <div class="row">
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={gokulan} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Gokulan</h4>
                    <p class="text-muted">Consultant</p>
                    <ul class="skills  mx-auto">
                        <li>Springboot-Java</li>
                        <li>FlexPLM</li>
                        <li>Postman</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>
           

        </div>,
        key: 5,
    },
    {
        header: <div class="row">
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={suneel} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Suneel Munthala</h4>
                    <p class="text-muted">  Senior consultant</p>
                    <ul class="skills  mx-auto">
                        <li>Springboot-Java</li>
                        <li>FlexPLM</li>
                        <li>Postman</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={venu} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Venu Regula</h4>
                    <p class="text-muted">Consultant</p>
                    <ul class="skills  mx-auto">
                        <li>Springboot-Java</li>
                        <li>FlexPLM</li>
                        <li>Postman</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>

        </div>,
        key: 6,
    },
    {
        header: <div class="row">
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={maheshkumar} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Maheshkumar</h4>
                    <p class="text-muted">Senior consultant</p>
                    <ul class="skills  mx-auto">
                        <li>Springboot-Java</li>
                        <li>FlexPLM</li>
                        <li>Postman</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src={rupavarshini} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Rupavarshini</h4>
                    <p class="text-muted">Tech Lead L1</p>
                    <ul class="skills  mx-auto">
                        <li>Springboot-Java</li>
                        <li>FlexPLM</li>
                        <li>Postman</li>
                        <li>DynamoDB</li>
                        <li>AWS EC2</li>
                    </ul>
                </div>
            </div>

        </div>,
        key: 7,
    },
    {
        header: <div class="row">
            <div class="col-lg-6">
            <div class="team-member">
                    <img class="mx-auto rounded-circle" src={annalakshmi} alt="..." style={{ maxWidth: 256 }} />
                    <h4>Annalakshmi</h4>
                    <p class="text-muted">Tech Lead L1</p>
                    <ul class="skills  mx-auto">
                        <li>Postman</li>
                        <li>AWS EC2</li>
                        <li>FlexPLM</li>
                        <li>Jira</li>
                        <li>Jenkins</li>
                        <li>Github</li>
                    </ul>
                </div>

            </div>
            <div class="col-lg-6">
                
            </div>

        </div>,
        key: 8,
    },
]

export {
    items,
};







class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExited = this.onExited.bind(this);
        this.onExiting = this.onExiting.bind(this);
        //this.ProductList1 =this.ProductList1.bind(this);
    }
    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex })
    }
    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex })
    }
    goToIndex = (newIndex) => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    };
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }
    // ProductList1() {
    // this.props.history.push(<ProductList/>);
    // }


    render() {
        const { activeIndex } = this.state

        const slides = items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.key}

                >
                    <br></br><br></br><br></br>{item.header}

                    <CarouselCaption
                    //captionText={item.altText}
                    //captionHeader={item.caption}
                    // className="btn btn-primary"

                    />

                </CarouselItem>
            );
        });
        return (
            <>
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}

                    {...this.props}
                >
                    <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={this.goToIndex}

                    />
                    {slides}
                    <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={this.previous}



                    />
                    <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={this.next}


                    />
                </Carousel>
            </>
        );


    }
}
export default withRouter(Slider);