import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { documentGeneration } from "../../../../api/HrApi";

class RequestForm extends Component {
  state = {
    letterRequest: {
      header: "",
      subHeader: "",
      documentBody: "",
    },
    showLoading: false,
    
  };

  componentDidMount() {
    document.title = "Letter Generation";
  }

  updateHeader = (event) => {
    let req = this.state.letterRequest;
    req.header = event.target.value;
    this.setState({ letterRequest: req });
  };
  updateSubHeader = (event) => {
    let req = this.state.letterRequest;
    req.subHeader = event.target.value;
    this.setState({ letterRequest: req });
  };
  updateDocumentBody = (event) => {
    let req = this.state.letterRequest;
    req.documentBody = event.target.value;
    this.setState({ letterRequest: req });
  };
  documentGeneration= (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
  
    documentGeneration(this.state.letterRequest)
      .then((res) => {
        //alert("res"+res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        //alert("url"+url);
        const link = document.createElement('a');
        //alert("link"+link);
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();

    
        this.props.showAlert(
          "Letter generated successfully !",
          "success"
        );
        this.setState({ showLoading: false });
        this.setState({letterRequest:''})
       this.props.history.push("/");
      this.props.history.push("hr/dashboard/letterHead");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };


  render() {
    return (
      <div>
        <h1>Create request</h1>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Document generation initiated</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                in progress
              </i>
            </div>
          ) : (
            <form onSubmit={(e) => this.documentGeneration(e)}>
              <div>
                <p className="card-title text-center">Request creation</p>
                <div className="mt-2">
                  <label htmlFor="inputHeader">Subject</label>
                  <input
                    type="text"
                    id="inputHeader"
                    className="form-control"
                    placeholder="Subject"
                    required
                    onChange={this.updateHeader}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="inputSubHeader">Request Info</label>
                  <input
                    type="text"
                    id="inputSubHeader"
                    className="form-control"
                    placeholder="Request info"
                    required
                    onChange={this.updateSubHeader}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="inputDocumentBody">Additional Info</label>
                  <textarea
                    type="text"
                    id="inputDocumentBody"
                    className="form-control"
                    placeholder="Additional Info"
                    required
                    onChange={this.updateDocumentBody}
                    autoComplete="on"
                  />
                </div>
                
                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(RequestForm);
