import React, { Component } from "react";
import { withRouter,NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { generateInvoice } from "../../../../api/commonApi";


class PaymentGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: props.subscription,
      invoiceDownloadRequest: {
        paymentId : props.showPaymentData.paymentId,
        subscriptionId : props.showPaymentData.subscriptionId,
        emailId:props.showPaymentData.userName,
        },
      
    };
   
  }

  static getDerivedStateFromProps(props, state) {
   
  const subscription = props.subscription;
  state.subscription = subscription;

  const dueDate = props.showPaymentData.dueDate;
  state.dueDate = dueDate
  const subscriberName = props.showPaymentData.subscriberName;
  state.subscriberName = subscriberName

  const subscriptionF = props.showPaymentData.subscriptionFor;
  state.subscriptionF = subscriptionF

  const subscriptionType = props.showPaymentData.subscriptionType;
  state.subscriptionType = subscriptionType
  const paymentStatus = props.showPaymentData.paymentStatus;
  state.paymentStatus = paymentStatus
  
  const subscriptionCost = props.showPaymentData.subscriptionCost;
  state.subscriptionCost = subscriptionCost
  
  const subscriptionId = props.showPaymentData.subscriptionId;
  state.subscriptionId = subscriptionId

  const costIncludingGstPay = props.showPaymentData.costIncludingGstPay;
  state.costIncludingGstPay = costIncludingGstPay

  const costExcludingGstPay = props.showPaymentData.costExcludingGstPay;
  state.costExcludingGstPay = costExcludingGstPay
  const paymentId = props.showPaymentData.paymentId;
  state.paymentId = paymentId
  const emailId=props.showPaymentData.userName;
  state.emailId=emailId;
  //alert("props content check !!!!"+JSON.stringify(subscriptionF));
   //alert("showData!!!!!"+showData);
   //alert("paymentInfo!!!!!"+paymentInfo);
    return state;
  }

 
  invoiceGenerator = (event) => {
    generateInvoice(this.state.invoiceDownloadRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        //response.headers.get("Content-Type")
        link.download = this.state.invoiceDownloadRequest.paymentId+".pdf";

        link.click();
        this.props.showAlert(
          "Your invoice is downloaded successfully. ",
          "success"
        );
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

 
  
  render() {
    const { subscriptionF, subscriberName,subscriptionCost,subscriptionId,subscriptionType,paymentStatus,dueDate,emailId,costExcludingGstPay,costIncludingGstPay,paymentId} = this.state;
    //const {subscriptionFor} = this.state.subscriptionF;

    return (
      <tr>
         
         <th scope="row" style={{width: "25%"}}><small>{subscriptionId+"Pay"+paymentId}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{subscriptionType}</small></th>  
        <th scope="row" style={{width: "25%"}}><small>{subscriptionF}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{subscriberName}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{subscriptionCost}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{paymentStatus}</small></th>
        <th scope="row"  style={{width: "25%"}}>
          <NavLink
            to={{
              pathname: "/client/payment/banking",
              paymentProps: {
                payAmount: costIncludingGstPay,
                paySubscriptionType: subscriptionF,
                payUsername: subscriberName,
                payCost: costExcludingGstPay,
                subscriptionId:subscriptionId,
                paymentId:paymentId,
                emailId:emailId
              },
            }}
            exact
          >
            <button type="button" class="btn btn-light">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          </NavLink>
        </th>
        <th scope="row" style={{width: "25%"}} ><small>
          <button type="button" class="btn btn-light" onClick={(e) => this.invoiceGenerator(e)}>
              <FontAwesomeIcon icon={faDownload} />
            </button></small></th>
        <th scope="row" style={{width: "25%"}} ><small>{dueDate}</small></th>
      </tr>
    );
  }
}

export default withRouter(PaymentGridLine);
