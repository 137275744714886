import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getProfilesPage } from "../../../../../api/ClientApi";
import IamGrid from "./IamGrid";
import AppPagination from "../../../../../generic/pagination/AppPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class Iam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      page: {},
      currentPage: 0,
      totalNumberOfElements: 0,
      pageSize: 0,
      loading: true,
      searchText: null,
    
    };

    this.changeCurrentPage = this.changeCurrentPage.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setStatePromise = this.setStatePromise.bind(this);
    this.updateSearchText=this.updateSearchText.bind(this);
  }

  componentDidMount() {
    document.title = "Iam management";
    this.loadData(0, this.state.searchText);
  }

  componentDidUpdate() {
    this.render();
  }

  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  changeCurrentPage(currentPage) {
    this.setState({
      loading: true,
      currentPage,
    });
  }
  updateSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };

  loadData(page) {
    this.setState({ loading: true });
    getProfilesPage(page,this.state.searchText,"interview-Iam")
      .then((res) => {
        let content = [];
        let user = {
          avatar: {},
          username: "",
          email: "",
          accountCreationDate: "",
          roles: [],
          usingTwoFA: false,
          experienceInMonths:'',
        };

        for (let i = 0; i < res.data.content.length; i++) {
          if(res.data.content[i].length!==0){
            user=res.data.content[i];
            user.experienceInMonths=user.employment.profileDetails?(user.employment.profileDetails.experienceInMonths!==null?user.employment.profileDetails.experienceInMonths:0):'0';
            
          }
        
          content.push(user);
        }
        content.sort((a,b) => (b > a.experienceInMonths) ? -1 : ((a.experienceInMonths > b.experienceInMonths) ? 1 : 0))
       
        this.setStatePromise({
          content: content,
          page: res.data,
          currentPage: res.data.pageNumber,
          totalNumberOfElements: res.data.totalNumberOfElements,
          pageSize: res.data.pageSize,
          loading: false,
        }).then(() => {
          this.forceUpdate();
        }); this.setState({searchText:null});
      })
      .catch((error) => {
        if (error.message) {
          this.props.showAlert(error.message, "error");
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ loading: false });
        console.log(error);
      });
      this.setState({ searchText: "" });
  }

  render() {
    const {
      content,
      pageSize,
      currentPage,
      totalNumberOfElements,
      loading,
      page,
    } = this.state;

    if (loading) {
      return (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading. Please Wait...</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">
            refresh
          </i>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            <h1>
              Iam {" "}
              <span className="text-muted" style={{ fontSize: 11 }}>
                developers
              </span>
            </h1>
            <span>
              <form onSubmit={(e) => this.loadData(e)}>
                <div>
                  <input
                    type="text"
                    id="inputSearchText"
                    className="form-search"
                    placeholder="Search here"
                    onChange={this.updateSearchText}
                    autoComplete="on"
                  />
                  <button type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </form>
            </span>
          </p>
          <hr />

          <IamGrid showAlert={this.props.showAlert} content={content} />
          <AppPagination
            showAlert={this.props.showAlert}
            page={page}
            pageSize={pageSize}
            currentPage={currentPage}
            totalNumberOfElements={totalNumberOfElements}
            handleChangePage={this.loadData}
          />
        </div>
      );
    }
  }
}

export default withRouter(Iam)
