import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class MaternityLeaves extends Component {
  render() {
    return (
      <>
        <h3 className={"pages-mainheader-text"}> Maternity Leaves </h3>
        <label></label>
        <p className={"pages-content-text"}>
          <strong>
            To provide leaves to Female Employees at the time of pregnancy and
            after child birth.
          </strong>
        </p>

        <label></label>
        <p>
          <li className={"pages-content-text"}>
            The company's Paternity leave policy for women as per norms of
            MATERNITY BENEFIT ACT, 1961 enacted by Central Govt of India.
          </li>
          <li className={"pages-content-text"}>
            Availing of paternity leave should be discussed with manager in
            advance.
          </li>
        </p>
      </>
    );
  }
}
export default withRouter(MaternityLeaves);
