import { useState, useEffect, useRef } from "react";

const TimeCounter = (props) => {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00:00");
  const [counter, setCounter] = useState(0);
  const [timeCounter, setTimeCounter] = useState(Math.ceil(props.minutesPerQuestion) * 60);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
      setTimeCounter((prevTimeCounter) => prevTimeCounter + 1);
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00:00");
    setTimeCounter(0);
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
      //setCounter((prevCounter) => prevCounter + 1);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + Math.ceil(props.minutesPerQuestion) * 60);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
    setCounter((prevCounter) => prevCounter + 1);
    props.handleAnswerAddClick();
  };
  const onClickStart = () => {
    clearTimer(getDeadTime());
  };

  return (
    <>
      <br></br>
      <br></br>
      <div className="container">
        <h1>Welcome to online assessment.</h1>
        <small>
          Total duration of the test.
          <small className="text-muted">
            {Math.ceil(props.minutesPerQuestion) * props.noOfQuestions} mins
          </small>{" "}
          <br></br> Total no of Questions:{" "}
          <small className="text-muted"> {props.noOfQuestions}</small>{" "}
        </small>
        <div class="bg-light p-4 rounded-4">
          <div class="text-primary fw-bolder mb-3">
            <small className="text-muted">Timer: {timer}</small>
            <p>
              <span>
                {counter > 0 && counter <= props.noOfQuestions ? (
                  <form>
                    <div className="form-group">
                      {
                        <div>
                          <label htmlFor="profileOverallSummary">
                            {props.questions[counter - 1]}
                          </label>
                          <textarea
                            onPaste={(e) => {
                              e.preventDefault();
                              props.showAlert(
                                "Paste is restricted, if you do this you may fail in this assessment.",
                                "error"
                              );
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              props.showAlert(
                                "Copy is restricted, if you do this you may fail in this assessment.",
                                "error"
                              );
                              return false;
                            }}
                            type="text"
                            className="form-control mt-2"
                            id="answer"
                            placeholder="Please type your answer here."
                            required
                            autoComplete="on"
                            value={props.answerList[counter]}
                            onChange={(e) => props.handleAnswerList(e, counter,props.questions[counter - 1])}
                          />
                          <p className="text-muted">
                            <small>
                              *Your answers will be auto saved and next question
                              will appear immediately when minutes per question
                              time expired.{" "}
                            </small>
                            <br></br>
                            <small className="text-muted">
                              *Please start type your answer and wait until time
                              to expire to see next question.
                            </small>
                            <br></br>
                            <small className="text-muted">
                              *Your moves are tracked if you change the screen
                              you may get disqualified and it may stopped
                              working.
                            </small>
                          </p>
                        </div>
                      }
                      {timeCounter === Math.ceil(props.minutesPerQuestion) * 60
                        ? onClickReset()
                        : null}
                    </div>
                  </form>
                ) : counter === 0 ? (
                  <>
                    {" "}
                    <small className="text-muted">
                      Click start to begin the online test.
                    </small>
                    <button onClick={onClickReset}>Start </button>
                    {" "} or {" "}
                    <button onClick={props.backToHome}>
                      Back.
                    </button>
                    <p className="text-muted">
                      <h7>Tips:</h7>
                      <br></br>
                      <small>
                        The online coding/theory/communication test itself is{" "}
                        {props.noOfQuestions * Math.ceil(props.minutesPerQuestion)}{" "}
                        minutes.
                      </small>
                      <br></br>
                      <small>
                        {" "}
                        *You cannot stop a section once you’ve begun, so I
                        encourage you to be in a quiet spot ahead of time where
                        you can focus without distraction.
                      </small>
                      <br></br>
                      <small>
                        *If you're not able to complete the assessment by the
                        due date, just let our hr know and we will extend the
                        link for you.
                      </small>
                    </p>
                    <p className="text-muted">
                      <h7>Note:</h7>
                      <br></br>
                      <small className="text-muted">
                        You can use any of the following languages you’re
                        comfortable with: C#, C, C++, Java, Python, Ruby, or
                        JavaScript.
                      </small>
                    </p>
                  </>
                ) : (
                  <>
                    <small>
                      Thanks your time up and to save your answers please click
                      submit.
                    </small>
                    <button onClick={props.onClickSubmitAnswers}>
                      Submit{" "}
                    </button>
                  </>
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeCounter;
