import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

import DeleteContent from "./DeleteContent";

class EditContentGridLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalUserInfo: false,
      showModalActionInfo: false,
      categoryPoint: props.categoryPoint,
      endPointList: props.categoryPoint.contentStateList,
      endpoint: "",
      showLoading: false,       
    };
   
    this.toggle = this.toggle.bind(this);
    this.action = this.action.bind(this);
  }
  

  static getDerivedStateFromProps(props, state) {
    const categoryPoint = props.categoryPoint;
    const endpointList = props.categoryPoint.contentStateList;
    state.categoryPoint = categoryPoint;
    state.endPointList = endpointList;
    //alert("jSON"+JSON.stringify(categoryPoint));
    return state;

  }
  
  toggle() {
    this.setState({
      showModalUserInfo: !this.state.showModalUserInfo,
    });
  }
  action() {
    this.setState({
      showModalActionInfo: !this.state.showModalActionInfo,
    });
  }
  endpoint = (event) => {
    this.setState({ endpoint: event.target.value });
  };
  
  deleteEndpoints = (event) => {
    event.preventDefault();
    this.setState({ showloading: false});
    const removeEndPoint = this.state.endpoint;
   // alert("data"+JSON.stringify(removeEndPoint));
   {/* deleteEndPoint(removeEndPoint)
        .then((response) => {
          const data = response.data;
          this.props.showAlert(data.message, "success");
          this.setState({ showLoading: false });
  
          this.setState({
            endpoint: '',
          });
        })
        .catch((error) => {
          this.props.showAlert(error, "error");
          this.setState({ showLoading: false });
        });
      */}
  }
 


  render() {
    const { categoryPoint, endPointList } = this.state;
    //alert("datacoming" + JSON.stringify(categoryPoint))
    //alert("datareceived" + JSON.stringify(endPointList))
    return (
      <>

        {
          endPointList.map((item, i) => (
            <tr>
              <th>
                {categoryPoint.category}
              </th>
              <th>
                {item.publishedBy}
              </th>
              <th>
                {item.endpoint}
              </th>
              <th>
              <NavLink
            to={{
              pathname: "/publish/editContent/updateContent",
              viewContentProps: {
                contentEndPoint: item.endpoint,
                category:categoryPoint.category,
              },
            }}
            exact
          >
            <button type="button" class="btn btn-light">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          </NavLink>
               
              </th>
              
              <th>
              <DeleteContent showAlert={this.props.showAlert} item={item.endpoint} category={categoryPoint.category}/>
          
        </th> 
            
            </tr>
              



          )
          )
        }
       


      </>


    );
  }
}

export default withRouter(EditContentGridLine);
