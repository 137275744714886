import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Alert } from "reactstrap";
import Applicants from "./applicants/Applicants";
import External from "./external/External";
import Intern from "./intern/Intern";
import Probation from "./probation/Probation";
import LetterHead from "./letterHead/LetterHead";
import Internal from "./internal/Internal";
import Clients from "./clients/Clients";
import Employee from "./employee/Employee";
import TrackAssessments from "./trackAssessments/TrackAssessments";
import Hire from "./clients/hiredeveloper/Hire";
import WebApp from "./clients/webapp/WebApp";
import Training from "./clients/training/Training";
import Project from "./clients/projectdevelopment/Project";

import EditContent from "../../user/publish/editcontent/EditContent";

class HrDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
    };

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  componentDidMount() {
    document.title = "Hr dashboard";
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 5000);
    });
  };
  render() {
    let render;
    let menuItem;
    if (this.props.match.params.render === "applicants") {
      render = <Applicants showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link active">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
       
       
      ];
    } else if (this.props.match.params.render === "probation") {
      render = <Probation showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link active">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link">
            letterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
       
      ];
    } else if (this.props.match.params.render === "intern") {
      render = <Intern showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link active">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
      
      ];
    } else if (this.props.match.params.render === "external") {
      render = <External showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link active">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
       
      ];
    } else if (this.props.match.params.render === "letterHead") {
      render = <LetterHead showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link active">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
        
      ];
    } else if (this.props.match.params.render === "internal") {
      render = <Internal showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link active">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
        
      ];
    } else if (this.props.match.params.render === "clientDashboard") {
      render = <Clients showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link active">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={10}>
          <Link to="/hr/dashboard/hireDeveloper" className="nav-link">
            Hire Developer
          </Link>
        </NavItem>,
        <NavItem key={11}>
          <Link to="/hr/dashboard/webApp" className="nav-link">
            Web App
          </Link>
        </NavItem>,
        <NavItem key={12}>
          <Link to="/hr/dashboard/training" className="nav-link">
            Training
          </Link>
        </NavItem>,
        <NavItem key={13}>
          <Link to="/hr/dashboard/projectDevelopment" className="nav-link">
            Project Development
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
       
      ];
    } else if (this.props.match.params.render === "hireDeveloper") {
      render = <Hire showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" startclassName="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={10}>
          <Link to="/hr/dashboard/hireDeveloper" className="nav-link active">
            Hire Developer
          </Link>
        </NavItem>,
        <NavItem key={11}>
          <Link to="/hr/dashboard/webApp" className="nav-link">
            Web App
          </Link>
        </NavItem>,
        <NavItem key={12}>
          <Link to="/hr/dashboard/training" className="nav-link">
            Training
          </Link>
        </NavItem>,
        <NavItem key={13}>
          <Link to="/hr/dashboard/projectDevelopment" className="nav-link">
            Project Development
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
        
      ];
    } else if (this.props.match.params.render === "webApp") {
      render = <WebApp showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" startclassName="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={10}>
          <Link to="/hr/dashboard/hireDeveloper" className="nav-link">
            Hire Developer
          </Link>
        </NavItem>,
        <NavItem key={11}>
          <Link to="/hr/dashboard/webApp" className="nav-link active">
            Web App
          </Link>
        </NavItem>,
        <NavItem key={12}>
          <Link to="/hr/dashboard/training" className="nav-link">
            Training
          </Link>
        </NavItem>,
        <NavItem key={13}>
          <Link to="/hr/dashboard/projectDevelopment" className="nav-link">
            Project Development
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
       
      ];
    } else if (this.props.match.params.render === "training") {
      render = <Training showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" startclassName="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={10}>
          <Link to="/hr/dashboard/hireDeveloper" className="nav-link">
            Hire Developer
          </Link>
        </NavItem>,
        <NavItem key={11}>
          <Link to="/hr/dashboard/webApp" className="nav-link">
            Web App
          </Link>
        </NavItem>,
        <NavItem key={12}>
          <Link to="/hr/dashboard/training" className="nav-link active">
            Training
          </Link>
        </NavItem>,
        <NavItem key={13}>
          <Link to="/hr/dashboard/projectDevelopment" className="nav-link">
            Project Development
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
       
      ];
    } else if (this.props.match.params.render === "projectDevelopment") {
      render = <Project showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" startclassName="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={10}>
          <Link to="/hr/dashboard/hireDeveloper" className="nav-link">
            Hire Developer
          </Link>
        </NavItem>,
        <NavItem key={11}>
          <Link to="/hr/dashboard/webApp" className="nav-link">
            Web App
          </Link>
        </NavItem>,
        <NavItem key={12}>
          <Link to="/hr/dashboard/training" className="nav-link">
            Training
          </Link>
        </NavItem>,
        <NavItem key={13}>
          <Link to="/hr/dashboard/projectDevelopment" className="nav-link active">
            Project Development
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
       
      ];
    } else if (this.props.match.params.render === "employee") {
      render = <Employee showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link active">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link">
            Track Assessments
          </Link>
        </NavItem>,
       
      ];
    } else if (this.props.match.params.render === "trackAssessments") {
      render = <TrackAssessments showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/hr/dashboard/applicants" className="nav-link">
            Applicants
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/hr/dashboard/probation" className="nav-link">
            Probation
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/hr/dashboard/intern" className="nav-link">
            Intern
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/hr/dashboard/internal" className="nav-link">
            Internal
          </Link>
        </NavItem>,
        <NavItem key={5}>
          <Link to="/hr/dashboard/external" className="nav-link">
            External
          </Link>
        </NavItem>,
        <NavItem key={6}>
          <Link to="/hr/dashboard/letterHead" className="nav-link">
            LetterHead
          </Link>
        </NavItem>,
        <NavItem key={7}>
          <Link to="/hr/dashboard/clientDashboard" className="nav-link">
            Clients
          </Link>
        </NavItem>,
        <NavItem key={8}>
          <Link to="/hr/dashboard/employee" className="nav-link">
            Employee
          </Link>
        </NavItem>,
        <NavItem key={9}>
          <Link to="/hr/dashboard/trackAssessments" className="nav-link active">
            Track Assessments
          </Link>
        </NavItem>,
       
      ];
    }
    
    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2">
            <Nav vertical pills>
              {menuItem}
              <hr />
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
            {/**
                        <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light" >
                            <h4 className="alert-heading">Welcome on your hr dashboard</h4>
                            <hr/>
                            <p className="mb-0">
                                This hr interface, will allow you to manage your applicants, intern, external.
                            </p>
                        </Alert> **/}
            {render}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(HrDashboard);
