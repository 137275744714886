import React, { Component } from "react";
import { withRouter,NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { generateInvoice } from "../../../../api/commonApi";


class ClientDataGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscription: props.subscription,
      paymentDetails: {
      userName : props.showClientData.userName,
      },
      
    };
   
  }

  static getDerivedStateFromProps(props, state) {
  const subscription = props.subscription;
  state.subscription = subscription;
  const Name = props.showClientData.name;
  state.Name = Name
  const userName = props.showClientData.userName;
  state.userName = userName
  const contactNo = props.showClientData.contactNo;
  state.contactNo = contactNo
  const Status = props.showClientData.status;
  state.Status = Status
  const noOfSubscriptions = props.showClientData.noOfSubscriptions;
  state.noOfSubscriptions = noOfSubscriptions
  const subscribedFrom = props.showClientData.subscribedFrom;
  state.subscribedFrom = subscribedFrom
  //alert("props content check !!!!"+JSON.stringify(subscriptionF));
   //alert("showData!!!!!"+showData);
   //alert("paymentInfo!!!!!"+paymentInfo);
    return state;
  }

 
  invoiceGenerator = (event) => {
    generateInvoice(this.state.paymentDetails)
      .then((res) => {
        this.props.showAlert(
          "Your request has been accepted ! role got changed for the user with email of " +
            this.state.user.email,
          "success"
        );
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

 
  
  render() {
    const { Name, userName, contactNo, Status, noOfSubscriptions, subscribedFrom} = this.state;
    //const {subscriptionFor} = this.state.subscriptionF;

    return (
      <tr>
        <th scope="row" style={{width: "25%"}}><small>{Name}</small></th>  
        <th scope="row" style={{width: "25%"}}><small>{userName}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{contactNo}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{Status}</small></th> 
        <th scope="row" style={{width: "25%"}}><small>{noOfSubscriptions}</small></th>
        <th scope="row"  style={{width: "25%"}}>
          <NavLink
            to={{
              pathname: "/financier/dashboard/viewClient",
              clientDataProps: {
                clientUserName: userName,
              },
            }}
            exact
          >
            <button type="button" class="btn btn-light">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button>
          </NavLink>
        </th>
        {/*<th scope="row" style={{width: "25%"}} ><small>
          <button type="button" class="btn btn-light" onClick={(e) => this.invoiceGenerator(e)}>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </button></small></th>*/}
        <th scope="row" style={{width: "25%"}} ><small>{subscribedFrom}</small></th>
      </tr>
    );
  }
}

export default withRouter(ClientDataGridLine);
