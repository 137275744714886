import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { documentGeneration } from "../../../../api/HrApi";
import Holiday from "./Holiday";


class LetterHead extends Component {
  state = {
    letterRequest: {
      header: "",
      subHeader: "",
      documentBody: "",
    },


    showLoading: false,
    
  };

  componentDidMount() {
    document.title = "Letter Generation";
  }

  updateHeader = (event) => {
    let req = this.state.letterRequest;
    req.header = event.target.value;
    this.setState({ letterRequest: req });
  };
  updateSubHeader = (event) => {
    let req = this.state.letterRequest;
    req.subHeader = event.target.value;
    this.setState({ letterRequest: req });
  };
  updateDocumentBody = (event) => {
    let req = this.state.letterRequest;
    req.documentBody = event.target.value;
    this.setState({ letterRequest: req });
  };
  

  documentGeneration= (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
  
    documentGeneration(this.state.letterRequest)
      .then((res) => {
        let binaryString = window.atob(res.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = this.state.letterRequest.header+".pdf";

        link.click(); /*

        alert("res"+res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        alert("url"+url);
        const link = document.createElement('a');
        alert("link"+link);
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
*/
    
        this.props.showAlert(
          "Letter generated successfully !",
          "success"
        );
        this.setState({ showLoading: false });
        this.setState({letterRequest:''})
      this.props.history.push("/hr/dashboard/letterHead");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
 


  render() {
    const { holidayData } = this.state;
    return (
      <div className="row" >
        <div className="col-md-6">
        <h3>Generate Document</h3>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Document generation initiated</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                in progress
              </i>
            </div>
          ) : (
            <form onSubmit={(e) => this.documentGeneration(e)}>
              <div>
                <p className="card-title text-center">Letter Generation</p>
                <div className="mt-2">
                  <label htmlFor="inputHeader">Header</label>
                  <input
                    type="text"
                    id="inputHeader"
                    className="form-control"
                    placeholder="Document Header"
                    required
                    onChange={this.updateHeader}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="inputSubHeader">Sub-Header</label>
                  <input
                    type="text"
                    id="inputSubHeader"
                    className="form-control"
                    placeholder="Document Sub Header"
                    required
                    onChange={this.updateSubHeader}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="inputDocumentBody">Document-Body</label>
                  <textarea
                    type="text"
                    id="inputDocumentBody"
                    className="form-control"
                    placeholder="Document Header"
                    required
                    onChange={this.updateDocumentBody}
                    autoComplete="on"
                  />
                </div>
                
                <br></br>
               
                <button
                  className="btn btn-primary btn-block text-uppercase mt-3"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          )}
        </div>
        </div>
        <div className="col-md-6">
        <Holiday showAlert={this.props.showAlert} ></Holiday>
        </div>
       
      </div>
    );
  }
}

export default withRouter(LetterHead);
