import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN } from "../../../../config/Config";
import { submitAnswers } from "../../../../api/AssessmentApi";
import TimeCounter from "./TimeCounter";

class AssessmentAttempt extends Component {

  constructor(props) {

    super(props);

    this.state = {
      alertIsVisible: true,
      imagePreviewUrl: "",
      user: "",
      showLoading: false,
      questions: [],
      userName: "",
      counter: 0,
      textIdx: 0,
      minutesPerQuestion: 0,
      answerList: [{}],
      questionAnswerList: [{}],
      testId: '',
    };
    this.onDismissAlert = this.onDismissAlert.bind(this);

    this.onShowAlert = this.onShowAlert.bind(this);
    this.updateRetrieveAssessmentData = this.updateRetrieveAssessmentData.bind(this);
    this.retrieveProfilePic = this.retrieveProfilePic.bind(this);
    this.backToHome = this.backToHome.bind(this);
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      this.props.showAlert(
        "Your session expired, You must be authenticated to access your online assessments !",
        "error"
      );
      this.props.history.push("/login");
    }
  }
  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 90000);
    });
  };
  componentDidMount() {
    this.updateRetrieveAssessmentData();
    this.onShowAlert();
  }
  /*
  componentDidMount() {
    this.updateViewProfileRequest();
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      let counter = this.state.counter;
      this.setState({ textIdx: currentIdx + 1 });
      this.setState({ counter: counter + 1 });
    }, this.state.minutesPerQuestion*1000);
  }

  componentDidUnmount() {
    clearInterval(this.timeout);
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      this.props.showAlert("Right click or select is restricted, if you do this you may fail in this assessment.","error");
      return false;
    });
  }
  */
  updateRetrieveAssessmentData = () => {
    this.setState({ showLoading: true });
    let req = this.state;

    req.questions = this.props.location.assessmentProps
      ? this.props.location.assessmentProps.assessment.questions
      : null;
    req.minutesPerQuestion = this.props.location.assessmentProps ? Math.ceil(this.props.location.assessmentProps.assessment.duration / req.questions.length) : 0;
    req.testId = this.props.location.assessmentProps ? (this.props.location.assessmentProps.assessment.testId) : 0;

    this.setState({ req });
    // alert(JSON.stringify(this.state));
    /* useEffect(() => {
       function handleContextMenu(e) {
         e.preventDefault(); // prevents the default right-click menu from appearing
       }
       // add the event listener to the component's root element
       const rootElement = document.getElementById('my-component');
       rootElement.addEventListener('contextmenu', handleContextMenu);
       // remove the event listener when the component is unmounted
       return () => {
         rootElement.removeEventListener('contextmenu', handleContextMenu);
       };
     }, []);*/
  };

  onClickSubmitAnswers = () => {

    this.setState({ showLoading: true });
    let req = this.state;
    const assessmentSubmitAnswerRequest = {
      answers: '',
      testId: ''
    };

    // assessmentSubmitAnswerRequest.answers=req.answerList.filter(el => Object.keys(el).length);
    assessmentSubmitAnswerRequest.answers = req.questionAnswerList.filter(el => Object.keys(el).length);
    assessmentSubmitAnswerRequest.testId = req.testId;

    // alert(JSON.stringify(assessmentSubmitAnswerRequest));
    submitAnswers(assessmentSubmitAnswerRequest)
      .then((res) => {
        this.props.showAlert(

          "Your answers saved, You can check your score in assessment dashboard.", "success"
        );
        this.setState({ showLoading: false });
        this.setState({ req: "" });
        this.props.history.push("/me/assessment/assessments");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  backToHome() {
    this.props.history.push("/me/assessment/assessments");
  }
  retrieveProfilePic = () => {
    this.setState({ showLoading: true });
    retrieveProfileImage()
      .then((res) => {
        this.setState({ imagePreviewUrl: res.data });
        this.props.showAlert(
          "Profile image retrieved successfully ",
          "success"
        );
        this.setState({ showLoading: false });
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert("Your profile image does't exist !", "error");
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };
  handleAnswerList = (e, index, question) => {
    const { name, value } = e.target;
    const list = this.state.answerList;
    const qaList = this.state.questionAnswerList;
    list[index] = value;
    qaList[index] = question + ":::::" + value;
    this.setState({ answerList: list })
    this.setState({ questionAnswerList: qaList })
    //alert("success"+JSON.stringify(this.state.questionAnswerList));
  };

  // handle click event of the Remove button
  handleAnswerRemoveClick = index => {
    const list = this.state.answerList;
    list.splice(index, 1);
    this.setState({ answerList: list })

  };

  // handle click event of the Add button
  handleAnswerAddClick = () => {
    this.setState({ answerList: [...this.state.answerList, {}] });
    this.setState({ formInput: '' })
  };

  render() {
    const { questions, counter, minutesPerQuestion, answerList, formInput } = this.state;
    const { imagePreviewUrl } = this.state;
    if (questions) {
      let textThatChanges = questions[this.state.textIdx % questions.length];

      return (

        <TimeCounter showAlert={this.props.showAlert} next={this.state.textIdx} minutesPerQuestion={minutesPerQuestion} noOfQuestions={questions.length} questions={questions}
          answerList={answerList}
          formInput={formInput}
          handleAnswerList={this.handleAnswerList}
          handleAnswerRemoveClick={this.handleAnswerRemoveClick}
          handleAnswerAddClick={this.handleAnswerAddClick}
          onClickSubmitAnswers={this.onClickSubmitAnswers}
          backToHome={this.backToHome}
        ></TimeCounter>

      );
    } else {

      return (
        <>
          <br></br>
          <br></br>
          <div className="container">

            <div class="d-flex align-items-center justify-content-between mb-4">
              <small class="text-dark fw-bolder mb-4">
                You countered the problem due to refresh or screen change.
              </small>

              <button
                class="btn btn-primary px-4 py-3"
                onClick={this.backToHome}
              >
                <div class="d-inline-block bi bi-download me-2"></div>
                Back to dashboard.
              </button>
            </div>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>

          </div>
        </>
      );
    }

  }
}

export default withRouter(AssessmentAttempt);

