import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ACCESS_TOKEN } from "../../../config/Config";
import { Nav, NavItem } from "reactstrap";
import Content from "./content/Content";
import EditContent from "./editcontent/EditContent";


class PublishContent extends Component {
  constructor(props) {
    super(props);

    if (!localStorage.getItem(ACCESS_TOKEN)) {
      this.props.showAlert(
        "You must be authenticated to access your account settings !",
        "error"
      );
      this.props.history.push("/login");
    }
  }

  render() {
    let render;
    let navItem;
    if (this.props.match.params.render === "content") {
      render = <Content showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/publish/content" className="nav-link active">
          Content
          </Link>
        </NavItem>, 
        <NavItem key={1} className="menu-item">
        <Link to="/publish/editContent" className="nav-link">
        Edit Content
        </Link>
      </NavItem>,
      ];
    } 
    else if (this.props.match.params.render === "editContent") {
      render = <EditContent showAlert={this.props.showAlert} />;
      navItem = [
        <NavItem key={1} className="menu-item">
          <Link to="/publish/content" className="nav-link">
          Content
          </Link>
        </NavItem>, 
        <NavItem key={2} className="menu-item">
        <Link to="/publish/editContent" className="nav-link active">
       Edit Content
        </Link>
      </NavItem>, 
      ];
    } 
   
   
   
  
   
    const token = localStorage.getItem(ACCESS_TOKEN);
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace("-", "+").replace("_", "/");
    const decodedToken = JSON.parse(window.atob(base64));

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <Nav vertical pills>
              {navItem}
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9">{render}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(PublishContent);