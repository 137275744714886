import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class LeaveWithoutPay extends Component {
  render() {
    return (
      <>
        <h3 className={"pages-mainheader-text"}>Leave Without Pay </h3>
        <label></label>

        <p>
          <li className={"pages-content-text"}>
            {" "}
            It is not the policy of the company to encourage this practice.
            Under exceptional circumstances like serious illness, the employee
            can avail of such a leave after approval from the approval
            authority. This may be sanctioned only when all other forms of leave
            are exhausted.
          </li>
          <li className={"pages-content-text"}>
            All other extra leaves taken beyond your limits will be deducted
            from your salary and on probation, you have to pay 500INR per day
            for taking extra leaves.{" "}
          </li>
        </p>
      </>
    );
  }
}
export default withRouter(LeaveWithoutPay);
