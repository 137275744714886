import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Alert } from "reactstrap";

import Projects from "./projects/Projects";
import Hire from "./hire/Hire";
import Payment from "./payment/Payment";
import RequestForm from "./request/RequestForm";

class ClientDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertIsVisible: true,
    };

    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
  }

  componentDidMount() {
    document.title = "Client dashboard";
    this.onShowAlert();
  }

  onDismissAlert() {
    this.setState({ alertIsVisible: false });
  }
  onShowAlert = () => {
    this.setState({ alertIsVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertIsVisible: false });
      }, 5000);
    });
  };
  render() {
    let render;
    let menuItem;
    if (this.props.match.params.render === "hire") {
      render = <Hire showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/dashboard/hire" className="nav-link active">
            Hired
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/dashboard/projects" className="nav-link">
            Projects
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/dashboard/payment" className="nav-link">
            Payment
          </Link>
        </NavItem>
      ];
    } else if (this.props.match.params.render === "projects") {
      render = <Projects showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/dashboard/hire" className="nav-link">
            Hired
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/dashboard/projects" className="nav-link active">
            Projects
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/dashboard/payment" className="nav-link">
            Payment
          </Link>
        </NavItem>
      ];
    } else if (this.props.match.params.render === "payment") {
      render = <Payment showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/dashboard/hire" className="nav-link">
            Hired
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/dashboard/projects" className="nav-link">
            Projects
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/dashboard/payment" className="nav-link active">
            Payment
          </Link>
        </NavItem>
      ];
    } /*else if (this.props.match.params.render === "request") {
      render = <RequestForm showAlert={this.props.showAlert} />;
      menuItem = [
        <NavItem key={1}>
          <Link to="/client/dashboard/hire" className="nav-link">
            Hired
          </Link>
        </NavItem>,
        <NavItem key={2}>
          <Link to="/client/dashboard/projects" className="nav-link">
            Projects
          </Link>
        </NavItem>,
        <NavItem key={3}>
          <Link to="/client/dashboard/payment" className="nav-link">
            Payment
          </Link>
        </NavItem>,
        <NavItem key={4}>
          <Link to="/client/dashboard/request" className="nav-link active">
            Request
          </Link>
        </NavItem>,
      ];
    }*/

    return (
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-2">
            <Nav vertical pills>
              {menuItem}
              <hr />
            </Nav>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
            {/**
                        <Alert isOpen={this.state.alertIsVisible} toggle={this.onDismissAlert} className="bg-primary text-light" >
                            <h4 className="alert-heading">Welcome to client dashboard.</h4>
                            <hr/>
                            <p className="mb-0">
                                This client interface, will allow you to manage your projects, request, payment and access the developer profiles pool.
                            </p>
                        </Alert>**/}
            {render}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ClientDashboard);
