import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { event } from "jquery";
import { contentData } from "../../../../api/commonApi";
import { CustomInput, FormGroup } from "reactstrap";
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryHeader: "",
      category: "java",
      subCategory: "",
      childCategory: "",
      contentInfoData: [
        {
          contentHeader: "",
          contentBody: "",
          codeSnippet: "",
          contentImageBody: null,
          contentImageName: "",
          invalidContentFile: false,
        },
      ],
      showLoading: false,
    };
  }

  componentDidMount() {
    document.title = "My content data settings";
  }

  primaryHeader = (event) => {
    this.setState({ primaryHeader: event.target.value });
  };

  category = (event) => {
    this.setState({ category: event.target.value });
  }
  subCategory = (event) => {

    this.setState({ subCategory: event.target.value });
  };
  childCategory = (event) => {

    this.setState({ childCategory: event.target.value });
  };

  handleInfoAddClick = () => {
    this.setState({
      contentInfoData: [
        ...this.state.contentInfoData,
        {
          contentHeader: "",
          contentBody: "",
          codeSnippet: "",
          contentImageBody: null,
          contentImageName: "",
          invalidContentFile: false,
        },
      ],
    });
  };

  toBase64 = fileData => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
  async fileDataConverter(fileData) {
    try {
      const result = await this.toBase64(fileData);
      return result
    } catch (error) {
      console.error(error);
      return;
    }

  }
  // handle click event of the onchange method
  handleContentInfo = (e, index) => {
    const { name, value } = e.target;
    if (e.target.name === "contentImageBody") {
      const files = e.target.files;
      if ({ target: { files } }) {
        const cancel = !files.length;
        if (cancel) return;
        const [{ size, name }] = files;
        const maxSize = 100000;
        if (size < maxSize) {
          const list = [...this.state.contentInfoData];
          list[index].contentImageName = name;
          list[index].invalidContentFile = false;
          //alert("files[0["+files[0])
          this.fileDataConverter(files[0]).then((response) => {
            list[index].contentImageBody = response;
            //alert("response"+response)
            this.setState({ contentInfoData: list });
          })
        } else {
          const list = [...this.state.contentInfoData];
          list[index].contentImageName = "";
          list[index].invalidContentFile = true;
          this.setState({ contentInfoData: list });
          this.props.showAlert("image size should not exceed 100kb", "error");
          this.setState({ showLoading: false });
          this.props.history.push("/publish/content");
        }
      }
    }
    const list = [...this.state.contentInfoData];
    list[index][name] = value;
    this.setState({ contentInfoData: list });
  };

  // handle click event of the Remove button
  handleInfoRemoveClick = (index) => {
    const list = [...this.state.contentInfoData];
    list.splice(index, 1);
    this.setState({ contentInfoData: list });
  };
  publishContentUpdate = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    const content = this.state;
    //alert("content details" + JSON.stringify(content));
    contentData(content)
      .then((response) => {
        const data = response.data;
        this.props.showAlert(data.message, "success");
        this.setState({ showLoading: false });

        this.setState({
          primaryHeader: "",
          category: "",
          subCategory: "",
          childCategory: "",
          publishedBy: "",
          contentInfoData: [
            {
              contentHeader: "",
              contentBody: "",
              codeSnippet: "",
              contentImageBody: null,
              contentImageName: "",
              invalidContentFile: false,
            },
          ],
          showLoading: false,
        });
        this.props.history.push("/");
      })
      .catch((error) => {
        this.props.showAlert(error, "error");
        this.setState({ showLoading: false });
      });
  };

  render() {
    const { contentInfoData } = this.state;

    return (
      <div>
        <h2>Publish Content</h2>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Updating your content data</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                refresh
              </i>
            </div>
          ) : (
            <form onSubmit={this.publishContentUpdate}>
              <div className="form-group">
                <label htmlFor="primaryHeader">Primary Header</label>
                <input
                  type="text"
                  className="form-control"
                  id="primaryHeader"
                  name="primaryHeader"
                  placeholder="primary header"
                  required
                  autoComplete="on"
                  onChange={this.primaryHeader}
                />
              </div>


              <div className="form-group">
                <label htmlFor="category"> Category</label>
                <select
                  className="w3-select"
                  name="option"
                  value={this.state.category}
                  onChange={(event) => this.category(event)}
                >
                  <option value="java">java</option>
                  <option value="golang">golang</option>
                  <option value="environmental-setup">
                    environment-setup
                  </option>
                  <option value="javascript">
                    javascript
                  </option>
                  <option value="flexPLM">
                    flexPLM
                  </option>
                  <option value="aws">
                    aws
                  </option>
                  <option value="identity-access-management">
                    identity-access-management
                  </option>
                  <option value="reactJs">reactJs</option>
                  <option value="camunda">camunda</option>
                  <option value="testing">testing</option>
                  <option value="mainframe-modernization">mainframe-modernization</option>
                  <option value="soft-skills">soft-skills</option>
                  <option value="data-structure-algorithms">data-structure-algorithms</option>
                  <option value="springboot">springboot</option>
                  <option value="database">database</option>
                  <option value="evaluation">evaluation</option>
                  <option value="others">others</option>

                </select>
              </div>
              <div className="form-group">
                <label htmlFor="subCategory">Subcategory</label>
                <input
                  type="text"
                  className="form-control"
                  id="subCategory"
                  name="subCategory"
                  placeholder="subcategory"
                  required
                  autoComplete="on"
                  onChange={this.subCategory}
                />
              </div>
              <div className="form-group">
                <label htmlFor="childCategory">Child Category</label>
                <input
                  type="text"
                  className="form-control"
                  id="childCategory"
                  name="childCategory"
                  placeholder="Child category"
                  required
                  autoComplete="on"
                  onChange={this.childCategory}
                />
              </div>

              <div className="form-group">
                <h4 htmlFor="contentInfoData">Content Info</h4>
                {contentInfoData.map((x, i) => {
                  return (
                    <div>
                      <label htmlFor="contentHeader" className="mt-2">
                        Content Header
                      </label>
                      <input
                        type="text"
                        className="form-control mt-2"
                        id="contentHeader"
                        name="contentHeader"
                        placeholder="content header"
                        required
                        autoComplete="on"
                        value={x.contentHeader}
                        onChange={(e) => this.handleContentInfo(e, i)}
                      />
                      <label htmlFor="contentBody" className="mt-2">
                        Content Body
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="contentBody"
                        name="contentBody"
                        required
                        autoComplete="on"
                        placeholder="content body"
                        value={x.contentBody}
                        onChange={(e) => this.handleContentInfo(e, i)}
                      />
                      <label htmlFor="codeSnippet" className="mt-2">
                        Code Snippet
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="codeSnippet"
                        name="codeSnippet"
                        autoComplete="on"
                        placeholder="code snippet"
                        value={x.codeSnippet}
                        onChange={(e) => this.handleContentInfo(e, i)}
                      />

                      <label htmlFor="profilePic">content Image Body</label>

                      <FormGroup>
                        <CustomInput
                          type="file"
                          id="exampleCustomFileBrowser"
                          name="contentImageBody"
                          label={
                            x.contentImageName ||
                            "choose an image file size less than 100kb"
                          }
                          onChange={(e) => this.handleContentInfo(e, i)}
                          invalid={x.invalidContentFile}
                        />
                      </FormGroup>

                      {contentInfoData.length !== 1 && (
                        <button
                          className="btn btn-danger mt-2"
                          onClick={this.handleInfoRemoveClick}
                        >
                          Remove
                        </button>
                      )}
                      {contentInfoData.length - 1 === i && (
                        <button
                          className="btn btn-primary mt-2 ml-2"
                          onClick={this.handleInfoAddClick}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>

              <button type="submit" className="btn btn-primary ms-auto">
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Content);
