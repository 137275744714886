import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { onboardTest } from "../../../../api/AssessmentApi";
import { CustomInput, FormGroup } from 'reactstrap';

class AssessmentOnboard extends Component {
  state = {
    assessmentOnboardRequest: {
      orgName: "",
      assessmentName: "",
      assessmentTotalDuration: "",
      assessmentTotalScore: "",
      assessmentMinimumScoreToPass: "",
      testCategory: "",
      testType: "",
    
    },
    qaFile: null,
    fileName:'',
    invalidFile:false,
    showLoading: false,
  };
  handleFileChange = this.handleFileChange.bind(this);
  handleFileChange({target: {files}}) {
    const cancel = !files.length;
    if (cancel) return;
    const [{ size, name }] = files;
    const maxSize = 100000000;
    if (size < maxSize) {
      this.setState({ fileName: name, invalidFile: false });
      this.setState({ qaFile: files[0]});
    } else {
      this.setState({ fileName: '', invalidFile: true });
     
    }
  }
  componentDidMount() {
    document.title = "Assessment Onboard";
  }
  updateTestType = (event) => {
    let req = this.state.assessmentOnboardRequest;
    req.testType = event.target.value;
    this.setState({ assessmentOnboardRequest: req });
  };
  updateTestCategory = (event) => {
    let req = this.state.assessmentOnboardRequest;
    req.testCategory = event.target.value;
    this.setState({ assessmentOnboardRequest: req });
  };
  updateAssessmentMinimumScoreToPass = (event) => {
    let req = this.state.assessmentOnboardRequest;
    req.assessmentMinimumScoreToPass = event.target.value;
    this.setState({ assessmentOnboardRequest: req });
  };
  updateAssessmentTotalScore = (event) => {
    let req = this.state.assessmentOnboardRequest;
    req.assessmentTotalScore = event.target.value;
    this.setState({ assessmentOnboardRequest: req });
  };
  updateAssessmentTotalDuration = (event) => {
    let req = this.state.assessmentOnboardRequest;
    req.assessmentTotalDuration = event.target.value;
    this.setState({ assessmentOnboardRequest: req });
  };

  updateAssessmentName = (event) => {
    let req = this.state.assessmentOnboardRequest;
    req.assessmentName = event.target.value;
    this.setState({ assessmentOnboardRequest: req });
  };
  updateOrgName = (event) => {
    let req = this.state.assessmentOnboardRequest;
    req.orgName = event.target.value;
    this.setState({ assessmentOnboardRequest: req });
  };
  
  onboardAssessment = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    const formData = new FormData();
      formData.append("file", this.state.qaFile);
      formData.append('assessmentOnboardRequest', new Blob([JSON.stringify(
        this.state.assessmentOnboardRequest
      )], {
        type: "application/json"
      }));
    onboardTest(formData)
      .then((res) => {
        this.props.showAlert(
          
          res.data.message,"success"
        );
                this.setState({ showLoading: false });
        this.setState({ assessmentOnboardRequest: "",qaFile:"" });
        this.props.history.push("/me/assessment/onboard");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  render() {
    const { fileName, invalidFile } = this.state;
    return (
      <div>
        <h1>Create Assessment</h1>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Onboard Assessment initiated</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                in progress
              </i>
            </div>
          ) : (
            <form onSubmit={(e) => this.onboardAssessment(e)}>
              <div>
             
                <div className="mt-0">
                  <label htmlFor="orgName">Org Name</label>
                  <input
                    type="text"
                    id="orgName"
                    className="form-control"
                    placeholder="Org who initiates online evaluation."
                    required
                    onChange={this.updateOrgName}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  
                  <select
                    className="w3-select"
                    name="option"
                    value={this.state.testCategory}
                    onChange={(event) => this.updateTestCategory(event)}
                  >
                   <option className="form-control" value="">Assessment Category</option>
                   <option value="ds-programming">ds-programming</option>
                    <option value="backend">backend</option>
                    <option value="frontend">frontend</option>
                    <option value="fullstack">fullstack</option>
                    <option value="devops">devops</option>
                    <option value="testing">testing</option>
                    <option value="flex-plm">flex-plm</option>
                    <option value="soft-skills">soft-skills</option>
                    <option value="others">others</option>
                  </select>
                </div>
                <div className="mt-0">
                  
                  <select
                    className="w3-select"
                    name="option"
                    value={this.state.testType}
                    onChange={(event) => this.updateTestType(event)}
                  >
                  <option className="form-control" value="">Assessment Type</option>
                    <option value="technical">technical</option>
                    <option value="non-technical">non-technical</option>
                    <option value="communication">communication</option>
                    <option value="others">others</option>
                  </select>
                </div>
                <div className="mt-0">
                  <label htmlFor="testName">Assessment name</label>
                  <input
                    type="text"
                    id="testName"
                    className="form-control"
                    placeholder="Name of the assessment"
                    required
                    onChange={this.updateAssessmentName}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="totalDuration">
                    Total duration of the Assessment
                  </label>
                  <input
                    type="text"
                    id="totalDuration"
                    className="form-control"
                    placeholder="Duration in mins"
                    required
                    onChange={this.updateAssessmentTotalDuration}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="totalScore">Assessment total score</label>
                  <input
                    type="text"
                    id="totalScore"
                    className="form-control"
                    placeholder="Total score divisible by no of question."
                    required
                    onChange={this.updateAssessmentTotalScore}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="minimumPass">Minimum pass score</label>
                  <input
                    type="text"
                    id="minimumPass"
                    className="form-control"
                    placeholder="Minimum pass criteria score."
                    required
                    onChange={this.updateAssessmentMinimumScoreToPass}
                    autoComplete="on"
                  />
                </div>
              
                <div className="mt-0">
                  <label htmlFor="qaFile">Assessment QA file</label>
                  <FormGroup>
                  <CustomInput
                    type="file"
                    id="exampleCustomFileBrowser"
                    name="customFile"
                    label={fileName || "choose an image file"}
                    onChange={this.handleFileChange}
                    invalid={invalidFile}
                  />
                </FormGroup>
                </div>
                <button
                  className="btn btn-primary btn-block text-uppercase mb-1"
                  type="submit"
                >
                  Onboard Assessment
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AssessmentOnboard);
