import React, { Component } from "react";
import { withRouter } from "react-router-dom";
//import { onboardTest } from "../../../../api/AssessmentApi";
import { CustomInput, FormGroup } from 'reactstrap';
import { sendBulkEmail } from "../../../../../api/commonApi";

class bulkEmail extends Component {
  state = {
    assessmentOnboardRequest: {
      orgName: "",
      assessmentName: "",
      assessmentTotalDuration: "",
      assessmentTotalScore: "",
      assessmentMinimumScoreToPass: "",
      testCategory: "",
      testType: "",
    
    },
    emailRequest:{
      recipientsList: "",
      service: "",
      mailBody:"",
      subject:""
    },
    qaFile: null,
    fileName:'',
    invalidFile:false,
    showLoading: false,
  };
  handleFileChange = this.handleFileChange.bind(this);
  handleFileChange({target: {files}}) {
    const cancel = !files.length;
    if (cancel) return;
    const [{ size, name }] = files;
    const maxSize = 100000;
    if (size < maxSize) {
      this.setState({ fileName: name, invalidFile: false });
      this.setState({ qaFile: files[0]});
    } else {
      this.setState({ fileName: '', invalidFile: true });
      this.props.showAlert("image size should not exceed 100kb", "error");
      this.setState({ showLoading: false });
      this.props.history.push("/hr/email/bulkEmail");
    }
  }
  componentDidMount() {
    document.title = "Bulk Email";
  }

  updateRecipientsList = (event) => {
    let req = this.state.emailRequest;
    req.recipientsList = event.target.value;
    this.setState({ emailRequest: req });
  };
  updateService = (event) => {
    let req = this.state.emailRequest;
    req.service = event.target.value;
    this.setState({ emailRequest: req });
  };
  updateMailBody = (event) => {
    let req = this.state.emailRequest;
    req.mailBody = event.target.value;
    this.setState({ emailRequest: req });
  };
  updateSubject = (event) => {
    let req = this.state.emailRequest;
    req.subject = event.target.value;
    this.setState({ emailRequest: req });
  };
  sendEmailFeature = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    const formData = new FormData();
      formData.append("file", this.state.qaFile);
      formData.append('emailRequest', new Blob([JSON.stringify(
        this.state.emailRequest
      )], {
        type: "application/json"
      }));
    sendBulkEmail(formData)
      .then((res) => {
        this.props.showAlert(
          
          res.data.message,"success"
        );
        this.setState({ showLoading: false });
        this.setState({ emailRequest: {
          recipientsList: "",
          service: "",
          mailBody:"",
          subject:""
        },qaFile:null });
        this.props.history.push("/hr/email/bulkEmail");
      })
      .catch((error) => {
        if (error.status === 401) {
          this.props.showAlert(
            "Your username or email does not exist !",
            "error"
          );
        } else {
          this.props.showAlert(error.message, "error");
        }
        this.setState({ showLoading: false });
      });
  };

  render() {
    const { fileName, invalidFile } = this.state;
    return (
      <div>
        <h1>Bulk Emailing</h1>
        <hr />
        <div>
          {this.state.showLoading ? (
            <div className="align-content-center text-center">
              <h3>Bulk Emailing initiated</h3>
              <i className="material-icons w3-xxxlarge w3-spin align-content-center">
                in progress
              </i>
            </div>
          ) : (
            <form onSubmit={(e) => this.sendEmailFeature(e)}>
              <div>
             
                <div className="mt-0">
                  <label htmlFor="orgName">Recipients</label>
                  <input
                    type="text"
                    id="orgName"
                    className="form-control"
                    placeholder="Please provide mail id's comma separated."
                    required
                    onChange={this.updateRecipientsList}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="subject">Email Subject</label>
                  <input
                    type="text"
                    id="subject"
                    className="form-control"
                    placeholder="Please provide mail id's comma separated."
                    required
                    onChange={this.updateSubject}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                  <label htmlFor="mailBody">Body of the mail</label>
                  <textarea
                    type="text"
                    id="mailBody"
                    className="form-control"
                    placeholder="Please provide mail id's comma separated."
                    required
                    onChange={this.updateMailBody}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-0">
                <label htmlFor="orgName">Image for promotion</label>
                  <select
                    className="w3-select"
                    name="option"
                    value={this.state.service}
                    onChange={(event) => this.updateService(event)}
                  >
                   <option className="form-control" value="">Select purpose of the email.</option>
                    <option value="business">business</option>
                    <option value="hr">hr</option>
                    <option value="info">info</option>
                    
                  </select>
                </div>
                <div className="mt-0">
                  <label htmlFor="qaFile">Service Image</label>
                  <FormGroup>
                  <CustomInput
                    type="file"
                    id="exampleCustomFileBrowser"
                    name="customFile"
                    label={fileName || "choose an image file"}
                    onChange={this.handleFileChange}
                    invalid={invalidFile}
                  />
                </FormGroup>
                </div>
                <button
                  className="btn btn-primary btn-block text-uppercase mb-1"
                  type="submit"
                >
                  Send Mail
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(bulkEmail);
