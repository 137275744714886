import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { unsubscribe } from "../../../api/Api";

class ConfirmUnSubscription extends Component {

    state = {
        unSubscriptionRequest: {
            id: 0,
            token: "",
            status: ""
        },
        showLoading: false
    }

    componentDidMount() {

        document.title = "Confirm unSubscription";
        if (this.props.match.params.id && this.props.match.params.token) {
            let req = this.state.unSubscriptionRequest;
            req.id = this.props.match.params.id;
            req.token = this.props.match.params.token;
            req.status = 'unsubscribed';
            this.setState({ unSubscriptionRequest: req });
        } else {
            this.props.showAlert("Invalid unSubscription link ! may be you can re create your account.", "error");
            this.props.history.push("/signup");
        }
        this.unsubscribeUser();

    }

    unsubscribeUser = () => {
        unsubscribe(this.state.unSubscriptionRequest)
            .then(res => {
                this.props.showAlert("Your account unSubscription has been completed ! Please un subscribe and login for further proceedings.", "success");
                this.props.history.push("/login");
                this.setState({ unSubscriptionRequest: '' });
            }).catch(error => {
                if (error.message && error.success === false) {
                    this.props.showAlert(error.message, "error");
                } else {
                    this.props.showAlert(error.message, "error");
                }

                console.log(error);
            });
    }


    render() {
        return (
            <div className="container">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div className="card card-signin my-5">
                        <div className="card-body">


                            {
                                this.state.showLoading
                                    ?
                                    <div>
                                        <h1 className="card-title text-center">Loading. Please wait...</h1>
                                        <i className="material-icons w3-xxxlarge w3-spin d-flex justify-content-center">refresh</i>
                                    </div>
                                    :
                                    <Redirect to="/login" />
                            }
                        </div>
                    </div>
                </div>
                <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
              crossorigin="anonymous"
            ></script>
         
            </div>
        );
    }
}
export default withRouter(ConfirmUnSubscription); 