import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { candidateOnboardRequest,leaveUpdateRequest,performanceUpdate } from "../../../../api/HrApi";

import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";

class InternalGridLine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            onboardRequest: {
                email:"",
                officialEmail: "",
                employmentStatus: "",
                joiningDate: "",
                designation: "",
        
              },
               performanceInput: {
                email:"",
                performanceLevel:"",
                comments:"",
        
              },
              leaveRequest: {
                email:"",
                leaveType:"",
                leaveFrom: "",
                leaveTo: "",
                noOfDays: "",
                leaveSummary: "",
                flowFrom:"hrPage",
        
              },
            showModalUserInfo: false,
            showModalLeaveUpdate:false,
            showModalLevelUpdate:false,
            user: props.user,
            performanceEvaluation: {},
            personalInfo: {},
            employeeLeaveData: {},
        };
        this.updateEmploymentStatus=this.updateEmploymentStatus.bind(this);
        this.candidateOnboard=this.candidateOnboard.bind(this);
        this.updateDesignation=this.updateDesignation.bind(this);
        this.updateOfficialEmail=this.updateOfficialEmail.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleLeaveUpdate=this.toggleLeaveUpdate.bind(this);
        this.toggleLevelUpdate=this.toggleLevelUpdate.bind(this);
        this.toggleViewProfile = this.toggleViewProfile.bind(this);
    }

    static getDerivedStateFromProps(props, state) {

        const user = props.user;
        state.user = user;
    state.employment=user.employment;
    state.performanceEvaluation=user.employment.performanceEvaluation;
    state.personalInfo=user.employment.profileDetails.personalInfo;
    state.employeeLeaveData=user.employment.employeeLeaveData;
    
        return state;
    }

    toggle() {
        this.setState({
            showModalUserInfo: !this.state.showModalUserInfo
        });
    }
    toggleLeaveUpdate() {
        this.setState({
            showModalLeaveUpdate: !this.state.showModalLeaveUpdate
        });
    }
    toggleLevelUpdate() {
        this.setState({
            showModalLevelUpdate: !this.state.showModalLevelUpdate
        });
  }
  toggleViewProfile() {
    this.setState({
      showModalProfileData: !this.state.showModalProfileData,
    });

    
    }
    updateOfficialEmail = (event) => {
        let req = this.state.onboardRequest;
        req.officialEmail = event.target.value;
        this.setState({ onboardRequest: req });
      };
      updateEmploymentStatus = (event) =>{
        let req = this.state.onboardRequest;
        req.employmentStatus = event.target.value;
        this.setState({ onboardRequest: req });
        }
      updateJoiningDate = (event) => {
        let req = this.state.onboardRequest;
        req.joiningDate = event.target.value;
        this.setState({ onboardRequest: req });
      };
      updateDesignation = (event) => {
        let req = this.state.onboardRequest;
        req.designation = event.target.value;
        this.setState({ onboardRequest: req });
      };
      updateLeaveType = (event) => {
        let req = this.state.leaveRequest;
        req.leaveType = event.target.value;
        this.setState({ leaveRequest: req });
      };
      updateLeaveFrom = (event) => {
        let req = this.state.leaveRequest;
        req.leaveFrom = event.target.value;
        this.setState({ leaveRequest: req });
      };
      updateLeaveTo = (event) => {
        let req = this.state.leaveRequest;
        req.leaveTo = event.target.value;
        this.setState({ leaveRequest: req });
      };
      updateNoOfLeaveDay = (event) => {
        let req = this.state.leaveRequest;
        req.noOfDays = event.target.value;
        this.setState({ leaveRequest: req });
      };
      updateLeaveSummary = (event) => {
        let req = this.state.leaveRequest;
        req.leaveSummary = event.target.value;
        this.setState({ leaveRequest: req });
      };
      
      updatePerformanceLevel = (event) => {
        let req = this.state.performanceInput;
        req.performanceLevel = event.target.value;
        this.setState({ performanceInput: req });
      };
      updateComments = (event) => {
        let req = this.state.performanceInput;
        req.comments = event.target.value;
        this.setState({ performanceInput: req });
      };
      
      performanceUpdate= (event) => {
        event.preventDefault();
        let req = this.state.performanceInput;
        req.email = this.state.user.email;
        this.setState({ performanceInput: req });
        this.setState({ showLoading: true });
        
        performanceUpdate(this.state.performanceInput)
          .then((res) => {
            this.props.showAlert(
              "Employee performance update request has been accepted !" +
                this.state.user.email,
              "success"
            );
            this.setState({ showLoading: false });
            this.toggleLevelUpdate();
            this.props.history.push("/");
            this.props.history.push("hr/dashboard/intern");
          })
          .catch((error) => {
            if (error.status === 401) {
              this.props.showAlert(
                "Your username or email does not exist !",
                "error"
              );
            } else {
              this.props.showAlert(error.message, "error");
            }
            this.setState({ showLoading: false });
          });
      };
      leaveUpdate= (event) => {
        event.preventDefault();
        let req = this.state.leaveRequest;
        req.email = this.state.user.email;
        this.setState({ leaveRequest: req });
        this.setState({ showLoading: true });

        leaveUpdateRequest(this.state.leaveRequest)
          .then((res) => {
            this.props.showAlert(
              "Employee leave update request has been accepted !" +
                this.state.user.email,
              "success"
            );
            this.setState({ showLoading: false });
            this.toggleLeaveUpdate();
            this.props.history.push("/");
            this.props.history.push("hr/dashboard/internal");
          })
          .catch((error) => {
            if (error.status === 401) {
              this.props.showAlert(
                "Your username or email does not exist !",
                "error"
              );
            } else {
              this.props.showAlert(error.message, "error");
            }
            this.setState({ showLoading: false });
          });
      };
      candidateOnboard = (event) => {
        event.preventDefault();
        let req = this.state.onboardRequest;
        req.email = this.state.user.email;
        this.setState({ onboardRequest: req });
        this.setState({ showLoading: true });
        
        candidateOnboardRequest(this.state.onboardRequest)
          .then((res) => {
            this.props.showAlert(
              "Employee onboard request has been accepted !" +
                this.state.user.email,
              "success"
            );
            this.setState({ showLoading: false });
            this.toggle();
            this.props.history.push("/");
            this.props.history.push("hr/dashboard/internal");
          })
          .catch((error) => {
            if (error.status === 401) {
              this.props.showAlert(
                "Your username or email does not exist !",
                "error"
              );
            } else {
              this.props.showAlert(error.message, "error");
            }
            this.setState({ showLoading: false });
          });
      };
    render() {

        let roles;

        if (this.state.user && this.state.user.roles) {
            roles = Object.keys(this.state.user.roles).map(
                key => " " + this.state.user.roles[key]
            );
        }

        return (
            <tr>
                <th scope="row">
                    {this.state.user.name}
                </th>
                <th>
                <button className="btn btn-success" onClick={this.toggleLeaveUpdate} data-toggle="tooltip" data-placement="bottom" title="Leave" >  <FontAwesomeIcon icon={faArrowAltCircleUp} /></button>
                </th>
                <th>
                <button className="btn btn-success" onClick={this.toggleLevelUpdate} data-toggle="tooltip" data-placement="bottom" title="Level">  <FontAwesomeIcon icon={faArrowAltCircleUp} /></button>
                </th>
                <th>
                    <button className="btn btn-success" onClick={this.toggle} data-toggle="tooltip" data-placement="bottom" title="Action" >  <FontAwesomeIcon icon={faArrowAltCircleUp} /></button>
                </th>
                <th>
          <button className="btn btn-success" onClick={this.toggleViewProfile} data-toggle="tooltip" data-placement="bottom" title="View">
            {" "}
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
          </button>
        </th>
                <Modal
          isOpen={this.state.showModalUserInfo}
          toggle={this.toggle}
          className={this.props.className}
        >
                    <ModalHeader toggle={this.toggle}>
                        <div className="align-content-center align-items-center align-self-center text-center">
              {" " +
                this.state.user.name +
                " " +
                this.state.user.email }
                        </div>
                    </ModalHeader>
                    <ModalBody>
            <form onSubmit={(e) => this.candidateOnboard(e)}>
              <div>
                <p className="card-title text-center">Employee Onboard</p>
                <div className="mt-2">
                  <label htmlFor="inputEmail">Official email</label>
                  <input
                    type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    required
                    onChange={this.updateOfficialEmail}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="employmentStatus">Employment Status</label>
                 
                  <select
                    className="custom-select"
                    id="employmentStatus"
                    placeholder="Select employment status"
                    onChange={this.updateEmploymentStatus}
                  ><option value="Employment Status">Employment Status</option>
                    <option value="probation">PROBATION</option>
                    <option value="intern">INTERN</option>
                    <option value="internal-employee">INTERNAL-EMPLOYEE</option>
                    <option value="external-employee">EXTERNAL-EMPLOYEE</option>
                    <option value="rejected">REJECTED</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label htmlFor="inputDate">Joining Date</label>
                  <input
                    type="Date"
                    id="inputDate"
                    className="form-control"
                    placeholder="actionDate"
                    required
                    onChange={this.updateJoiningDate}
                    autoComplete="on"
                  />
                </div>

                <div className="mt-2">
                  <label htmlFor="inputDesignation">Designation</label>
                  <input
                    type="text"
                    id="inputDesignation"
                    className="form-control"
                    placeholder="Designation"
                    required
                    onChange={this.updateDesignation}
                    autoComplete="on"
                  />
                </div>
               
                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          </ModalBody>
                </Modal>
                <Modal
          isOpen={this.state.showModalLeaveUpdate}
          toggle={this.toggleLeaveUpdate}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleLeaveUpdate}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " +
                this.state.user.name +
                "  " +
                this.state.user.email}
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.leaveUpdate(e)}>
              <div>
                <p className="card-title text-center">Apply Leave</p>
                <div className="mt-2">
                  <label htmlFor="employmentStatus">Leave Type</label>
                 
                  <select
                    className="custom-select"
                    id="employmentStatus"
                    placeholder="Select employment status"
                    onChange={this.updateLeaveType}
                  ><option value="Leave Type">Leave Type</option>
                    <option value="sickLeave">Sick Leave</option>
                    <option value="vacationLeave">vacation Leave</option>
                    <option value="lopLeave">Lop Leave</option>
                    <option value="paternityLeave">Paternity Leave</option>
                    <option value="maternityLeave">Maternity Leave</option>
                  </select>
                </div>
                   
                <div className="mt-2">
                  <label htmlFor="inputLeaveFrom">Leave From</label>
                  <input
                    type="Date"
                    id="inputLeaveFrom"
                    className="form-control"
                    placeholder="Leave from"
                    required
                    onChange={this.updateLeaveFrom}
                    autoComplete="on"
                  />
                </div>
                   
                <div className="mt-2">
                  <label htmlFor="inputLeaveTo">Leave To</label>
                  <input
                    type="Date"
                    id="inputLeaveTo"
                    className="form-control"
                    placeholder="Leave To"
                    required
                    onChange={this.updateLeaveTo}
                    autoComplete="on"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="inputNoOfLeave">No of Days</label>
                  <input
                    type="number"
                    id="inputNoOfDays"
                    className="form-control"
                    placeholder="No of leaves"
                    required
                    onChange={this.updateNoOfLeaveDay}
                    autoComplete="on"
                  />
                  </div>
                <div className="mt-2">
                  <label htmlFor="inputLeave">Leave summary</label>
                  <input
                    type="text"
                    id="inputLeave"
                    className="form-control"
                    placeholder="Leave reason"
                    required
                    onChange={this.updateLeaveSummary}
                    autoComplete="on"
                  />
                </div>
                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
          </ModalBody>
                </Modal>
                <Modal
          isOpen={this.state.showModalLevelUpdate}
          toggle={this.toggleLevelUpdate}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleLevelUpdate}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " +
                this.state.user.name +
                " " +
                this.state.user.email}
            </div>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={(e) => this.performanceUpdate(e)}>
              <div>
                <p className="card-title text-center">Performance Update</p>
               
                <div className="mt-2">
                  <label htmlFor="inputPerformanceLevel">Probation Level</label>
                 
                  <select
                    className="custom-select"
                    id="inputPerformanceLevel"
                    placeholder="Select probation level"
                    onChange={this.updatePerformanceLevel}
                  ><option value="Employment Status">Probation Level</option>
                   <option value="danger">danger</option>
                    <option value="secondary">secondary</option>
                    <option value="info">info</option>
                    <option value="dark">dark</option>
                    <option value="primary">primary</option>
                    <option value="success">success</option>
                   
                    
                  </select>
                </div>
                <div className="mt-2">
                  <label htmlFor="inputComments">Current Level comments</label>
                  <input
                    type="text"
                    id="inputComments"
                    className="form-control"
                    placeholder="Performance Comments"
                    required
                    onChange={this.updateComments}
                    autoComplete="on"
                  />
                </div>
                <br></br>
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase mb-2"
                  type="submit"
                >
                  Action
                </button>
              </div>
            </form>
                    </ModalBody>
                </Modal>
        <Modal
          size="lg" style={{maxWidth: '900px', width: '100%'}}
          isOpen={this.state.showModalProfileData}
          toggle={this.toggleViewProfile}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleViewProfile}>
            <div className="align-content-center align-items-center align-self-center text-center">
              {" " + this.state.user.name + " " + this.state.user.email}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <p className="card-title text-center">Employee Data</p>
              <div className="row">
              <div className="col-7">
                  <table className="table table-hover">
                    <thead>
                      <h1>
                        <span className="text-muted" style={{ fontSize: 11 }}>
                          Employment Data
                        </span>
                      </h1>
                    </thead>
                    <tbody>
                      <tr scope="row">
                        <th className={"w-25"}> Start Date</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.performanceEvaluation?this.state.performanceEvaluation.probationStartDate:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}> Next evaluation date</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.performanceEvaluation?this.state.performanceEvaluation.nextEvaluationDate:"NA"}
                        </th>{" "}
                      </tr>

                      <tr scope="row">
                        <th className={"w-25"}> End Date</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.performanceEvaluation?this.state.performanceEvaluation.probationEndDate:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}> Remaining Days </th>
                        <th className={"w-25"}>
                          {" "}
                          {
                            this.state.performanceEvaluation?this.state.performanceEvaluation
                              .remainingProbationDays:"NA"
                          }
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}> Performance Rating</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.performanceEvaluation?this.state.performanceEvaluation.performanceRating:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}> Official Email</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.employment.employeeOfficialEmailId}
                        </th>{" "}
                      </tr>

                      <tr scope="row">
                        <th className={"w-25"}>Employee Id</th>
                        <th className={"w-25"}> {this.state.employment.employeeId}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-7">
                  {" "}
                  <table className="table table-hover">
                    <thead>
                      <h1>
                        <span className="text-muted" style={{ fontSize: 11 }}>
                          Personal Info
                        </span>
                      </h1>
                    </thead>
                    <tbody>
                      <tr scope="row">
                        <th className={"w-25"}>PanCard</th>
                        <th className={"w-25"}> {this.state.personalInfo?this.state.personalInfo.pan:"NA"}</th>{" "}
                      </tr>{" "}
                      <tr scope="row">
                        <th className={"w-25"}>Emergency Contact No</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.personalInfo?this.state.personalInfo.emergencyContactNo:"NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Mobile</th>
                        <th className={"w-25"}> {this.state.personalInfo.phoneNo}</th>
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Date Of Birth</th>
                        <th className={"w-25"}> {this.state.personalInfo.dob}</th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Permanent Address </th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.personalInfo.permanentAddress}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Present Address</th>
                        <th className={"w-25"}> {this.state.personalInfo.presentAddress}</th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Gender</th>
                        <th className={"w-25"}> {this.state.personalInfo.gender}</th>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-7">
                  {" "}
                  <table className="table table-hover">
                    <thead>
                      <h1>
                        <span className="text-muted" style={{ fontSize: 11 }}>
                          Leave Data
                        </span>
                      </h1>
                    </thead>
                    <tbody>
                      <tr scope="row">
                        <th className={"w-25"}>Remaining Sick Leaves</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.employeeLeaveData
                            ? this.state.employeeLeaveData.sickLeavesAvailable
                            : "NA"}
                        </th>{" "}
                      </tr>{" "}
                      <tr scope="row">
                        <th className={"w-25"}>Availed Sick Leaves</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.employeeLeaveData
                            ? this.state.employeeLeaveData.availedSickLeave
                            : "NA"}
                        </th>{" "}
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Remaining Vacation Leaves</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.employeeLeaveData
                            ? this.state.employeeLeaveData
                                .vacationLeavesAvailable
                            : "NA"}
                        </th>
                      </tr>
                      <tr scope="row">
                        <th className={"w-25"}>Availed Vacation Leaves</th>
                        <th className={"w-25"}>
                          {" "}
                          {this.state.employeeLeaveData
                            ? this.state.employeeLeaveData.availedVacationLeave
                            : "NA"}
                        </th>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
            </tr>
        );
    }
}

export default withRouter(InternalGridLine)