import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { COOKIE_PREF } from "../../../config/Config";

import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Team from "./Team";
import Slider from "./Slider";
import ContentManagement from "./ContentManagement";

class Home extends Component {
  state = {
    showCookieModal: false,
    showChooseCookieModal: false,
    cookieRequest: {
      socialMedia: false,
      essential: false,
      commercial: false,
      fullCookie: false,
    },
  };
  constructor(props) {
    super(props);
    this.showCookieModal = this.showCookieModal.bind(this);
    this.showChooseCookieModal = this.showChooseCookieModal.bind(this);
    this.socialMediaCookie = this.socialMediaCookie.bind(this);
    this.essentialSaveCookie = this.essentialSaveCookie.bind(this);
    this.commercialCookie = this.commercialCookie.bind(this);

    this.saveCustomizeCookie = this.saveCustomizeCookie.bind(this);

    this.saveFullCookie = this.saveFullCookie.bind(this);
    this.saveEssentialCookie = this.saveEssentialCookie.bind(this);
  }
  componentDidMount() {
    document.title = "Home page";
    if (!localStorage.getItem(COOKIE_PREF)) {
      this.setState({ showCookieModal: true });
    }
  }
  saveCustomizeCookie = (event) => {
    let req = this.state.cookieRequest;

    this.setState({ cookieRequest: req });
    localStorage.setItem(COOKIE_PREF, cookieRequest);
  };
  socialMediaCookie = (event) => {
    let req = this.state.cookieRequest;
    req.socialMedia = !req.socialMedia;
    this.setState({ cookieRequest: req });
  };
  commercialCookie = (event) => {
    let req = this.state.cookieRequest;
    req.commercial = !req.commercial;
    this.setState({ cookieRequest: req });
  };
  essentialSaveCookie = (event) => {
    let req = this.state.cookieRequest;
    req.essential = !req.essential;
    this.setState({ cookieRequest: req });
  };
  saveEssentialCookie = (event) => {
    let req = this.state.cookieRequest;
    req.essentialCookie = !req.essentialCookie;
    this.setState({ cookieRequest: req });
    localStorage.setItem(COOKIE_PREF, this.state.cookieRequest);
    this.setState({ showChooseCookieModal: !this.state.showChooseCookieModal });
  };

  saveFullCookie = (event) => {
    let req = this.state.cookieRequest;
    req.fullCookie = !req.fullCookie;
    this.setState({ cookieRequest: req });
    localStorage.setItem(COOKIE_PREF, this.state.cookieRequest);
    this.setState({ showCookieModal: !this.state.showCookieModal });
  };
  componentDidUpdate() {
    if (this.props.notFound === true) {
      this.props.showAlert("Page not found ! Back to the home page.", "error");
    }
  }
  showCookieModal() {
    this.setState({ showCookieModal: !this.state.showCookieModal });
  }
  showChooseCookieModal() {
    this.setState({ showChooseCookieModal: !this.state.showChooseCookieModal });
    this.setState({ showCookieModal: !this.state.showCookieModal });
  }
  goToContact() {
    this.props.history.push("/contact");
  }
  render() {
    return (
      /** <Modal isOpen={this.state.showCookieModal}
          toggle={this.showCookieModal}>
          <ModalHeader toggle={this.showCookieModal}  >Cookies Policy</ModalHeader>
          <ModalBody>
            <p>
              <strong>
                Real cookies are the best, we know it. But the digital kind help us offer you a tailor-made experience on our website and
                on partner website.</strong>
            </p>
            <p>
              <strong> To get YaazhTech's full experience ,tap 'Accept all'.To only keep the cookies that are strictly necessary to use our website ,tap 'Essential cookies only '. You can also choose
                the types ok cookies you want by tapping 'Customize'.</strong>
            </p>
            <a style={{ color: '#0B77C2' }} onClick={this.showChooseCookieModal}>
              Customize </a>
          </ModalBody>
          <ModalFooter>

            <Button color="secondary" type="submit" value="essentitalCookie" onClick={this.saveEssentialCookie} isOpen={this.state.showCookieModal} toggle={this.showCookieModal}
            >Essential Cookies only</Button>
            <Button color="secondary" type="submit" value="fullCookie" onClick={this.saveFullCookie} isOpen={this.state.showCookieModal} toggle={this.showCookieModal}
            >Accept all</Button>

          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showChooseCookieModal}
          toggle={this.showChooseCookieModal} >
          <ModalHeader toggle={this.showChooseCookieModal}>Choose Your Cookies</ModalHeader>
          <ModalBody>
            <p>
              <p><strong>YaazhTech respect your privacy . That's why YaazhTech ask you for your preferences regarding the use of cookies and similar technologies (such as pixels)</strong> </p>
              <hr />
              <div
                className="mb-4 mt-4 custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" className="custom-control-input"
                  id="name" value="socialMedia"
                  onChange={this.socialMediaCookie}
                />
                <label className="custom-control-label"
                  htmlFor="choice">
                  Commercial cookies on YaazhTech and marketing cookies on your social media and third-party websites
                </label>
              </div>
              <p> Commercial and marketing cookies allow us to show you more relevant ads and content on YaazhTech, on you social media and on third-party websites .This is based , for example , on your online behaviour and/or on other client data. As such ,you will be shown content and ads that are more relevant for you.</p>
              <p>Example: on YaazhTech, you do researching the Yaazhtech Lion Assistance travel insurance.If you do not buy it, you may see an ad next time you browse on social media that higlights the features of this insurance.   </p>
              <p>Or: you have just taken out a loan on your new car. You will then no longer receive ads on third-party websites regarding this products.Refusing marketing and Commerical cookies doses not mean that you will no longer receive ads form YaazhTech while browsing .It simply means that those ads will be less suited to your interests.</p>
              <hr />
              <div
                className="mb-4 mt-4 custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" className="custom-control-input"
                  id="name" value="commercial"
                  onChange={this.commericalCookie}
                />
                <label className="custom-control-label"
                  htmlFor="choice">
                  Commerical cookies on YaazhTech
                </label>
              </div>
              <p>Do you want to improve your experience on YaazhTech? then accept commerical cookies: they allow us to show you more relevant content on YaazhTech based , for example, on your website behaviour and/or other customer data</p>
              <p>Example:you stopped the application process online to get a credit card. During your next visit on YaazhTech, we'll invite you to resume the process.Or:if you do not have a car insurancewith YaazhTech yet, then  a message on this matter could be displayed on YaazhTech</p>
              <hr />
              <div
                className="mb-4 mt-4 custom-control custom-checkbox mr-sm-2">
                <input type="checkbox" className="custom-control-input"
                  id="name" value="essential"
                  onChange={this.essentialSaveCookie}
                />
                <label className="custom-control-label"
                  htmlFor="choice">
                  Essential cookies
                </label>
              </div>
              <p>Essential cookie are mandatory for the website to function properly. They save and use web local Storage, for example, your language perferences or automatically fill in forms. </p>
            </p>


          </ModalBody>
          <ModalFooter>
            <form onSubmit={this.saveCustomiseCookie}>
              <a style={{ color: '#0B77C2' }} onClick={this.showCookieModal}>
                Back </a>
              <Button color="secondary" type="submit"
              >Save my choice</Button>
            </form>
          </ModalFooter>
        </Modal> */

      <>
        <div class="container py-md-5">
          <div class="row">
            <div class="col-lg-6">
              <div class="form">
                <h1 className="text-muted" style={{ fontSize: 35 }}>
                  The fastest way to hire great-fit remote developers at
                  flexible contract.
                </h1>
                <br></br>
                <p class="subtitle text-muted" style={{ fontWeight: 300 }}>
                  Build your dream team with handpicked developers from
                  Yaazhtech, as{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    immediate joiners.{" "}
                  </span>
                </p>
                <br></br>
                <div class="cta-wrapper">
                  <Link
                    to="/hireSubscriptionPricing"
                    class="btn btn btn-primary btn-hover-arrow-right"
                  >
                    Get Started
                  </Link>
                </div>
                <br></br>
                <h1
                  className="text-muted"
                  style={{ fontSize: 12, fontWeight: "bold" }}
                >
                  We have experts in following technology and services:
                </h1>

                <ul
                  className="mt-2 text-muted"
                  style={{ fontSize: 12, listStyle: "none", fontWeight: 200 }}
                >
                  <li>
                    <span style={{ fontWeight: "bold" }}>✓</span> FlexPLM PTC
                    products.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>✓</span>{" "}
                    Java-Springboot micro services.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>✓</span>{" "}
                    Javascript-ReactJs SPA reactive pages.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>✓</span> Mainframe to
                    Spring-boot micro services modernization.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>✓</span> IAM, SSO
                    (SailPoint, Ping federate, FIM, KeyCloak, OpenID).
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>✓</span> Camunda BPMN
                    with micro service orchestration.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>✓</span> AWS solution
                    architects.
                  </li>
                  <li>
                    <span style={{ fontWeight: "bold" }}>✓</span> API test
                    engineers.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <Slider></Slider>
            </div>
          </div>
        </div>

        <header>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6696823685578914"
            crossorigin="anonymous"
          ></script>
          {/*<div class="container">  
                <div class="masthead-heading text-uppercase">Start online business for free.</div>
                <div style={{ color: '#fff' }} >Life time free Mobile friendly web apps!!</div>
                <Link to="/devSubscriptionPricing" class="btn btn-primary btn-xl text-uppercase" >Get Started</Link>
            </div>*/}
        </header>

        <div className="container">
          <Services></Services>
        </div>
        <div className="container">
          <About></About>
        </div>
        <div className="container">
          <Team></Team>
        </div>
        <div className="container">
          <Contact></Contact>
        </div>
        <div className="container">
          <ContentManagement></ContentManagement>
        </div>

        {/*<section class="page-section" id="yaazhtechservices">
        <div className="container">
          <div>
            <br></br>
            <div>
              <div>
                <div>
                  <h1 > {greeting.title} </h1>
                  <p>
                    <br></br>
                    {greeting.subTitle1}
                    {greeting.subTitle}
                  </p>

                  <div>
                    <Button text="Contact us" href="/contact" />
                  </div>
                </div>
              </div>
              <div>
                {illustration.animated ? (
                  <DisplayLottie animationData={landingPerson} />
                ) : (
                  <img
                    alt="man sitting on table"
                    src={require("../../../assets/images/404-1.jpeg")}
                  ></img>
                )}
                <hr></hr>
              </div>
            </div>
          </div>
          <div>
            <Business></Business>
          </div>
        </div>
        </section>
        <section class="page-section" id="yaazhtechbusinessservices">
          <div>
            <Business></Business>
          </div>
        </section>
        <section class="page-section" id="webapps">
          <div>
            <Business></Business>
          </div>
        </section>*/}
      </>
    );
  }
}

export default withRouter(Home);
