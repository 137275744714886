import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getPageUsers } from "../../../../api/AdminApi";
import UserGrid from "./UserGrid";
import AppPagination from "../../../../generic/pagination/AppPagination";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      page: {},
      currentPage: 0,
      totalNumberOfElements: 0,
      pageSize: 0,
      loading: true,
      searchText: null,
      category: "applicant",
    };
    this.updateCategory = this.updateCategory.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
    this.changeCurrentPage = this.changeCurrentPage.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setStatePromise = this.setStatePromise.bind(this);
  }

  componentDidMount() {
    document.title = "Users management";
    this.loadData(0);
  }

  componentDidUpdate() {
    this.render();
  }

  setStatePromise(newState) {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  changeCurrentPage(currentPage) {
    this.setState({
      loading: true,
      currentPage,
    });
  }

  loadData(page) {

    this.setState({ loading: true });
    getPageUsers(page, this.state.category, this.state.searchText)
      .then((res) => {
        let content = [];
        let user = {
          avatar: {},
          username: "",
          email: "",
          accountCreationDate: "",
          roles: [],
          usingTwoFA: false,
        };

        for (let i = 0; i < res.data.content.length; i++) {
          user = res.data.content[i];
          user.avatar = (
            <img
              src={"http://cravatar.eu/helmavatar/" + user.username + "/32.png"}
              alt={user.username}
              className="img-fluid"
            />
          );
          content.push(user);
        }

        this.setStatePromise({
          content: content,
          page: res.data,
          currentPage: res.data.pageNumber,
          totalNumberOfElements: res.data.totalNumberOfElements,
          pageSize: res.data.pageSize,
          loading: false,
        }).then(() => {
          this.forceUpdate();
        });
        this.setState({ searchText: null });
      })
      .catch((error) => {
        if (error.message && error.success === false) {
          this.props.showAlert(error.message, "error");
        } else {
          this.props.showAlert(
            "Sorry! Something went wrong. Please try again!",
            "error"
          );
        }
        this.setState({ loading: false });
        console.log(error);
      });
  }
  updateSearchText = (event) => {
    this.setState({ searchText: event.target.value });
  };
  updateCategory = (event) => {
    this.setState({ category: event.target.value });
  };
  
  render() {
    const {
      content,
      pageSize,
      currentPage,
      totalNumberOfElements,
      loading,
      page,category,
    } = this.state;

    if (loading) {
      return (
        <div className="align-content-center text-center">
          <h4 className="text-muted">Loading. Please Wait...</h4>
          <i className="material-icons w3-xxxlarge w3-spin align-content-center">
            refresh
          </i>
        </div>
      );
    } else {
      return (
        <div>
          <h1>
            Users{" "}
            <span className="text-muted" style={{ fontSize: 11 }}>
              management
            </span>{" "}
          </h1>
          <hr />
          <form  onSubmit={(e) => this.loadData(e)}>
            <select
              className="w3-select"
              name="option"
              value={category}
              onChange={(e) => this.updateCategory(e)}
            >
              <option value="">Search category</option>
              <option value="applicant">Applicant</option>
              <option value="intern">Intern</option>
              <option value="probation">Probation</option>
              <option value="internal-employee">Internal Employee</option>
              <option value="external-employee">External Employee</option>
              <option value="interview-backend">Interview backend</option>
              <option value="interview-frontend">Interview frontend</option>
              <option value="interview-fullstack">Interview fullstack</option>
            </select>
            <br></br>

            <div>         
              <input
                type="text"
                id="inputSearchText"
                className="form-control"
                placeholder="Search Text"
                onChange={this.updateSearchText}
                autoComplete="on"
              />
              <button type="submit" className="btn btn-success btn-lg mt-4">
                Search
              </button>
            </div>
          </form>
          <UserGrid showAlert={this.props.showAlert} content={content} />
          <AppPagination
            showAlert={this.props.showAlert}
            page={page}
            pageSize={pageSize}
            currentPage={currentPage}
            totalNumberOfElements={totalNumberOfElements}
            handleChangePage={this.loadData}
          />
        </div>
      );
    }
  }
}

export default withRouter(Users);
