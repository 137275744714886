import {ACCESS_TOKEN, config} from "../config/Config";
import axios from 'axios';
const sendRequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(
            response =>
                response.json()
                    .then(
                        json => {
                            if (!response.ok) {
                                return Promise.reject(json)
                            }
                            return json;
                        }
                    )
        )
};
// -- Axios

const instance = axios.create({
    baseURL: config.url.API_BASE_URL_ACTUATOR,
  });

  const appInstance = axios.create({
    baseURL: config.url.API_BASE_URL,
  });

export function checkHealth() {
    return instance.get('/health', {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}
export function getHttpTraces() {
    return instance.get('/httptrace', {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}
export function getApiUptime() {
    return instance.get('/metrics/process.uptime', {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}

export function getJvmMemUsed() {
    return instance.get('/metrics/jvm.memory.used', {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}

export function getJvmMemMax() {
    return instance.get('/metrics/jvm.memory.max', {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}

export function getCpuUsage() {
    return instance.get('/metrics/system.cpu.usage', {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}

export function getCpuCount() {
    return instance.get('/metrics/system.cpu.count', {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
}



export function getPageUsers(pageNumber,category,searchText){
    
    
   if(!searchText){
    if(pageNumber!==undefined && pageNumber!==null && !isNaN(+pageNumber)){
        return appInstance.get("/secure/admin/dashboard/users/"+category+"?page=" + pageNumber, {
            headers: { "Content-type": "application/json",
              "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
          })
       
    } else {
        return appInstance.get("/secure/admin/dashboard/users/"+category+"?page=0", {
            headers: { "Content-type": "application/json",
              "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
          })
     
    }}
    else{ return appInstance.get("/secure/admin/dashboard/users/"+category+"?page=0&text=" + searchText, {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
    
          
       
        
    }

}

export function getStatistics(){
    return appInstance.get("/secure/admin/dashboard/statistics", {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
    
    
}


export function requestChangeRole(email,role) {
    return sendRequest({
        url: config.url.API_BASE_URL + "/secure/admin/roleChange/"+email+"/"+role,
        method: 'POST'
    });
}


  export function deleteEndPoint(removeEndPoint,category){
    return appInstance.delete("/secure/admin/deleteContent/"+removeEndPoint+"/"+category, {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })
    }
      export function documentGeneration(offerLetterRequest) {
        return appInstance.post('/secure/admin/offerLetterGeneration', offerLetterRequest, {
            headers: { "Content-type": "application/json",
              "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
          })
    
}
export function experienceLetterGeneration(exitEmployeeDetails) {
    return appInstance.post('/api/org/generateExperienceLetter', exitEmployeeDetails, {
        headers: { "Content-type": "application/json",
          "Authorization":localStorage.getItem(ACCESS_TOKEN)?"Bearer " +localStorage.getItem(ACCESS_TOKEN):null }
      })

}