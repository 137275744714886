import React, { Component } from "react";
import { withRouter,NavLink } from "react-router-dom";
import { payViaGateWayBackend } from "../../../api/commonApi";
import { FINANCIER_TOKEN,transactionTrackingId,delimiter} from "../../../config/Config";
class Banking extends Component {
  
  constructor(props) {
    super(props);
    console.log(this.props.location.paymentProps.payCost);
    this.state = {
      xVerify:'',
      payCost: '',
      payUsername: '',
      paySubscriptionType: '',
      payCost: '',
      
    
      transactionRequest: {
        subscriptionId: "",
        paymentId: "",
        amount: "",
        email:""
      },
      showLoading: false,

    };
    this.updatePaymentInfo = this.updatePaymentInfo.bind(this);
  }

  componentDidMount() {
    document.title = "My banking data settings";
    this.updatePaymentInfo();
  }

  updatePaymentInfo = () => {
    this.setState({ showLoading: true });
    let req = this.state;
    req.transactionRequest.subscriptionId=this.props.location.paymentProps.subscriptionId;
    req.transactionRequest.paymentId=this.props.location.paymentProps.paymentId;
    req.transactionRequest.amount=this.props.location.paymentProps.payCost;
    req.transactionRequest.email=this.props.location.paymentProps.emailId;
    alert(this.props.location.paymentProps.emailId);
    this.setState({ req });
  }


  payAmount = (event) => {
    let req = this.state.transactionRequest;
    req.amount = event.target.value;
    this.setState({ transactionRequest: req });
  };

 

  
  paymentGateWay = (event) => {
    event.preventDefault();
    this.setState({ showLoading: true });
    
    const transactionRequest = this.state.transactionRequest;
    localStorage.setItem(transactionTrackingId,transactionRequest.subscriptionId+delimiter+transactionRequest.paymentId+delimiter+transactionRequest.email);
    
    payViaGateWayBackend(transactionRequest).then((response) => {
      let res = response.data;
       let data=res.data;
       const redirectInfo=data.data.instrumentResponse.redirectInfo;
       window.location.replace(redirectInfo.url);
       this.setState({ showLoading: false });
     })
     .catch((error) => {
       this.props.showAlert(error);
       this.setState({ showLoading: false });
     });
  

  
  };

  render() {
    const { payCost, payUsername, paySubscriptiontype } = this.state;
    return (
      <>
        <br></br>
        <br></br>
        <div class="container">
        <div class="text-center">
            <h3 class="section-subheading text-muted">Yaazhtech Payment Details
            <br/></h3>
          </div>
          <div className="row">
            <div className="col-md-6">
              <br/> <br/> <br/>
              <h5 className="section-subheading text-muted">Payee Name : {payUsername} </h5>
              <h5 className="section-subheading text-muted">Plan Cost: {payCost} </h5>
              <h5 className="section-subheading text-muted">Subscription Type: {paySubscriptiontype} </h5>
              
            </div>
            <div className="col-md-6">
            
              <h5 className="mb-3"><img
                  src={require("../../../assets/images/phoneph.png")}
                  class="rounded-circle ml-2"
                />Pay via payment gateway</h5>
              <form onSubmit={this.paymentGateWay}>
                <div className="form-group">
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    id="amount"
                    name="amount"
                    placeholder="Amount"
                    required
                    autoComplete="on"
                    value={this.props.location.paymentProps.payCost}
                    onChange={this.payAmount}
                  />
                </div>

                <button class="btn btn-md  btn-block btn-primary w-50 mx-auto">
                  Pay
                </button>
                {localStorage.getItem(FINANCIER_TOKEN)?(<NavLink
                  to={{
                    pathname: "/financier/dashboard/clients",
                  }}
                  exact
                >
                  <button type="button" class="btn btn-md  btn-block btn-dark w-50 mx-auto">
                    Back
                  </button>
                </NavLink>):(<NavLink
                  to={{
                    pathname: "/client/dashboard/payment",
                  }}
                  exact
                >
                  <button type="button" class="btn btn-md  btn-block btn-dark w-50 mx-auto">
                    Back
                  </button>
                </NavLink>)}
                
                
                
              </form>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </>
    );
  }
}

export default withRouter(Banking);
