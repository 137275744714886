import React, { Component } from "react";
import { withRouter } from "react-router-dom";


class VacationLeave extends Component {
    render() {
        return (
            <>
                <h3 className={"pages-mainheader-text"}> Vacation Leave </h3>
                <label></label>
                <p className={"pages-content-text"} ><strong>To provide an opportunity to Employees for time off, family vacation, rest and recuperation.</strong></p>
                <p ></p>
                <label></label>
                <p>
                    <li className={"pages-content-text"}>An Employee is eligible for 18 calendar working days of leaves in an Accounting year as in the ratio of 1.5days per month.</li>
                    <li className={"pages-content-text"} >Intervening holidays like weekends and public holidays falling within the leave period will not be taken into account.</li>
                    <li className={"pages-content-text"} >This leave will be fully paid by the Company.</li>
                    <li className={"pages-content-text"} >These leaves are should be planned and reported priorly to manager in advance of atleast one month.</li>
                    <li className={"pages-content-text"} >Leaves will be fully credited into Employee account at start of the Accounting year.</li>
                    <li className={"pages-content-text"} >Unused leaves are lapsed on end of financial year december.</li>
                    <li className={"pages-content-text"} >There is no carryforward or encashment for the unused leaves.</li>

                    
                </p>
                
            </>
        )

    }
          
        

    
}
export default withRouter(VacationLeave);