import React, { Component } from "react";
import { withRouter } from "react-router-dom";


class TimeSheetGridLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeSheet: props.timeSheet,
    
    };
   
  }

  static getDerivedStateFromProps(props, state) {
    const timeSheet = props.timeSheet;
    state.timeSheet = timeSheet;
   
    
    return state;
  }

 
 
  
  render() {
 

    return (
      <tr>
       <th scope="row" style={{width: "25%"}}><small>{JSON.stringify(this.state.timeSheet)}</small></th>  
      </tr>
    );
  }
}

export default withRouter(TimeSheetGridLine);
